package bootstrap


import csstype.ClassName
import react.*

@JsNonModule
@JsModule("react-bootstrap/NavLink")
private external val navLinkModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bNavLink: ComponentType<NavLinkProps> = navLinkModule.default

external interface NavLinkProps : PropsWithChildren {
    var active: Boolean
    var `as`: Any
    var disabled: Boolean
    var eventKey: Any? /* string | number */
    var href: String
    var role: String
    var bsPrefix: String
    var className: ClassName
    var id: String
}
