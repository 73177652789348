package bootstrap

import react.ComponentType
import org.w3c.dom.events.Event
import react.PropsWithChildren

@JsNonModule
@JsModule("react-bootstrap/AccordionHeader")
private external val accordionHeaderModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bAccordionHeader: ComponentType<AccordionHeaderProps> = accordionHeaderModule.default

external interface AccordionHeaderProps : PropsWithChildren {
    var `as`: Any // Set a custom element for this component
    var bsPrefix: String // Change the underlying component CSS base class name and modifier class names prefix.

    var onClick: ((event: Event) -> Unit) // Click handler for the AccordionButton element
}
