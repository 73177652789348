package della8.web.items

import bootstrap.bCard
import bootstrap.bContainer
import bootstrap.bFormControlFeedback
import bootstrap.bRow
import della8.core.items.RuleItem
import della8.core.screens.RuleValue
import della8.core.support.DesignSystem
import della8.core.support.EditableOwnership
import della8.web.components.*
import della8.web.support.className
import della8.web.support.useAsyncEffect
import della8.web.theme.Della8Colors
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import react.useState
import techla.base.*

external interface RuleItemOwnershipProps : PropsWithChildren {
    var className: String
    var viewModel: RuleItem.ViewModel.Ownership
    var onChange: (Identifier<RuleItem>, RuleValue) -> Unit
    var onInfo: (String, Boolean) -> Unit
}


val D8RuleItemOwnership = FC<RuleItemOwnershipProps> { props ->
    val (firstName, setFirstName) = useState("")
    val (lastName, setLastName) = useState("")
    val (govId, setGovId) = useState("")
    val (share, setShare) = useState<Pair<String, Int?>>("" to 0)
    val (reRender, setReRender) = useState(false)

    fun onStyle(value: DesignSystem.Option) {
        (props.viewModel.value as? RuleValue.OwnershipValue)?.let { ownershipValue ->
            (value.data as? EditableOwnership.Style)?.let {
                props.onChange(props.viewModel.id, RuleValue.OwnershipValue(ownershipValue.value.copy(style = it)))
            }
        }
    }

    fun onDelete(item: DesignSystem.ListItem) {
        (props.viewModel.value as? RuleValue.OwnershipValue)?.let { ownershipValue ->
            if (ownershipValue.value.style == EditableOwnership.Style.SHARES) {
                val shares = ownershipValue.value.shares.filterIndexed { index, _ -> index != item.index }
                props.onChange(props.viewModel.id, RuleValue.OwnershipValue(ownershipValue.value.copy(shares = shares)))
            }
        }
    }

    fun onAdd() {
        (props.viewModel.value as? RuleValue.OwnershipValue)?.let { ownershipValue ->
            if (ownershipValue.value.style == EditableOwnership.Style.SHARES) {
                val shares = ownershipValue.value.shares + tupleOf(firstName, lastName, govId, share)
                setReRender(true)
                props.onChange(props.viewModel.id, RuleValue.OwnershipValue(ownershipValue.value.copy(shares = shares)))
            }
        }
    }

    useAsyncEffect(reRender) {
        if (reRender) {
            setFirstName("")
            setLastName("")
            setGovId("")
            setShare("" to 0)
            setReRender(false)
        }
    }

    fun onOther(value: String) {
        (props.viewModel.value as? RuleValue.OwnershipValue)?.let { ownershipValue ->
            props.onChange(props.viewModel.id, RuleValue.OwnershipValue(ownershipValue.value.copy(description = value)))
        }
    }

    bCard {
        className = className("radius-10 border-0 card-shadow my-3 px-4 py-3")
        bContainer {
            className = className("p-0")
            D8RuleItemHeader {
                viewModel = props.viewModel
                onChange = props.onChange
                onInfo = props.onInfo
            }
            if (props.viewModel.value !is RuleValue.Off) {
                div {
                    className = className("flex-row")
                    if (props.viewModel.editable) {
                        bRow {
                            className = className("mb-3")
                            d8SelectInput(classNameInput = "d-inline mb-2", design = props.viewModel.style, onClick = ::onStyle)
                        }

                        if (!reRender && (props.viewModel.firstName.visible || props.viewModel.lastName.visible || props.viewModel.govId.visible || props.viewModel.share.visible)) {
                            bRow {
                                className = className("row-cols-1 row-cols-md-2 row-cols-lg-2 mb-0 mb-md-1")
                                div {
                                    className = className("pt-2")
                                    d8TextInput(
                                        design = props.viewModel.firstName,
                                        overrideValue = firstName,
                                        onChange = { setFirstName(it) })
                                }
                                div {
                                    className = className("pt-2")
                                    d8TextInput(
                                        design = props.viewModel.lastName,
                                        overrideValue = lastName,
                                        onChange = { setLastName(it) })
                                }
                                div {
                                    className = className("pt-2")
                                    d8TextInput(
                                        design = props.viewModel.govId,
                                        overrideValue = govId,
                                        onChange = { setGovId(it) })
                                }
                                if (props.viewModel.share.visible) {
                                    div {
                                        className = className("d-flex align-items-end pt-2")
                                        d8TextInput(
                                            design = props.viewModel.share,
                                            overrideValueInt = share.second,
                                            onChangeInt = { setShare(it) })
                                    }
                                }
                            }
                            div {
                                className = className("d-flex flex-row mb-3 mt-2")
                                d8Button(className = "ms-auto", design = props.viewModel.add, onClick = ::onAdd)
                            }
                            d8ListView(className = "p-0", design = props.viewModel.list, onClick = ::onDelete)
                        }
                        div {
                            className = className("pt-2")
                            d8TextInput(design = props.viewModel.other, onChange = ::onOther)
                        }
                        props.viewModel.status.message?.let {
                            bFormControlFeedback {
                                type = it
                                className = className(Della8Colors.designColor(DesignSystem.Color.RUBY))
                                +it
                            }
                        }
                    } else {
                        d8ListView(className = "p-0", design = props.viewModel.list, onClick = ::onDelete)
                        d8Text(design = props.viewModel.show)
                    }
                }
            }
            D8RuleItemFooter {
                viewModel = props.viewModel
            }
        }
    }
}