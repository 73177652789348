package della8.core.screens

import della8.core.items.*
import della8.core.services.createTerm
import della8.core.services.editTerm
import della8.core.support.*
import techla.agreement.Agreement
import techla.agreement.Term
import techla.base.*

data class EditableInt(
    val value: Pair<String, Int?>,
) {
    companion object {
        fun fromTruth(truth: Int) =
            EditableInt(truth.toString() to truth)
    }

    val truth: Int
        get() =
            value.second ?: 0
}

sealed class RuleValue {
    object None : RuleValue()
    data class Off(val previous: RuleValue) : RuleValue()
    data class IntValue(val value: EditableInt) : RuleValue()
    data class StringValue(val value: String) : RuleValue()
    data class OptionValue(val value: DesignSystem.Option) : RuleValue()
    data class OptionsValue(val value: List<DesignSystem.Option>) : RuleValue()
    data class DoubleOptionsValue(val value: List<Pair<DesignSystem.Option, DesignSystem.Option>>) : RuleValue()
    data class OwnershipValue(val value: EditableOwnership) : RuleValue()
    data class FinancingValue(val value: EditableFinancing) : RuleValue()
    data class AddressValue(val value: EditableAddress) : RuleValue()
    data class ConditionsOfSaleValue(val value: EditableConditionsOfSale) : RuleValue()
    data class AdherenceValue(val value: EditableAdherence) : RuleValue()
    data class ParagraphValue(val value: EditableParagraph) : RuleValue()
}


object RulesScreen {
    data class Texts(
        val partnership: String,
        val partnershipTitle: String,
        val partnershipBody: String,
        val booking: String,
        val bookingTitle: String,
        val bookingBody: String,
        val save: String,
        val backToObject: String,
        val ruleOn: String,
        val ruleOff: String,
        val back: String,
        val loading: String,
        val saving: String,
        val saved: String,
        val previous: String,
        val next: String,
        val choose: String,
        val bodyChoose: String,
        val motorHome: String,
        val holidayHome: String,
        val toStart: String,
        val changeTitle: String,
        val changeBody: String,
        val edit: String,
        val duplicateHighValue: String,
        val warningHighValueFirstBiggerSecond: String,
        val noRules: String,
        override val progressInfo: String,
        override val failureTitle: String,
        override val successTitle: String,
        override val successInfo: String,
        override val successNext: String,
    ) : ProgressTexts, FailureTexts, SuccessTexts {
        companion object
    }

    enum class Mode { CREATE, EDIT, SHOW }

    data class State(
        val obj: Object = Object.None,
        val clauses: List<EditableClause> = emptyList(),
        val mode: Mode = Mode.SHOW,
        val page: Int = 0,
        val module: Module = Module.None,
    ) {
        val classification: Classification
            get() =
                clauses.classification?.clause?.classification ?: Classification.None
    }

    data class ReadyParams(
        val title: String,
        val body: String,
        val editable: Boolean,
        val items: List<RuleItem.ViewModel>,
        val leader: Boolean
    ) {
        fun update(items: List<RuleItem.ViewModel>) =
            copy(items = items)
    }

    sealed class ViewModel(open val texts: Texts, open val state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimal
        )

        data class Loading(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Ready(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val params: ReadyParams,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val body: DesignSystem.Text,
            val ruleOn: DesignSystem.Text,
            val ruleOff: DesignSystem.Text,
            val items: List<RuleItem.ViewModel>,
            val previous: DesignSystem.Button,
            val next: DesignSystem.Button,
            val start: DesignSystem.Button,
            val back: DesignSystem.Button,
            val modal: DesignSystem.Modal,
            val status: DesignSystem.Status,
            val pagination: DesignSystem.Text,
            val toStart: DesignSystem.Button,
            val changeTitle: DesignSystem.Text,
            val changeBody: DesignSystem.Text,
            val changeImage: DesignSystem.ImageView,
            val edit: DesignSystem.Button,
            val backToObject: DesignSystem.Button,
            val noRules: DesignSystem.Text,
            val noRulesImage: DesignSystem.ImageView,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Saving(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Saved(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val success: DesignSystem.Success,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Failed(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        fun loading(texts: Texts, state: State): ViewModel =
            Loading(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(
                    texts = object : ProgressTexts {
                        override val progressInfo get() = texts.loading
                    }
                ),
            )

        fun ready(state: State, params: ReadyParams, status: List<DesignSystem.Status> = emptyList()): ViewModel {
            val first = state.page == 0
            val next = if (state.page + 1 < pages(state)) texts.next else texts.save
            return Ready(
                texts = texts,
                state = state,
                params = params,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing),
                image = DesignSystem.ImageView(image = DesignSystem.Image.GAME_RULES, visible = first),
                title = DesignSystem.Text(text = params.title, style = DesignSystem.TextStyle.TITLE1, visible = true),
                body = DesignSystem.Text(text = params.body, style = DesignSystem.TextStyle.BODY, visible = first && params.editable, isMarkdown = true),
                ruleOn = DesignSystem.Text(text = texts.ruleOn, style = DesignSystem.TextStyle.SUBHEAD, image = DesignSystem.Image.RULE_ON_BLACK, iconAlignment = DesignSystem.IconAlignment.LEFT, visible = params.editable && !first),
                ruleOff = DesignSystem.Text(text = texts.ruleOff, style = DesignSystem.TextStyle.SUBHEAD, image = DesignSystem.Image.RULE_OFF_BLACK, iconAlignment = DesignSystem.IconAlignment.LEFT, visible = params.editable && !first),
                items = params.items,
                previous = DesignSystem.Button(title = texts.previous, style = DesignSystem.ButtonStyle.TERTIARY, visible = params.editable && !first),
                next = DesignSystem.Button(title = next, visible = params.editable && !first),
                start = DesignSystem.Button(title = next, visible = params.editable && first),
                modal = DesignSystem.Modal(firstButton = DesignSystem.Button(title = "OK", visible = false)),
                pagination = DesignSystem.Text(text = "${state.page}/${pages(state).minus(1)}", visible = !first),
                status = status.overallStatus(),
                changeTitle = DesignSystem.Text(text = texts.changeTitle, style = DesignSystem.TextStyle.TITLE2, visible = !params.editable && params.leader && first && state.obj.currentAgreement.status is Agreement.Status.Approved),
                changeBody = DesignSystem.Text(text = texts.changeBody, visible = !params.editable && params.leader && first && state.obj.currentAgreement.status is Agreement.Status.Approved),
                edit = DesignSystem.Button(title = texts.edit, visible = (!params.editable && params.leader && first && state.obj.currentAgreement.status !is Agreement.Status.Approved), style = DesignSystem.ButtonStyle.SECONDARY),
                changeImage = DesignSystem.ImageView(image = DesignSystem.Image.HIGHLIGHT, visible = !params.editable && params.leader && first && state.obj.currentAgreement.status is Agreement.Status.Approved),
                back = DesignSystem.Button(title = texts.backToObject, location = Location.overview(state.obj), visible = !params.editable && first && state.obj.currentAgreement.status is Agreement.Status.Approved),
                backToObject = DesignSystem.Button(title = texts.backToObject, location = Location.overview(state.obj), visible = !params.editable && first && state.obj.currentAgreement.status !is Agreement.Status.Approved),
                toStart = DesignSystem.Button(title = texts.toStart, location = Location.Landing, visible = (!params.editable && params.leader && first && state.obj.currentAgreement.status is Agreement.Status.Approved)),
                noRules = DesignSystem.Text(text = texts.noRules, visible = state.clauses.isEmpty() && state.mode == Mode.SHOW, alignment = DesignSystem.TextAlignment.CENTER, style = DesignSystem.TextStyle.SUBHEAD),
                noRulesImage = DesignSystem.ImageView(image = DesignSystem.Image.EMPTY_CONTRACT, alt = "Inga regler", visible = state.clauses.isEmpty() && state.mode == Mode.SHOW)
            )
        }

        fun saving(): ViewModel =
            Saving(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(
                    texts = object : ProgressTexts {
                        override val progressInfo get() = texts.saving
                    }
                ),
            )

        fun saved(): ViewModel =
            Saved(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.success,
                success = success(texts = texts),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoading get() = this as? Loading
        val asReady get() = this as? Ready
        val asSaving get() = this as? Saving
        val asSaved get() = this as? Saved
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    private fun Scene.Input<ViewModel>.failed(message: String) =
        sceneOf(viewModel.failed(message))

    fun start(scene: Scene.Input<ViewModel>, module: Module): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()
        val texts = Texts(
            partnership = "Samägande",
            partnershipTitle = "Avtalsregler - Samägande för ",
            partnershipBody = """
                Här kan ni ställa in ert avtal efter era behov och önskemål med vår smarta avtalsmodul för att få ett tryggt samägande. När ni är överens signerar ni med BankID.
                 
                ### Info-i och hemliga ögat
                För att förtydliga vissa regler finns ett info-i som ni kan klicka på för att få mer information. Om ni inte vill reglera en avtalspunkt kan ni enkelt stänga av den genom att trycka på ögat som då tar bort regeln i ert avtal. 

                ### Tålamod
                Just denna delen kräver lite tid, men vi är övertygade om att ni kommer tacka er efteråt för att ni har gjort detta ordentligt.
                 
                Har ni någon fråga eller feedback får ni gärna maila oss på [hej@della8.se](mailto:hej@della8.se)
            """.trimIndent(),
            booking = "Bokningsregler",
            bookingTitle = "Avtalsregler - Bokning för ",
            bookingBody = """
                Planera ditt samägande i vår intelligenta bokningsmodul. Genom en enkel och rättvis fördelning får ni ut så mycket som möjligt av ert objekt. Nedan förklarar vi lite mer om våra olika inställningar. 
                 
                ### Kort- respektive långbokning
                Ni kommer gemensamt överens om vad en kort- respektive långbokning är. Kortbokning är förslagsvis tre dagar och en lång, kanske två veckor. Ni bestämmer och anger det i verktyget. Givetvis kan ni ändra detta under ert samägande. 
                 
                ### Guldveckor
                Guldveckor är det vi kallar attraktiva veckor som kan vara mer populära och som det är viktigt att ni känner fördelas rättvist. Det varierar såklart beroende på vilket objekt ni har. Därför kan ni enkelt ställa in just era guldveckor. 
                 
                Har ni någon fråga eller feedback får ni gärna maila oss på [hej@della8.se](mailto:hej@della8.se) 
            """.trimIndent(),
            save = "Spara",
            backToObject = "Till objektet",
            ruleOn = "Regel på",
            ruleOff = "Regel av",
            back = "Tillbaka",
            loading = "Laddar...",
            saving = "Sparar...",
            saved = "Informationen sparades",
            failureTitle = "Något gick fel",
            successTitle = "Reglerna sparades",
            successInfo = "Samtliga delägare måste acceptera för att reglerna ska gälla enligt avtal, tills alla accepterat gäller de redan avtalade reglerna.",
            successNext = "OK",
            previous = "Föregående",
            next = "Nästa",
            choose = "Vilken typ av objekt?",
            bodyChoose = "Vilken typ av objekt är det ni ska samäga? Ställ in nedan så får ni fördefinerade regler som ni behöver sätta upp",
            motorHome = "Husbil",
            holidayHome = "Fastighet",
            progressInfo = "Laddar...",
            toStart = "Till startsidan",
            changeTitle = "Vill du förändra i avtalet?",
            changeBody = "Gå till objektet på startsidan och klicka på pilen och sedan Skapa avtalsutkast. Där kan du sedan ändra och skicka ut ett nytt avtalsförslag för godkännande.",
            edit = "Ändra",
            duplicateHighValue = "Perioden är redan tillagd",
            warningHighValueFirstBiggerSecond = "Guldveckans start måste vara tidigare än vald slutvecka",
            noRules = "Där är inga regler uppsatta i avtalet",
        )
        val state = viewModel.state.copy(
            module = module,
        )
        return sceneOf(viewModel.loading(texts = texts, state = state))
    }

    suspend fun load(
        scene: Scene.Input<ViewModel>,
        objectId: Identifier<Object>,
        agreementId: Identifier<Agreement>,
        edit: Boolean
    ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val title = when (viewModel.state.module) {
            is Module.None -> ""
            is Module.Partnership -> viewModel.texts.partnershipTitle
            is Module.Booking -> viewModel.texts.bookingTitle
        }
        val body = when (viewModel.state.module) {
            is Module.None -> ""
            is Module.Partnership -> viewModel.texts.partnershipBody
            is Module.Booking -> viewModel.texts.bookingBody
        }
        return store.refreshObject(objectId = objectId, agreementId = agreementId)
            .flatMap { (actions, obj) ->
                if (edit && obj.signatures.isNotEmpty())
                    return scene.failed("Can't edit an agreement with signatures")
                else
                    successfulOf(tupleOf(actions, obj))
            }
            .map { (actions, obj) ->
                val mode = when (viewModel.state.module) {
                    is Module.None -> Mode.SHOW
                    is Module.Partnership -> when {
                        !edit -> Mode.SHOW
                        edit && obj.partnershipTerm == null -> Mode.CREATE
                        else -> Mode.EDIT
                    }

                    is Module.Booking -> when {
                        !edit -> Mode.SHOW
                        edit && obj.bookingTerm == null -> Mode.CREATE
                        else -> Mode.EDIT
                    }
                }
                val clauses = when (viewModel.state.module) {
                    is Module.Partnership -> obj.partnershipTerm?.clauses ?: defaultPartnershipClauses
                    is Module.Booking -> obj.bookingTerm?.clauses ?: defaultBookingClauses
                    is Module.None -> emptyList()
                }
                val state = viewModel.state.copy(
                    obj = obj,
                    mode = mode,
                    page = 0,
                    clauses = clauses.makeEditable()
                )
                val params = ReadyParams(
                    title = title + obj.resource.name,
                    body = body,
                    items = buildItems(state),
                    editable = state.mode != Mode.SHOW,
                    leader = obj.isGroupLeader()
                )
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    fun update(scene: Scene.Input<ViewModel>, rule: Identifier<RuleItem>, value: RuleValue): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        if (viewModel !is ViewModel.Ready) return scene.failed("Can't update values if not Ready")

        val obj = viewModel.state.obj
        var classification = viewModel.state.classification
        val previous =
            if (rule.header == TermHeader.partnershipClassification) {
                classification = ((value as? RuleValue.OptionValue)?.value?.data as? Classification) ?: Classification.None
                when {
                    classification is Classification.MotorHome && obj.partnershipTerm?.clauses == null ->
                        defaultMotorHomeClauses(classification).makeEditable()

                    classification is Classification.HolidayHome && obj.partnershipTerm?.clauses == null ->
                        defaultHolidayHomeClauses(classification).makeEditable()

                    classification is Classification.Yacht && obj.partnershipTerm?.clauses == null ->
                        defaultYachtClauses(classification).makeEditable()

                    classification is Classification.Other && obj.partnershipTerm?.clauses == null ->
                        defaultOtherClauses(classification).makeEditable()

                    else -> viewModel.state.clauses
                }
            } else
                viewModel.state.clauses

        var status: DesignSystem.Status = DesignSystem.Status.Valid

        val validatedValue = when (value) {
            is RuleValue.DoubleOptionsValue -> {
                // Check So first value is lower than second
                val sorted = value.value.filter { it.first.value?.toInt()!! <= it.second.value?.toInt()!! }

                var itemValue = value.value
                // Check for duplicates
                val filtered = value.value.distinct()
                status = DesignSystem.Status.Valid
                if (value.value != filtered) {
                    status = DesignSystem.Status.Invalid(warning = viewModel.texts.duplicateHighValue)
                    itemValue = filtered
                }
                if (sorted.size < value.value.size) {
                    status = DesignSystem.Status.Invalid(warning = viewModel.texts.warningHighValueFirstBiggerSecond)
                    itemValue = sorted
                }

                value.copy(value = itemValue)
            }

            else -> value
        }

        val state = viewModel.state.copy(
            clauses = EditableClauses.update(classification = classification, header = rule.header, value = validatedValue, previous = previous, status = status)
        )

        val params = viewModel.params.update(items = buildItems(state))

        return sceneOf(viewModel.ready(state = state, params = params))
    }

    fun validate(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        if (viewModel !is ViewModel.Ready) return scene.failed("Can't validate values if not Ready")

        val classification = viewModel.state.classification
        val state = viewModel.state.copy(
            clauses = EditableClauses.validate(classification = classification, previous = viewModel.state.clauses)
        )
        val params = viewModel.params.update(items = buildItems(state))
        val status = params.items.map { it.status }

        return sceneOf(viewModel.ready(state = viewModel.state, params = params, status = status))
    }

    suspend fun previous(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        if (viewModel !is ViewModel.Ready) return scene.failed("Can't go previous if not Ready")
        val state = viewModel.state.copy(
            page = viewModel.state.page - 1
        )
        return if (state.page < pages(state)) {
            val updated = viewModel.params.update(items = buildItems(state))
            sceneOf(viewModel.ready(state = state, params = updated))
        } else
            sceneOf(viewModel.saving())
    }

    suspend fun next(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        if (viewModel !is ViewModel.Ready) return scene.failed("Can't go next if not Ready")

        val state = viewModel.state.copy(
            page = viewModel.state.page + 1,
        )
        return if (state.page < pages(state)) {
            val updated = viewModel.params.update(items = buildItems(state))
            sceneOf(viewModel.ready(state = state, params = updated))
        } else
            sceneOf(viewModel.saving())
    }

    suspend fun save(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return when (viewModel.state.mode) {
            Mode.SHOW -> scene.failed("Can't save in mode Edit")
            Mode.CREATE -> create(scene)
            Mode.EDIT -> edit(scene)
        }
    }

    suspend fun success(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return load(scene = scene, objectId = viewModel.state.obj.id, agreementId = viewModel.state.obj.currentAgreement.id, edit = false)
    }

    private suspend fun create(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val key = when (viewModel.state.module) {
            is Module.None -> return scene.failed("Module None can't be created")
            is Module.Partnership -> Term.partnershipKey
            is Module.Booking -> Term.bookingKey
        }
        val name = when (viewModel.state.module) {
            is Module.None -> return scene.failed("Module None can't be created")
            is Module.Partnership -> viewModel.texts.partnership
            is Module.Booking -> viewModel.texts.booking
        }

        val obj = viewModel.state.obj
        val create = Term.Create(
            agreement = viewModel.state.obj.currentAgreement.key,
            key = key,
            name = name,
            clauses = viewModel.state.clauses.removeEditable()
        )
        return store.createTerm(obj = obj, create = create)
            .flatMap { (actions, _) ->
                store.editAgreementContent(obj)
                    .accumulate(actions)
            }
            .map { (actions, _) ->
                sceneOf(viewModel.saved(), actions)
            }
            .failed { scene.failed(result = it) }
    }

    private suspend fun edit(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val id = when (viewModel.state.module) {
            is Module.None -> return scene.failed("Module None can't be edited")
            is Module.Partnership -> viewModel.state.obj.partnershipTerm?.id ?: return scene.failed("Missing id")
            is Module.Booking -> viewModel.state.obj.bookingTerm?.id ?: return scene.failed("Missing id")
        }

        val obj = viewModel.state.obj
        val edit = Term.Edit(
            clauses = modifiedOf(viewModel.state.clauses.removeEditable())
        )
        return store.editTerm(obj = obj, id = id, edit = edit)
            .flatMap { (actions, _) ->
                store.editAgreementContent(obj)
                    .accumulate(actions)
            }
            .map { (actions, _) ->
                sceneOf(viewModel.saved(), actions)
            }
            .failed { scene.failed(result = it) }
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }

    private val defaultPartnershipClauses: List<Term.Clause>
        get() = listOf(
            Defaults.partnershipClassification,
        )

    private fun defaultMotorHomeClauses(classification: Classification): List<Term.Clause> = listOf(
        Term.Clause.Classification(classification = classification),
        Defaults.partnershipRegistration,
        Defaults.partnershipInsurance(classification),
        Defaults.partnershipOwnership,
        Defaults.partnershipService,
        Defaults.partnershipStorage(classification),
        Defaults.partnershipDrivingRange,
        Defaults.partnershipDrivingDistance,
        Defaults.partnershipDamage(classification),
        Defaults.partnershipExpense(classification),
        Defaults.partnershipConduct(classification),
        Defaults.partnershipRental,
        Defaults.partnershipSale,
        Defaults.partnershipPublicAuction,
        Defaults.partnershipOther,
        Defaults.partnershipTermination,

        // With meeting with Martin 14/9 -22 these were removed for new agreements
        // Defaults.partnershipFinancing,
        // Defaults.partnershipUnexpectedExpense,
        // Defaults.partnershipProfit,
    )

    private fun defaultHolidayHomeClauses(classification: Classification): List<Term.Clause> = listOf(
        Term.Clause.Classification(classification = classification),
        Defaults.partnershipProperty,
        Defaults.partnershipRegistration,
        Defaults.partnershipInsurance(classification),
        Defaults.partnershipOwnership,
        Defaults.partnershipDamage(classification),
        Defaults.partnershipExpense(classification),
        Defaults.partnershipConduct(classification),
        Defaults.partnershipRental,
        Defaults.partnershipSale,
        Defaults.partnershipPublicAuction,
        Defaults.partnershipOther,
        Defaults.partnershipTermination,

        // With meeting with Martin 14/9 -22 these were removed for new agreements
        // Defaults.partnershipUnexpectedExpense,
        // Defaults.partnershipFinancing,
        // Defaults.partnershipProfit,
    )

    private fun defaultYachtClauses(classification: Classification): List<Term.Clause> = listOf(
        Term.Clause.Classification(classification = classification),
        Defaults.partnershipProperty,
        Defaults.partnershipRegistration,
        Defaults.partnershipInsurance(classification),
        Defaults.partnershipOwnership,
        Defaults.partnershipStorage(classification),
        Defaults.partnershipDamage(classification),
        Defaults.partnershipExpense(classification),
        Defaults.partnershipConduct(classification),
        Defaults.partnershipRental,
        Defaults.partnershipSale,
        Defaults.partnershipPublicAuction,
        Defaults.partnershipProfit,
        Defaults.partnershipOther,
        Defaults.partnershipTermination,
        Defaults.partnershipRisk(classification),

        )

    private fun defaultOtherClauses(classification: Classification): List<Term.Clause> = listOf(
        Term.Clause.Classification(classification = classification),
        Defaults.partnershipOwnership,
        Defaults.partnershipDamage(classification),
        Defaults.partnershipExpense(classification),
        Defaults.partnershipRental,
        Defaults.partnershipSale,
        Defaults.partnershipPublicAuction,
        Defaults.partnershipOther,
        Defaults.partnershipTermination,

        // With meeting with Martin 14/9 -22 these were removed for new agreements
        // Defaults.partnershipUnexpectedExpense,
        // Defaults.partnershipFinancing,
        // Defaults.partnershipProfit,
    )

    private val defaultBookingClauses: List<Term.Clause>
        get() =
            Defaults.bookingHighValue +
                    listOf(
                        Defaults.bookingUnlockedShort,
                        Defaults.bookingUnlockedLong,
                        Defaults.bookingDurationShort,
                        Defaults.bookingDurationLong,
                        Defaults.bookingConcurrentLong,
                        Defaults.bookingConcurrentLongHighValue,
                        Defaults.bookingConcurrentShortHighValue,
                    )

    private fun buildItems(state: State): List<RuleItem.ViewModel> {
        val editable = state.mode != Mode.SHOW
        return when (state.module) {
            is Module.None -> emptyList()
            is Module.Partnership -> partnership(state = state, editable = editable)
            is Module.Booking -> booking(state = state, editable = editable)
        }
    }

    private fun pages(state: State): Int =
        when {
            state.module is Module.Partnership && state.classification is Classification.MotorHome -> 6
            state.module is Module.Partnership && state.classification is Classification.HolidayHome -> 5
            state.module is Module.Partnership && state.classification is Classification.Yacht -> 7
            state.module is Module.Partnership && state.classification is Classification.Other -> 4
            state.module is Module.Partnership && state.classification is Classification.None -> 2
            state.module is Module.Booking -> 2
            else -> 0
        }

    private fun partnership(state: State, editable: Boolean) =
        when {
            editable && state.page == 1 -> listOfNotNull(
                RuleItem.simpleSection("Vilken typ av objekt?"),
                RuleItem.partnershipClassification(clauses = state.clauses, editable = editable),
            )

            // MOTOR_HOME
            editable && state.classification is Classification.MotorHome && state.page == 2 -> listOfNotNull(
                RuleItem.simpleSection("Objekt"),
                RuleItem.partnershipRegistration(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
                RuleItem.partnershipInsurance(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
                RuleItem.partnershipOwnership(clauses = state.clauses, editable = editable),
                // RuleItem.partnershipFinancing(clauses = state.clauses, editable = editable),
            )

            editable && state.classification is Classification.MotorHome && state.page == 3 -> listOfNotNull(
                RuleItem.simpleSection("Underhåll"),
                RuleItem.partnershipService(clauses = state.clauses, editable = editable),
                RuleItem.partnershipDrivingRange(clauses = state.clauses, editable = editable),
                RuleItem.partnershipDrivingDistance(clauses = state.clauses, editable = editable),
                RuleItem.partnershipStorage(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
                RuleItem.simpleSection("Utgifter"),
                // RuleItem.partnershipUnexpectedExpense(clauses = state.clauses, editable = editable),
                RuleItem.partnershipDamage(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
                RuleItem.partnershipExpense(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
                RuleItem.simpleSection("Uthyrning"),
                RuleItem.partnershipRental(clauses = state.clauses, editable = editable),
            )

            editable && state.classification is Classification.MotorHome && state.page == 4 -> listOfNotNull(
                RuleItem.simpleSection("Ordning"),
                RuleItem.partnershipConduct(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
                RuleItem.simpleSection("Försäljning"),
                RuleItem.partnershipSale(clauses = state.clauses, editable = editable),
                // RuleItem.partnershipProfit(clauses = state.clauses, editable = editable),
                RuleItem.partnershipPublicAuction(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
            )

            editable && state.classification is Classification.MotorHome && state.page == 5 -> listOfNotNull(
                RuleItem.simpleSection("Övrigt"),
                RuleItem.partnershipOther(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
                RuleItem.partnershipTermination(clauses = state.clauses, editable = editable),
            )

            !editable && state.classification is Classification.MotorHome ->
                listOfNotNull(
                    RuleItem.partnershipClassification(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipRegistration(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipInsurance(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipOwnership(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipFinancing(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipService(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipDrivingRange(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipDrivingDistance(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipStorage(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipUnexpectedExpense(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipDamage(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipExpense(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipConduct(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipRental(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipSale(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipProfit(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipPublicAuction(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipOther(classification = Classification.MotorHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipTermination(clauses = state.clauses, editable = editable),
                )

            // HOLIDAY HOME
            editable && state.classification is Classification.HolidayHome && state.page == 2 -> listOfNotNull(
                RuleItem.simpleSection("Objekt"),
                RuleItem.partnershipProperty(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                RuleItem.partnershipRegistration(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                RuleItem.partnershipInsurance(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                RuleItem.partnershipOwnership(clauses = state.clauses, editable = editable),
                // RuleItem.partnershipFinancing(clauses = state.clauses, editable = editable),
            )

            editable && state.classification is Classification.HolidayHome && state.page == 3 -> listOfNotNull(
                RuleItem.simpleSection("Utgifter"),
                // RuleItem.partnershipUnexpectedExpense(clauses = state.clauses, editable = editable),
                RuleItem.partnershipDamage(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                RuleItem.partnershipExpense(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                RuleItem.simpleSection("Ordning"),
                RuleItem.partnershipConduct(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                RuleItem.simpleSection("Uthyrning"),
                RuleItem.partnershipRental(clauses = state.clauses, editable = editable),
                RuleItem.simpleSection("Försäljning"),
                RuleItem.partnershipSale(clauses = state.clauses, editable = editable),
                // RuleItem.partnershipProfit(clauses = state.clauses, editable = editable),
                RuleItem.partnershipPublicAuction(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
            )

            editable && state.classification is Classification.HolidayHome && state.page == 4 -> listOfNotNull(
                RuleItem.simpleSection("Övrigt"),
                RuleItem.partnershipOther(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                RuleItem.partnershipTermination(clauses = state.clauses, editable = editable),
            )

            !editable && state.classification is Classification.HolidayHome ->
                listOfNotNull(
                    RuleItem.partnershipClassification(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipProperty(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipRegistration(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipInsurance(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipOwnership(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipFinancing(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipUnexpectedExpense(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipDamage(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipExpense(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipConduct(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipRental(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipSale(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipProfit(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipPublicAuction(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipOther(classification = Classification.HolidayHome, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipTermination(clauses = state.clauses, editable = editable),
                )


            // YACHT
            /*editable && state.classification is Classification.Yacht && state.page == 2 -> listOfNotNull(
                RuleItem.simpleSection("Köp"),
            )
             */
            editable && state.classification is Classification.Yacht && state.page == 2 -> listOfNotNull(
                RuleItem.simpleSection("Objekt"),
                RuleItem.partnershipProperty(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                RuleItem.partnershipRegistration(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                RuleItem.partnershipInsurance(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                RuleItem.partnershipOwnership(clauses = state.clauses, editable = editable),
            )

            editable && state.classification is Classification.Yacht && state.page == 3 -> listOfNotNull(
                RuleItem.simpleSection("Utgifter"),
                RuleItem.partnershipExpense(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                RuleItem.partnershipDamage(classification = Classification.Yacht, clauses = state.clauses, editable = editable),

                RuleItem.simpleSection("Uthyrning"),
                RuleItem.partnershipRental(clauses = state.clauses, editable = editable),
            )

            editable && state.classification is Classification.Yacht && state.page == 4 -> listOfNotNull(
                RuleItem.simpleSection("Ordning"),
                RuleItem.partnershipConduct(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
            )

            editable && state.classification is Classification.Yacht && state.page == 5 -> listOfNotNull(
                RuleItem.simpleSection("Försäljning"),
                RuleItem.partnershipSale(clauses = state.clauses, editable = editable),
                RuleItem.partnershipPublicAuction(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                RuleItem.partnershipProfit(clauses = state.clauses, editable = editable),
            )

            editable && state.classification is Classification.Yacht && state.page == 6 -> listOfNotNull(
                RuleItem.simpleSection("Övrigt"),
                RuleItem.partnershipStorage(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                RuleItem.partnershipRisk(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                RuleItem.partnershipOther(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
            )

            !editable && state.classification is Classification.Yacht ->
                listOfNotNull(
                    RuleItem.partnershipProperty(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipClassification(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipRegistration(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipInsurance(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipOwnership(clauses = state.clauses, editable = editable),

                    RuleItem.partnershipDamage(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipExpense(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipConduct(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipRental(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipSale(clauses = state.clauses, editable = editable),

                    RuleItem.partnershipProfit(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipPublicAuction(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipStorage(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipRisk(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipOther(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                )


            // OTHER
            editable && state.classification is Classification.Other && state.page == 2 -> listOfNotNull(
                RuleItem.simpleSection("Objekt"),
                RuleItem.partnershipOwnership(clauses = state.clauses, editable = editable),
                RuleItem.simpleSection("Utgifter"),
                // RuleItem.partnershipUnexpectedExpense(clauses = state.clauses, editable = editable),
                RuleItem.partnershipDamage(classification = state.classification, clauses = state.clauses, editable = editable),
                RuleItem.partnershipExpense(classification = Classification.Yacht, clauses = state.clauses, editable = editable),
                RuleItem.simpleSection("Uthyrning"),
                RuleItem.partnershipRental(clauses = state.clauses, editable = editable),
                RuleItem.simpleSection("Försäljning"),
                RuleItem.partnershipSale(clauses = state.clauses, editable = editable),
                // RuleItem.partnershipProfit(clauses = state.clauses, editable = editable),
                RuleItem.partnershipPublicAuction(classification = state.classification, clauses = state.clauses, editable = editable),
            )

            editable && state.classification is Classification.Other && state.page == 3 -> listOfNotNull(
                RuleItem.simpleSection("Övrigt"),
                RuleItem.partnershipOther(classification = state.classification, clauses = state.clauses, editable = editable),
                RuleItem.partnershipTermination(clauses = state.clauses, editable = editable),
            )

            !editable && state.classification is Classification.Other ->
                listOfNotNull(
                    RuleItem.partnershipClassification(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipOwnership(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipUnexpectedExpense(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipDamage(classification = state.classification, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipExpense(classification = state.classification, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipRental(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipSale(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipProfit(clauses = state.clauses, editable = editable),
                    RuleItem.partnershipPublicAuction(classification = state.classification, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipOther(classification = state.classification, clauses = state.clauses, editable = editable),
                    RuleItem.partnershipTermination(clauses = state.clauses, editable = editable),
                )

            else -> emptyList()
        }

    private fun booking(state: State, editable: Boolean) =
        when {
            editable && state.page == 1 || !editable -> listOfNotNull(
                RuleItem.simpleSection("Guldveckor", visible = state.clauses.isNotEmpty()),
                RuleItem.bookingHighValue(clauses = state.clauses, editable = editable),
                RuleItem.simpleSection("Kortbokning", visible = state.clauses.isNotEmpty()),
                RuleItem.bookingDurationShort(clauses = state.clauses, editable = editable),
                RuleItem.bookingUnlockedShort(clauses = state.clauses, editable = editable),
                RuleItem.bookingConcurrentShortHighValue(clauses = state.clauses, editable = editable),
                RuleItem.simpleSection("Långbokning", visible = state.clauses.isNotEmpty()),
                RuleItem.bookingDurationLong(clauses = state.clauses, editable = editable),
                RuleItem.bookingUnlockedLong(clauses = state.clauses, editable = editable),
                RuleItem.bookingConcurrentLongHighValue(clauses = state.clauses, editable = editable),
                RuleItem.bookingConcurrentLong(clauses = state.clauses, editable = editable),
            )

            else -> emptyList()
        }
}
