package bootstrap

import csstype.ClassName
import react.ComponentType
import react.PropsWithChildren

external interface ColProps : PropsWithChildren {
    var className: ClassName
    var lg: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var md: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var sm: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var xl: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var xs: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var bsPrefix: String
}

@JsNonModule
@JsModule("react-bootstrap/Col")
private external val colModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bCol: ComponentType<ColProps> = colModule.default

/*
import kotlinext.js.jsObject
import react.*

@JsModule("react-bootstrap/Col")
private external val colModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val colComponent: ComponentType<ColProps> = colModule.default

external interface ColProps : PropsWithChildren {
    var className: String
    var lg: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var md: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var sm: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var xl: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var xs: dynamic /* boolean | "auto" | number | { span: boolean | "auto" | number, offset: number, order: "first" | "last" | number } */
    var bsPrefix: String
}

class ColElementBuilder internal constructor(
    private val type: ComponentType<ColProps>, props: ColProps = jsObject { }
) : RElementBuilderImpl<ColProps>(props) {

    fun create() = createElement(type, attrs, *childList.toTypedArray())
}

fun RBuilder.bCol(
    className: String? = null,
    block: ColElementBuilder.() -> Unit = {},
) =
    child(ColElementBuilder(colComponent)
        .apply {
            className?.let { attrs.className = it }
            block()
        }.create()
    )
*/