package della8.web.views

import StoreContext
import bootstrap.bCol
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.screens.InviteScreen
import della8.core.support.DesignSystem
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.*
import kotlinx.js.get
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.router.useParams
import techla.base.Identifier
import techla.base.techla_log

val Invite = FC<PropsWithChildren>("Invite") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(InviteScreen.ViewModel.None as InviteScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()

    val scopedCall = scopedCall<InviteScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    val mainCall = mainCall<InviteScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is InviteScreen.ViewModel.None ->
                scopedCall(coroutineScope) {  InviteScreen.start(sceneInputOf(store, viewModel)) }

            is InviteScreen.ViewModel.Loading -> {
                params["objectId"]?.let { objectId ->
                    params["agreementId"]?.let { agreementId ->
                        scopedCall(coroutineScope) { InviteScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId)) }
                    }
                }
            }
            is InviteScreen.ViewModel.Ready -> {
            }
            is InviteScreen.ViewModel.Failed ->
                techla_log("failed ")
        }
    }

    fun handleCopy(text: DesignSystem.Text, data: Any) {
        mainCall { InviteScreen.copyClipboard(sceneInputOf(store, viewModel), text = text.text ?: "", data as InviteScreen.Copied) }
    }

    fun logout() {
        mainCall { InviteScreen.logout(sceneInputOf(store, viewModel)) }
        navigate("/")
    }

    main {
        when (viewModel) {
            is InviteScreen.ViewModel.Ready-> d8Navigation(
                design = viewModel.navigation,
                className = "nav-top",
                onClick =  standardNavigation(navigate))
            else -> d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
        }
        if (viewModel is InviteScreen.ViewModel.Ready) {
            bContainer {
                className = className("px-5 position-relative n-bottom-space-160 n-top-space")

                bRow {
                    className = className("pb-4 justify-content-center")

                    bCol {
                        xs = 12; md = 5
                        d8Image(className = "d-block mx-auto", design = viewModel.image)
                        d8Text(className = "text-center", design = viewModel.title)
                        d8Text(className = "", design = viewModel.body)
                        d8Text(className = "", design = viewModel.titleCode)
                        div {
                            className = className("n-code-bg d-flex justify-content-center align-items-center")
                            d8Text(className = "text-center d-block m-0", design = viewModel.code)
                        }
                        d8Button(design = viewModel.copyCode, onClick = { viewModel.copyCode.data?.let { it1 -> handleCopy(viewModel.code, it1) } })
                        div {
                            className = className("float-end d-flex flex-row pt-2")
                            d8Text(className = "fst-italic d8-body d8-marine m-0", design = viewModel.successCopyCode)
                        }
                        d8Text(className = "mt-3", design = viewModel.titleInvite)
                        d8Text(className = "fst-italic d8-body d8-marine", design = viewModel.invite)
                        d8Button(design = viewModel.copyMessage, onClick = { viewModel.copyMessage.data?.let { it1 -> handleCopy(viewModel.invite, it1) } })
                        div {
                            className = className("float-end d-flex flex-row pt-2")
                            d8Text(className = "fst-italic d8-body d8-marine m-0", design = viewModel.successCopyMessage)
                        }
                    }
                }
            }

        }

        if (viewModel is InviteScreen.ViewModel.Failed) {
            bContainer {
                className = className("px-5 n-top-space ")
                d8failure(design = viewModel.failure, ::logout)
            }
        }
    }
}