package bootstrap

import csstype.ClassName
import org.w3c.dom.events.Event
import react.*

@JsNonModule
@JsModule("react-bootstrap/Form")
private external val formModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bFormCheck: ComponentType<FormCheckProps> = formModule.default.Check

external interface FormCheckProps : PropsWithChildren {
    var className: ClassName
    var ref: RefObject<Any>
    var `as`: dynamic /* 'input' | elementType */
    var disabled: Boolean
    var checked: Boolean
    //var feedback: ReactElement
    var feedbackTooltip: Boolean
    var id: String
    var inline: Boolean
    var isInvalid: Boolean
    var isValid: Boolean
    var onChange: (Event) -> Unit
    var label: dynamic
    var title: String
    var type: String /* 'radio' | 'checkbox' | 'switch' */
    var bsPrefix: String
    var bsCustomPrefix: String
    var defaultChecked: Boolean

}

/*
class FormCheckElementBuilder internal constructor(
    private val type: ComponentType<FormCheckProps>, props: FormCheckProps = jsObject { }
) : RElementBuilderImpl<FormCheckProps>(props) {

    fun create() = createElement(type, attrs, *childList.toTypedArray())
}

fun RBuilder.bFormCheck(
    id: String? = null,
    type: String? = null,
    inline: Boolean? = null,
    disabled: Boolean? = null,
    onChange: ((Event) -> Unit)? = null,
    block: FormCheckElementBuilder.() -> Unit = {},
) =
    child(FormCheckElementBuilder(formCheckComponent)
        .apply {
            id?.let { attrs.id = it }
            type?.let { attrs.type = it }
            inline?.let { attrs.inline = it }
            disabled?.let { attrs.disabled = it }
            onChange?.let { attrs.onChange = it }
            block()
        }.create()
    )

fun <T: Any> RBuilder.bFormCheck(
    ref: RefObject<T>? = null,
    id: String? = null,
    type: String? = null,
    inline: Boolean? = null,
    disabled: Boolean? = null,
    block: FormCheckElementBuilder.() -> Unit = {},
) =
    child(FormCheckElementBuilder(formCheckComponent)
        .apply {
            id?.let { attrs.id = it }
            type?.let { attrs.type = it }
            inline?.let { attrs.inline = it }
            disabled?.let { attrs.disabled = it }
            block()
        }.create()
    )


 */