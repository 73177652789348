package della8.core.screens

import della8.core.support.*
import techla.base.*

object ShopScreen {
    data class Texts(
        val title: String,
        val back: String,
        override val failureTitle: String,
    ) : FailureTexts {
        companion object
    }


    sealed class ViewModel(open var texts: Texts, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", ""),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, navigation) {
            companion object
        }

        data class Ready(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,

            val title: DesignSystem.Text,
        ) : ViewModel(texts, navigation) {
            companion object
        }

        data class Failed(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, navigation) {
            companion object
        }

        fun loading() = Loading(texts = texts, navigation = navigation)

        fun ready(texts: Texts) =
            Ready(
                texts = texts,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing),
                title = DesignSystem.Text(
                    text = texts.title,
                    style = DesignSystem.TextStyle.TITLE1
                ),

                )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false) =
            Failed(
                texts = texts,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val ready get() = this as? Ready
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        return sceneOf<ViewModel>(viewModel.loading())
    }

    suspend fun load(
        scene: Scene.Input<ViewModel>,
        objectId: Identifier<Object>
    ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val obj = store.objects?.firstOrNull { it.id == objectId }!!


        val texts = Texts(
            title = "Shop",
            back = "Tillbaka",
            failureTitle = "Oj, ett fel har uppstått",

            )

        return sceneOf<ViewModel>(viewModel.ready(texts))
    }
}


