package della8.core.support

data class Deployment(
    val applicationKey: String,
    val applicationSecret: String,
    val redirect: String,
    val isSandbox: Boolean,
    val version: String,
    val build: Int,
    val della8Home: String,
    val della8Finder: String,
)