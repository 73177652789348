package della8.web.items

import della8.core.items.RuleItem
import della8.core.screens.RuleValue
import della8.web.components.d8Button
import della8.web.components.d8Text
import della8.web.support.className
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import techla.base.Identifier

external interface D8RuleItemHeaderProps : PropsWithChildren {
    var className: String
    var viewModel: RuleItem.ViewModel
    var onChange: (Identifier<RuleItem>, RuleValue) -> Unit
    var onInfo: (String, Boolean) -> Unit
}

val D8RuleItemHeader = FC<D8RuleItemHeaderProps>("Della8.RuleItemHeader") { props ->
    fun onOnOff() {
        val value = props.viewModel.value
        if (value is RuleValue.Off)
            props.onChange(props.viewModel.id, value.previous)
        else
            props.onChange(props.viewModel.id, RuleValue.Off(value))
    }

    fun onInfo() {
        props.viewModel.header.infoText?.let {
            props.onInfo(it, true)
        }
    }
    if (props.viewModel.header.title.visible) {
        div {
            className = className("d-flex flex-row")

            div {
                className = className("ms-auto")
                d8Button(className = "p-0", design = props.viewModel.header.onOff, onClick = ::onOnOff)
                d8Button(className = "ms-2 p-0", design = props.viewModel.header.info, onClick = ::onInfo)
            }

        }
        if(props.viewModel.value !is RuleValue.Off){
            div {
                className = className("d-flex justify-content-between align-items-center")
                div {
                    className = className("dashedBorder mb-3 mt-3")
                }
                d8Text(className = "d-inline pe-2 mb-0 flex-shrink-0", design = props.viewModel.header.agreementText)
                div {
                    className = className("dashedBorder mt-4 mb-4")
                }
            }
        }
        d8Text(className = "mt-2", design = props.viewModel.header.title)
    }
}
