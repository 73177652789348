package della8.core.support

import techla.agreement.Term
import techla.base.Address

val Address.displayName
    get() =
        when (this) {
            is Address.None -> "Ingen"
            is Address.Swedish -> "$street, $postalCode, $city"
            is Address.Other -> description
            else -> ""
        }

val Term.Clause.Property.displayName
    get() =
        address.displayName

data class EditableAddress(
    val style: Style,
    val street: String = "",
    val postalCode: Pair<String, Int?> = "" to 0,
    val city: String = "",
    val description: String = "",
) {
    enum class Style { SWEDISH, OTHER }

    companion object {
        fun fromTruth(truth: Address): EditableAddress =
            when (truth) {
                is Address.Swedish ->
                    EditableAddress(
                        style = Style.SWEDISH,
                        street = truth.street,
                        postalCode = (if (truth.postalCode == 0) "" else truth.postalCode.toString()) to truth.postalCode,
                        city = truth.city,
                    )
                is Address.Other ->
                    EditableAddress(
                        style = Style.OTHER,
                        description = truth.description
                    )
                else ->
                    EditableAddress(
                        style = Style.OTHER,
                        description = ""
                    )
            }
    }

    val truth
        get() =
            when {
                style == Style.OTHER -> Address.Other(description = description)
                style == Style.SWEDISH && postalCode.second != null -> Address.Swedish(street = street, postalCode = postalCode.second!!, city = city)
                else -> null
            }
}

val EditableAddress.Style.option
    get() =
        when (this) {
            EditableAddress.Style.SWEDISH -> DesignSystem.Option.item(title = "Sverige", value = name, data = this)
            EditableAddress.Style.OTHER -> DesignSystem.Option.other(title = "Annat", value = name, data = this)
        }
