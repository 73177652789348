package della8.core.support

fun DesignSystem.Footer.Companion.front() =
    DesignSystem.Footer(
        title = DesignSystem.Text(
            text = "Följ oss",
            style = DesignSystem.TextStyle.TITLE3,
            background = DesignSystem.Background.LIGHT
        ),
        body = DesignSystem.Text(
            text = "Copyright 2022 Della8",
            style = DesignSystem.TextStyle.BODY,
            background = DesignSystem.Background.LIGHT
        ),
        buttons = listOf(
            DesignSystem.Button(
                image = DesignSystem.Image.LINKEDIN,
                background = DesignSystem.Color.BATTLESHIP,
                style = DesignSystem.ButtonStyle.IMAGE,
                action = DesignSystem.Action.LINKEDIN,
            ),
            DesignSystem.Button(
                image = DesignSystem.Image.INSTAGRAM,
                background = DesignSystem.Color.BATTLESHIP,
                style = DesignSystem.ButtonStyle.IMAGE,
                action = DesignSystem.Action.INSTAGRAM,
            ),
        ),
        links = listOf(
            DesignSystem.Button(
                title = "Presskit",
                style = DesignSystem.ButtonStyle.TRANSPARENT,
                action = DesignSystem.Action.PRESS_KIT,
            ),

            DesignSystem.Button(
                title = "Cookies",
                style = DesignSystem.ButtonStyle.TRANSPARENT,
                action = DesignSystem.Action.COOKIES,
                location = Location.Cookies,
            ),
            DesignSystem.Button(
                title = "Privacy policy",
                style = DesignSystem.ButtonStyle.TRANSPARENT,
                action = DesignSystem.Action.PRIVACY,
                location = Location.Policy,
            ),
        )
    )