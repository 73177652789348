package della8.web.views

import StoreContext
import bootstrap.bCard
import bootstrap.bCol
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.items.RuleItem
import della8.core.screens.RuleValue
import della8.core.screens.RulesScreen
import della8.core.support.DesignSystem
import della8.core.support.Module
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.items.*
import della8.web.support.*
import kotlinx.browser.window
import kotlinx.js.get
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.router.useParams
import techla.base.Identifier
import techla.base.techla_log

private val s = "auto"


val RulesView = FC<PropsWithChildren>("RulesView") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(RulesScreen.ViewModel.None as RulesScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()
    val (showModal, setShowModal) = useState(false)
    val (text, setText) = useState("")
    val (classification, setClassification) = useState(DesignSystem.Option.None as DesignSystem.Option)

    val scopedCall = scopedCall<RulesScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }
    val call = call<RulesScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    val mainCall = mainCall<RulesScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    fun onInfo(text: String, boolean: Boolean) {
        techla_log("InfoText is -> $text , Boolean-> $boolean")
        setText(text)
        setShowModal(boolean)
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is RulesScreen.ViewModel.None -> {
                val string = params["module"]
                if (string != null) {
                    val module = Module.fromRawValue(string)
                    window.scrollTo(x = 0.0, y = 0.0)
                    scopedCall(coroutineScope) { RulesScreen.start(sceneInputOf(store, viewModel), module = module) }
                }
            }

            is RulesScreen.ViewModel.Loading -> {
                params["objectId"]?.let { objectId ->
                    params["agreementId"]?.let { agreementId ->
                        params["mode"]?.let { mode ->
                            window.scrollTo(x = 0.0, y = 0.0)
                            scopedCall(coroutineScope) { RulesScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId), edit = mode === "edit") }
                        }
                    }
                }
            }

            else -> {}
        }
    }

    suspend fun onPrevious() {
        call { RulesScreen.previous(sceneInputOf(store, viewModel)) }
        window.scrollTo(x = 0.0, y = 0.0)
    }

    suspend fun onNext() {
        RulesScreen.validate(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
            window.scrollTo(x = 0.0, y = 0.0)
            if (viewModel.asReady?.status is DesignSystem.Status.Valid) {
                RulesScreen.next(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    window.scrollTo(x = 0.0, y = 0.0)
                    setViewModel(viewModel)
                    dispatch(actions)

                    viewModel.asSaving?.let {
                        call { RulesScreen.save(sceneInputOf(store, viewModel)) }
                    }
                }
            }
        }
    }

    fun onUpdate(rule: Identifier<RuleItem>, value: RuleValue) {
        RulesScreen.update(sceneInputOf(store, viewModel), rule = rule, value = value).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    suspend fun onNavigation(mode: String) {
        params["objectId"]?.let { objectId ->
            params["agreementId"]?.let { agreementId ->
                window.scrollTo(x = 0.0, y = 0.0)
                call { RulesScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId), edit = mode === "edit") }
            }
        }
    }

    suspend fun onSuccess() {
        call { RulesScreen.success(sceneInputOf(store, viewModel)) }
    }

    fun logout() {
        mainCall { RulesScreen.logout(sceneInputOf(store, viewModel)) }
        navigate("/")
    }

    fun recover() {
        mainCall { RulesScreen.success(sceneInputOf(store, viewModel)) }
    }

    main {
        d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))

        if (viewModel is RulesScreen.ViewModel.Ready) {
            bContainer {
                className = className("px-5 position-relative n-bottom-space-320 n-top-space")
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        xs = 12; md = 7
                        d8Image(className = "d-block mx-auto", design = viewModel.image)
                        d8Text(className = "text-center", design = viewModel.title)
                        d8Text(className = "", design = viewModel.body)
                        div {
                            className = className("d-flex flex-row-reverse")
                            d8Text(className = "ms-2", design = viewModel.ruleOn)
                            d8Text(className = "ms-2", design = viewModel.ruleOff)
                        }

                        viewModel.items.map { item ->
                            when (item) {
                                is RuleItem.ViewModel.SimpleSection -> D8RuleItemSimpleSection { this.viewModel = item }
                                is RuleItem.ViewModel.TextInput -> D8RuleItemTextInput { this.viewModel = item; onChange = ::onUpdate; onInfo = ::onInfo }
                                is RuleItem.ViewModel.SelectInput -> D8RuleItemSelectInput { this.viewModel = item; onChange = ::onUpdate; onInfo = ::onInfo }
                                is RuleItem.ViewModel.ImageInput -> D8RuleItemImageInput { this.viewModel = item; onChange = ::onUpdate; onInfo = ::onInfo }
                                is RuleItem.ViewModel.List -> D8RuleItemList { this.viewModel = item; onChange = ::onUpdate; onInfo = ::onInfo }
                                is RuleItem.ViewModel.DoubleList -> D8RuleItemDoubleList { this.viewModel = item; onChange = ::onUpdate; onInfo = ::onInfo }
                                is RuleItem.ViewModel.Ownership -> D8RuleItemOwnership { this.viewModel = item; onChange = ::onUpdate; onInfo = ::onInfo }
                                is RuleItem.ViewModel.Financing -> D8RuleItemFinancing { this.viewModel = item; onChange = ::onUpdate; onInfo = ::onInfo }
                                is RuleItem.ViewModel.Address -> D8RuleItemAddress { this.viewModel = item; onChange = ::onUpdate; onInfo = ::onInfo }
                                is RuleItem.ViewModel.Paragraph -> D8RuleItemParagraph { this.viewModel = item; onChange = ::onUpdate; onInfo = ::onInfo }
                                is RuleItem.ViewModel.ConditionsOfSale -> D8RuleItemConditionsOfSale { this.viewModel = item; onChange = ::onUpdate; onInfo = ::onInfo }
                                is RuleItem.ViewModel.Adherence -> D8RuleItemAdherence { this.viewModel = item; onChange = ::onUpdate; onInfo = ::onInfo }
                            }
                        }
                        if (viewModel.noRules.visible) {
                            bCard {
                                className = className("radius-25 p-lg-5 p-3 mt-4 border-0 card-shadow d-flex align-items-center")
                                d8Image(className = "img-fluid radius-25 w-25 pb-4", design = viewModel.noRulesImage)
                                d8Text(className = "pt-1", design = viewModel.noRules)
                            }
                        }

                        div {
                            className = className("d-grid")
                            d8Button(className = "mb-5", design = viewModel.back)
                            d8Button(className = "mb-5", design = viewModel.start, onClick = ::onNext)
                        }
                        div {
                            className = className("float-start")
                            d8Button(className = "", design = viewModel.previous, onClick = ::onPrevious)
                        }

                        div {
                            className = className("float-end")
                            d8Button(className = "", design = viewModel.next, onClick = ::onNext)
                        }
                        d8Text(className = "text-center", design = viewModel.pagination)

                        bContainer {
                            fluid = true
                            className = className("n-booking-summary")
                            bRow {
                                className = className("row  justify-content-center")
                                bCol {
                                    className = className("col-auto")
                                    d8Image(className = " my-2 my-md-4", design = viewModel.changeImage)
                                }
                                bCol {
                                    className = className("col-12 col-sm-10 col-md-8")
                                    d8Text(className = "mt-2 mt-md-4 mb-0 ml-3", design = viewModel.changeTitle)
                                    d8Text(className = "mb-0 ml-3", design = viewModel.changeBody)
                                    d8Button(className = "my-4 mt-3  ml-3 d-inline", design = viewModel.toStart)
                                    div {
                                        className = className("col-auto col mx-lg-5 mx-2")
                                        div {
                                            className = className("d-grid mx-lg-5 mx-2 d-flex flex-md-row flex-column align-items-center justify-content-center")
                                            d8Button(className = "my-md-4 mt-4 mb-2 mx-4 w-75", design = viewModel.edit, onClick = { onNavigation("edit") })
                                            d8Button(className = "my-md-4 mb-4 mt-2 mx-4 w-75", design = viewModel.backToObject)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            d8Modal(
                design = viewModel.modal,
                show = showModal,
                onHide = { setShowModal(false) },
                onClickFirst = { onInfo("", false) },
                overrideBody = DesignSystem.Text(text = text),
            )
        }

        if (viewModel is RulesScreen.ViewModel.Loading) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is RulesScreen.ViewModel.Saving) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is RulesScreen.ViewModel.Saved) {
            Della8.Success { design = viewModel.success; onSuccess = ::onSuccess }
        }

        if (viewModel is RulesScreen.ViewModel.Failed) {
            d8failure(viewModel.failure, onLogout = ::logout, onRecover = ::recover)
        }
    }
}
