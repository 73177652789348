package della8.web.views

import StoreContext
import bootstrap.bCol
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.screens.LoginScreen
import della8.core.support.DesignSystem
import della8.core.support.Location
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.className
import della8.web.support.mainCall
import della8.web.support.standardNavigation
import della8.web.support.useAsyncEffect
import della8.web.theme.Design
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import lottie.lPlayer
import react.FC
import react.PropsWithChildren
import react.dom.html.ButtonType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.useContext
import react.useState
import techla.base.techla_log

val Login = FC<PropsWithChildren>("LOGIN") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(LoginScreen.ViewModel.None as LoginScreen.ViewModel)
    val navigate = useNavigate()
    val (count, setCount) = useState(0)
    val (saveValueFrom, setSaveValueFrom) = useState(true)

    val mainCall = mainCall<LoginScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }


    fun saveValueFromLocalStorage(value: String) {
        LoginScreen.update(sceneInputOf(store, viewModel), value, null, remember = true).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
            setSaveValueFrom(false)
        }
    }

    suspend fun handleAdditionalSubmit(formData: Map<String, FormValue>) {
        val email = formData.getValue(LoginScreen.Header.email.id).string
        mainCall { LoginScreen.update(sceneInputOf(store, viewModel), email = email) }
    }

    fun handleCancel() {
        MainScope().launch {
            LoginScreen.cancel(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
                setCount(0)
            }
        }
    }

    fun handleLogout() {
        MainScope().launch {
            LoginScreen.logout(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
                navigate(Location.Start.route)
            }
        }
    }

    suspend fun handleSignIn(formData: Map<String, FormValue>) {
        val govId = formData.getValue(LoginScreen.Header.govId.id).string
        val remember = formData.getValue(LoginScreen.Header.remember.id).boolean

        LoginScreen.saveInLocalStorage(sceneInputOf(store, viewModel), govId = govId, remember = remember).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }

        LoginScreen.login(sceneInputOf(store, viewModel), govId = govId, remember = false).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
            if (viewModel.ready?.govId?.status == DesignSystem.Status.Valid) {
                setCount(1)
            }
        }
    }

    fun check() {
        MainScope().launch {
            setCount(count + 1)
            LoginScreen.check(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                when (viewModel) {
                    is LoginScreen.ViewModel.Waiting -> {
                        dispatch(actions)
                    }

                    is LoginScreen.ViewModel.Starting -> {}
                    is LoginScreen.ViewModel.Finished -> {
                        dispatch(actions)
                        navigate("/landing")
                    }

                    else -> {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }
            }
        }
    }

    useAsyncEffect(count) { coroutineScope ->
        if (count > 0) {
            if (coroutineScope.isActive) {
                delay(1000L)
                check()
            }
        }
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is LoginScreen.ViewModel.None -> LoginScreen.start(sceneInputOf(store, viewModel))
                .also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }

            is LoginScreen.ViewModel.Loading -> {
                LoginScreen.load(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                        setCount(1)
                    }
                }
            }

            is LoginScreen.ViewModel.Ready -> {
                if (store.rememberMe.isNotEmpty() && saveValueFrom) {
                    saveValueFromLocalStorage(store.rememberMe)
                }
                if (store.qrCode == null) {
                    setCount(0)
                }
            }

            is LoginScreen.ViewModel.Starting -> {
                if (count == 0) {
                    check()
                }
            }

            is LoginScreen.ViewModel.Waiting -> {
                techla_log("Waiting ")
            }

            is LoginScreen.ViewModel.Additional -> {
                if (count != 0) {
                    setCount(0)
                }
            }

            is LoginScreen.ViewModel.Finished -> {
                navigate("/landing")
            }

            is LoginScreen.ViewModel.Failed -> {
                setCount(0)
            }

            else -> {}
        }
    }

    main {
        when (viewModel) {
            is LoginScreen.ViewModel.Ready -> d8Navigation(
                design = viewModel.navigation,
                onClick = standardNavigation(navigate, store)
            )

            is LoginScreen.ViewModel.Starting -> d8Navigation(
                design = viewModel.navigation, className = "position-relative", onClick = standardNavigation(navigate, store)
            )

            else -> d8Navigation(
                design = viewModel.navigation,
                className = "position-relative text-center",
                onClick = standardNavigation(navigate, store)
            )
        }

        if (viewModel is LoginScreen.ViewModel.Ready) {
            bContainer {
                className = className("px-5 position-relative n-bottom-space n-top-space")
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        className = className("py-3 px-1 py-4 px-md-4 d-flex align-items-center")
                        xs = 12; md = 6

                        div {
                            className = className("ps-lg-5")
                            d8Text(design = viewModel.infoTitle)
                            d8Text(className = "n-markdown", design = viewModel.infoBody)
                        }
                    }

                    bCol {
                        xs = 12; md = 7; lg = 5
                        d8Text(className = "text-white text-center", design = viewModel.title)

                        if (store.qrCode != null) {
                            D8BankId { this.value = store.qrCode!! }
                        } else { // Fallback to govId if QR-code is not available
                            D8Form {
                                onSubmit = ::handleSignIn
                                d8TextInput(classNameLabel = "text-white", design = viewModel.govId)
                                div {
                                    className = className("d-grid")
                                    d8Button(
                                        className = "btn-lg mt-3 mb-3",
                                        design = viewModel.start,
                                        type = ButtonType.submit
                                    )
                                }
                                d8BooleanInput(
                                    classNameInput = "d-inline n-checkbox",
                                    classNameLabel = "text-white ms-2",
                                    design = viewModel.remember,
                                    defaultChecked = store.rememberMe.isNotEmpty()
                                )
                            }
                        }

                        div {
                            className = className("pt-3 mb-4 mt-2 d-flex flex-column align-items-center")
                            d8Text(className = "text-white d-block mb-0", design = viewModel.accept)
                            d8Button(
                                className = "p-0 ps-1 m-0 border-0 d-block",
                                design = viewModel.terms,
                                onClick = { window.location.assign("${store.deployment.della8Home}/policy") })
                        }

                        d8Text(className = "mt-3 text-center", design = viewModel.version)
                    }
                }
            }

        }
        if (viewModel is LoginScreen.ViewModel.Starting) {
            bContainer {
                className = className("px-5 position-relative n-bottom-space n-top-space")
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        xs = 12; md = 7; lg = 5
                        div {
                            className = className("n-spinner d-flex justify-content-center")
                            lPlayer {
                                animationData = Design.animation(DesignSystem.Animation.SPINNER)
                                loop = true
                                autoplay = true
                            }
                        }
                        d8Text(className = "text-center text-white", design = viewModel.title)
                        d8Text(className = "text-center text-white", design = viewModel.info)
                        div {
                            className = className("d-grid")
                            d8Button(className = "btn-lg", design = viewModel.cancel, onClick = ::handleCancel)
                        }
                    }
                }
            }
        }

        if (viewModel is LoginScreen.ViewModel.Additional) {
            bContainer {
                className = className("px-5 position-relative n-bottom-space n-top-space")
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        xs = 12; md = 7; lg = 5
                        d8Text(className = "text-center text-white", design = viewModel.title)
                        d8Text(className = "text-center text-white mt-3", design = viewModel.info)
                        D8Form {
                            this.onSubmit = ::handleAdditionalSubmit
                            d8TextInput(classNameInput = "", classNameLabel = "text-white", design = viewModel.email)
                            div {
                                className = className("d-grid")
                                d8Button(className = "btn-lg mt-3", design = viewModel.next, type = ButtonType.submit)
                            }
                            div {
                                className = className("d-grid")
                                d8Button(className = "btn-lg mt-3", design = viewModel.cancel, onClick = ::handleCancel)
                            }

                        }
                    }
                }
            }
        }

        if (viewModel is LoginScreen.ViewModel.Failed) {
            bContainer {
                className = className("px-5 position-relative text-center n-top-space")
                d8failure(viewModel.failure, ::handleLogout)

            }
        }
    }
}
