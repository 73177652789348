package bootstrap

import csstype.ClassName
import org.w3c.dom.events.Event
import react.*
import react.dom.html.ButtonType

@JsNonModule
@JsModule("react-bootstrap/Button")
private external val buttonModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bButton: ComponentType<ButtonProps> = buttonModule.default

external interface ButtonProps : PropsWithChildren {
    var active: Boolean
    var block: Boolean
    var disabled: Boolean
    var href: String
    var size: String /* 'sm' | 'lg' */
    var target: Any
    var type: ButtonType? /* 'button' | 'reset' | 'submit' | null */
    var variant: String
    var bsPrefix: String
    var className: ClassName
    var onClick: ((event: Event) -> Unit)
}