package della8.core.support

import techla.base.Paragraph

val Paragraph.displayName
    get() =
        when (this) {
            is Paragraph.Recommended -> description
            is Paragraph.Other -> description
            else -> ""
        }

val Paragraph.option
    get() =
        when (this) {
            is Paragraph.Recommended -> DesignSystem.Option.item(title = "Della8's rekommendation", value = rawValue, data = this)
            is Paragraph.Other -> DesignSystem.Option.other(title = "Annat", value = rawValue, data = this, other = description)
            else -> DesignSystem.Option.None
        }

// We migrate Recommended values to Other if the recommendations has changed
fun Paragraph.migrate(recommendation: Paragraph.Recommended) =
    (this as? Paragraph.Recommended)?.let { recommended ->
        if (recommended.description != recommendation.description)
            Paragraph.Other(description = recommended.description)
        else
            recommended
    } ?: this

data class EditableParagraph(
    val style: Style,
    val recommendation: String = "",
    val description: String = "",
) {
    enum class Style { NONE, RECOMMENDED, OTHER }

    companion object {
        fun fromTruth(truth: Paragraph, recommendation: Paragraph.Recommended? = null) =
            when (truth) {
                is Paragraph.None ->
                    EditableParagraph(
                        style = Style.NONE,
                        recommendation = recommendation?.description ?: "",
                    )
                is Paragraph.Recommended ->
                    EditableParagraph(
                        style = Style.RECOMMENDED,
                        recommendation = truth.description,
                    )
                is Paragraph.Other ->
                    EditableParagraph(
                        style = Style.OTHER,
                        description = truth.description,
                        recommendation = recommendation?.description ?: "",
                    )
                else ->
                    EditableParagraph(
                        style = Style.OTHER,
                        description = "",
                        recommendation = recommendation?.description ?: "",
                    )
            }
    }

    val truth
        get() =
            when (style) {
                Style.NONE -> Paragraph.None
                Style.RECOMMENDED -> Paragraph.Recommended(description = recommendation)
                Style.OTHER -> Paragraph.Other(description = description)
            }
}

val EditableParagraph.Style.option
    get() =
        when (this) {
            EditableParagraph.Style.NONE -> DesignSystem.Option.item(title = "Ingen", value = name, data = this)
            EditableParagraph.Style.RECOMMENDED -> DesignSystem.Option.item(title = "Della8 rekommenderar", value = name, data = this)
            EditableParagraph.Style.OTHER -> DesignSystem.Option.other(title = "Annat", value = name, data = this)
        }
