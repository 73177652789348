package della8.web.items

import bootstrap.bCard
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.items.RuleItem
import della8.core.screens.RuleValue
import della8.core.support.DesignSystem
import della8.core.support.EditableAddress
import della8.web.components.*
import della8.web.support.className
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import techla.base.*

external interface RuleItemAddressProps : PropsWithChildren {
    var className: String
    var viewModel: RuleItem.ViewModel.Address
    var onChange: (Identifier<RuleItem>, RuleValue) -> Unit
    var onInfo: (String, Boolean) -> Unit
}


val D8RuleItemAddress = FC<RuleItemAddressProps> { props ->
    fun onStyle(value: DesignSystem.Option) {
        (props.viewModel.value as? RuleValue.AddressValue)?.let { addressValue ->
            (value.data as? EditableAddress.Style)?.let {
                props.onChange(props.viewModel.id, RuleValue.AddressValue(addressValue.value.copy(style = it)))
            }
        }
    }

    /*
    value.value?.let {
                val style = EditableAddress.Style.valueOf(it)
                props.onChange(props.viewModel.id, RuleValue.AddressValue(addressValue.value.copy(style = style)))
            }
     */

    fun onStreet(value: String) {
        (props.viewModel.value as? RuleValue.AddressValue)?.let { addressValue ->
            props.onChange(props.viewModel.id, RuleValue.AddressValue(addressValue.value.copy(street = value)))
        }
    }

    fun onPostalCode(value: Pair<String, Int?>) {
        (props.viewModel.value as? RuleValue.AddressValue)?.let { addressValue ->
            props.onChange(props.viewModel.id, RuleValue.AddressValue(addressValue.value.copy(postalCode = value)))
        }
    }

    fun onCity(value: String) {
        (props.viewModel.value as? RuleValue.AddressValue)?.let { addressValue ->
            props.onChange(props.viewModel.id, RuleValue.AddressValue(addressValue.value.copy(city = value)))
        }
    }

    fun onOther(value: String) {
        (props.viewModel.value as? RuleValue.AddressValue)?.let { addressValue ->
            props.onChange(props.viewModel.id, RuleValue.AddressValue(addressValue.value.copy(description = value)))
        }
    }

    bCard {
        className = className("radius-10 border-0 card-shadow my-3 px-4 py-3")
        bContainer {
            className = className("p-0")
            D8RuleItemHeader {
                viewModel = props.viewModel
                onChange = props.onChange
                onInfo = props.onInfo
            }
            if (props.viewModel.value !is RuleValue.Off) {
                div {
                    className = className("flex-row")
                    if (props.viewModel.editable) {
                        bRow {
                            className = className("mb-3")
                            d8SelectInput(classNameInput = "d-inline mb-2", design = props.viewModel.style, onClick = ::onStyle)
                        }
                        if (props.viewModel.street.visible || props.viewModel.postalCode.visible || props.viewModel.city.visible) {
                            bRow {
                                div {
                                    className = className("pt-2")
                                    d8TextInput(design = props.viewModel.street, onChange = ::onStreet)
                                }
                            }
                            bRow {
                                div {
                                    className = className("pt-2")
                                    d8TextInput(design = props.viewModel.postalCode, onChangeInt = ::onPostalCode)
                                }
                            }
                            bRow {
                                div {
                                    className = className("pt-2")
                                    d8TextInput(design = props.viewModel.city, onChange = ::onCity)
                                }
                            }
                        }
                        div {
                            className = className("pt-2")
                            d8TextInput(design = props.viewModel.other, onChange = ::onOther)
                        }
                    } else {
                        d8Text(design = props.viewModel.show)
                        d8Element(design = props.viewModel.showStreet)
                        d8Element(design = props.viewModel.showPostalCode)
                        d8Element(design = props.viewModel.showCity)
                    }
                }
            }
            D8RuleItemFooter {
                viewModel = props.viewModel
            }
        }
    }
}