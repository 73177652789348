package della8.web.theme

import della8.core.support.DesignSystem

object Della8Typography {
    val mega = "d8-mega"
    val largetitle = "d8-large-title"
    val title1 = "d8-title1"
    val title2 = "d8-title2"
    val title3 = "d8-title3"
    val headline = "d8-headline"
    val subhead = "d8-subhead"
    val body = "d8-body"
    val proud = "d8-proud"
    val footnote = "d8-footnote"
}


val DesignSystem.TextAlignment.textAlignment
    get() =
        when (this) {
            DesignSystem.TextAlignment.NONE -> ""
            DesignSystem.TextAlignment.LEFT -> "text-left"
            DesignSystem.TextAlignment.CENTER -> "text-center"
            DesignSystem.TextAlignment.RIGHT -> "text-right"
        }