package della8.web.components

import bootstrap.bCol
import bootstrap.bRow
import della8.core.support.DesignSystem
import della8.web.support.className
import della8.web.theme.Della8Colors.designColor
import della8.web.theme.background
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p

external interface D8MonthProps : PropsWithChildren {
    var className: String?
    var page: String?
    var design: DesignSystem.Month
    var onClick: suspend (DesignSystem.Cell) -> Unit
}



val D8Month = FC<D8MonthProps>("D8Month") { props ->
    props.design.let {
        div {
            className = className("pb-2")
            d8Text(className = "d-inline pe-2", design = it.year)
            d8Text(className = "d-inline", design = it.month)
        }
        it.rows.map { row ->
            bRow {
                className = className("position-relative")

                div {
                    className = className(handleGroup(row.annotations))
                    setAnnotationToImage(row.annotations)?.let { it1 -> d8Image(it1) }
                }

                div {
                    className = className("d8-week")
                    p {
                        className = className("small mb-0 mt-1 text-muted")
                        +"v";
                        +row.text
                    }

                }

                row.cells.map { cell ->
                    bCol {
                        className = className("text-center")
                        div {
                            className = className(handleCursor(cell.annotations, props.page ?: ""))
                            onClick = {
                                MainScope().launch { props.onClick(cell) }
                            }
                            bRow {
                                className = className("position-relative")
                                bCol {
                                    className = className(
                                        listOfNotNull(
                                            "mt-1",
                                            "cellIcon p-0 m-0",
                                            setStart(cell.annotations),
                                        )
                                    )
                                }
                                if (cell.text != "") {
                                    bCol {
                                        className = className(
                                            listOfNotNull(
                                                "mt-1",
                                                "cellIcon p-0 m-0",
                                                setEnd(cell.annotations),
                                            )
                                        )
                                    }
                                    div {
                                        className = className("w-100 mt-1 position-absolute")
                                        div {
                                            className = className(
                                                listOfNotNull(
                                                    "cellCircle  mx-auto",
                                                    bgColor(cell.annotations),
                                                )
                                            )
                                            p {
                                                className = className(
                                                    listOfNotNull(
                                                        "mb-0 pt-1",
                                                        textColor(cell.annotations)
                                                    )
                                                )
                                                +cell.text
                                            }
                                        }

                                    }
                                }
                            }
                        }
                    }

                }
            }

        }

    }

}


private fun textColor(annotations: List<DesignSystem.Annotation>): String =
    when (annotations.firstOrNull()) {
        DesignSystem.Annotation.BOOKED_ME_START -> "text-white"
        DesignSystem.Annotation.BOOKED_ME -> "text-white"
        DesignSystem.Annotation.BOOKED_ME_END -> "text-white"
        DesignSystem.Annotation.BOOKED_OTHER_START -> "text-white"
        DesignSystem.Annotation.BOOKED_OTHER -> "text-white"
        DesignSystem.Annotation.BOOKED_OTHER_END -> "text-white"
        DesignSystem.Annotation.ONGOING_START -> "text-white"
        DesignSystem.Annotation.TODAY -> "text-white"
        DesignSystem.Annotation.LOCKED -> "d8-battleship"
        else -> ""
    }


private fun bgColor(annotations: List<DesignSystem.Annotation>): String =
    if (annotations.contains(DesignSystem.Annotation.TODAY)) {
        designColor(DesignSystem.Color.BATTLESHIP).background
    } else if (annotations.contains(DesignSystem.Annotation.BOOKED_ME_START) && annotations.contains(DesignSystem.Annotation.ONGOING_END)) {
        designColor(DesignSystem.Color.BLUEBERRY).background
    } else if (annotations.contains(DesignSystem.Annotation.BOOKED_OTHER_START) && annotations.contains(DesignSystem.Annotation.ONGOING_END)) {
        designColor(DesignSystem.Color.LAVENDER).background
    } else {
        ""
    }



private fun setAnnotationToImage(annotations: List<DesignSystem.Annotation>): DesignSystem.ImageView? =
    if (annotations.size <= 1) {
        when (annotations.firstOrNull()) {
            DesignSystem.Annotation.LOCKED -> DesignSystem.ImageView(image = DesignSystem.Image.BOOKING_LOCK, alt = annotations.toString())
            DesignSystem.Annotation.GOLD -> DesignSystem.ImageView(image = DesignSystem.Image.BOOKING_GOLD, alt = annotations.toString())
            DesignSystem.Annotation.GOLD_START -> DesignSystem.ImageView(image = DesignSystem.Image.BOOKING_GOLD, alt = annotations.toString())
            DesignSystem.Annotation.GOLD_END -> DesignSystem.ImageView(image = DesignSystem.Image.BOOKING_GOLD, alt = annotations.toString())
            else -> null
        }
    } else {
        DesignSystem.ImageView(image = DesignSystem.Image.BOOKING_GOLD_LOCKED, alt = annotations.toString())
    }


private fun handleGroup(annotations: List<DesignSystem.Annotation>): String =
    when (annotations.firstOrNull()) {
        DesignSystem.Annotation.GOLD_START -> "n-booking-annotations n-booking-annotations-group n-booking-annotations-start py-1 px-1 m-0"
        DesignSystem.Annotation.GOLD_END -> "n-booking-annotations n-booking-annotations-group n-booking-annotations-end py-1 px-1 m-0"
        DesignSystem.Annotation.GOLD -> "n-booking-annotations n-booking-annotations-group py-1 px-1 m-0"
        else -> "n-booking-annotations py-1 px-1 m-0"
    }



fun handleCursor(annotations: List<DesignSystem.Annotation>, page: String): String =
    if (page === "availability") {
        when (annotations.firstOrNull()) {
            DesignSystem.Annotation.BOOKED_ME_START -> "cursorAvailability"
            DesignSystem.Annotation.BOOKED_ME -> "cursorAvailability"
            DesignSystem.Annotation.BOOKED_ME_END -> "cursorAvailability"
            DesignSystem.Annotation.BOOKED_OTHER_START -> "cursorAvailability"
            DesignSystem.Annotation.BOOKED_OTHER -> "cursorAvailability"
            DesignSystem.Annotation.BOOKED_OTHER_END -> "cursorAvailability"
            DesignSystem.Annotation.LOCKED -> "cursorReservation"
            else -> ""
        }
    } else if (page === "reservation") {
        if (annotations.filter { it != DesignSystem.Annotation.TODAY && it != DesignSystem.Annotation.ONGOING_END && it != DesignSystem.Annotation.ONGOING_START }.size >= 2) {
            "cursorReservation"
        } else {
            when (annotations.firstOrNull()) {
                DesignSystem.Annotation.BOOKED_ME_START -> "cursorAvailability"
                DesignSystem.Annotation.BOOKED_ME -> "cursorReservation"
                DesignSystem.Annotation.BOOKED_ME_END -> "cursorAvailability"
                DesignSystem.Annotation.BOOKED_OTHER_START -> "cursorAvailability"
                DesignSystem.Annotation.BOOKED_OTHER -> "cursorReservation"
                DesignSystem.Annotation.BOOKED_OTHER_END -> "cursorAvailability"
                DesignSystem.Annotation.LOCKED -> "cursorReservation"
                DesignSystem.Annotation.ONGOING -> "cursorAvailability"
                DesignSystem.Annotation.ONGOING_START -> "cursorAvailability"
                DesignSystem.Annotation.ONGOING_END -> "cursorAvailability"
                else -> "cursorAvailability"
            }
        }
    } else {
        ""
    }



fun setEnd(annotations: List<DesignSystem.Annotation>): String =
    if (annotations.filter { it != DesignSystem.Annotation.TODAY }.size == 1) {
        annotations.map {
            when (it) {
                DesignSystem.Annotation.BOOKED_OTHER_END -> "d8-lavender-background d8-lavender-stroke cellEnd"
                DesignSystem.Annotation.BOOKED_ME_END -> "d8-blueberry-background d8-blueberry-stroke cellEnd"
                DesignSystem.Annotation.BOOKED_OTHER -> "d8-lavender-background d8-lavender-stroke"
                DesignSystem.Annotation.BOOKED_ME -> "d8-blueberry-background d8-blueberry-stroke"
                DesignSystem.Annotation.BOOKED_OTHER_START -> "d8-lavender-background d8-lavender-stroke"
                DesignSystem.Annotation.BOOKED_ME_START -> "d8-blueberry-background d8-blueberry-stroke"
                DesignSystem.Annotation.ONGOING -> "ongoing"
                DesignSystem.Annotation.ONGOING_START -> "ongoing d8-blueberry-background d8-blueberry-stroke"
                DesignSystem.Annotation.ONGOING_END -> "ongoing ongoingEnd"
                else -> ""
            }
        }.firstOrNull() ?: ""
    } else {
        if (annotations.contains(DesignSystem.Annotation.ONGOING_START)) {
            "ongoing d8-blueberry-background d8-blueberry-stroke"
        } else if (annotations.contains(DesignSystem.Annotation.BOOKED_ME_START)) {
            "d8-blueberry-background d8-blueberry-stroke"
        } else if (annotations.contains(DesignSystem.Annotation.BOOKED_OTHER_START)) {
            "d8-lavender-background d8-lavender-stroke"
        } else if (annotations.contains(DesignSystem.Annotation.BOOKED_ME)) {
            "d8-blueberry-background d8-blueberry-stroke"
        } else if (annotations.contains(DesignSystem.Annotation.BOOKED_OTHER)) {
            "d8-lavender-background d8-lavender-stroke"
        } else if (annotations.contains(DesignSystem.Annotation.BOOKED_ME_END)) {
            "d8-blueberry-background d8-blueberry-stroke"
        } else if (annotations.contains(DesignSystem.Annotation.BOOKED_OTHER_END)) {
            "d8-lavender-background d8-lavender-stroke"
        } else if (annotations.contains(DesignSystem.Annotation.ONGOING)) {
            "ongoing"
        } else if (annotations.contains(DesignSystem.Annotation.ONGOING_END)) {
            "ongoing"
        } else {
            ""
        }
    }


fun setStart(annotations: List<DesignSystem.Annotation>): String =
    if (annotations.filter { it != DesignSystem.Annotation.TODAY }.size == 1) {
        annotations.map {
            when (it) {
                DesignSystem.Annotation.BOOKED_ME_START -> "d8-blueberry-background d8-blueberry-stroke cellStart"
                DesignSystem.Annotation.BOOKED_OTHER_START -> "d8-lavender-background d8-lavender-stroke cellStart"
                DesignSystem.Annotation.BOOKED_OTHER -> "d8-lavender-background d8-lavender-stroke"
                DesignSystem.Annotation.BOOKED_ME -> "d8-blueberry-background d8-blueberry-stroke"
                DesignSystem.Annotation.BOOKED_OTHER_END -> "d8-lavender-background d8-lavender-stroke"
                DesignSystem.Annotation.BOOKED_ME_END -> "d8-blueberry-background d8-blueberry-stroke"
                DesignSystem.Annotation.ONGOING_START -> "ongoing ongoingStart d8-blueberry-background d8-blueberry-stroke cellStart"
                DesignSystem.Annotation.ONGOING -> "ongoing"
                DesignSystem.Annotation.ONGOING_END -> "ongoing"
                else -> ""
            }
        }.firstOrNull() ?: ""
    } else {
        if (annotations.contains(DesignSystem.Annotation.ONGOING_END)) {
            "ongoing"
        } else if (annotations.contains(DesignSystem.Annotation.BOOKED_ME_END)) {
            "d8-blueberry-background d8-blueberry-stroke"
        } else if (annotations.contains(DesignSystem.Annotation.BOOKED_OTHER_END)) {
            "d8-lavender-background d8-lavender-stroke"
        } else if (annotations.contains(DesignSystem.Annotation.BOOKED_ME)) {
            "d8-blueberry-background d8-blueberry-stroke"
        } else if (annotations.contains(DesignSystem.Annotation.BOOKED_OTHER)) {
            "d8-lavender-background d8-lavender-stroke"
        } else if (annotations.contains(DesignSystem.Annotation.BOOKED_ME_START)) {
            "d8-blueberry-background d8-blueberry-stroke"
        } else if (annotations.contains(DesignSystem.Annotation.BOOKED_OTHER_START)) {
            "d8-lavender-background d8-lavender-stroke"
        } else if (annotations.contains(DesignSystem.Annotation.ONGOING)) {
            "ongoing"
        } else if (annotations.contains(DesignSystem.Annotation.ONGOING_START)) {
            "ongoing"
        } else {
            ""
        }
    }



fun ChildrenBuilder.d8Month(
    design: DesignSystem.Month,
    className: String? = null,
    onClick: suspend (DesignSystem.Cell) -> Unit,
    page: String
) =
    D8Month {
        this.className = className
        this.design = design
        this.onClick = onClick
        this.page = page
    }
