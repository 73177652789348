package della8.web.views

import StoreContext
import bootstrap.bCol
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.screens.ProfileScreen
import della8.core.support.DesignSystem
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.*
import react.*
import react.dom.html.ButtonType
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.router.useParams
import kotlin.time.ExperimentalTime


val ProfileView = FC<PropsWithChildren>("ProfileView") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(ProfileScreen.ViewModel.None as ProfileScreen.ViewModel)
    val navigate = useNavigate()

    val scopedCall = scopedCall<ProfileScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    val mainCall = mainCall<ProfileScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    suspend fun handleSave(formData: Map<String, FormValue>) {
        val firstName = formData.getValue(ProfileScreen.Header.firstName.id).string
        val lastName = formData.getValue(ProfileScreen.Header.lastName.id).string
        val email = formData.getValue(ProfileScreen.Header.email.id).string
        val phone = formData.getValue(ProfileScreen.Header.phone.id).string
        ProfileScreen.validate(sceneInputOf(store, viewModel), firstName, lastName, email, phone).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    fun handleDone() =
        mainCall { ProfileScreen.load(sceneInputOf(store, viewModel)) }


    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is ProfileScreen.ViewModel.None -> {
                scopedCall(coroutineScope) { ProfileScreen.start(sceneInputOf(store, viewModel)) }
            }

            is ProfileScreen.ViewModel.Loading -> {
                scopedCall(coroutineScope) { ProfileScreen.load(sceneInputOf(store, viewModel)) }
            }

            is ProfileScreen.ViewModel.Saving -> {
                scopedCall(coroutineScope) { ProfileScreen.save(sceneInputOf(store, viewModel)) }
            }

            else -> {
            }
        }
    }

    fun logout() {
        mainCall { ProfileScreen.logout(sceneInputOf(store, viewModel)) }
        navigate("/")
    }

    main {
        when (viewModel) {
            is ProfileScreen.ViewModel.Ready -> d8Navigation(
                design = viewModel.navigation,
                className = "nav-top",
                onClick = { action ->
                    when (action) {
                        DesignSystem.Action.BACK -> navigate("/landing")
                        else -> {}
                    }
                })

            else -> d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
        }

        if (viewModel is ProfileScreen.ViewModel.Ready) {
            bContainer {
                className = className("px-5 position-relative n-bottom-space n-top-space")
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        xs = 12; md = 7
                        d8Text(className = "text-center", design = viewModel.title)
                        d8Text(className = "text-center", design = viewModel.body)

                    }
                }
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        xs = 12; md = 7
                        D8Form {
                            this.onSubmit = ::handleSave
                            d8TextInput(classNameInput = "mb-2", design = viewModel.firstName)
                            d8TextInput(classNameInput = "mb-2", design = viewModel.lastName)
                            d8TextInput(classNameInput = "mb-2", design = viewModel.email)
                            d8TextInput(classNameInput = "mb-2", design = viewModel.phone)
                            d8Button(design = viewModel.save, type = ButtonType.submit)
                        }
                        d8Text(className = "text-center mt-4", design = viewModel.cancelAccount)
                    }

                }
            }
        }

        if (viewModel is ProfileScreen.ViewModel.Saved) {
            bContainer {
                className = className("px-5 n-top-space")

                bRow {
                    className = className("justify-content-center")

                    bCol {
                        xs = 12; md = 6
                        d8Icon(className = "d-block mx-auto", design = viewModel.icon)
                        d8Text(className = "text-center", design = viewModel.title)
                        d8Text(className = "text-center", design = viewModel.info)
                    }
                }
                bRow {
                    bCol {
                        className = className("p-0 pb-4 d-flex justify-content-center")
                        d8Button(
                            className = "btn-lg mt-3 px-5",
                            design = viewModel.done,
                            onClick = { handleDone() }
                        )
                    }
                }
            }
        }

        if (viewModel is ProfileScreen.ViewModel.Loading) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is ProfileScreen.ViewModel.Saving) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is ProfileScreen.ViewModel.Failed) {
            d8failure(viewModel.failure, ::logout)
        }
    }
}
