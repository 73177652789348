package della8.web.theme

import della8.core.support.DesignSystem

object Della8Colors {
    val clear = ""
    val blueberry = "d8-blueberry"
    val snow = "d8-snow"
    val licorice = "d8-licorice"
    val battleship = "d8-battleship"
    val outerspace = "d8-outerspace"
    val pearl = "d8-pearl"
    val dusty = "d8-dusty"
    val ocean = "d8-ocean"
    val lavender = "d8-lavender"
    val marine = "d8-marine"
    val sea = "d8-sea"
    val peach = "d8-peach"
    val ruby = "d8-ruby"
    val neon = "d8-neon"

    fun designColor(image: DesignSystem.Color?): String {
        return when (image) {
            DesignSystem.Color.CLEAR -> clear
            DesignSystem.Color.DUSTY -> dusty
            DesignSystem.Color.OCEAN -> ocean
            DesignSystem.Color.LAVENDER -> lavender
            DesignSystem.Color.MARINE -> marine
            DesignSystem.Color.NEON -> neon
            DesignSystem.Color.BLUEBERRY -> blueberry
            DesignSystem.Color.PEACH -> peach
            DesignSystem.Color.BATTLESHIP -> battleship
            DesignSystem.Color.SEA -> sea
            DesignSystem.Color.RUBY -> ruby
            DesignSystem.Color.PEARL -> pearl
            DesignSystem.Color.SNOW -> snow
            null -> ""
        }
    }
}

val String.background get() = if (isEmpty()) "" else "$this-bg"