package bootstrap

import react.ComponentType
import react.PropsWithChildren

@JsNonModule
@JsModule("react-bootstrap/AccordionBody")
private external val accordionBodyModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bAccordionBody: ComponentType<AccordionBodyProps> = accordionBodyModule.default

external interface AccordionBodyProps : PropsWithChildren {
    var `as`: Any // Set a custom element for this component
    var bsPrefix: String // Change the underlying component CSS base class name and modifier class names prefix.
}
