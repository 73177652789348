package della8.web.items

import bootstrap.bCard
import bootstrap.bContainer
import della8.core.items.RuleItem
import della8.core.screens.EditableInt
import della8.core.screens.RuleValue
import della8.web.components.*
import della8.web.support.className
import react.ChildrenBuilder
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import techla.base.Identifier

external interface RuleItemTextInputProps : PropsWithChildren {
    var className: String?
    var viewModel: RuleItem.ViewModel.TextInput
    var onChange: (Identifier<RuleItem>, RuleValue) -> Unit
    var onInfo: (String, Boolean) -> Unit
}


val D8RuleItemTextInput = FC<RuleItemTextInputProps> { props ->
    fun onChange(value: String) {
        props.onChange(props.viewModel.id, RuleValue.StringValue(value))
    }

    fun onChangeInt(value: Pair<String, Int?>) {
        if (value.second != null)
            props.onChange(props.viewModel.id, RuleValue.IntValue(EditableInt(value)))
        else
            props.onChange(props.viewModel.id, RuleValue.IntValue(EditableInt(value)))
    }

    bCard {
        className = className("radius-10 border-0 card-shadow my-3 px-4 py-3")
        bContainer {
            className = className("p-0")
            D8RuleItemHeader {
                viewModel = props.viewModel
                onChange = props.onChange
                onInfo = props.onInfo
            }
            if (props.viewModel.value !is RuleValue.Off) {
                div {
                    if (props.viewModel.editable) {
                        d8TextInput(design = props.viewModel.edit, onChange = ::onChange, onChangeInt = ::onChangeInt)
                    } else {
                        d8Text(className = "d-inline mb-2", design = props.viewModel.show)
                    }
                }
            }
            D8RuleItemFooter {
                viewModel = props.viewModel
            }
        }
    }
}


fun ChildrenBuilder.ruleItemTextInput(
    viewModel: RuleItem.ViewModel.TextInput,
    className: String = "",
    onChange: (Identifier<RuleItem>, RuleValue) -> Unit,
    onInfo: (String, Boolean) -> Unit
) =
    D8RuleItemTextInput {
        this.className = className
        this.viewModel = viewModel
        this.onChange = onChange
        this.onInfo = onInfo
    }