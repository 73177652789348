package della8.core.items

import della8.core.native.formatDateTimeLong
import della8.core.support.DesignSystem
import della8.core.support.Object
import io.ktor.http.*
import techla.base.Identifier
import techla.conversation.Message
import techla.conversation.Reaction

val Message.articleHero
    get() =
        publicImages
            .firstOrNull { it.key.rawValue == "HERO" }
            ?.url


val Message.articlePartner
    get() =
        publicImages
            .firstOrNull { it.key.rawValue == "PARTNER" }
            ?.url

val Message.articleTitle
    get() =
        inlineTitles
            .firstOrNull { it.key.rawValue == "TITLE" }
            ?.title ?: ""

val Message.articleIntro
    get() =
        inlineTexts
            .firstOrNull { it.key.rawValue == "INTRO" }
            ?.text ?: ""

val Message.articleBody
    get() =
        inlineTexts
            .firstOrNull { it.key.rawValue == "BODY" }
            ?.text ?: ""

val Message.partnerArticle
    get() =
        articlePartner != null

val Message.feedExternalImage
    get() =
        publicImages
            .firstOrNull { it.key.rawValue == "1" }
            ?.url

val Message.imageId
    get() =
        assetImages.firstOrNull()?.assetId
val Message.movieId
    get() =
        assetMovies.firstOrNull()?.assetId

val Message.feedBody
    get() =
        inlineTexts
            .firstOrNull { it.key.rawValue == "TEXT" }
            ?.text ?: ""

val Reaction.Kind.type: String?
    get() =
        when (this) {
            is Reaction.Kind.Comment -> text
            else -> null
        }

fun getMuxToken(url: Url?, char: String = "=") =
    url.toString().substringAfter(char)

object MessageItem {

    object Header {
        val menu = DesignSystem.Header(id = "menu")
    }

    sealed class ViewModel(open val id: Identifier<MessageItem>) {

        data class Article(
            override val id: Identifier<MessageItem>,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val body: DesignSystem.Text,
            val more: DesignSystem.Button,
            val cooperation: DesignSystem.Text,
            val partner: DesignSystem.ImageView,
        ) : ViewModel(id = id)

        data class Blog(
            override val id: Identifier<MessageItem>,
            val images: DesignSystem.Carousel,
            val movieSource: DesignSystem.MovieView,
            val title: DesignSystem.Text,
            val body: DesignSystem.Text,
            val initials: DesignSystem.Profile,
            val author: DesignSystem.Text,
            val date: DesignSystem.Text,
            val reactions: DesignSystem.Text,
            val menu: DesignSystem.SelectInput,
            val like: DesignSystem.Button,
            val comment: DesignSystem.Button,
            val reaction: DesignSystem.Button,
            val likeAnimation: Boolean,
        ) : ViewModel(id = id)

        data class Reaction(
            override val id: Identifier<MessageItem>,
            val reaction: DesignSystem.ImageView,
            val initials: DesignSystem.Profile,
            val author: DesignSystem.Text,
            val date: DesignSystem.Text,
            val comment: DesignSystem.Text,
        ) : ViewModel(id = id)
    }

    fun article(message: Message) =
        ViewModel.Article(
            id = Identifier(message.id.rawValue),
            image = DesignSystem.ImageView(href = message.articleHero.toString(), alt = "Della8"),
            title = DesignSystem.Text(text = message.articleTitle, style = DesignSystem.TextStyle.HEADLINE, background = DesignSystem.Background.LIGHT),
            body = DesignSystem.Text(text = message.articleIntro, style = DesignSystem.TextStyle.BODY, background = DesignSystem.Background.LIGHT),
            more = DesignSystem.Button(title = "Läs mer", style = DesignSystem.ButtonStyle.PRIMARY, visible = true),
            cooperation = DesignSystem.Text(text = "I samarbete med", style = DesignSystem.TextStyle.BODY, visible = message.partnerArticle),
            partner = if (message.partnerArticle)
                DesignSystem.ImageView(href = message.articlePartner?.toString())
            else
                DesignSystem.ImageView(alt = "Della8", image = DesignSystem.Image.ARTICLE_LOGO)
        )

    fun blog(message: Message, obj: Object, profile: String, author: String, canEdit: Boolean, images: List<Url> = emptyList(), muxMovie: Triple<String?, String?, String?>? = null): ViewModel.Blog {

        return ViewModel.Blog(
            id = Identifier(message.id.rawValue),
            images = DesignSystem.Carousel(images = images.map { DesignSystem.ImageView(href = it.toString()) }, visible = images.isNotEmpty()),
            movieSource = DesignSystem.MovieView(href = muxMovie?.second, muxPlaybackToken = muxMovie?.first, muxThumbnailToken = muxMovie?.third, player = DesignSystem.MoviePlayer.MUX, loop = false, muted = false, autoplay = false, showControls = true, visible = muxMovie != null),
            title = DesignSystem.Text(text = message.articleTitle, style = DesignSystem.TextStyle.HEADLINE, background = DesignSystem.Background.LIGHT),
            body = DesignSystem.Text(text = message.feedBody, style = DesignSystem.TextStyle.BODY, background = DesignSystem.Background.LIGHT, isMarkdown = true),
            initials = DesignSystem.Profile(initials = profile),
            author = DesignSystem.Text(text = author),
            date = DesignSystem.Text(text = formatDateTimeLong(message.timestamp, obj.resource.timeZone).dropLast(5), textColor = DesignSystem.Color.BATTLESHIP),
            reactions = DesignSystem.Text(text = "${message.comments + message.likes} reaktioner", textColor = DesignSystem.Color.BATTLESHIP, visible = message.comments > 0 || message.likes > 0),
            menu = DesignSystem.SelectInput(
                header = LandingItem.Header.menu,
                style = DesignSystem.SelectStyle.DROP_DOWN,
                visible = canEdit,
                options = listOf(
                    DesignSystem.Option.item(title = "Ta bort", action = DesignSystem.Action.REMOVE, data = message.id.rawValue),
                    DesignSystem.Option.item(title = "Ändra", action = DesignSystem.Action.EDIT, data = message.id.rawValue)
                )
            ),
            likeAnimation = message.liked,
            like = DesignSystem.Button(image = if (message.liked) DesignSystem.Image.FEED_LIKE_FILLED else DesignSystem.Image.FEED_LIKE_OUTLINED, action = DesignSystem.Action.LIKE, style = DesignSystem.ButtonStyle.IMAGE, background = DesignSystem.Color.CLEAR, data = message.id.rawValue),
            comment = DesignSystem.Button(image = DesignSystem.Image.FEED_EDIT, action = DesignSystem.Action.COMMENT, style = DesignSystem.ButtonStyle.IMAGE, background = DesignSystem.Color.CLEAR, data = message.id.rawValue),
            reaction = DesignSystem.Button(image = DesignSystem.Image.FEED_REACTIONS, action = DesignSystem.Action.REACTIONS, style = DesignSystem.ButtonStyle.IMAGE, background = DesignSystem.Color.CLEAR, data = message.id.rawValue)
        )
    }

    fun reaction(reaction: Reaction, obj: Object, profile: String, author: String) =
        ViewModel.Reaction(
            id = Identifier(reaction.id.rawValue),
            reaction = DesignSystem.ImageView(image = DesignSystem.Image.FEED_LIKE_FILLED, visible = reaction.kind == Reaction.Kind.Like),
            initials = DesignSystem.Profile(initials = profile),
            author = DesignSystem.Text(text = author),
            date = DesignSystem.Text(text = formatDateTimeLong(reaction.timestamp, obj.resource.timeZone).dropLast(5), textColor = DesignSystem.Color.BATTLESHIP),
            comment = DesignSystem.Text(text = reaction.kind.type, visible = reaction.kind.type != null),
        )
}
