package della8.core.support

import techla.base.Classification

val Classification.displayName
    get() =
        when (this) {
            is Classification.MotorHome -> "Husbil"
            is Classification.HolidayHome -> "Fritidshus"
            is Classification.Yacht -> "Båt"
            is Classification.Other -> description
            else -> ""
        }

val Classification.option
    get() =
        when (this) {
            is Classification.MotorHome -> DesignSystem.Option.item(image = DesignSystem.Image.MOTOR_HOME, title = displayName, value = rawValue, data = this)
            is Classification.HolidayHome -> DesignSystem.Option.item(image = DesignSystem.Image.HOLIDAY_HOME, title = displayName, value = rawValue, data = this)
            is Classification.Yacht -> DesignSystem.Option.item(image = DesignSystem.Image.YACHT_HOME, title = displayName, value = rawValue, data = this)
            is Classification.None -> DesignSystem.Option.item(image = DesignSystem.Image.PRESENT_AGREEMENT, title = "Hoppa över", value = rawValue, data = this)
            is Classification.Other -> DesignSystem.Option.other(image = DesignSystem.Image.OTHER_OBJECT, title = "Annat", value = rawValue, data = this, other = description)
            else -> DesignSystem.Option.None
        }