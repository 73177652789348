package bootstrap

import csstype.ClassName
import org.w3c.dom.events.Event
import react.*

@JsNonModule
@JsModule("react-bootstrap/Card")
private external val cardModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bCard: ComponentType<CardProps> = cardModule.default

@Suppress("UnsafeCastFromDynamic")
val bCardHeader: ComponentType<CardHeaderProps> = cardModule.default.Header

@Suppress("UnsafeCastFromDynamic")
val bCardBody: ComponentType<CardBodyProps> = cardModule.default.Body

@Suppress("UnsafeCastFromDynamic")
val bCardFooter: ComponentType<CardFooterProps> = cardModule.default.Footer

external interface CardProps : PropsWithChildren {
    var className: ClassName
    var bg: String /* 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' */
    var body: Boolean
    var border: String /* 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' */
    var text: String /* 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'light' | 'white' | 'muted' */
    var onClick: (Event) -> Unit
    var bsPrefix: String
}

external interface CardHeaderProps : PropsWithChildren {
}

external interface CardBodyProps : PropsWithChildren {
    var className: ClassName
    var bsPrefix: String
}

external interface CardFooterProps : PropsWithChildren {
    var className: ClassName
}
