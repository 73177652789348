package della8.web.items

import bootstrap.*
import della8.core.items.MessageItem
import della8.core.support.DesignSystem
import della8.web.components.*
import della8.web.support.className
import della8.web.support.useDelayedAsyncEffect
import della8.web.theme.Design
import lottie.lPlayer
import react.*
import react.dom.html.ReactHTML.div
import techla.base.leftOf
import techla.base.rightOf

external interface MessageItemBlogProps : PropsWithChildren {
    var viewModel: MessageItem.ViewModel.Blog
    var onClick: (option: DesignSystem.Option) -> Unit
}


val MessageItemBlog = FC<MessageItemBlogProps>("MessageItemBlog") { props ->
    val (animation, setAnimation) = useState(false)

    suspend fun handleOption(option: DesignSystem.Option? = null, id: String? = null, action: DesignSystem.Action? = null) {
        if (option != null)
            props.onClick(option)
        else {
            props.onClick(DesignSystem.Option.item(action = action, data = id))
        }
    }

    useDelayedAsyncEffect(700, props.viewModel.likeAnimation) {
        if (props.viewModel.likeAnimation && animation)
            setAnimation(!animation)
    }

    div {
        className = className("py-2 py-md-2 position-relative")
        div {
            className = className("px-5 d-flex align-items-center ")
            d8Element(className = "", design = props.viewModel.initials)
            d8Text(className = "px-1 d-block m-0", design = props.viewModel.author)
        }
        div {
            className = className("px-1 py-2 px-md-4 py-md-2 align-items-stretch")
            div {
                className = className("d-flex flex-column")

                div {
                    className = className("triangle")

                }
                bCard {
                    className = className("radius-25 border-0 card-shadow")
                    bCardBody {
                        className = className("p-0")

                        div {
                            className = className("p-3")
                            div {
                                className = className("position-relative")
                                D8Carousel { design = props.viewModel.images }
                                d8Movie(className = "img-fluid radius-25", design = props.viewModel.movieSource)

                                if (animation && (props.viewModel.images.visible || props.viewModel.movieSource.visible))
                                    div {
                                        className = className("position-absolute top-50 start-50 translate-middle")
                                        lPlayer {
                                            animationData = Design.animation(DesignSystem.Animation.LIKE)
                                            loop = true
                                            autoplay = true
                                        }
                                    }
                            }

                            div {
                                className = className("py-2")
                                d8Text(design = props.viewModel.title)
                                d8Text(className = "d8-marine",design = props.viewModel.body)

                                d8Text(className = "d-block m-0", design = props.viewModel.date)
                                div {
                                    className = className("pt-4 pt-md-4 d-flex align-items-center ")
                                    d8Button(className = "p-1", design = props.viewModel.like, onClick = {
                                        handleOption(action = props.viewModel.like.action, id = props.viewModel.like.data.toString()); setAnimation(!props.viewModel.likeAnimation)
                                    })

                                    d8Button(className = "p-1", design = props.viewModel.comment, onClick = { handleOption(action = props.viewModel.comment.action, id = props.viewModel.comment.data.toString()) })
                                    d8Button(className = "p-1", design = props.viewModel.reaction, onClick = { handleOption(action = props.viewModel.reaction.action, id = props.viewModel.comment.data.toString()) })
                                    d8Text(className = "w-100 m-0 pl-1 ps-2", design = props.viewModel.reactions)
                                    div {
                                        className = className("container-fluid px-0 d-flex align-items-center justify-content-end")
                                        d8SelectInput(classNameInput = "d-inline text-center", design = props.viewModel.menu, onClick = ::handleOption)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (animation && !props.viewModel.images.visible && !props.viewModel.movieSource.visible)
            div {
                className = className("position-absolute top-50 start-50 translate-middle")
                lPlayer {
                    animationData = Design.animation(DesignSystem.Animation.LIKE)
                    loop = true
                    autoplay = true

                }
            }
    }
}


fun ChildrenBuilder.messageItemBlog(viewModel: MessageItem.ViewModel.Blog, onClick: (DesignSystem.Option) -> Unit = {}) =
    MessageItemBlog {
        this.viewModel = viewModel
        this.onClick = onClick
    }
