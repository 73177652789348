package della8.core.support

import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime
import techla.base.*
import techla.reservation.Availability
import techla.reservation.Reservation
import kotlin.time.Duration.Companion.days

val Availability.Restriction.reservationId: Identifier<Reservation>?
    get() =
        when (this) {
            is Availability.Restriction.Unknown -> null
            is Availability.Restriction.None -> null
            is Availability.Restriction.BookedByMe -> id
            is Availability.Restriction.BookedByOther -> id
            is Availability.Restriction.Locked -> null
        }

fun Date.neutralize() =
    Date.dateAt(year = dateTime.year, month = dateTime.monthNumber, day = dateTime.dayOfMonth, hour = 12, minute = 0, second = 0)


object AvailabilityHelper {
    interface Texts {
        val january: String
        val february: String
        val mars: String
        val april: String
        val may: String
        val june: String
        val july: String
        val august: String
        val september: String
        val october: String
        val november: String
        val december: String
    }

    data class WeekDay(
        val date: Date,
        val restrictions: List<Availability.Restriction>,
    ) {
        val year get() = date.dateTime.year
        val month get() = date.dateTime.monthNumber
        val dayOfMonth get() = date.dateTime.dayOfMonth
        val isToday get() = isEqual(Date.now())
        val id get() = Identifier<DesignSystem.Cell>(DateSerializer.serialize(date))
        fun isEqual(date: Date?) = date?.let { this.date.neutralize().dateTime == it.neutralize().dateTime  } ?: false
        fun isInRange(start: Date?, end: Date?) = start?.let { s -> end?.let { e -> this.date.neutralize().dateTime >= s.neutralize().dateTime && this.date.neutralize().dateTime <= e.neutralize().dateTime }} ?: false
    }

    data class Week(
        val number: Int,
        val start: Availability,
        val end: Availability,
        val monday: WeekDay?,
        val tuesday: WeekDay?,
        val wednesday: WeekDay?,
        val thursday: WeekDay?,
        val friday: WeekDay?,
        val saturday: WeekDay?,
        val sunday: WeekDay?,
    ) {
        val days
            get() =
                listOf(monday, tuesday, wednesday, thursday, friday, saturday, sunday)
    }

    data class Month(
        val year: Int,
        val monthNumber: Int,
        val weeks: List<Week>,
    ) {
        fun month(texts: Texts) =
            when (monthNumber) {
                1 -> texts.january
                2 -> texts.february
                3 -> texts.mars
                4 -> texts.april
                5 -> texts.may
                6 -> texts.june
                7 -> texts.july
                8 -> texts.august
                9 -> texts.september
                10 -> texts.october
                11 -> texts.november
                12 -> texts.december
                else -> ""
            }
    }

    fun buildItems(texts: Texts, obj: Object, availability: List<Availability>, style: Reservation.Style, startsAt: Date?, endsAt: Date?): List<DesignSystem.Month> {
        // Dates are given in UTC, but needs to be in tz of resource
        val zone = TimeZone.of(obj.resource.timeZone)
        val zoned = availability.map {
            it.copy(date = Date(it.date.toKotlinxInstant().toLocalDateTime(zone)))
        }
        val months = zoned.groupBy { tupleOf(it.date.dateTime.year, it.date.dateTime.monthNumber) }
        return months.map { (group, days) ->
            val weeks = days.groupBy { it.date.weekOfYear }
            Month(
                year = group.first,
                monthNumber = group.second,
                weeks = weeks.map { (number, days) ->
                    Week(
                        number = number,
                        start = days.first(),
                        end = days.last(),
                        monday = days.getOrNull(DayOfWeek.MONDAY)?.let { WeekDay(it.date, it.restrictions) },
                        tuesday = days.getOrNull(DayOfWeek.TUESDAY)?.let { WeekDay(it.date, it.restrictions) },
                        wednesday = days.getOrNull(DayOfWeek.WEDNESDAY)?.let { WeekDay(it.date, it.restrictions) },
                        thursday = days.getOrNull(DayOfWeek.THURSDAY)?.let { WeekDay(it.date, it.restrictions) },
                        friday = days.getOrNull(DayOfWeek.FRIDAY)?.let { WeekDay(it.date, it.restrictions) },
                        saturday = days.getOrNull(DayOfWeek.SATURDAY)?.let { WeekDay(it.date, it.restrictions) },
                        sunday = days.getOrNull(DayOfWeek.SUNDAY)?.let { WeekDay(it.date, it.restrictions) },
                    )
                }
            )
        }
            .map { month ->
                DesignSystem.Month(
                    month = DesignSystem.Text(
                        text = month.month(texts = texts),
                        style = DesignSystem.TextStyle.HEADLINE
                    ),
                    year = DesignSystem.Text(text = month.year.toString(), style = DesignSystem.TextStyle.HEADLINE),
                    rows = month.weeks.map { week ->
                        DesignSystem.Row(
                            text = week.number.toString(),
                            cells = week.days.map { weekday ->
                                DesignSystem.Cell(
                                    id = weekday?.id ?: Identifier(""),
                                    text = weekday?.dayOfMonth?.toString() ?: "",
                                    annotations = (checkBookings(weekday = weekday) + checkToday(weekday = weekday) + checkOngoing(weekday = weekday, obj = obj, style = style, startsAt = startsAt, endsAt = endsAt))
                                        .also { annotations ->
                                            if (annotations.isNotEmpty()) {
                                              //  techla_log("ANNOTATIONS: date='${weekday?.date}', a=${annotations.joinToString(", ") { it.name }}")
                                            }
                                        },
                                    reservations = weekday?.restrictions?.mapNotNull { it.reservationId } ?: emptyList(),
                                )
                            },
                            annotations = (checkHighValueWeek(week = week) + checkLockedWeek(week = week))
                                .also { annotations ->
                                    if (annotations.isNotEmpty()) {
                                      //  techla_log("ANNOTATIONS: week='${week.number}', a=${annotations.joinToString(", ") { it.name }}")
                                    }
                                },
                        )
                    }
                )
            }
    }

    private fun checkBookings(weekday: WeekDay?): List<DesignSystem.Annotation> {
        return (weekday?.restrictions ?: emptyList()).mapNotNull { r ->
            when (r) {
                is Availability.Restriction.BookedByMe ->
                    when {
                        r.isStart -> DesignSystem.Annotation.BOOKED_ME_START
                        r.isEnd -> DesignSystem.Annotation.BOOKED_ME_END
                        else -> DesignSystem.Annotation.BOOKED_ME
                    }
                is Availability.Restriction.BookedByOther ->
                    when {
                        r.isStart -> DesignSystem.Annotation.BOOKED_OTHER_START
                        r.isEnd -> DesignSystem.Annotation.BOOKED_OTHER_END
                        else -> DesignSystem.Annotation.BOOKED_OTHER
                    }
                is Availability.Restriction.Locked ->
                    DesignSystem.Annotation.LOCKED
                else -> null
            }
        }
    }

    private fun checkToday(weekday: WeekDay?): List<DesignSystem.Annotation> {
        return if (weekday?.isToday == true)
            listOf(DesignSystem.Annotation.TODAY)
        else
            emptyList()
    }

    private fun checkOngoing(weekday: WeekDay?, obj: Object, style: Reservation.Style, startsAt: Date?, endsAt: Date?): List<DesignSystem.Annotation> {
        val zone = TimeZone.of(obj.resource.timeZone)
        val numberOfDays = obj.duration(style)
        val suggestedEndsAt = numberOfDays?.let { startsAt?.dateTime?.toInstant(zone)?.plus(it.days)?.toTechlaDate() }
        return when {
            weekday?.isEqual(startsAt) == true && endsAt == null -> listOf(DesignSystem.Annotation.ONGOING_START)
            weekday?.isEqual(suggestedEndsAt) == true && endsAt == null -> listOf(DesignSystem.Annotation.ONGOING_END)
            weekday?.isInRange(startsAt, suggestedEndsAt) == true && endsAt == null -> listOf(DesignSystem.Annotation.ONGOING)
            weekday?.isEqual(startsAt) == true && endsAt != null -> listOf(DesignSystem.Annotation.BOOKED_ME_START)
            weekday?.isEqual(endsAt) == true && endsAt != null -> listOf(DesignSystem.Annotation.BOOKED_ME_END)
            weekday?.isInRange(startsAt, endsAt) == true && endsAt != null -> listOf(DesignSystem.Annotation.BOOKED_ME)
            else -> emptyList()
        }
    }

    private fun checkHighValueWeek(week: Week): List<DesignSystem.Annotation> {
        val start = week.start.value
        val end = week.end.value
        return when {
            start is Availability.Value.High && start.isStart -> listOf(DesignSystem.Annotation.GOLD_START)
            end is Availability.Value.High && end.isEnd -> listOf(DesignSystem.Annotation.GOLD_END)
            start is Availability.Value.High -> listOf(DesignSystem.Annotation.GOLD)
            else -> emptyList()
        }
    }

    private fun checkLockedWeek(week: Week): List<DesignSystem.Annotation> {
        val locks = week.start.restrictions.filterIsInstance<Availability.Restriction.Locked>()
        return if (locks.isNotEmpty())
            listOf(DesignSystem.Annotation.LOCKED)
        else
            emptyList()
    }
}
