package della8.web.admin

import StoreContext
import bootstrap.bCard
import bootstrap.bCol
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.admin.DashboardScreen
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.className
import della8.web.support.standardNavigation
import della8.web.support.useAsyncEffect
import kotlinx.coroutines.isActive
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML
import react.router.useNavigate
import react.useContext
import react.useState

val D8Dashboard = FC<PropsWithChildren>("DASHBOARD") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(DashboardScreen.ViewModel.None as DashboardScreen.ViewModel)
    val navigate = useNavigate()

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is DashboardScreen.ViewModel.None ->
                DashboardScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }
            is DashboardScreen.ViewModel.Loading -> {
                DashboardScreen.load(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }
            }
            else -> {
            }
        }
    }

    ReactHTML.main {
        d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
        if (viewModel is DashboardScreen.ViewModel.Ready) {
            bContainer {
                className = className("px-5 position-relative n-bottom-space n-top-space")
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        xs = 12; md = 7
                        bCard {
                            className = className("radius-10 border-0 card-shadow my-3 px-4 py-3")
                            bContainer {
                                bRow {
                                    d8Text(className = "n-markdown-dark", design = viewModel.body)
                                }
                            }
                        }
                    }
                }
            }
        }

        if (viewModel is DashboardScreen.ViewModel.Failed) {
            bContainer {
                className =  className("px-5 n-top-space")
                d8failure(viewModel.failure)
            }
        }
    }
}