package della8.core.screens

import della8.core.items.ModuleItem
import della8.core.services.editAgreement
import della8.core.services.editGroup
import della8.core.support.*
import techla.agreement.Agreement
import techla.base.*
import techla.guard.Group

object ObjectScreen {
    data class Texts(
        val back: String,
        val add: String,
        val owners: String,
        val draft: String,
        val pending: String,
        override val progressInfo: String,
        override val failureTitle: String,
        override val successTitle: String,
        override val successNext: String,
        override val successInfo: String,
    ) : ProgressTexts, FailureTexts, SuccessTexts, ClippyTexts {
        companion object
    }

    data class State(
        val obj: Object = Object.None,
    )

    sealed class ViewModel(open val texts: Texts, open val state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", ""),
            state = State(Object.None),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Ready(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val movie: DesignSystem.MovieView,
            val draft: DesignSystem.Draft,
            val items: List<ModuleItem.ViewModel>,
            val clippy: DesignSystem.Clippy,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Launching(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Launched(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val success: DesignSystem.Success,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Failed(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        fun loading(texts: Texts) =
            Loading(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(texts = texts),
            )

        fun ready(
            state: State,
            items: List<ModuleItem.ViewModel>,
            title: String,
            movie: DesignSystem.Movie,
            status: Group.Status,
        ) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.objectLight(obj = state.obj, title = texts.back, logoLocation = Location.Landing, active = Location.overview(state.obj), backLocation = Location.Landing),
                title = DesignSystem.Text(
                    text = title,
                    style = DesignSystem.TextStyle.TITLE1,
                    background = DesignSystem.Background.LIGHT
                ),
                movie = DesignSystem.MovieView(movie = movie),
                draft = status.draft(draft = texts.draft, pending = texts.pending),
                items = items,
                clippy = clippy(texts, visible = state.obj.currentAgreement.status !is Agreement.Status.Approved),
            )

        fun launching() =
            Launching(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(texts = texts),
            )

        fun launched() =
            Launched(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(movie = DesignSystem.Movie.FIREWORKS),
                success = success(texts = texts, background = DesignSystem.Background.DARK),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false) =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoad get() = this as? Loading
        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        val texts = Texts(
            back = "Till objekten",
            add = "Lägg till objekt",
            owners = "SAMÄGARE",
            draft = "Utkast",
            pending = "Väntar på godkännande",
            progressInfo = "Laddar...",
            failureTitle = "Oj, ett fel har uppstått",
            successTitle = "Kör i vind!",
            successInfo = "Objekt uppe, regler satta och samägare är överens och ni har ett signerat avtal. Nu är det bara för er att njuta av ett enklare samägande och nyttja tjänster som vi byggt för att underlätta för er.",
            successNext = "Fortsätt",
        )
        return sceneOf<ViewModel>(viewModel.loading(texts = texts))

    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(listOf(Module.Partnership, Module.Booking)).noActions()
            .flatMap { (actions, modules) ->
                store.reduce(actions).refreshObject(objectId = objectId, agreementId = agreementId)
                    .accumulate(actions)
                    .map { tupleOf(it.first, modules, it.second) }
            }
            .map { (actions, modules, obj) ->
                val state = viewModel.state.copy(obj = obj)
                sceneOf<ViewModel>(
                    viewModel.ready(
                        state = state,
                        items = buildItems(store = store, texts = viewModel.texts, obj = obj, modules = modules),
                        title = obj.group.name,
                        movie = obj.group.visualization.movie,
                        status = obj.group.status,
                    ), actions
                )
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun launch(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(1).noActions()
            .map { (actions, _) ->
                sceneOf<ViewModel>(viewModel.launching(), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun liftoff(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val obj = viewModel.state.obj
        val edit = Group.Edit(
            status = modifiedOf(Group.Status.Active)
        )
        return store.editGroup(obj = obj, edit = edit)
            .flatMap { (actions, _) ->
                // TODO: Security problem?
                val edit2 = Agreement.Edit(
                    status = modifiedOf(Agreement.Status.Approved)
                )
                store.reduce(actions).editAgreement(obj = obj, id = obj.currentAgreement.id, edit = edit2)
                    .accumulate(actions)
            }
            .map { (actions, _) ->
                sceneOf<ViewModel>(viewModel.launched(), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun success(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return load(scene = scene, objectId = viewModel.state.obj.id, agreementId = viewModel.state.obj.currentAgreement.id)
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }

    private fun buildItems(store: Store, texts: Texts, obj: Object, modules: List<Module>): List<ModuleItem.ViewModel> {
        val items = modules.mapNotNull { module ->
            when {
                module is Module.Partnership && module.stateFor(obj) is Module.State.Start ->
                    ModuleItem.partnershipStart(
                        id = Identifier(),
                        disabled = !obj.isGroupLeader(),
                        image = DesignSystem.Image.STEP1,
                        title = "Samägande",
                        info = "Regler för hur samägandeskapet ska se ut."
                    )

                module is Module.Partnership && module.stateFor(obj) is Module.State.Pending ->
                    ModuleItem.partnershipPending(
                        id = Identifier(),
                        disabled = false,
                        image = DesignSystem.Image.STEP1,
                        title = "Samägande",
                        info = "Regler för hur samägandeskapet ska se ut."
                    )

                module is Module.Partnership ->
                    ModuleItem.partnership(
                        id = Identifier(),
                        disabled = false,
                        image = DesignSystem.Image.BLOCK_OWNERSHIP,
                        title = "Samägande",
                        info = "Regler för hur samägandeskapet ska se ut."
                    )

                module is Module.Booking && module.stateFor(obj) is Module.State.Start ->
                    ModuleItem.bookingStart(
                        id = Identifier(),
                        disabled = modules.stateForPartnership(obj) is Module.State.Start || !obj.isGroupLeader(),
                        image = DesignSystem.Image.STEP2,
                        title = "Bokning",
                        info = "Smart och rättvis bokning."
                    )

                module is Module.Booking && module.stateFor(obj) is Module.State.Pending ->
                    ModuleItem.bookingPending(
                        id = Identifier(),
                        disabled = false,
                        image = DesignSystem.Image.STEP2,
                        title = "Bokning",
                        info = "Smart och rättvis bokning."
                    )

                module is Module.Booking ->
                    ModuleItem.booking(
                        id = Identifier(),
                        image = DesignSystem.Image.BLOCK_BOOKING,
                        disabled = false,
                        title = "Bokning",
                        info = "Smart och rättvis bokning."
                    )

                else -> null
            }
        }
        val launch = if (obj.isGroupActive)

            if (obj.currentAgreement.status !is Agreement.Status.Approved) {
                listOf(
                    ModuleItem.approve(
                        id = Identifier(),
                        disabled = !(obj.isRulesReady && obj.hasGroupLeaderSigned().isNotEmpty() || obj.isGroupLeader()),
                        image = DesignSystem.Image.STEP3,
                        title = "Godkänn avtalet",
                        info = "Godkänn avtal som skapats utifrån inställda avtalsregler."
                    ),
                ) + if (obj.isGroupLeader())
                    listOf(
                        ModuleItem.status(
                            id = Identifier(),
                            disabled = !(obj.hasInvites && obj.group.members.count() > 1 && obj.isGroupLeader() && obj.group.members.count() == obj.signatures.count()),
                            image = DesignSystem.Image.STEP5,
                            title = "Anslutna samägare",
                            info = "När anslutna samägare accepterat avtalet, kör igång!",
                            approved = "Underskrivet avtal:",
                            pending = "Ansluten, ej skrivit under avtal:",
                            approvedList = obj.bios.filter { bio -> obj.signatures.map { it.profileId }.contains(bio.profileId) }.map { it.initials },
                            pendingList = obj.bios.filter { bio -> !obj.signatures.map { it.profileId }.contains(bio.profileId) }.map { it.initials },
                        ),
                    ) else
                    emptyList()
            } else {
                emptyList()
            }
        else
            listOf(
                ModuleItem.approve(
                    id = Identifier(),
                    disabled = !obj.isRulesReady,
                    image = DesignSystem.Image.STEP3,
                    title = "Godkänn avtalet",
                    info = "Godkänn avtal som skapats utifrån inställda avtalsregler."
                ),
            ) + if (obj.isGroupLeader())
                listOf(
                    ModuleItem.invite(
                        id = Identifier(),
                        disabled = !(obj.signatures.haveIApproved(store = store) && obj.isGroupLeader()),
                        image = DesignSystem.Image.STEP4,
                        title = "Bjud in",
                        info = "Bjud in samägare att gå med på objektet."
                    ),
                    ModuleItem.status(
                        id = Identifier(),
                        disabled = !(obj.hasInvites && obj.group.members.count() > 1 && obj.isGroupLeader() && obj.group.members.count() == obj.signatures.count()),
                        image = DesignSystem.Image.STEP5,
                        title = "Anslutna samägare",
                        info = "När anslutna samägare accepterat avtalet, kör igång!",
                        approved = "Underskrivet avtal:",
                        pending = "Ansluten, ej skrivit under avtal:",
                        approvedList = obj.bios.filter { bio -> obj.signatures.map { it.profileId }.contains(bio.profileId) }.map { it.initials },
                        pendingList = obj.bios.filter { bio -> !obj.signatures.map { it.profileId }.contains(bio.profileId) }.map { it.initials },
                    ),
                )
            else
                emptyList()
        return items + launch
    }
}