package della8.core.items

import della8.core.native.formatDecimal
import della8.core.native.formatNumber
import della8.core.screens.RuleValue
import della8.core.support.*
import techla.base.*

val DesignSystem.Header.ruleItem
    get() =
        Identifier<RuleItem>(rawValue = id)

val Identifier<RuleItem>.header
    get() =
        DesignSystem.Header(id = rawValue)

fun Identifier<RuleItem>.header(index: Int) =
    DesignSystem.Header(id = "$rawValue-$index")

object RuleItem {
    data class Header(
        val title: DesignSystem.Text,
        val onOff: DesignSystem.Button,
        val info: DesignSystem.Button,
        val infoText: String?,
        val agreementText: DesignSystem.Text,
    )

    data class Footer(
        val tips: DesignSystem.Text,
    )

    sealed class ViewModel(
        open val id: Identifier<RuleItem>,
        open val editable: Boolean,
        open val value: RuleValue,
        open val header: Header,
        open val footer: Footer,
        open val status: DesignSystem.Status,
    ) {
        data class TextInput(
            override val id: Identifier<RuleItem>,
            override val editable: Boolean,
            override val value: RuleValue,
            override val header: Header,
            override val footer: Footer,
            override val status: DesignSystem.Status,
            val edit: DesignSystem.TextInput,
            val show: DesignSystem.Text,
        ) : ViewModel(id = id, editable = editable, value = value, header = header, footer = footer, status = status) {
            companion object
        }

        data class SelectInput(
            override val id: Identifier<RuleItem>,
            override val editable: Boolean,
            override val value: RuleValue,
            override val header: Header,
            override val footer: Footer,
            override val status: DesignSystem.Status,
            val edit: DesignSystem.SelectInput,
            val other: DesignSystem.TextInput,
            val show: DesignSystem.Text,
        ) : ViewModel(id = id, editable = editable, value = value, header = header, footer = footer, status = status) {
            companion object
        }

        data class ImageInput(
            override val id: Identifier<RuleItem>,
            override val editable: Boolean,
            override val value: RuleValue,
            override val header: Header,
            override val footer: Footer,
            override val status: DesignSystem.Status,
            val edit: DesignSystem.ImageInput,
            val other: DesignSystem.TextInput,
            val show: DesignSystem.Property,
        ) : ViewModel(id = id, editable = editable, value = value, header = header, footer = footer, status = status) {
            companion object
        }

        data class List(
            override val id: Identifier<RuleItem>,
            override val editable: Boolean,
            override val value: RuleValue,
            override val header: Header,
            override val footer: Footer,
            override val status: DesignSystem.Status,
            val edit: DesignSystem.SelectInput,
            val add: DesignSystem.Button,
            val list: DesignSystem.ListView,
            val show: DesignSystem.Text,
        ) : ViewModel(id = id, editable = editable, value = value, header = header, footer = footer, status = status) {
            companion object
        }

        data class DoubleList(
            override val id: Identifier<RuleItem>,
            override val editable: Boolean,
            override val value: RuleValue,
            override val header: Header,
            override val footer: Footer,
            override val status: DesignSystem.Status,
            val edit1: DesignSystem.SelectInput,
            val edit2: DesignSystem.SelectInput,
            val add: DesignSystem.Button,
            val list: DesignSystem.ListView,
            val show: DesignSystem.Text,
        ) : ViewModel(id = id, editable = editable, value = value, header = header, footer = footer, status = status) {
            companion object
        }

        data class Ownership(
            override val id: Identifier<RuleItem>,
            override val editable: Boolean,
            override val value: RuleValue,
            override val header: Header,
            override val footer: Footer,
            override val status: DesignSystem.Status,
            val style: DesignSystem.SelectInput,
            val govId: DesignSystem.TextInput,
            val firstName: DesignSystem.TextInput,
            val lastName: DesignSystem.TextInput,
            val share: DesignSystem.TextInput,
            val other: DesignSystem.TextInput,
            val add: DesignSystem.Button,
            val list: DesignSystem.ListView,
            val show: DesignSystem.Text,
        ) : ViewModel(id = id, editable = editable, value = value, header = header, footer = footer, status = status) {
            companion object
        }

        data class Financing(
            override val id: Identifier<RuleItem>,
            override val editable: Boolean,
            override val value: RuleValue,
            override val header: Header,
            override val footer: Footer,
            override val status: DesignSystem.Status,
            val style: DesignSystem.SelectInput,
            val amount: DesignSystem.TextInput,
            val due: DesignSystem.TextInput,
            val account: DesignSystem.TextInput,
            val other: DesignSystem.TextInput,
            val show: DesignSystem.Text,
            val showAmount: DesignSystem.Property,
            val showDue: DesignSystem.Property,
            val showAccount: DesignSystem.Property,
        ) : ViewModel(id = id, editable = editable, value = value, header = header, footer = footer, status = status) {
            companion object
        }

        data class Address(
            override val id: Identifier<RuleItem>,
            override val editable: Boolean,
            override val value: RuleValue,
            override val header: Header,
            override val footer: Footer,
            override val status: DesignSystem.Status,
            val style: DesignSystem.SelectInput,
            val street: DesignSystem.TextInput,
            val postalCode: DesignSystem.TextInput,
            val city: DesignSystem.TextInput,
            val other: DesignSystem.TextInput,
            val show: DesignSystem.Text,
            val showStreet: DesignSystem.Property,
            val showPostalCode: DesignSystem.Property,
            val showCity: DesignSystem.Property,
        ) : ViewModel(id = id, editable = editable, value = value, header = header, footer = footer, status = status) {
            companion object
        }

        data class Paragraph(
            override val id: Identifier<RuleItem>,
            override val editable: Boolean,
            override val value: RuleValue,
            override val header: Header,
            override val footer: Footer,
            override val status: DesignSystem.Status,
            val edit: DesignSystem.SelectInput,
            val recommended: DesignSystem.Text,
            val other: DesignSystem.TextInput,
            val show: DesignSystem.Text,
        ) : ViewModel(id = id, editable = editable, value = value, header = header, footer = footer, status = status) {
            companion object
        }

        data class ConditionsOfSale(
            override val id: Identifier<RuleItem>,
            override val editable: Boolean,
            override val value: RuleValue,
            override val header: Header,
            override val footer: Footer,
            override val status: DesignSystem.Status,
            val edit: DesignSystem.SelectInput,
            val other: DesignSystem.TextInput,
            val show: DesignSystem.Text,
        ) : ViewModel(id = id, editable = editable, value = value, header = header, footer = footer, status = status) {
            companion object
        }

        data class Adherence(
            override val id: Identifier<RuleItem>,
            override val editable: Boolean,
            override val value: RuleValue,
            override val header: Header,
            override val footer: Footer,
            override val status: DesignSystem.Status,
            val edit: DesignSystem.SelectInput,
            val other: DesignSystem.TextInput,
            val show: DesignSystem.Text,
        ) : ViewModel(id = id, editable = editable, value = value, header = header, footer = footer, status = status) {
            companion object
        }

        data class SimpleSection(
            override val id: Identifier<RuleItem>,
            override val editable: Boolean,
            override val value: RuleValue,
            override val header: Header,
            override val footer: Footer,
            val visible: Boolean,
        ) : ViewModel(id = id, editable = editable, value = value, header = header, footer = footer, status = DesignSystem.Status.Valid) {
            companion object
        }
    }

    private fun header(editable: Boolean, title: String?, value: RuleValue, info: String?, titleStyle: DesignSystem.TextStyle = DesignSystem.TextStyle.SUBHEAD): Header {
        val image = when (value) {
            is RuleValue.Off -> DesignSystem.Image.RULE_OFF_BTN
            else -> DesignSystem.Image.RULE_ON_BTN
        }

        val agreementText = when (editable) {
            true -> "Läggs till i avtalet"
            else -> "Står i avtalet"
        }
        return Header(
            title = DesignSystem.Text(text = title, style = titleStyle, visible = title != null),
            onOff = DesignSystem.Button(image = image, background = DesignSystem.Color.CLEAR, style = DesignSystem.ButtonStyle.IMAGE, visible = editable),
            info = DesignSystem.Button(image = DesignSystem.Image.INFO, background = DesignSystem.Color.CLEAR, style = DesignSystem.ButtonStyle.IMAGE, visible = !info.isNullOrEmpty()),
            infoText = info,
            agreementText = DesignSystem.Text(text = agreementText, visible = value !is RuleValue.Off, image = DesignSystem.Image.AGREEMENT_SM, iconAlignment = DesignSystem.IconAlignment.LEFT),
        )
    }

    private fun footer(tips: String?) =
        Footer(
            tips = DesignSystem.Text(text = "Della8 tipsar - $tips", style = DesignSystem.TextStyle.BODY, visible = !tips.isNullOrEmpty(), image = DesignSystem.Image.TIP, iconAlignment = DesignSystem.IconAlignment.LEFT),
        )

    fun textInput(id: Identifier<RuleItem>, editable: Boolean, title: String, value: RuleValue, input: DesignSystem.Input = DesignSystem.Input.TEXT, unit: String? = null, info: String? = null, tips: String? = null, status: DesignSystem.Status): ViewModel {
        val text = when (value) {
            is RuleValue.StringValue -> value.value
            is RuleValue.IntValue -> value.value.value.first
            else -> null
        }

        return ViewModel.TextInput(
            id = id,
            editable = editable,
            value = value,
            header = header(editable = editable, title = title, value = value, info = info),
            footer = footer(tips),
            edit = DesignSystem.TextInput(header = id.header, value = text, input = input, unit = unit, status = status),
            show = DesignSystem.Text(text = text),
            status = status,
        )
    }

    fun selectInput(id: Identifier<RuleItem>, editable: Boolean, title: String, choices: List<DesignSystem.Option>, value: RuleValue, info: String? = null, tips: String? = null, status: DesignSystem.Status): ViewModel {
        val selected = when (value) {
            is RuleValue.OptionValue -> value.value
            else -> null
        }
        val other = selected as? DesignSystem.Option.Other
        return ViewModel.SelectInput(
            id = id,
            editable = editable,
            value = value,
            header = header(editable = editable, title = title, value = value, info = info),
            footer = footer(tips = tips),
            edit = DesignSystem.SelectInput(header = id.header, options = choices, selected = selected),
            other = if (other != null)
                DesignSystem.TextInput(header = id.header, value = other.other, input = DesignSystem.Input.NOVEL)
            else
                DesignSystem.TextInput(header = id.header, visible = false),
            show = if (other != null)
                DesignSystem.Text(text = other.other)
            else
                DesignSystem.Text(text = selected?.title),
            status = status,
        )
    }

    fun imageInput(id: Identifier<RuleItem>, editable: Boolean, title: String?, otherTitle: String, otherPlaceholder: String?, choices: List<DesignSystem.Option>, value: RuleValue, info: String? = null, tips: String? = null, status: DesignSystem.Status): ViewModel {
        val selected = when (value) {
            is RuleValue.OptionValue -> value.value
            else -> null
        }
        val options = if (editable) choices else listOf(selected ?: choices.first())
        val other = selected as? DesignSystem.Option.Other
        return ViewModel.ImageInput(
            id = id,
            editable = editable,
            value = value,
            header = header(editable = editable, title = title, value = value, info = info),
            footer = footer(tips),
            edit = DesignSystem.ImageInput(header = id.header(1), options = options, selected = if (editable) selected else null, status = status),
            other = if (other != null)
                DesignSystem.TextInput(header = id.header(2), title = otherTitle, value = other.other, input = DesignSystem.Input.TEXT, placeholder = otherPlaceholder)
            else
                DesignSystem.TextInput(header = id.header(2), visible = false),
            show = if (other != null)
                DesignSystem.Property(
                    header = DesignSystem.Text(text = otherTitle, style = DesignSystem.TextStyle.SUBHEAD),
                    value = DesignSystem.Text(text = other.other)
                )
            else
                DesignSystem.Property(visible = false),
            status = status,
        )
    }

    fun list(id: Identifier<RuleItem>, editable: Boolean, title: String, add: String, choices: List<DesignSystem.Option>, value: RuleValue, info: String? = null, tips: String? = null, status: DesignSystem.Status): ViewModel {
        val options = when (value) {
            is RuleValue.OptionsValue -> value.value
            else -> null
        }
        return ViewModel.List(
            id = id,
            editable = editable,
            value = value,
            header = header(editable = editable, title = title, value = value, info = info),
            footer = footer(tips),
            edit = DesignSystem.SelectInput(header = id.header, options = choices),
            add = DesignSystem.Button(title = add),
            list = DesignSystem.ListView(
                items = options?.mapIndexed { index, option ->
                    DesignSystem.ListItem(
                        index = index,
                        text = DesignSystem.Text(text = option.title),
                        action = DesignSystem.Button(image = DesignSystem.Image.TRASH, background = DesignSystem.Color.CLEAR, style = DesignSystem.ButtonStyle.IMAGE),
                    )
                }
            ),
            show = DesignSystem.Text(text = options?.map { it.title }?.joinToString(", ")),
            status = status,
        )
    }

    fun doubleList(id: Identifier<RuleItem>, editable: Boolean, title: String, add: String, choices1Label: String? = null, choices1: List<DesignSystem.Option>, choices2Label: String? = null, choices2: List<DesignSystem.Option>, value: RuleValue, itemTitle: (Pair<DesignSystem.Option, DesignSystem.Option>) -> String, info: String? = null, tips: String? = null, status: DesignSystem.Status): ViewModel {
        val options = when (value) {
            is RuleValue.DoubleOptionsValue -> value.value
            else -> null
        }
        return ViewModel.DoubleList(
            id = id,
            editable = editable,
            value = value,
            header = header(editable = editable, title = title, value = value, info = info),
            footer = footer(tips),
            edit1 = DesignSystem.SelectInput(header = id.header(1), title = choices1Label, options = choices1, selected = choices1.first(), status = status),
            edit2 = DesignSystem.SelectInput(header = id.header(2), title = choices2Label, options = choices2, selected = choices2.first()),
            add = DesignSystem.Button(title = add),
            list = DesignSystem.ListView(
                items = options?.mapIndexed { index, options ->
                    DesignSystem.ListItem(
                        index = index,
                        text = DesignSystem.Text(text = itemTitle(options)),
                        action = DesignSystem.Button(image = DesignSystem.Image.TRASH, background = DesignSystem.Color.CLEAR, style = DesignSystem.ButtonStyle.IMAGE),
                    )
                }
            ),
            show = DesignSystem.Text(text = options?.joinToString(", ") { itemTitle(it) }),
            // We hardcode the status to Valid because we validate the input on every change in the high value week input,
            // therefor the status cannot be Invalid. This might be a problem if we use DoubleList on more places in the
            // future.
            // Setting `status = status` here, show the correct validation but also invalidates the whole item meaning that
            // we cannot advance to the next page in the form.
            status = DesignSystem.Status.Valid,
        )
    }

    fun ownership(id: Identifier<RuleItem>, editable: Boolean, title: String, add: String, choices: List<DesignSystem.Option>, value: RuleValue, info: String? = null, tips: String? = null, status: DesignSystem.Status): ViewModel {
        val ownership = when (value) {
            is RuleValue.OwnershipValue -> value.value
            else -> null
        }
        val selected = choices.firstOrNull { it.data == ownership?.style }
        val isShares = ownership?.style == EditableOwnership.Style.SHARES
        val isOther = ownership?.style == EditableOwnership.Style.OTHER
        return ViewModel.Ownership(
            id = id,
            editable = editable,
            value = value,
            header = header(editable = editable, title = title, value = value, info = info),
            footer = footer(tips),
            style = DesignSystem.SelectInput(header = id.header(1), options = choices, selected = selected),
            govId = if (isShares)
                DesignSystem.TextInput(header = id.header(2), title = "Personnummer (10 eller 12 siffror)", value = "", input = DesignSystem.Input.GOV_ID)
            else
                DesignSystem.TextInput(header = id.header(2), visible = false),
            firstName = if (isShares)
                DesignSystem.TextInput(header = id.header(3), title = "Förnamn", value = "")
            else
                DesignSystem.TextInput(header = id.header(3), visible = false),
            lastName = if (isShares)
                DesignSystem.TextInput(header = id.header(4), title = "Efternamn", value = "")
            else
                DesignSystem.TextInput(header = id.header(4), visible = false),
            share = if (isShares)
                DesignSystem.TextInput(header = id.header(5), value = "", input = DesignSystem.Input.NUMBER, placeholder = "Andel", unit = "st")
            else
                DesignSystem.TextInput(header = id.header(5), visible = false),
            other = if (isOther)
                DesignSystem.TextInput(header = id.header(7), value = ownership?.description, input = DesignSystem.Input.NOVEL)
            else
                DesignSystem.TextInput(header = id.header(7), visible = false),
            add = if (isShares)
                DesignSystem.Button(title = add)
            else
                DesignSystem.Button(visible = false),
            list = DesignSystem.ListView(
                items = (ownership?.shares?.mapIndexed { index, s ->
                    val (firstName, lastName, govId, share) = s
                    DesignSystem.ListItem(
                        index = index,
                        text = DesignSystem.Text(text = "$firstName $lastName, $govId"),
                        text2 = DesignSystem.Text(text = "${formatNumber(share.second)} st"),
                        action = if (editable)
                            DesignSystem.Button(image = DesignSystem.Image.TRASH, background = DesignSystem.Color.CLEAR, style = DesignSystem.ButtonStyle.IMAGE)
                        else null,
                    )
                } ?: emptyList())
            ),
            show = if (isOther)
                DesignSystem.Text(text = ownership?.description, isMarkdown = true)
            else
                DesignSystem.Text(visible = false),
            status = status,
        )
    }

    fun financing(id: Identifier<RuleItem>, editable: Boolean, title: String, choices: List<DesignSystem.Option>, value: RuleValue, info: String? = null, tips: String? = null, status: DesignSystem.Status): ViewModel {
        val financing = when (value) {
            is RuleValue.FinancingValue -> value.value
            else -> null
        }
        val selected = choices.firstOrNull { it.data == financing?.style }
        val amountTitle = "Hur mycket avgift ska var och en betala?"
        val dueTitle = "När avgiften ska betalas in?"
        val accountTitle = "Var ska avgiften betalas in?"
        return ViewModel.Financing(
            id = id,
            editable = editable,
            value = value,
            header = header(editable = editable, title = title, value = value, info = info),
            footer = footer(tips),
            style = DesignSystem.SelectInput(header = id.header(1), options = choices, selected = selected),
            amount = if (financing?.style == EditableFinancing.Style.TRANSFER)
                DesignSystem.TextInput(header = id.header(2), title = amountTitle, value = financing.amount.first, input = DesignSystem.Input.DECIMAL, unit = "SEK")
            else
                DesignSystem.TextInput(header = id.header(2), visible = false),
            due = if (financing?.style == EditableFinancing.Style.TRANSFER)
                DesignSystem.TextInput(header = id.header(3), title = dueTitle, value = financing.due)
            else
                DesignSystem.TextInput(header = id.header(3), visible = false),
            account = if (financing?.style == EditableFinancing.Style.TRANSFER)
                DesignSystem.TextInput(header = id.header(4), title = accountTitle, value = financing.account)
            else
                DesignSystem.TextInput(header = id.header(4), visible = false),
            other = if (financing?.style == EditableFinancing.Style.OTHER)
                DesignSystem.TextInput(header = id.header(5), value = financing.description, input = DesignSystem.Input.NOVEL)
            else
                DesignSystem.TextInput(header = id.header(5), visible = false),
            show = if (financing?.style == EditableFinancing.Style.OTHER)
                DesignSystem.Text(text = financing.description)
            else
                DesignSystem.Text(text = selected?.title),
            showAmount = if (financing?.style == EditableFinancing.Style.TRANSFER)
                DesignSystem.Property(
                    header = DesignSystem.Text(text = amountTitle, style = DesignSystem.TextStyle.SUBHEAD),
                    value = DesignSystem.Text(text = financing.amount.first.toString())
                )
            else
                DesignSystem.Property(visible = false),
            showDue = if (financing?.style == EditableFinancing.Style.TRANSFER)
                DesignSystem.Property(
                    header = DesignSystem.Text(text = dueTitle, style = DesignSystem.TextStyle.SUBHEAD),
                    value = DesignSystem.Text(text = financing.due)
                )
            else
                DesignSystem.Property(visible = false),
            showAccount = if (financing?.style == EditableFinancing.Style.TRANSFER)
                DesignSystem.Property(
                    header = DesignSystem.Text(text = accountTitle, style = DesignSystem.TextStyle.SUBHEAD),
                    value = DesignSystem.Text(text = financing.account)
                )
            else
                DesignSystem.Property(visible = false),
            status = status,
        )
    }

    fun address(id: Identifier<RuleItem>, editable: Boolean, title: String, choices: List<DesignSystem.Option>, value: RuleValue, info: String? = null, tips: String? = null, status: DesignSystem.Status): ViewModel {
        val address = when (value) {
            is RuleValue.AddressValue -> value.value
            else -> null
        }
        val selected = choices.firstOrNull { it.data == address?.style }
        val streetTitle = "Gata"
        val postalCodeTitle = "Postnummer"
        val cityTitle = "Ort"
        return ViewModel.Address(
            id = id,
            editable = editable,
            value = value,
            header = header(editable = editable, title = title, value = value, info = info),
            footer = footer(tips),
            style = DesignSystem.SelectInput(header = id.header(1), options = choices, selected = selected),
            street = if (address?.style == EditableAddress.Style.SWEDISH)
                DesignSystem.TextInput(header = id.header(2), title = streetTitle, value = address.street)
            else
                DesignSystem.TextInput(header = id.header(2), visible = false),
            postalCode = if (address?.style == EditableAddress.Style.SWEDISH)
                DesignSystem.TextInput(header = id.header(3), title = postalCodeTitle, value = address.postalCode.first, input = DesignSystem.Input.NUMBER)
            else
                DesignSystem.TextInput(header = id.header(3), visible = false),
            city = if (address?.style == EditableAddress.Style.SWEDISH)
                DesignSystem.TextInput(header = id.header(4), title = cityTitle, value = address.city)
            else
                DesignSystem.TextInput(header = id.header(4), visible = false),
            other = if (address?.style == EditableAddress.Style.OTHER)
                DesignSystem.TextInput(header = id.header(5), value = address.description, input = DesignSystem.Input.NOVEL)
            else
                DesignSystem.TextInput(header = id.header(5), visible = false),
            show = if (address?.style == EditableAddress.Style.OTHER)
                DesignSystem.Text(text = address.description)
            else
                DesignSystem.Text(text = selected?.title),
            showStreet = if (address?.style == EditableAddress.Style.SWEDISH)
                DesignSystem.Property(
                    header = DesignSystem.Text(text = streetTitle, style = DesignSystem.TextStyle.SUBHEAD),
                    value = DesignSystem.Text(text = address.street)
                )
            else
                DesignSystem.Property(visible = false),
            showPostalCode = if (address?.style == EditableAddress.Style.SWEDISH)
                DesignSystem.Property(
                    header = DesignSystem.Text(text = postalCodeTitle, style = DesignSystem.TextStyle.SUBHEAD),
                    value = DesignSystem.Text(text = address.postalCode.second?.toString())
                )
            else
                DesignSystem.Property(visible = false),
            showCity = if (address?.style == EditableAddress.Style.SWEDISH)
                DesignSystem.Property(
                    header = DesignSystem.Text(text = cityTitle, style = DesignSystem.TextStyle.SUBHEAD),
                    value = DesignSystem.Text(text = address.city)
                )
            else
                DesignSystem.Property(visible = false),
            status = status,
        )
    }

    fun paragraph(id: Identifier<RuleItem>, editable: Boolean, title: String, choices: List<DesignSystem.Option>, value: RuleValue, info: String? = null, tips: String? = null, status: DesignSystem.Status): ViewModel {
        val paragraph = when (value) {
            is RuleValue.ParagraphValue -> value.value
            else -> null
        }
        val selected = choices.firstOrNull { it.data == paragraph?.style }
        return ViewModel.Paragraph(
            id = id,
            editable = editable,
            value = value,
            header = header(editable = editable, title = title, value = value, info = info),
            footer = footer(tips),
            edit = DesignSystem.SelectInput(header = id.header, options = choices, selected = selected),
            recommended = if (paragraph?.style == EditableParagraph.Style.RECOMMENDED)
                DesignSystem.Text(text = paragraph.recommendation)
            else
                DesignSystem.Text(visible = false),
            other = if (paragraph?.style == EditableParagraph.Style.OTHER)
                DesignSystem.TextInput(header = id.header, value = paragraph.description, input = DesignSystem.Input.NOVEL)
            else
                DesignSystem.TextInput(header = id.header, visible = false),
            show = when (paragraph?.style) {
                EditableParagraph.Style.RECOMMENDED -> DesignSystem.Text(text = paragraph.recommendation)
                EditableParagraph.Style.OTHER -> DesignSystem.Text(text = paragraph.description)
                else -> DesignSystem.Text(visible = false)
            },
            status = status,
        )
    }

    fun conditionsOfSale(id: Identifier<RuleItem>, editable: Boolean, title: String, choices: List<DesignSystem.Option>, value: RuleValue, info: String? = null, tips: String? = null, status: DesignSystem.Status): ViewModel {
        val conditionsOfSale = when (value) {
            is RuleValue.ConditionsOfSaleValue -> value.value
            else -> null
        }
        val selected = choices.firstOrNull { it.data == conditionsOfSale?.style }
        return ViewModel.ConditionsOfSale(
            id = id,
            editable = editable,
            value = value,
            header = header(editable = editable, title = title, value = value, info = info),
            footer = footer(tips),
            edit = DesignSystem.SelectInput(header = id.header(1), options = choices, selected = selected),
            other = if (conditionsOfSale?.style == EditableConditionsOfSale.Style.OTHER)
                DesignSystem.TextInput(header = id.header(2), value = conditionsOfSale.description, input = DesignSystem.Input.NOVEL)
            else
                DesignSystem.TextInput(header = id.header(2), visible = false),
            show = if (conditionsOfSale?.style == EditableConditionsOfSale.Style.OTHER)
                DesignSystem.Text(text = conditionsOfSale.description)
            else
                DesignSystem.Text(text = selected?.title),
            status = status,
        )
    }

    fun adherence(id: Identifier<RuleItem>, editable: Boolean, title: String, choices: List<DesignSystem.Option>, value: RuleValue, info: String? = null, tips: String? = null, status: DesignSystem.Status): ViewModel {
        val adherence = when (value) {
            is RuleValue.AdherenceValue -> value.value
            else -> null
        }
        val selected = choices.firstOrNull { it.data == adherence?.style }
        return ViewModel.Adherence(
            id = id,
            editable = editable,
            value = value,
            header = header(editable = editable, title = title, value = value, info = info),
            footer = footer(tips),
            edit = DesignSystem.SelectInput(header = id.header(1), options = choices, selected = selected),
            other = if (adherence?.style == EditableAdherence.Style.OTHER)
                DesignSystem.TextInput(header = id.header(2), value = adherence.description, input = DesignSystem.Input.NOVEL)
            else
                DesignSystem.TextInput(header = id.header(2), visible = false),
            show = if (adherence?.style == EditableAdherence.Style.OTHER)
                DesignSystem.Text(text = adherence.description)
            else
                DesignSystem.Text(text = selected?.title),
            status = status,
        )
    }

    fun simpleSection(title: String, visible: Boolean = true): ViewModel {
        return ViewModel.SimpleSection(
            id = Identifier(title),
            editable = false,
            value = RuleValue.None,
            header = header(editable = false, title = title, value = RuleValue.None, info = null, titleStyle = DesignSystem.TextStyle.TITLE2),
            footer = footer(null),
            visible = visible
        )
    }
}

fun RuleItem.partnershipClassification(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val classification = clauses.classification ?: EditableDefaults.partnershipClassification(editable) ?: return null
    val ruleValue = RuleValue.OptionValue(classification.clause.classification.option)
    val choices = listOf(Classification.MotorHome, Classification.HolidayHome, Classification.Yacht, Classification.Other(classification.other ?: ""), Classification.None).map { it.option }
    return imageInput(
        id = TermHeader.partnershipClassification.ruleItem,
        editable = editable,
        title = Titles.partnershipClassification,
        otherTitle = "Vad är det för typ av objekt?",
        otherPlaceholder = "ex. snöskoter, mc eller annat",
        value = if (classification.isOff) RuleValue.Off(ruleValue) else ruleValue,
        choices = choices,
        info = Info.partnershipClassification,
        tips = Tips.partnershipClassification,
        status = classification.status,
    )
}

fun RuleItem.partnershipProperty(classification: Classification, clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val property = clauses.property ?: EditableDefaults.partnershipProperty(editable) ?: return null
    val ruleValue = RuleValue.AddressValue(property.address)
    val choices = listOf(EditableAddress.Style.SWEDISH, EditableAddress.Style.OTHER).map { it.option }
    return address(
        id = TermHeader.partnershipProperty.ruleItem,
        editable = editable,
        title = Titles.partnershipProperty(classification),
        choices = choices,
        value = if (property.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipProperty,
        tips = Tips.partnershipProperty,
        status = property.status,
    )
}

fun RuleItem.partnershipRegistration(classification: Classification, clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val registration = clauses.registration ?: EditableDefaults.partnershipRegistration(editable) ?: return null
    val ruleValue = RuleValue.StringValue(registration.clause.registration)
    return textInput(
        id = TermHeader.partnershipRegistration.ruleItem,
        editable = editable,
        title = Titles.partnershipRegistration(classification),
        value = if (registration.isOff) RuleValue.Off(ruleValue) else ruleValue,
        input = DesignSystem.Input.REGISTRATION,
        info = Info.partnershipRegistration(classification),
        tips = Tips.partnershipRegistration(classification),
        status = registration.status,
    )
}

fun RuleItem.partnershipInsurance(classification: Classification, clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val insurance = clauses.insurance ?: EditableDefaults.partnershipInsurance(classification, editable) ?: return null
    val ruleValue = RuleValue.ParagraphValue(insurance.paragraph)
    val choices = listOf(EditableParagraph.Style.RECOMMENDED, EditableParagraph.Style.OTHER).map { it.option }
    return paragraph(
        id = TermHeader.partnershipInsurance.ruleItem,
        editable = editable,
        title = Titles.partnershipInsurance,
        choices = choices,
        value = if (insurance.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipInsurance(classification),
        tips = Tips.partnershipInsurance(classification),
        status = insurance.status,
    )
}

fun RuleItem.partnershipOwnership(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val ownership = clauses.ownership ?: EditableDefaults.partnershipOwnership(editable) ?: return null
    val ruleValue = RuleValue.OwnershipValue(ownership.ownership)
    val choices = listOf(EditableOwnership.Style.SHARES/*, EditableOwnership.Style.PERCENTAGE*/, EditableOwnership.Style.OTHER).map { it.option }
    return ownership(
        id = TermHeader.partnershipOwnership.ruleItem,
        editable = editable,
        title = Titles.partnershipOwnership,
        add = "Lägg till",
        choices = choices,
        value = if (ownership.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipOwnership,
        tips = Tips.partnershipOwnership,
        status = ownership.status,
    )
}

fun RuleItem.partnershipFinancing(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val financing = clauses.financing ?: EditableDefaults.partnershipFinancing(editable) ?: return null
    val ruleValue = RuleValue.FinancingValue(financing.financing)
    val choices = listOf(EditableFinancing.Style.NONE, EditableFinancing.Style.TRANSFER, EditableFinancing.Style.OTHER).map { it.option }
    return financing(
        id = TermHeader.partnershipFinancing.ruleItem,
        editable = editable,
        title = Titles.partnershipFinancing,
        choices = choices,
        value = if (financing.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipFinancing,
        tips = Tips.partnershipFinancing,
        status = financing.status,
    )
}

fun RuleItem.partnershipService(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val service = clauses.service ?: EditableDefaults.partnershipService(editable) ?: return null
    val ruleValue = RuleValue.ParagraphValue(service.paragraph)
    val choices = listOf(EditableParagraph.Style.RECOMMENDED, EditableParagraph.Style.OTHER).map { it.option }
    return paragraph(
        id = TermHeader.partnershipService.ruleItem,
        editable = editable,
        title = Titles.partnershipService,
        choices = choices,
        value = if (service.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipService,
        tips = Tips.partnershipService,
        status = service.status,
    )
}

fun RuleItem.partnershipStorage(classification: Classification, clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val storage = clauses.storage ?: EditableDefaults.partnershipStorage(classification, editable) ?: return null
    val ruleValue = RuleValue.ParagraphValue(storage.paragraph)
    val choices = listOf(EditableParagraph.Style.RECOMMENDED, EditableParagraph.Style.OTHER).map { it.option }
    return paragraph(
        id = TermHeader.partnershipStorage.ruleItem,
        editable = editable,
        title = Titles.partnershipStorage(classification),
        choices = choices,
        value = if (storage.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipStorage(classification),
        tips = Tips.partnershipStorage(classification),
        status = storage.status,
    )
}

fun RuleItem.partnershipRisk(classification: Classification, clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val risk = clauses.risk ?: EditableDefaults.partnershipRisk(classification, editable) ?: return null
    val ruleValue = RuleValue.ParagraphValue(risk.paragraph)
    val choices = listOf(EditableParagraph.Style.RECOMMENDED, EditableParagraph.Style.OTHER).map { it.option }

    return paragraph(
        id = TermHeader.partnershipRisk.ruleItem,
        editable = editable,
        title = Titles.partnershipRisk(classification),
        choices = choices,
        value = if (risk.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipRisk,
        tips = Tips.partnershipRisk,
        status = risk.status,
    )
}

fun RuleItem.partnershipDrivingRange(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val drivingRange = clauses.drivingRange ?: EditableDefaults.partnershipDrivingRange(editable) ?: return null
    val ruleValue = RuleValue.OptionValue(drivingRange.clause.region.option)
    val choices = listOf(Region.Sweden, Region.Nordics, Region.Europe, Region.World, Region.Other(drivingRange.other ?: "")).map { it.option }
    return selectInput(
        id = TermHeader.partnershipDrivingRange.ruleItem,
        editable = editable,
        title = Titles.partnershipDrivingRange,
        choices = choices,
        value = if (drivingRange.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipDrivingRange,
        tips = Tips.partnershipDrivingRange,
        status = drivingRange.status,
    )
}

fun RuleItem.partnershipDrivingDistance(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val drivingDistance = clauses.drivingDistance ?: EditableDefaults.partnershipDrivingDistance(editable) ?: return null
    val ruleValue = RuleValue.IntValue(drivingDistance.value)
    return textInput(
        id = TermHeader.partnershipDrivingDistance.ruleItem,
        editable = editable,
        title = Titles.partnershipDrivingDistance,
        input = DesignSystem.Input.NUMBER,
        value = if (drivingDistance.isOff) RuleValue.Off(ruleValue) else ruleValue,
        unit = "km",
        info = Info.partnershipDrivingDistance,
        tips = Tips.partnershipDrivingDistance,
        status = drivingDistance.status,
    )
}

fun RuleItem.partnershipUnexpectedExpense(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val unexpectedExpense = clauses.unexpectedExpense ?: EditableDefaults.partnershipUnexpectedExpense(editable) ?: return null
    val ruleValue = RuleValue.ParagraphValue(unexpectedExpense.paragraph)
    val choices = listOf(EditableParagraph.Style.RECOMMENDED, EditableParagraph.Style.OTHER).map { it.option }
    return paragraph(
        id = TermHeader.partnershipUnexpectedExpense.ruleItem,
        editable = editable,
        title = Titles.partnershipUnexpectedExpense,
        choices = choices,
        value = if (unexpectedExpense.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipUnexpectedExpense,
        tips = Tips.partnershipUnexpectedExpense,
        status = unexpectedExpense.status,
    )
}

fun RuleItem.partnershipDamage(classification: Classification, clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val damage = clauses.damage ?: EditableDefaults.partnershipDamage(classification, editable) ?: return null
    val ruleValue = RuleValue.ParagraphValue(damage.paragraph)
    val choices = listOf(EditableParagraph.Style.RECOMMENDED, EditableParagraph.Style.OTHER).map { it.option }
    return paragraph(
        id = TermHeader.partnershipDamage.ruleItem,
        editable = editable,
        title = Titles.partnershipDamage(classification),
        choices = choices,
        value = if (damage.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipDamage,
        tips = Tips.partnershipDamage(classification),
        status = damage.status,
    )
}

fun RuleItem.partnershipExpense(classification: Classification, clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val expense = clauses.expense ?: EditableDefaults.partnershipExpense(classification, editable) ?: return null
    val ruleValue = RuleValue.ParagraphValue(expense.paragraph)
    val choices = listOf(EditableParagraph.Style.RECOMMENDED, EditableParagraph.Style.OTHER).map { it.option }
    return paragraph(
        id = TermHeader.partnershipExpense.ruleItem,
        editable = editable,
        title = Titles.partnershipExpense(classification),
        choices = choices,
        value = if (expense.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipExpense,
        tips = Tips.partnershipExpense,
        status = expense.status,
    )
}

fun RuleItem.partnershipConduct(classification: Classification, clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val conduct = clauses.conduct ?: EditableDefaults.partnershipConduct(classification, editable) ?: return null
    val ruleValue = RuleValue.ParagraphValue(conduct.paragraph)
    val choices = listOf(EditableParagraph.Style.RECOMMENDED, EditableParagraph.Style.OTHER).map { it.option }
    return paragraph(
        id = TermHeader.partnershipConduct.ruleItem,
        editable = editable,
        title = Titles.partnershipConduct,
        choices = choices,
        value = if (conduct.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipConduct,
        tips = Tips.partnershipConduct,
        status = conduct.status,
    )
}

fun RuleItem.partnershipRental(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val rental = clauses.rental ?: EditableDefaults.partnershipRental(editable) ?: return null
    val ruleValue = RuleValue.OptionValue(rental.clause.approval.option)
    val choices = listOf(Approval.Approved, Approval.NotApproved, Approval.Other(rental.other ?: "")).map { it.option }
    return selectInput(
        id = TermHeader.partnershipRental.ruleItem,
        editable = editable,
        title = Titles.partnershipRental,
        choices = choices,
        value = if (rental.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipRental,
        tips = Tips.partnershipRental,
        status = rental.status,
    )
}

fun RuleItem.partnershipSale(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val sale = clauses.sale ?: EditableDefaults.partnershipSale(editable) ?: return null
    val ruleValue = RuleValue.ConditionsOfSaleValue(sale.conditionsOfSale)
    val choices = listOf(EditableConditionsOfSale.Style.ACCORDING_TO_LAW, /* EditableConditionsOfSale.Style.EXTERNAL_VALUATION,*/ EditableConditionsOfSale.Style.PREEMPTION, EditableConditionsOfSale.Style.OTHER).map { it.option }
    return conditionsOfSale(
        id = TermHeader.partnershipSale.ruleItem,
        editable = editable,
        title = Titles.partnershipSale,
        choices = choices,
        value = if (sale.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipSale,
        tips = Tips.partnershipSale,
        status = sale.status,
    )
}

fun RuleItem.partnershipProfit(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val profit = clauses.profit ?: EditableDefaults.partnershipProfit(editable) ?: return null
    val ruleValue = RuleValue.ParagraphValue(profit.paragraph)
    val choices = listOf(EditableParagraph.Style.RECOMMENDED, EditableParagraph.Style.OTHER).map { it.option }
    return paragraph(
        id = TermHeader.partnershipProfit.ruleItem,
        editable = editable,
        title = Titles.partnershipProfit,
        choices = choices,
        value = if (profit.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipProfit,
        tips = Tips.partnershipProfit,
        status = profit.status,
    )
}

fun RuleItem.partnershipPublicAuction(classification: Classification, clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val publicAction = clauses.publicAuction ?: EditableDefaults.partnershipPublicAuction(editable) ?: return null
    val ruleValue = RuleValue.AdherenceValue(publicAction.adherence)
    val choices = listOf(EditableAdherence.Style.ACCORDING_TO_LAW, EditableAdherence.Style.DEROGATION, EditableAdherence.Style.OTHER).map { it.option }
    return adherence(
        id = TermHeader.partnershipPublicAuction.ruleItem,
        editable = editable,
        title = Titles.partnershipPublicAuction(classification),
        choices = choices,
        value = if (publicAction.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipPublicAuction,
        tips = Tips.partnershipPublicAuction,
        status = publicAction.status,
    )
}

fun RuleItem.partnershipOther(classification: Classification, clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val other = clauses.other ?: EditableDefaults.partnershipOther(editable) ?: return null
    val ruleValue = RuleValue.StringValue(other.clause.description)
    return textInput(
        id = TermHeader.partnershipOther.ruleItem,
        editable = editable,
        title = Titles.partnershipOther(classification),
        input = DesignSystem.Input.NOVEL,
        value = if (other.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipOther,
        tips = Tips.partnershipOther(classification),
        status = other.status,
    )
}

fun RuleItem.partnershipTermination(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val termination = clauses.termination ?: EditableDefaults.partnershipTermination(editable) ?: return null
    val ruleValue = RuleValue.ParagraphValue(termination.paragraph)
    val choices = listOf(EditableParagraph.Style.RECOMMENDED, EditableParagraph.Style.OTHER).map { it.option }
    return paragraph(
        id = TermHeader.partnershipTermination.ruleItem,
        editable = editable,
        title = Titles.partnershipTermination,
        choices = choices,
        value = if (termination.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.partnershipTermination,
        tips = Tips.partnershipTermination,
        status = termination.status,
    )
}

fun RuleItem.bookingHighValue(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val highValue = clauses.highValue ?: EditableDefaults.bookingHighValue(editable) ?: return null

    fun toItem(weekOfYear: Int) =
        DesignSystem.Option.item(title = "Vecka $weekOfYear", value = weekOfYear.toString(), data = weekOfYear)

    val weeks = (1..52).map { toItem(it) }
    val options = highValue.clause
        .sortedBy { it.fromWeekOfYear }
        .map {
            toItem(it.fromWeekOfYear) to toItem(it.toWeekOfYear)
        }
    val ruleValue = RuleValue.DoubleOptionsValue(options)
    return doubleList(
        id = TermHeader.bookingHighValue.ruleItem,
        editable = editable,
        title = Titles.bookingHighValue,
        add = "Lägg till",
        choices1 = weeks,
        choices2 = weeks,
        itemTitle = { "Vecka ${it.first.data} - ${it.second.data}" },
        value = if (highValue.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.bookingHighValue,
        tips = Tips.bookingHighValue,
        status = highValue.status,
        choices1Label = "Från",
        choices2Label = "Till",
    )
}

fun RuleItem.bookingUnlockedLong(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val unlockedLong = clauses.unlockedLong ?: EditableDefaults.bookingUnlockedLong(editable) ?: return null
    val ruleValue = RuleValue.IntValue(unlockedLong.value)
    return textInput(
        id = TermHeader.bookingUnlockedLong.ruleItem,
        editable = editable,
        title = Titles.bookingUnlockedLong,
        input = DesignSystem.Input.NUMBER,
        value = if (unlockedLong.isOff) RuleValue.Off(ruleValue) else ruleValue,
        unit = "veckor",
        info = Info.bookingUnlockedLong,
        tips = Tips.bookingUnlockedLong,
        status = unlockedLong.status,
    )
}

fun RuleItem.bookingUnlockedShort(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val unlockedShort = clauses.unlockedShort ?: EditableDefaults.bookingUnlockedShort(editable) ?: return null
    val ruleValue = RuleValue.IntValue(unlockedShort.value)
    return textInput(
        id = TermHeader.bookingUnlockedShort.ruleItem,
        editable = editable,
        title = Titles.bookingUnlockedShort,
        input = DesignSystem.Input.NUMBER,
        value = if (unlockedShort.isOff) RuleValue.Off(ruleValue) else ruleValue,
        unit = "veckor",
        info = Info.bookingUnlockedShort,
        tips = Tips.bookingUnlockedShort,
        status = unlockedShort.status,
    )
}

fun RuleItem.bookingDurationLong(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val durationLong = clauses.durationLong ?: EditableDefaults.bookingDurationLong(editable) ?: return null
    val ruleValue = RuleValue.IntValue(durationLong.value)
    return textInput(
        id = TermHeader.bookingDurationLong.ruleItem,
        editable = editable,
        title = Titles.bookingDurationLong,
        input = DesignSystem.Input.NUMBER,
        value = if (durationLong.isOff) RuleValue.Off(ruleValue) else ruleValue,
        unit = "dagar",
        info = Info.bookingDurationLong,
        tips = Tips.bookingDurationLong,
        status = durationLong.status,
    )
}

fun RuleItem.bookingDurationShort(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val durationShort = clauses.durationShort ?: EditableDefaults.bookingDurationShort(editable) ?: return null
    val ruleValue = RuleValue.IntValue(durationShort.value)
    return textInput(
        id = TermHeader.bookingDurationShort.ruleItem,
        editable = editable,
        title = Titles.bookingDurationShort,
        input = DesignSystem.Input.NUMBER,
        value = if (durationShort.isOff) RuleValue.Off(ruleValue) else ruleValue,
        unit = "dagar",
        info = Info.bookingDurationShort,
        tips = Tips.bookingDurationShort,
        status = durationShort.status,
    )
}

fun RuleItem.bookingConcurrentLong(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val concurrentLong = clauses.concurrentLong ?: EditableDefaults.bookingConcurrentLong(editable) ?: return null
    val ruleValue = RuleValue.IntValue(concurrentLong.value)
    return textInput(
        id = TermHeader.bookingConcurrentLong.ruleItem,
        editable = editable,
        title = Titles.bookingConcurrentLong,
        input = DesignSystem.Input.NUMBER,
        value = if (concurrentLong.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.bookingConcurrentLong,
        tips = Tips.bookingConcurrentLong,
        status = concurrentLong.status,
    )
}

fun RuleItem.bookingConcurrentShort(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val concurrentShort = clauses.concurrentShort ?: EditableDefaults.bookingConcurrentShort(editable) ?: return null
    val ruleValue = RuleValue.IntValue(concurrentShort.value)
    return textInput(
        id = TermHeader.bookingConcurrentShort.ruleItem,
        editable = editable,
        title = Titles.bookingConcurrentShort,
        input = DesignSystem.Input.NUMBER,
        value = if (concurrentShort.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.bookingConcurrentShort,
        tips = Tips.bookingConcurrentShort,
        status = concurrentShort.status,
    )
}

fun RuleItem.bookingConcurrentLongHighValue(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val concurrentLongHighValue = clauses.concurrentLongHighValue ?: EditableDefaults.bookingConcurrentLongHighValue(editable) ?: return null
    val ruleValue = RuleValue.IntValue(concurrentLongHighValue.value)
    return textInput(
        id = TermHeader.bookingConcurrentLongHighValue.ruleItem,
        editable = editable,
        title = Titles.bookingConcurrentLongHighValue,
        input = DesignSystem.Input.NUMBER,
        value = if (concurrentLongHighValue.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.bookingConcurrentLongHighValue,
        tips = Tips.bookingConcurrentLongHighValue,
        status = concurrentLongHighValue.status,
    )
}

fun RuleItem.bookingConcurrentShortHighValue(clauses: List<EditableClause>, editable: Boolean): RuleItem.ViewModel? {
    val concurrentShortHighValue = clauses.concurrentShortHighValue ?: EditableDefaults.bookingConcurrentShortHighValue(editable) ?: return null
    val ruleValue = RuleValue.IntValue(concurrentShortHighValue.value)
    return textInput(
        id = TermHeader.bookingConcurrentShortHighValue.ruleItem,
        editable = editable,
        title = Titles.bookingConcurrentShortHighValue,
        input = DesignSystem.Input.NUMBER,
        value = if (concurrentShortHighValue.isOff) RuleValue.Off(ruleValue) else ruleValue,
        info = Info.bookingConcurrentShortHighValue,
        tips = Tips.bookingConcurrentShortHighValue,
        status = concurrentShortHighValue.status,
    )
}
