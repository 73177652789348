package bootstrap

import csstype.ClassName
import react.*

@JsNonModule
@JsModule("react-bootstrap/InputGroup")
private external val inputGroupModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bInputGroup: ComponentType<InputGroupProps> = inputGroupModule.default

external interface InputGroupProps : PropsWithChildren {
    var className: ClassName
    var `as`: dynamic /* 'input' | 'textarea' | 'select' | elementType */
    var hasValidation: Boolean
    var size: String /* 'sm' | 'lg' */
    var bsPrefix: String
}