package della8.core.native

import della8.core.support.svLocale
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.datetime.internal.JSJoda.DateTimeFormatter as jtDateTimeFormatter
import kotlinx.datetime.internal.JSJoda.LocalDateTime as jtLocalDateTime
import techla.base.Date
import techla.base.toKotlinxInstant

private fun Date.toJTLocalDateTime(): jtLocalDateTime =
    jtLocalDateTime.of(dateTime.year, dateTime.monthNumber, dateTime.dayOfMonth, dateTime.hour, dateTime.minute, dateTime.second, dateTime.nanosecond)

private fun Double.localeString(locale: String) : String =
    this.asDynamic().toLocaleString(locale) as String
private fun Double.fixed(decimals: Int) : String =
    this.asDynamic().toFixed(decimals) as String

actual fun formatCurrency(value: Double?, plusSign: Boolean): String {
    val sign = if (plusSign && value != null && value > 0.0) "+" else ""
    return "$sign${(value ?: 0.0).fixed(0).toDouble().localeString("sv-SE")} kr"
}

actual fun formatCurrencyLong(value: Double?): String {
    return "${(value ?: 0.0).fixed(2).toDouble().localeString("sv-SE")} kr"
}

actual fun formatNumber(value: Int?): String {
    return (value ?: 0).asDynamic().toLocaleString("sv-SE") as String
}

actual fun formatDecimal(value: Double?): String {
    return (value ?: 0.0).asDynamic().toLocaleString("sv-SE") as String
}

actual fun formatDate(value: Date?, timeZone: String): String {
    val zone = TimeZone.of(timeZone)
    val formatter = jtDateTimeFormatter
        .ofPattern("yyyy-MM-dd")
        .withLocale(svLocale)
    return value?.let { d ->
        Date(d.toKotlinxInstant().toLocalDateTime(zone))
            .toJTLocalDateTime().format(formatter)
    } ?: ""
}

actual fun formatDateTime(value: Date?, timeZone: String): String {
    val zone = TimeZone.of(timeZone)
    val formatter = jtDateTimeFormatter
        .ofPattern("yyyy-MM-dd HH:mm")
        .withLocale(svLocale)
    return value?.let { d ->
        Date(d.toKotlinxInstant().toLocalDateTime(zone))
            .toJTLocalDateTime().format(formatter)
    } ?: ""
}

actual fun formatDateTimeLong(value: Date?, timeZone: String): String {
    val zone = TimeZone.of(timeZone)
    val formatter = jtDateTimeFormatter
        .ofPattern("EEEE d MMMM yyyy HH:mm")
        .withLocale(svLocale)
    return value?.let { d ->
        Date(d.toKotlinxInstant().toLocalDateTime(zone))
            .toJTLocalDateTime()
            .format(formatter)
            .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
    } ?: ""
}

actual fun parseDecimal(value: String): Pair<String, Double?> {
    val v = value
        .trim()
        .replace(" ", "")
        .replace(",", ".")
        .toDoubleOrNull()
    return value to v
}

actual fun parseDate(value: String): Pair<String, Date?> {
    return "" to null
}

actual fun parseDateTime(value: String): Pair<String, Date?> {
    return "" to null
}
