package della8.core.support

import io.ktor.client.*
import techla.base.*
import techla.content.*
import techla.conversation.Message
import techla.content.ShortLink
import techla.guard.*

typealias ApplicationContext = Any?

data class Store(
    val applicationContext: ApplicationContext,
    val deployment: Deployment,
    val device: Device,
    val bankIdInstalled: Boolean,
    val demoMode: Boolean = false,
    val applicationToken: String? = null,
    val medias: List<Media>? = null,
    val articles: List<Message>? = null,
    val me: Me? = null,
    val group: Key<Group>? = null,
    val objects: List<Object>? = null,
    val userAuthenticationId: Identifier<UserAuthentication>? = null,
    val tokens: List<Token>? = null,
    val profileId: Identifier<Profile>? = null,
    val cookie: Boolean = false,
    val rememberMe: String = "",
    val shortLinks: List<ShortLink>? = null,

    ) {
    sealed class Action {
        data class ApplicationAuthenticationComplete(val applicationToken: String) : Action()
        data class MediasLoaded(val medias: List<Media>) : Action()
        data class ShortLinkLoaded(val shortLinks: List<ShortLink>?) : Action()
        data class ArticlesLoaded(val articles: List<Message>) : Action()

        data class ObjectsRefreshed(val me: Me, val objects: List<Object>) : Action()
        data class UserAuthenticationStarted(
            val userAuthenticationId: Identifier<UserAuthentication>,
            val tokens: List<Token>,
            val demoMode: Boolean = false
        ) : Action()

        data class UserAuthenticationCompleted(val tokens: List<Token>, val profileId: Identifier<Profile>) : Action()
        data class TokenRefresh(val tokens: List<Token>) : Action()
        data class ChangeGroup(val group: Key<Group>) : Action()
        data class ReplaceObject(val id: Identifier<Object>, val obj: Object) : Action()
        data class AgreedCookies(val cookie: Boolean) : Action()
        data class RememberMe(val rememberMe: String) : Action()
        object Logout : Action()
    }

    fun get(media: Key<Tag>, content: Key<Content>) =
        medias?.get(media)?.getOrNull(content)?.text ?: "<Needs translation>"


    val httpClient = HttpClient() {
        expectSuccess = false
    }

    val userToken: String?
        get() =
            tokens?.filterIsInstance<Token.User>()?.firstOrNull { it.group == (group ?: Group.STANDARD) }?.token

    val adminToken: String?
        get() =
            tokens?.filterIsInstance<Token.Admin>()?.firstOrNull()?.token

    val tokenExpiresAt: Date?
        get() =
            tokens?.filterIsInstance<Token.User>()?.firstOrNull { it.group == (group ?: Group.STANDARD) }?.expiresAt

    val isValid: Boolean
        get() =
            userAuthenticationId != null

    val qrCode: String?
        get() =
            tokens?.filterIsInstance<Token.QR>()?.firstOrNull()?.code

    val autoStartToken: String?
        get() =
            tokens?.filterIsInstance<Token.AutoStart>()?.firstOrNull()?.token

    fun reduce(action: Action) =
        when (action) {
            is Action.ApplicationAuthenticationComplete ->
                copy(applicationToken = action.applicationToken)

            is Action.MediasLoaded ->
                copy(medias = action.medias)

            is Action.ArticlesLoaded ->
                copy(articles = action.articles)

            is Action.ObjectsRefreshed ->
                copy(me = action.me, objects = action.objects)

            is Action.UserAuthenticationStarted ->
                copy(
                    userAuthenticationId = action.userAuthenticationId,
                    tokens = action.tokens,
                    demoMode = action.demoMode,
                    group = Group.STANDARD
                )

            is Action.UserAuthenticationCompleted ->
                copy(tokens = action.tokens, profileId = action.profileId)

            is Action.TokenRefresh ->
                copy(tokens = action.tokens)

            is Action.ChangeGroup ->
                copy(group = action.group)

            is Action.ReplaceObject -> {
                copy(objects = objects?.map { if (it.id == action.id) action.obj else it })
            }

            is Action.Logout ->
                copy(demoMode = false, userAuthenticationId = null, tokens = tokens?.filterIsInstance<Token.Application>(), profileId = null, objects = null)

            is Action.AgreedCookies -> {
                copy(cookie = action.cookie)
            }

            is Action.RememberMe -> {
                copy(rememberMe = action.rememberMe)
            }

            is Action.ShortLinkLoaded -> {
                copy(shortLinks = action.shortLinks)
            }
        }

    fun reduce(actions: List<Action>) =
        actions.fold(this) { store, action -> store.reduce(action) }
}
