package della8.core.support

import techla.agreement.Agreement

val DesignSystem.Meta.Companion.minimal
    get() =
        DesignSystem.Meta(title = "", description = "", type = "", url = "", image = "")

val DesignSystem.Navigation.Companion.minimal
    get() =
        DesignSystem.Navigation(title = "Della8")

interface LandingTexts {
    val landingTitle: String
    val landingRedeem: String
    val landingProfile: String
    val landingDashboard: String
    val landingAdvanced: String
    val landingSignOut: String
}

fun DesignSystem.Navigation.Companion.landing(texts: LandingTexts, isAdmin: Boolean, backLocation: Location) =
    DesignSystem.Navigation(
        title = texts.landingTitle,
        menu = DesignSystem.Menu(
            items = listOf(
                DesignSystem.Option.item(title = texts.landingRedeem, value = "RedeemCode", location = Location.RedeemCode),
                DesignSystem.Option.item(title = texts.landingProfile, value = "Profile", location = Location.Profile),
            ) + if (isAdmin)
                listOf(
                    DesignSystem.Option.item(title = texts.landingDashboard, value = "Dashboard", location = Location.Dashboard),
                    DesignSystem.Option.item(title = texts.landingAdvanced, value = "Advanced", location = Location.Advanced),
                )
            else
                emptyList(),
        ),
        navigationButtons = listOf(
            DesignSystem.Button(title = texts.landingSignOut, style = DesignSystem.ButtonStyle.PRIMARY, action = DesignSystem.Action.LOGOUT, location = backLocation)
        ),
        location = Location.Landing
    )

val DesignSystem.Navigation.Companion.minimalLight
    get() =
        DesignSystem.Navigation(
            title = "Della8",
            background = DesignSystem.Background.LIGHT,
        )

val DesignSystem.Navigation.Companion.minimalLightStart
    get() =
        DesignSystem.Navigation(
            title = "Della8",
            background = DesignSystem.Background.LIGHT,
            location = Location.Start
        )

fun DesignSystem.Navigation.Companion.progress(movie: DesignSystem.Movie = DesignSystem.Movie.NONE) =
    when (movie) {
        DesignSystem.Movie.NONE ->
            DesignSystem.Navigation(
                title = "Della8",
                background = DesignSystem.Background.LIGHT,
            )

        else ->
            DesignSystem.Navigation(
                title = "Della8",
                background = DesignSystem.Background.DARK,
                movie = DesignSystem.MovieView(movie = movie),
            )
    }

val DesignSystem.Navigation.Companion.success
    get() =
        minimalLight

val DesignSystem.Navigation.Companion.failure
    get() =
        minimalLightStart

fun DesignSystem.Navigation.Companion.backLight(title: String, action: DesignSystem.Action = DesignSystem.Action.BACK, location: Location? = null, backLocation: Location? = null) =
    DesignSystem.Navigation(
        title = "Della8",
        navigation = DesignSystem.Option.item(title = title, action = action, location = backLocation),
        background = DesignSystem.Background.LIGHT,
        location = location,
    )

fun DesignSystem.Navigation.Companion.backDark(title: String, action: DesignSystem.Action = DesignSystem.Action.BACK, location: Location? = null, backLocation: Location? = null) =
    DesignSystem.Navigation(
        title = "Della8",
        navigation = DesignSystem.Option.item(title = title, image = DesignSystem.Image.BACK_WHITE, action = action, location = backLocation),
        background = DesignSystem.Background.DARK,
        location = location,
    )

val DesignSystem.Navigation.Companion.fireworks
    get() =
        DesignSystem.Navigation(
            title = "Della8",
            background = DesignSystem.Background.DARK,
            movie = DesignSystem.MovieView(movie = DesignSystem.Movie.FIREWORKS, player = DesignSystem.MoviePlayer.MUX),
        )

fun DesignSystem.Navigation.Companion.front(location: Location? = null) =
    DesignSystem.Navigation(
        title = "Della8",
        menu = DesignSystem.Menu(
            items = listOf(
                DesignSystem.Option.item(title = "Hitta objekt", value = "Storefront", location = Location.Storefront),
                DesignSystem.Option.item(title = "Om oss", value = "About", location = Location.About),
                DesignSystem.Option.item(title = "Varför Della8", value = "Calculator", location = Location.Calculator),
                DesignSystem.Option.item(title = "Artiklar", value = "Articles", location = Location.Articles),
                DesignSystem.Option.item(title = "Kontakt", value = "Contact", action = DesignSystem.Action.CONTACT),
            ),
            selected = when (location) {
                is Location.Storefront -> DesignSystem.Option.item(title = "Hitta objekt", value = "Storefront", location = Location.Storefront)
                is Location.About -> DesignSystem.Option.item(title = "Om oss", value = "About", location = Location.About)
                is Location.Calculator -> DesignSystem.Option.item(title = "Varför Della8", value = "Calculator", location = Location.Calculator)
                is Location.Articles -> DesignSystem.Option.item(title = "Artiklar", value = "Articles", location = Location.Articles)
                else -> null
            }
        ),
        navigationButtons = listOf(
            DesignSystem.Button(
                title = "Till tjänsten",
                style = DesignSystem.ButtonStyle.PRIMARY,
                location = Location.Login,
                disabled = false,
            )
        ),
        location = Location.Start,
    )

fun DesignSystem.Navigation.Companion.objectLight(obj: Object, title: String, action: DesignSystem.Action = DesignSystem.Action.BACK, logoLocation: Location? = null, active: Location, backLocation: Location? = null) =
    DesignSystem.Navigation(
        title = "Della8",
        navigation = DesignSystem.Option.item(title = title, action = action, location = backLocation),
        background = DesignSystem.Background.LIGHT,
        location = logoLocation,
        objectButtons = listOf(
            DesignSystem.Button(
                title = "Start",
                image = DesignSystem.Image.START,
                location = Location.Overview(obj.id, obj.currentAgreement.id),
                style = DesignSystem.ButtonStyle.TRANSPARENT,
                iconAlignment = DesignSystem.IconAlignment.TOP,
                disabled = false,
                selected = active is Location.Overview,
            ),
            DesignSystem.Button(
                title = "Flöde",
                image = DesignSystem.Image.FEED,
                location = Location.Feed(obj.id, obj.currentAgreement.id),
                //action = DesignSystem.Action.FEED,
                style = DesignSystem.ButtonStyle.TRANSPARENT,
                iconAlignment = DesignSystem.IconAlignment.TOP,
                disabled = false,
                visible = true,
                selected = active is Location.Feed,
            ),
            DesignSystem.Button(
                title = "Avtal",
                image = if (obj.isAgreementEmpty) DesignSystem.Image.AGREEMENT else if (obj.currentAgreement.status !is Agreement.Status.Approved) DesignSystem.Image.AGREEMENT_UPDATES else DesignSystem.Image.AGREEMENT,
                location = Location.Agreement(obj.id, obj.currentAgreement.id),
                //  action = DesignSystem.Action.AGREEMENT,
                style = DesignSystem.ButtonStyle.TRANSPARENT,
                iconAlignment = DesignSystem.IconAlignment.TOP,
                disabled = false,
                selected = active is Location.Agreement,
            ),
            DesignSystem.Button(
                title = "Bjud in",
                image = DesignSystem.Image.INVITE,
                location = Location.Invite(obj.id, obj.currentAgreement.id),
                // action = DesignSystem.Action.INVITE,
                style = DesignSystem.ButtonStyle.TRANSPARENT,
                iconAlignment = DesignSystem.IconAlignment.TOP,
                disabled = obj.signatures.isEmpty(),
                visible = obj.isGroupLeader(),
                selected = active is Location.Invite,
            ),
            DesignSystem.Button(
                title = "Shop",
                image = DesignSystem.Image.SHOP,
                location = Location.Shop(obj.id, obj.currentAgreement.id),
                // action = DesignSystem.Action.SHOP,
                style = DesignSystem.ButtonStyle.TRANSPARENT,
                iconAlignment = DesignSystem.IconAlignment.TOP,
                disabled = false,
                visible = false,
                selected = active is Location.Landing,
            ),
        ),
    )
