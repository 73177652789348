package della8.web.views

import StoreContext
import bootstrap.*
import della8.core.screens.ReservationScreen
import della8.core.support.DesignSystem
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.*
import della8.web.theme.Della8Colors.designColor
import della8.web.theme.background
import kotlinx.js.get
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.router.useParams
import techla.base.Identifier
import techla.base.techla_log

val ReservationView = FC<PropsWithChildren>("ReservationView") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(ReservationScreen.ViewModel.None as ReservationScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()
    val (showErrorModal, setErrorModal) = useState(false)

    val scopedCall = scopedCall<ReservationScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    val mainCall = mainCall<ReservationScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    suspend fun handlePrevious() {
        mainCall { ReservationScreen.prev(sceneInputOf(store, viewModel)) }
    }

    suspend fun handleNext() {
        mainCall { ReservationScreen.next(sceneInputOf(store, viewModel)) }
    }

    suspend fun handleStayType(stayType: DesignSystem.Option) {
        mainCall { ReservationScreen.setStyle(sceneInputOf(store, viewModel), option = stayType) }
    }

    suspend fun handleSetValues(cell: DesignSystem.Cell) {
        if (!cell.annotations.contains(DesignSystem.Annotation.BOOKED_ME) &&
            !cell.annotations.contains(DesignSystem.Annotation.BOOKED_OTHER) &&
            !cell.annotations.contains(DesignSystem.Annotation.LOCKED) &&
            cell.annotations.filter { it != DesignSystem.Annotation.TODAY && it != DesignSystem.Annotation.ONGOING && it != DesignSystem.Annotation.ONGOING_END }.size <= 1
        ) {
            mainCall { ReservationScreen.setValues(sceneInputOf(store, viewModel), cell = cell) }
            setErrorModal(false)
        }
    }


    suspend fun handleBook() {
        if (viewModel.state.endsAt != null && viewModel.state.startsAt != null) {
            mainCall {
                ReservationScreen.check(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    when (viewModel) {
                        is ReservationScreen.ViewModel.Creating -> mainCall { ReservationScreen.book(sceneInputOf(store, viewModel)) }
                        else -> {
                            setViewModel(viewModel)
                            dispatch(actions)
                        }
                    }
                }
            }
        } else setErrorModal(true)
    }

    suspend fun handleDone() {
        params["objectId"]?.let { objectId ->
            params["agreementId"]?.let { agreementId ->
                mainCall { ReservationScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId)) }
            }
        }
    }

    fun handleGoTo(path: String) {
        navigate("/object/${params["objectId"]}/${params["agreementId"]}/${path}")
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is ReservationScreen.ViewModel.None ->
                scopedCall(coroutineScope) { ReservationScreen.start(sceneInputOf(store, viewModel)) }

            is ReservationScreen.ViewModel.Loading ->
                params["objectId"]?.let { objectId ->
                    params["agreementId"]?.let { agreementId ->
                        scopedCall(coroutineScope) { ReservationScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId)) }
                    }
                }

            is ReservationScreen.ViewModel.Ready -> techla_log("READY")
            is ReservationScreen.ViewModel.Creating -> techla_log("Creating")
            is ReservationScreen.ViewModel.Created -> techla_log("Created")
            is ReservationScreen.ViewModel.Failed -> techla_log("failed ")
        }
    }

    fun logout() {
        mainCall { ReservationScreen.logout(sceneInputOf(store, viewModel)) }
        navigate("/")
    }

    fun recover() {
        mainCall { ReservationScreen.success(sceneInputOf(store, viewModel)) }
    }

    suspend fun dismiss() {
        ReservationScreen.dismiss(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    main {
        d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))

        if (viewModel is ReservationScreen.ViewModel.Ready) {

            bContainer {
                className = className("px-3 px-md-5 position-relative n-bottom-space n-top-space")

                bRow {
                    className = className("pb-4 px-0 justify-content-center")

                    bCol {
                        xs = 12; md = 10
                        bContainer {
                            className = className("py-3 px-0")
                            d8Text(className = "text-center d-inline", design = viewModel.title)

                            div {
                                className = className("float-end")

                                d8Button(design = viewModel.settings, onClick = { handleGoTo("show/booking/rules") })
                                d8Button(design = viewModel.help, onClick = { handleGoTo("reservation/help") })
                            }
                        }
                        bContainer {
                            className = className("px-3 px-md-5 n-white-bg-box")

                            bRow {
                                className = className("py-3")

                                viewModel.helpers.map { helper ->
                                    bCol {
                                        className = className("col-md-auto px-0 n-top-flex")
                                        d8Text(design = helper, className = "d-inline ps-1")
                                    }
                                }
                            }
                            bRow() {
                                bCol {
                                    div {
                                        className = className("d-block")

                                        d8Text(design = viewModel.stayTypeTitle, className = "text-center")
                                    }
                                    div {
                                        className = className("d-block d-inline")
                                        d8MenuButton(design = viewModel.stayType, onClick = ::handleStayType, className = "text-center")
                                    }
                                }
                                /*bCol {
                                    small {
                                        className = className("font-italic")

                                        viewModel.timeTip.image?.let {
                                            ReactHTML.img {
                                                src = Design.image(it)
                                            }
                                        }
                                        viewModel.timeTip.title?.let {
                                            p {
                                                +it
                                            }
                                        }
                                    }
                                }*/
                            }
                            div {
                                className = className("py-2 d-flex flex-row justify-content-between")

                                d8Button(className = "mt-0", design = viewModel.prev, onClick = { handlePrevious() })
                                d8Button(className = "mt-0", design = viewModel.next, onClick = { handleNext() })
                            }
                            bRow() {
                                div {
                                    className = className("n-booking-annotations py-2")
                                }
                                div {
                                    className = className("d8-week")

                                }
                                viewModel.weekdays.map {
                                    bCol {
                                        className = className("text-center")
                                        d8Text(design = it)
                                    }
                                }
                            }
                        }
                        bContainer {
                            className = className("px-3 px-md-5 py-5 mb-5 n-white-bg-box")
                            d8Month(page = "reservation", design = viewModel.first, onClick = ::handleSetValues)
                            d8Month(page = "reservation", design = viewModel.second, onClick = ::handleSetValues)
                        }
                    }
                }
            }
            bContainer {
                fluid = true
                className = className("py-3 n-booking-summary text-center")
                d8Text(className = "d-inline px-2", design = viewModel.currentBooking)
                d8Icon(className = "d-inline px-2", design = viewModel.bookingIcon)
                d8Text(className = "d-inline px-2", design = viewModel.startDate)
                d8Text(className = "d-inline px-2", design = viewModel.endDate)
                d8Button(className = "d-inline px-4", design = viewModel.book, onClick = { handleBook() })
                if (showErrorModal) {
                    bContainer {
                        bRow {
                            className = className("justify-content-center")
                            bCol {
                                xs = 12; md = 10
                                bContainer {
                                    className = className(
                                        listOfNotNull(
                                            designColor(DesignSystem.Color.RUBY).background,
                                            "px-2 mx-5 mt-2 pt-1 radius-10 container mx-auto"
                                        )
                                    )
                                    d8Text(className = "", design = viewModel.errorBooking)
                                }
                            }
                        }
                    }
                }
            }
            d8Modal(
                design = viewModel.warning,
                show = viewModel.warning.visible,
                onHide = ::dismiss,
                onClickFirst = ::dismiss,
            )
        }

        if (viewModel is ReservationScreen.ViewModel.Created) {
            bContainer {
                className = className("px-4 px-md-5 position-relative n-bottom-space n-top-space")
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        xs = 12; md = 7
                        d8Image(className = "d-block mx-auto", design = viewModel.image)
                        d8Text(className = "text-center", design = viewModel.title)
                        d8Text(className = "text-center", design = viewModel.body)
                        bCard {
                            className = className("radius-10 border-0 card-shadow my-3 px-4 py-3")
                            bCard {
                                className =
                                    className("radius-10 border-0 card-shadow my-3 px-4 py-3 d8-marine-bg-opacity-2 mb-4")
                                bContainer {
                                    className = className("p-0")
                                    bRow {
                                        className = className("align-items-center mb-3 mt-2")
                                        div {
                                            className = className("mb-3")
                                            d8Text(className = "mb-0 ml-3 d-inline", design = viewModel.from)
                                        }
                                    }
                                    bRow {
                                        className = className("align-items-center")
                                        div {
                                            className = className("mb-3")
                                            d8Text(className = "mb-0 ml-3 d-inline", design = viewModel.to)
                                        }
                                    }
                                }
                            }
                        }
                        d8Button(className = "d-flex mx-auto text-center btn-lg px-5 px-md-5", design = viewModel.done, onClick = { handleDone() })
                    }
                }
            }
            d8Modal(
                design = viewModel.warning,
                show = viewModel.warning.visible,
                onHide = ::dismiss,
                onClickFirst = ::dismiss,
            )
        }

        if (viewModel is ReservationScreen.ViewModel.Loading) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is ReservationScreen.ViewModel.Failed) {
            bContainer {
                className = className("px-5 n-top-space")

                d8failure(viewModel.failure, onLogout = ::logout, onRecover = ::recover)
            }
        }
    }
}