package della8.core.screens

import della8.core.items.MessageItem
import della8.core.items.getMuxToken
import della8.core.items.movieId
import della8.core.services.*
import della8.core.support.*
import techla.agreement.Agreement
import techla.base.*
import techla.conversation.Message
import techla.conversation.Reaction
import techla.storage.Asset

object FeedScreen {

    data class Texts(
        val back: String,
        val title: String,
        val next: String,
        val emptyFeed: String,
        override val progressInfo: String,
        override val failureTitle: String,
    ) : ProgressTexts, FailureTexts {
        companion object
    }

    data class State(
        val obj: Object = Object.None,
        val messages: List<Message> = emptyList(),
        val assets: List<Asset> = emptyList()
    )

    sealed class ViewModel(open val texts: Texts, open val state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", ""),
            state = State(Object.None),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Ready(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val next: DesignSystem.Button,
            val items: List<MessageItem.ViewModel>,
            val emptyFeed: DesignSystem.Text,
            val emptyFeedImage: DesignSystem.ImageView,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Failed(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        fun loading(texts: Texts) =
            Loading(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(texts = texts),
            )

        fun ready(state: State, items: List<MessageItem.ViewModel>) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing, backLocation = Location.overview(state.obj)),
                title = DesignSystem.Text(text = texts.title, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.LIGHT),
                next = DesignSystem.Button(title = texts.next),
                items = items,
                emptyFeed = DesignSystem.Text(text = texts.emptyFeed, visible = items.isEmpty(), alignment = DesignSystem.TextAlignment.CENTER),
                emptyFeedImage = DesignSystem.ImageView(image = DesignSystem.Image.NOOBJECT, alt = "Della", visible = items.isEmpty())
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false) =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoad get() = this as? Loading
        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed

    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))


    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        val texts = Texts(
            back = "Tillbaka",
            title = "Flöde",
            next = "Skapa ett inlägg",
            progressInfo = "Laddar...",
            failureTitle = "Oj, ett fel har uppstått",
            emptyFeed = "Hoppsan! Här var det tomt, men inte länge till. Detta är er yta att prata om allt mellan himmel och jord, mellan tak och golv. Lägg bilder, kommentera och gilla. Ciao!"
        )
        return sceneOf<ViewModel>(viewModel.loading(texts = texts))
    }

    suspend fun removeMessage(scene: Scene.Input<ViewModel>, messageId: Identifier<Message>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.flag(viewModel.state.obj, messageId)
            .flatMap { (actions, _) ->
                store.reduce(actions).personal(viewModel.state.obj)
                    .accumulate(actions)
                    .map { tupleOf(it.first, it.second) }
            }
            .map { (actions, messages) ->
                val state = viewModel.state.copy(messages = messages)
                sceneOf<ViewModel>(
                    viewModel.ready(state = viewModel.state, items = buildItems(store, state)), actions
                )
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun likeMessage(scene: Scene.Input<ViewModel>, messageId: Identifier<Message>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.react(
            viewModel.state.obj, messageId, Reaction.Create(
                kind = Reaction.Kind.Like,
                mode = Reaction.Mode.Toggle
            )
        )
            .flatMap { (actions, _) ->
                store.reduce(actions).personal(viewModel.state.obj)
                    .accumulate(actions)
            }
            .map { (actions, messages) ->
                val state = viewModel.state.copy(messages = messages)
                sceneOf<ViewModel>(
                    viewModel.ready(state = state, items = buildItems(store, state)), actions
                )
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.refreshObject(objectId = objectId, agreementId = agreementId)
            .flatMap { (actions, obj) ->
                store.prepareGroupFeed(obj)
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj) }
            }
            .flatMap { (actions, obj) ->
                store.personal(obj)
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj, it.second) }
            }
            .flatMap { (actions, obj, messages) ->
                val assetIds = messages.flatMap { message ->
                    if (message.status !is Message.Status.Published) {
                        techla_log("WARN: Unpublished message found!")
                    }

                    message.attachments.mapNotNull { attachment ->
                        when (attachment) {
                            is Message.Attachment.AssetImage -> attachment.assetId
                            is Message.Attachment.AssetMovie -> attachment.assetId
                            else -> null
                        }
                    }
                }
                store.reduce(actions).getAssets(obj, batch = Asset.Batch(ids = assetIds))
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj, messages, it.second) }
            }

            .map { (actions, obj, messages, assets) ->
                val state = viewModel.state.copy(obj = obj, messages = messages, assets = assets)
                sceneOf<ViewModel>(
                    viewModel.ready(
                        state = state,
                        items = buildItems(store, state),
                    ), actions
                )
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun success(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return load(scene = scene, objectId = viewModel.state.obj.id, agreementId = viewModel.state.obj.currentAgreement.id)
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }

    private fun buildItems(store: Store, state: State): List<MessageItem.ViewModel> {
        val items = state.messages.mapNotNull { message ->
            when (message.kind) {
                is Message.Kind.Blog -> {
                    val bio = state.obj.bios.firstOrNull { bio -> bio.profileId.rawValue == message.author?.rawValue }
                    val movie = state.assets.firstOrNull { it.id == message.movieId }

                    MessageItem.blog(
                        message = message,
                        obj = state.obj,
                        profile = bio?.initials ?: "??",
                        author = "${bio?.firstName} ${bio?.lastName}",
                        canEdit = store.profileId == message.author,
                        images = message.attachments.filterIsInstance<Message.Attachment.AssetImage>().flatMap { m -> state.assets.filter { it.id == m.assetId }.mapNotNull { it.thumbnailUrl } },
                        muxMovie = Triple(getMuxToken(movie?.limitedUrl), movie?.playbackId?.rawValue, getMuxToken(movie?.thumbnailUrl)),
                    )
                }

                is Message.Kind.News -> null
                else -> null
            }
        }
        return items
    }
}