package della8.web.views

import StoreContext
import bootstrap.*
import della8.core.screens.AvailabilityScreen
import della8.core.support.DesignSystem
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.*
import kotlinx.browser.window
import kotlinx.js.get
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.router.useParams
import techla.base.Identifier
import techla.base.techla_log
import techla.reservation.Reservation


val AvailabilityView = FC<PropsWithChildren>("AvailabilityView") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(AvailabilityScreen.ViewModel.None as AvailabilityScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()
    val (showModal, setShowModal) = useState(false)
    val (cellReservations, setCellReservations) = useState<List<Identifier<Reservation>>>()

    val scopedCall = scopedCall<AvailabilityScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }
    val call = call<AvailabilityScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }
    val mainCall = mainCall<AvailabilityScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is AvailabilityScreen.ViewModel.None ->
                scopedCall(coroutineScope) { AvailabilityScreen.start(sceneInputOf(store, viewModel)) }
            is AvailabilityScreen.ViewModel.Loading -> {
                params["objectId"]?.let { objectId ->
                    params["agreementId"]?.let { agreementId ->
                        scopedCall(coroutineScope) { AvailabilityScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId)) }
                    }
                }
            }
            is AvailabilityScreen.ViewModel.Ready -> {
                techla_log("READY")
            }
            is AvailabilityScreen.ViewModel.Failed ->
                techla_log("failed ")
        }
    }


    fun handleGoTo(path: String) {
        navigate("/object/${params["objectId"]}/${params["agreementId"]}/${path}")
    }

    fun handleReservation() {
        navigate("/object/${params["objectId"]}/${params["agreementId"]}/reservation")
    }

    fun handleBooking(cell: DesignSystem.Cell) {
        if (cell.annotations.isNotEmpty() &&
            !cell.annotations.contains(DesignSystem.Annotation.LOCKED) &&
            !cell.annotations.contains(DesignSystem.Annotation.TODAY)
        ) {
            setCellReservations(cell.reservations)
            setShowModal(true)
        }
    }

    fun handleModal() {
        setCellReservations(null)
        setShowModal(false)
        params["objectId"]?.let { objectId ->
            params["agreementId"]?.let { agreementId ->
                mainCall { AvailabilityScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId)) }
            }
        }
    }

    fun logout() {
        mainCall { AvailabilityScreen.logout(sceneInputOf(store, viewModel)) }
        navigate("/")
    }

    fun recover() {
        mainCall { AvailabilityScreen.success(sceneInputOf(store, viewModel)) }
    }

    fun loadMore() {
        mainCall { AvailabilityScreen.loadMore(sceneInputOf(store, viewModel)) }
    }

    main {
        when (viewModel) {
            is AvailabilityScreen.ViewModel.Ready -> d8Navigation(design = viewModel.navigation, className = "nav-top", onClick = standardNavigation(navigate))
            else -> d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
        }

        if (viewModel is AvailabilityScreen.ViewModel.Ready) {
            bContainer {
                className = className("px-3 px-md-5 position-relative n-bottom-space n-top-space")
                bRow {
                    className = className("pb-4 justify-content-center")

                    bCol {
                        xs = 12; md = 10
                        bContainer {
                            className = className("py-3 px-0")

                            d8Text(className = "d-inline", design = viewModel.title)

                            d8Button(
                                className = "d-inline float-end btn-lg px-2 px-md-3",
                                design = viewModel.reservation,
                                onClick = ::handleReservation
                            )
                            div {
                                className = className("float-end")

                                d8Button(design = viewModel.settings, onClick = { handleGoTo("show/booking/rules") })
                                d8Button(design = viewModel.help, onClick = { handleGoTo("reservation/help") })
                            }
                            bCol {
                                xs = 12; xl = 9
                                d8Text(className = "mt-3", design = viewModel.helpText)
                            }
                        }
                        bContainer {
                            className = className("px-3 px-md-5 n-white-bg-box n-sticky")

                            bRow {
                                className = className("py-3")

                                viewModel.helpers.map { helper ->
                                    bCol {
                                        className = className("col-md-auto px-0 n-top-flex")
                                        d8Text(design = helper, className = "d-inline ps-1")
                                    }
                                }
                            }
                            bRow {
                                div {
                                    className = className("n-booking-annotations py-2")
                                }
                                div {
                                    className = className("d8-week")

                                }
                                viewModel.weekdays.map {
                                    bCol {
                                        className = className("text-center")

                                        d8Text(design = it)
                                    }
                                }
                            }
                        }
                        bContainer {
                            className = className("px-3 px-md-5 py-5 mb-5 n-white-bg-box")

                            viewModel.items.map { item ->
                                d8Month(page = "availability", design = item, onClick = ::handleBooking)
                            }
                        }
                        div {
                            className = className("mb-4 mt-2 d-flex align-self-center")
                            d8Button(design = viewModel.more, onClick = ::loadMore)
                            d8Button(design = viewModel.scroll, onClick = { window.scrollTo(x = 10.00, y = 0.0) })
                        }
                    }
                }

                if (cellReservations != null && showModal) {
                    params["objectId"]?.let { objectId ->
                        params["agreementId"]?.let { agreementId ->
                            bookingView(cellReservations, objectId = Identifier(objectId), agreementId = Identifier(agreementId), showModal, ::handleModal)
                        }
                    }
                }
            }
        }
        if (viewModel is AvailabilityScreen.ViewModel.Loading) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is AvailabilityScreen.ViewModel.Failed) {
            bContainer {
                className = className("px-5 n-top-space")

                d8failure(viewModel.failure, onLogout = ::logout, onRecover = ::recover)
            }
        }
    }
}
