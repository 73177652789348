package della8.core.support

import della8.core.screens.EditableInt
import della8.core.screens.RuleValue
import della8.core.services.editAgreement
import della8.core.services.listTerms
import techla.agreement.Agreement
import techla.agreement.Term
import techla.base.*
import techla.reservation.Reservation

val Term.Companion.partnershipKey get() = Key<Term>("PARTNERSHIP")
val Term.Companion.bookingKey get() = Key<Term>("BOOKING")

object TermHeader {
    val partnershipClassification = DesignSystem.Header(id = "partnershipClassification")
    val partnershipProperty = DesignSystem.Header(id = "partnershipProperty")
    val partnershipRegistration = DesignSystem.Header(id = "partnershipRegistration")
    val partnershipInsurance = DesignSystem.Header(id = "partnershipInsurance")
    val partnershipOwnership = DesignSystem.Header(id = "partnershipOwnership")
    val partnershipFinancing = DesignSystem.Header(id = "partnershipFinancing")
    val partnershipService = DesignSystem.Header(id = "partnershipService")
    val partnershipStorage = DesignSystem.Header(id = "partnershipStorage")
    val partnershipDrivingRange = DesignSystem.Header(id = "partnershipDrivingRange")
    val partnershipDrivingDistance = DesignSystem.Header(id = "partnershipDrivingDistance")
    val partnershipUnexpectedExpense = DesignSystem.Header(id = "partnershipUnexpectedExpense")
    val partnershipDamage = DesignSystem.Header(id = "partnershipDamage")
    val partnershipExpense = DesignSystem.Header(id = "partnershipExpense")
    val partnershipConduct = DesignSystem.Header(id = "partnershipConduct")
    val partnershipRental = DesignSystem.Header(id = "partnershipRental")
    val partnershipSale = DesignSystem.Header(id = "partnershipSale")
    val partnershipProfit = DesignSystem.Header(id = "partnershipProfit")
    val partnershipPublicAuction = DesignSystem.Header(id = "partnershipPublicAuction")
    val partnershipOther = DesignSystem.Header(id = "partnershipOther")
    val partnershipTermination = DesignSystem.Header(id = "partnershipTermination")
    val partnershipRisk = DesignSystem.Header(id = "partnershipRisk")

    val bookingHighValue = DesignSystem.Header(id = "bookingHighValue")
    val bookingUnlockedLong = DesignSystem.Header(id = "bookingUnlockedLong")
    val bookingUnlockedShort = DesignSystem.Header(id = "bookingUnlockedShort")
    val bookingDurationLong = DesignSystem.Header(id = "bookingDurationLong")
    val bookingDurationShort = DesignSystem.Header(id = "bookingDurationShort")
    val bookingConcurrentLong = DesignSystem.Header(id = "bookingConcurrentLong")
    val bookingConcurrentShort = DesignSystem.Header(id = "bookingConcurrentShort")
    val bookingConcurrentLongHighValue = DesignSystem.Header(id = "bookingConcurrentLongHighValue")
    val bookingConcurrentShortHighValue = DesignSystem.Header(id = "bookingConcurrentShortHighValue")
}

object Titles {
    const val partnershipClassification = "Typ"
    fun partnershipProperty(classification: Classification) = if (classification is Classification.Yacht) "Hamnadress" else "Adress"
    fun partnershipRegistration(classification: Classification) = when (classification) {
        is Classification.HolidayHome -> "Objektets fastighetsbeteckning"
        is Classification.Yacht -> "Objektets modell"
        else -> "Objektets registreringnummer"
    }

    const val partnershipInsurance = "Vårt objekt ska alltid vara försäkrat enligt"
    const val partnershipOwnership = "Hur ser ägandet och eventuell andelsfördelning ut?"
    const val partnershipFinancing = "Finns det extern finansiering?"
    const val partnershipService = "Service av objektet"
    fun partnershipStorage(classification: Classification) = when (classification) {
        else -> "Vinterförvaring"
    }

    fun partnershipRisk(classification: Classification) = when (classification) {
        is Classification.Yacht -> "Force major situationer ex vid svåra väderförhållanden"
        else -> "Risk"
    }

    const val partnershipDrivingRange = "Var får fordonet köras?"
    const val partnershipDrivingDistance = "Hur lång körsträcka får fordonet köras/år/samägare?"
    const val partnershipUnexpectedExpense = "Vem bär kostnaden vid oväntade kostnader?"
    fun partnershipDamage(classification: Classification) = when (classification) {
        is Classification.Yacht -> "Vem bär ansvaret vid grundstötning?"
        else -> "Vad händer vid skada av objekt?"
    }

    fun partnershipExpense(classification: Classification) = when (classification) {
        is Classification.Yacht -> "Vem bär kostnaden vid oväntade kostnader?"
        else -> "Vad händer vid utgift?"
    }

    const val partnershipConduct = "Efter bokningens slut gäller"
    const val partnershipRental = "Vi tillåter uthyrning av vårt objekt"
    const val partnershipSale = "Försäljning av andel"
    const val partnershipProfit = "Vad händer med eventuell vinst vid försäljning?"
    fun partnershipPublicAuction(classification: Classification) = when (classification) {
        is Classification.Yacht -> "Försäljning av båt"
        else -> "Möjlighet att begära att egendomen säljs via offentlig auktion"
    }

    fun partnershipOther(classification: Classification) = when (classification) {
        is Classification.Yacht -> "I vårt samägandeavtal vill vi också reglera"
        else -> "Ytterligare avtalsregler som ni vill ha?"
    }

    const val partnershipTermination = "Villkor vid ändring och uppsägning av avtal"

    const val bookingHighValue = "Våra guldveckor"
    const val bookingUnlockedLong = "Antal veckor man kan boka långbokning i förväg"
    const val bookingUnlockedShort = "Antal veckor man kan boka kortbokning i förväg"
    const val bookingDurationLong = "Max dagar för en långbokning"
    const val bookingDurationShort = "Max antal dagar för en kortbokning"
    const val bookingConcurrentLong = "Antal långbokningar man får göra under vanliga veckor"
    const val bookingConcurrentShort = ""
    const val bookingConcurrentLongHighValue = "Antal långbokningar man får göra under guldveckor"
    const val bookingConcurrentShortHighValue = "Antal kortbokningar man får göra under guldveckor"
}

object Recommendations {
    val partnershipClassification = Classification.None
    val partnershipProperty = Address.Swedish("", 0, "")
    const val partnershipRegistration = ""
    fun partnershipInsurance(classification: Classification) = Paragraph.Recommended(
        when (classification) {
            is Classification.HolidayHome -> "Fritidshusförsäkring, det mest kompletta skyddet mot ditt fritidshus."
            is Classification.Yacht -> "En Båtförsäkring samt eventuellt en tilläggsförsäkring om du önskar  att hyra ut den."
            else -> "Helförsäkring, den mest omfattande försäkringen som skyddar dig och ditt fordon grundligt."
        }
    )

    val partnershipOwnership = Ownership.None
    val partnershipFinancing = Financing.None
    val partnershipService = Paragraph.Recommended("Hur ofta husbilen ska servas står i serviceboken och det beror bland annat på vilken husbil det är och hur många mil den har kört. Service ska därför ske enligt serviceboken, minst en gång om året, av en auktoriserad verkstad.")
    fun partnershipStorage(classification: Classification) = Paragraph.Recommended(
        when (classification) {
            is Classification.Yacht -> "Vår båt ska förvaras på land under vintern."
            is Classification.MotorHome -> "Husbilen ska förvaras i en ventilerad lokal och vara rengjord med påfyllda oljor inför vintern."
            else -> ""
        }
    )

    fun partnershipRisk(classification: Classification) = Paragraph.Recommended(
        when (classification) {
            is Classification.Yacht -> "Om väderförhållandena är sådana att det inte är möjligt att lämna hamnen, eller om det förestår ett stort oväder, ska du kontakta övriga samägarna för att diskutera en eventuell ändring av datum."
            else -> ""
        }
    )

    val partnershipDrivingRange = Region.Nordics
    const val partnershipDrivingDistance = 2_000
    val partnershipUnexpectedExpense = Paragraph.Recommended("I första hand ur befintlig försäkring för objektet. Är objektet bokat är det ansvarig samägare som bär kostnaden för självrisken. Om objektet inte är bokat delas kostnaden mellan alla samägare. Om försäkringen inte täcker skadan är det fortfarande ovan ordning som gäller för att bära kostnaden.")

    fun partnershipDamage(classification: Classification) = Paragraph.Recommended(
        when (classification) {
            is Classification.Yacht -> "Den samägare som står på bokningen vid tidpunkt för grundstötning är ansvarig för att självrisk täcks."
            else -> "Befintlig försäkring av objektet ska tas i anspråk om möjligt. Orsakas skadan vårdslöst av en samägare bär samägaren kostnad för självrisken eller, om försäkringen inte kan tas i anspråk eller ersättning inte kan erhållas från tredje part, för hela skadan. Om objektet skadas i andra fall delas självrisken och kostnaden av skadan enligt deras ägandeandel."
        }
    )

    fun partnershipExpense(classification: Classification) = Paragraph.Recommended(
        when (classification) {
            is Classification.Yacht -> "I första hand ur befintlig försäkring för objektet. Är objektet bokat är det ansvarig samägare som bär kostnaden för självrisken. Om objektet ligger i hemmahamn delas kostnaden mellan alla samägare. Om försäkringen inte täcker skadan är det fortfarande ovan ordning som gäller för att bära kostnaden."
            else -> "Det finns skyndsamma och icke skyndsamma utgifter. En skyndsam utgift är något som omedelbart behöver tas hand om och en icke skyndsam är exempelvis att en lampa slutat att lysa. Della8 rekommenderar att man i båda fall delar denna utgift enligt sina ägandeandelar. Icke skyndsamma utgifter behöver godkännas av samägare innan utgiften görs. En skyndsam utgift krävs för att undvika eller minska fara för objektet/människor/annans egendom och där det av tidsskäl inte är möjligt att inhämta samägarnas samtycke. En sådan utgift kan en samägare ensamt besluta om."
        }
    )

    fun partnershipConduct(classification: Classification) = Paragraph.Recommended(
        when (classification) {
            is Classification.HolidayHome -> "För allas trevnad så ska fastigheten vara i väl rengjort skick och lämnas på det sättet som du velat mötas av den."
            is Classification.Yacht -> "Båten ska vara i väl rengjort skick, fulltankad och ligga väl förankrad i sin hemmahamn. Med ett av försäkringsbolaget godkänt lås."
            else -> "Objektet ska vara i körklart skick vilket innebär att den är städad, tömd på sopor, fulltankad, tvättad utvändigt, tömd gråvattentank, påfylld med olja och spolarvätska samt minst en halv gasolflaska."
        }
    )

    val partnershipRental = Approval.Approved
    val partnershipSale = ConditionsOfSale.AccordingToLaw
    val partnershipProfit = Paragraph.Recommended("Eventuell vinst delas enligt andel.")
    val partnershipPublicAuction = Adherence.AccordingToLaw
    const val partnershipOther = ""
    val partnershipTermination =
        Paragraph.Recommended("Genom att ni elektroniskt undertecknar dessa spelregler uppstår ett samägandeavtal, som är bindande för er alla under sin löptid. Denna löptid bestäms till ett år åt gången, räknat från att alla parter har undertecknat avtalet, med en uppsägningstid om tre månader. Om någon av parterna inte vill att avtalet ska förlängas efter det första året eller något av de följande åren, kan parten alltså säga upp avtalet. Detta gör man genom att meddela övriga samägare per mail. När det har skett en sådan uppsägning i tid upphör avtalet att gälla vid respektive följande årsdag mellan ALLA parter. Specifika överenskommelser om enskilda punkter som ni träffat innan avtalet har gått ut, liksom bokningar som ni har gjort innan detta datum, är gällande vidare även efter slutdatumet.")

    val bookingHighValue: List<Pair<Int, Int>> = listOf(28 to 31) // listOf(1 to 2, 7 to 9, 28 to 29, 30 to 31, 44 to 44, 51 to 52)
    const val bookingUnlockedLong = 24
    const val bookingUnlockedShort = 8
    const val bookingDurationLong = 7
    const val bookingDurationShort = 3
    const val bookingConcurrentLong = 2
    const val bookingConcurrentShort = 100
    const val bookingConcurrentLongHighValue = 1
    const val bookingConcurrentShortHighValue = 2
}

object Info {
    val partnershipClassification = null
    val partnershipProperty = null
    fun partnershipRegistration(classification: Classification) = if (classification is Classification.HolidayHome) null else "Om fordonet inte fått sitt registreringsnummer ännu kan detta fältet lämnas tomt"
    fun partnershipInsurance(classification: Classification) = when (classification) {
        is Classification.HolidayHome -> "Många fritidshusförsäkringar skyddar ditt fritidshus bland annat om byggnaden skadas av brand, läckage, skadegörelse, naturskador och skadedjur - men kolla upp exakt vad som ingår med ditt försäkringsbolag!"
        is Classification.Yacht -> "Det finns olika båtförsäkringar men i en komplett båtförsäkring ingår vanligtvis följande: sjöskador, till exempel grundstötning, förtöjningsskador, kollision, ombordläggning och stormskador."
        else -> null
    }

    const val partnershipOwnership = "Om ni leasar ert objekt kan ni skriva ner delarna i ert leasingavtal här."
    const val partnershipFinancing = "Om det finns extern finansiering (till exempel lån) så anger ni det här. Ni behöver ange hur stor avgift som ska betalas, i vilket intervall den ska betalas (till exempel per månad eller per år), samt var avgiften ska betalas in (till exempel bankkonto inklusive cl nummer eller swish-nummer)."
    val partnershipService = null
    fun partnershipStorage(classification: Classification) = when (classification) {
        is Classification.Yacht -> "Ska er båt även förvaras inomhus ange förslagvis \"Annat\" och skriv var i textfältet. "
        is Classification.MotorHome -> "Om fordonet förvaras inomhus blir det mindre risk för skador och då blir det mindre risk för oväntade kostnader, vilket leder till mindre risk för konflikter."
        else -> null
    }

    val partnershipRisk = null
    val partnershipDrivingRange = null
    val partnershipDrivingDistance = null
    val partnershipUnexpectedExpense = null
    val partnershipDamage = null
    val partnershipExpense = null
    val partnershipConduct = null
    val partnershipRental = "Längre fram planerar vi att ha en hel uthyrningsmodul. Håll utkik!"
    val partnershipSale = null
    val partnershipProfit = null
    val partnershipPublicAuction = null
    val partnershipOther = null
    val partnershipTermination = "Om ni önskar ha en avvikande reglering rörande avtalstid och uppsägning går det bra att komma överens om det och skriva ner det under \"annat\"."

    const val bookingHighValue = "Guldveckor är det vi kallar attraktiva veckor som kan vara mer populära och som det är viktigt att ni känner fördelas rättvist. Det varierar såklart beroende på vilket objekt ni har. Därför kan ni enkelt ställa in just era guldveckor här!"
    val bookingUnlockedLong = null
    val bookingUnlockedShort = null
    const val bookingDurationLong = "Ni kommer gemensamt överens om vad en kort- respektive långbokning är. Vill ni göra olika regler för dessa veckor så kan ni skriva ner det här."
    const val bookingDurationShort = "Ni kommer gemensamt överens om vad en kort- respektive långbokning är. Vill ni göra olika regler för dessa veckor så kan ni skriva ner det här."
    val bookingConcurrentLong = null
    val bookingConcurrentShort = null
    val bookingConcurrentLongHighValue = null
    val bookingConcurrentShortHighValue = null
}

object Tips {
    const val partnershipClassification = "Om du inte vill reglera samägandet utan bara använda bokningsmodulen, välj Hoppa över"
    val partnershipProperty = null
    fun partnershipRegistration(classification: Classification) = null
    fun partnershipInsurance(classification: Classification) = when (classification) {
        is Classification.HolidayHome -> "Hör med ditt försäkringsbolag om de erbjuder denna typ av försäkring!"
        is Classification.Yacht -> "Tänk på att höra med ditt försäkringsbolag om de erbjuder denna typ av försäkring!"
        else -> "Kom ihåg att det är obligatoriskt att ha en trafikförsäkring på din husbil. Fundera på om ni ska ha husbilen mycket avställd under vinterhalvåret eller ifall ni tänker ha den avställd på camping samtidigt som ni bor i den. Era behov kan påverka vilken försäkring som blir bäst och mest ekonomisk för er."
    }

    val partnershipOwnership = null
    val partnershipFinancing = null
    const val partnershipService = "På hösten är det mindre belastning på verkstäderna så passa på att göra er service då!"
    fun partnershipStorage(classification: Classification) = when (classification) {
        is Classification.Yacht -> "Kontrollera att din försäkring täcker om båten skadas vid lyft, transport eller vid uppläggning genom en plötslig och oförutsedd händelse."
        is Classification.MotorHome -> "Frysskador omfattas inte av garantin så var därför noga med att tömma vattensystemet. Det här är också bra för att undvika fukt i husbilen. Köps husbilen av en återförsäljare omfattas inte frysskador av deras garantier."
        else -> null
    }

    val partnershipRisk = null

    const val partnershipDrivingRange = "Glöm inte att kontrollera vilka länder som försäkringen täcker!"
    const val partnershipDrivingDistance = "En husbil rullar i snitt totalt 680 mil/år."
    val partnershipUnexpectedExpense = null
    fun partnershipDamage(classification: Classification) = when (classification) {
        is Classification.Yacht -> "Båtar är idag ännu mer ömtåliga än förr och är  ej byggda för att köra på grund och ledtiden för  att laga dem kan förstöra en hel sommar. Se därför över i er försäkring hur detta täcks."
        else -> null
    }

    val partnershipExpense = null
    val partnershipConduct = null
    val partnershipRental = "Vill ni ha ett uthyrningsavtal som endast hanterar er uthyrning? Detta kan vi hjälpa er med, kontakta oss på hej@della8.se"
    val partnershipSale = "Känner ni osäkerhet i denna fråga och känner att ni vill tillfråga en jurist. Detta kan vi hjälpa er med, kontakta oss på hej@della8.se"
    val partnershipProfit = null
    val partnershipPublicAuction = "Känner ni osäkerhet i denna fråga och känner att ni vill tillfråga en jurist. Detta kan vi hjälpa er med, kontakta oss på hej@della8.se"
    fun partnershipOther(classification: Classification) = when (classification) {
        is Classification.HolidayHome -> "Förslagsvis kan ni reglera vad som gäller för visst lösöre i fastigheten. Vill ni har flera regler skriv dessa under varandra."
        is Classification.Yacht -> "Det är vanligt att man årligen har ett skepparmöte med sina samägare. Då ges möjlighet att se över ordningsregler och annat."
        else -> null
    }

    val partnershipTermination = null

    val bookingHighValue = null
    val bookingUnlockedLong = null
    val bookingUnlockedShort = null
    val bookingDurationLong = null
    val bookingDurationShort = null
    val bookingConcurrentLong = null
    val bookingConcurrentShort = null
    val bookingConcurrentLongHighValue = null
    val bookingConcurrentShortHighValue = null
}

object Defaults {
    val partnershipClassification = Term.Clause.Classification(classification = Recommendations.partnershipClassification)
    val partnershipProperty = Term.Clause.Property(address = Recommendations.partnershipProperty)
    val partnershipRegistration = Term.Clause.Registration(registration = Recommendations.partnershipRegistration)
    fun partnershipInsurance(classification: Classification) = Term.Clause.Insurance(paragraph = Recommendations.partnershipInsurance(classification))
    val partnershipOwnership = Term.Clause.Ownership(ownership = Recommendations.partnershipOwnership)
    val partnershipFinancing = Term.Clause.Financing(financing = Recommendations.partnershipFinancing)
    val partnershipService = Term.Clause.Service(paragraph = Recommendations.partnershipService)
    fun partnershipStorage(classification: Classification) = Term.Clause.Storage(paragraph = Recommendations.partnershipStorage(classification = classification))
    val partnershipDrivingRange = Term.Clause.DrivingRange(region = Recommendations.partnershipDrivingRange)
    val partnershipDrivingDistance = Term.Clause.DrivingDistance(kilometers = Recommendations.partnershipDrivingDistance)
    val partnershipUnexpectedExpense = Term.Clause.UnexpectedExpense(paragraph = Recommendations.partnershipUnexpectedExpense)
    fun partnershipDamage(classification: Classification) = Term.Clause.Damage(paragraph = Recommendations.partnershipDamage(classification))
    fun partnershipExpense(classification: Classification) = Term.Clause.Expense(paragraph = Recommendations.partnershipExpense(classification))
    fun partnershipConduct(classification: Classification) = Term.Clause.Conduct(paragraph = Recommendations.partnershipConduct(classification), extra = emptyList())
    val partnershipRental = Term.Clause.Rental(approval = Recommendations.partnershipRental)
    val partnershipSale = Term.Clause.Sale(conditionsOfSale = Recommendations.partnershipSale)
    val partnershipProfit = Term.Clause.Profit(paragraph = Recommendations.partnershipProfit)
    val partnershipPublicAuction = Term.Clause.PublicAuction(adherence = Recommendations.partnershipPublicAuction)
    val partnershipOther = Term.Clause.Other(description = Recommendations.partnershipOther)
    val partnershipTermination = Term.Clause.Termination(paragraph = Recommendations.partnershipTermination)
    fun partnershipRisk(classification: Classification) = Term.Clause.Risk(paragraph = Recommendations.partnershipRisk(classification = classification), extra = emptyList())

    val bookingHighValue = Recommendations.bookingHighValue.map { Term.Clause.HighValue(fromWeekOfYear = it.first, toWeekOfYear = it.second) }
    val bookingUnlockedLong = Term.Clause.Unlocked(numberOfWeeks = Recommendations.bookingUnlockedLong, style = Reservation.Style.Long)
    val bookingUnlockedShort = Term.Clause.Unlocked(numberOfWeeks = Recommendations.bookingUnlockedShort, style = Reservation.Style.Short)
    val bookingDurationLong = Term.Clause.Duration(numberOfDays = Recommendations.bookingDurationLong, style = Reservation.Style.Long)
    val bookingDurationShort = Term.Clause.Duration(numberOfDays = Recommendations.bookingDurationShort, style = Reservation.Style.Short)
    val bookingConcurrentLong = Term.Clause.Concurrent(numberOfTimes = Recommendations.bookingConcurrentLong, style = Reservation.Style.Long, highValue = false)
    val bookingConcurrentShort = Term.Clause.Concurrent(numberOfTimes = Recommendations.bookingConcurrentShort, style = Reservation.Style.Short, highValue = false)
    val bookingConcurrentLongHighValue = Term.Clause.Concurrent(numberOfTimes = Recommendations.bookingConcurrentLongHighValue, style = Reservation.Style.Long, highValue = true)
    val bookingConcurrentShortHighValue = Term.Clause.Concurrent(numberOfTimes = Recommendations.bookingConcurrentShortHighValue, style = Reservation.Style.Short, highValue = true)
}

sealed class EditableClause(open val isOff: Boolean, open val status: DesignSystem.Status, val order: Double) {
    data class Classification(override val isOff: Boolean, val clause: Term.Clause.Classification, val other: String?) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 1.0)
    data class Property(override val isOff: Boolean, val clause: Term.Clause.Property, val address: EditableAddress) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 2.0)
    data class Registration(override val isOff: Boolean, val clause: Term.Clause.Registration, override val status: DesignSystem.Status = DesignSystem.Status.Unknown) : EditableClause(isOff = isOff, status = status, order = 3.0)
    data class Insurance(override val isOff: Boolean, val clause: Term.Clause.Insurance, val paragraph: EditableParagraph) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 4.0)
    data class Ownership(override val isOff: Boolean, val clause: List<Term.Clause.Ownership>, val ownership: EditableOwnership, override val status: DesignSystem.Status = DesignSystem.Status.Valid) : EditableClause(isOff = isOff, status = status, order = 5.0)
    data class Financing(override val isOff: Boolean, val clause: Term.Clause.Financing, val financing: EditableFinancing) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 6.0)
    data class Service(override val isOff: Boolean, val clause: Term.Clause.Service, val paragraph: EditableParagraph) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 7.0)
    data class Storage(override val isOff: Boolean, val clause: Term.Clause.Storage, val paragraph: EditableParagraph) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 8.0)
    data class DrivingRange(override val isOff: Boolean, val clause: Term.Clause.DrivingRange, val other: String?) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 9.0)
    data class DrivingDistance(override val isOff: Boolean, val clause: Term.Clause.DrivingDistance, val value: EditableInt) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 10.0)
    data class UnexpectedExpense(override val isOff: Boolean, val clause: Term.Clause.UnexpectedExpense, val paragraph: EditableParagraph) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 11.0)
    data class Damage(override val isOff: Boolean, val clause: Term.Clause.Damage, val paragraph: EditableParagraph) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 11.1)
    data class Expense(override val isOff: Boolean, val clause: Term.Clause.Expense, val paragraph: EditableParagraph) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 11.2)
    data class Conduct(override val isOff: Boolean, val clause: Term.Clause.Conduct, val paragraph: EditableParagraph) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 12.0)
    data class Rental(override val isOff: Boolean, val clause: Term.Clause.Rental, val other: String?) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 13.0)
    data class Sale(override val isOff: Boolean, val clause: Term.Clause.Sale, val conditionsOfSale: EditableConditionsOfSale) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 14.0)
    data class Profit(override val isOff: Boolean, val clause: Term.Clause.Profit, val paragraph: EditableParagraph) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 15.0)
    data class PublicAuction(override val isOff: Boolean, val clause: Term.Clause.PublicAuction, val adherence: EditableAdherence) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 16.0)
    data class Other(override val isOff: Boolean, val clause: Term.Clause.Other) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 17.0)
    data class Termination(override val isOff: Boolean, val clause: Term.Clause.Termination, val paragraph: EditableParagraph) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 18.0)

    data class HighValue(override val isOff: Boolean, val clause: List<Term.Clause.HighValue>, override val status: DesignSystem.Status = DesignSystem.Status.Valid) : EditableClause(isOff = isOff, status = status, order = 20.0)
    data class UnlockedLong(override val isOff: Boolean, val clause: Term.Clause.Unlocked, val value: EditableInt) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 21.0)
    data class UnlockedShort(override val isOff: Boolean, val clause: Term.Clause.Unlocked, val value: EditableInt) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 22.0)
    data class DurationLong(override val isOff: Boolean, val clause: Term.Clause.Duration, val value: EditableInt) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 23.0)
    data class DurationShort(override val isOff: Boolean, val clause: Term.Clause.Duration, val value: EditableInt) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 24.0)
    data class ConcurrentLong(override val isOff: Boolean, val clause: Term.Clause.Concurrent, val value: EditableInt) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 25.0)
    data class ConcurrentShort(override val isOff: Boolean, val clause: Term.Clause.Concurrent, val value: EditableInt) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 26.0)
    data class ConcurrentLongHighValue(override val isOff: Boolean, val clause: Term.Clause.Concurrent, val value: EditableInt) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 27.0)
    data class ConcurrentShortHighValue(override val isOff: Boolean, val clause: Term.Clause.Concurrent, val value: EditableInt) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 28.0)
    data class Risk(override val isOff: Boolean, val clause: Term.Clause.Risk, val paragraph: EditableParagraph) : EditableClause(isOff = isOff, status = DesignSystem.Status.Valid, order = 29.0)

    val clauses: List<Term.Clause>
        get() =
            when (this) {
                is Classification -> listOf(clause)
                is Property -> listOf(clause)
                is Registration -> listOf(clause)
                is Insurance -> listOf(clause)
                is Ownership -> clause
                is Financing -> listOf(clause)
                is Service -> listOf(clause)
                is Storage -> listOf(clause)
                is DrivingRange -> listOf(clause)
                is DrivingDistance -> listOf(clause)
                is UnexpectedExpense -> listOf(clause)
                is Damage -> listOf(clause)
                is Expense -> listOf(clause)
                is Conduct -> listOf(clause)
                is Rental -> listOf(clause)
                is Sale -> listOf(clause)
                is Profit -> listOf(clause)
                is PublicAuction -> listOf(clause)
                is Other -> listOf(clause)
                is Termination -> listOf(clause)
                is HighValue -> clause
                is UnlockedLong -> listOf(clause)
                is UnlockedShort -> listOf(clause)
                is DurationLong -> listOf(clause)
                is DurationShort -> listOf(clause)
                is ConcurrentLong -> listOf(clause)
                is ConcurrentShort -> listOf(clause)
                is ConcurrentLongHighValue -> listOf(clause)
                is ConcurrentShortHighValue -> listOf(clause)
                is Risk -> listOf(clause)
            }

    fun autoOff() =
        when (this) {
            is Classification -> this
            is Property -> this
            is Registration -> this
            is Insurance -> this
            is Ownership -> this
            is Financing -> this
            is Service -> this
            is Storage -> this
            is DrivingRange -> this
            is DrivingDistance -> this
            is UnexpectedExpense -> this
            is Damage -> this
            is Expense -> this
            is Conduct -> this
            is Rental -> this
            is Sale -> this
            is Profit -> this
            is PublicAuction -> this
            is Other -> if (clause.description.isEmpty()) copy(isOff = true) else this
            is Termination -> this
            is HighValue -> this
            is UnlockedLong -> this
            is UnlockedShort -> this
            is DurationLong -> this
            is DurationShort -> this
            is ConcurrentLong -> this
            is ConcurrentShort -> this
            is ConcurrentLongHighValue -> this
            is ConcurrentShortHighValue -> this
            is Risk -> this
        }
}

object EditableDefaults {
    fun partnershipClassification(editable: Boolean) = if (editable) EditableClause.Classification(isOff = true, clause = Defaults.partnershipClassification, other = null) else null
    fun partnershipProperty(editable: Boolean) = if (editable) EditableClause.Property(isOff = true, clause = Defaults.partnershipProperty, address = EditableAddress.fromTruth(truth = Defaults.partnershipProperty.address)) else null
    fun partnershipRegistration(editable: Boolean) = if (editable) EditableClause.Registration(isOff = true, clause = Defaults.partnershipRegistration) else null
    fun partnershipInsurance(classification: Classification, editable: Boolean) = if (editable) EditableClause.Insurance(isOff = true, clause = Defaults.partnershipInsurance(classification), paragraph = EditableParagraph.fromTruth(Defaults.partnershipInsurance(classification).paragraph)) else null
    fun partnershipOwnership(editable: Boolean) = if (editable) EditableClause.Ownership(isOff = true, clause = emptyList(), EditableOwnership.fromTruth(truth = emptyList())) else null
    fun partnershipFinancing(editable: Boolean) = if (editable) EditableClause.Financing(isOff = true, clause = Defaults.partnershipFinancing, financing = EditableFinancing.fromTruth(truth = Defaults.partnershipFinancing.financing)) else null
    fun partnershipService(editable: Boolean) = if (editable) EditableClause.Service(isOff = true, clause = Defaults.partnershipService, paragraph = EditableParagraph.fromTruth(Defaults.partnershipService.paragraph)) else null
    fun partnershipStorage(classification: Classification, editable: Boolean) = if (editable) EditableClause.Storage(isOff = true, clause = Defaults.partnershipStorage(classification), paragraph = EditableParagraph.fromTruth(Defaults.partnershipStorage(classification).paragraph)) else null
    fun partnershipDrivingRange(editable: Boolean) = if (editable) EditableClause.DrivingRange(isOff = true, clause = Defaults.partnershipDrivingRange, other = null) else null
    fun partnershipDrivingDistance(editable: Boolean) = if (editable) EditableClause.DrivingDistance(isOff = true, clause = Defaults.partnershipDrivingDistance, value = EditableInt.fromTruth(Defaults.partnershipDrivingDistance.kilometers)) else null
    fun partnershipUnexpectedExpense(editable: Boolean) = if (editable) EditableClause.UnexpectedExpense(isOff = true, clause = Defaults.partnershipUnexpectedExpense, paragraph = EditableParagraph.fromTruth(Defaults.partnershipUnexpectedExpense.paragraph)) else null
    fun partnershipDamage(classification: Classification, editable: Boolean) = if (editable) EditableClause.Damage(isOff = true, clause = Defaults.partnershipDamage(classification), paragraph = EditableParagraph.fromTruth(Defaults.partnershipDamage(classification).paragraph)) else null
    fun partnershipExpense(classification: Classification, editable: Boolean) = if (editable) EditableClause.Expense(isOff = true, clause = Defaults.partnershipExpense(classification), paragraph = EditableParagraph.fromTruth(Defaults.partnershipExpense(classification).paragraph)) else null
    fun partnershipConduct(classification: Classification, editable: Boolean) = if (editable) EditableClause.Conduct(isOff = true, clause = Defaults.partnershipConduct(classification), paragraph = EditableParagraph.fromTruth(Defaults.partnershipConduct(classification).paragraph)) else null
    fun partnershipRental(editable: Boolean) = if (editable) EditableClause.Rental(isOff = true, clause = Defaults.partnershipRental, other = null) else null
    fun partnershipSale(editable: Boolean) = if (editable) EditableClause.Sale(isOff = true, clause = Defaults.partnershipSale, conditionsOfSale = EditableConditionsOfSale.fromTruth(Defaults.partnershipSale.conditionsOfSale)) else null
    fun partnershipProfit(editable: Boolean) = if (editable) EditableClause.Profit(isOff = true, clause = Defaults.partnershipProfit, paragraph = EditableParagraph.fromTruth(Defaults.partnershipProfit.paragraph)) else null
    fun partnershipPublicAuction(editable: Boolean) = if (editable) EditableClause.PublicAuction(isOff = true, clause = Defaults.partnershipPublicAuction, adherence = EditableAdherence.fromTruth(Defaults.partnershipPublicAuction.adherence)) else null
    fun partnershipOther(editable: Boolean) = if (editable) EditableClause.Other(isOff = true, clause = Defaults.partnershipOther) else null
    fun partnershipTermination(editable: Boolean) = if (editable) EditableClause.Termination(isOff = true, clause = Defaults.partnershipTermination, paragraph = EditableParagraph.fromTruth(Defaults.partnershipTermination.paragraph)) else null
    fun partnershipRisk(classification: Classification, editable: Boolean) = if (editable) EditableClause.Risk(isOff = true, clause = Defaults.partnershipRisk(classification), paragraph = EditableParagraph.fromTruth(Defaults.partnershipRisk(classification).paragraph)) else null

    fun bookingHighValue(editable: Boolean) = if (editable) EditableClause.HighValue(isOff = true, clause = Defaults.bookingHighValue) else null
    fun bookingUnlockedLong(editable: Boolean) = if (editable) EditableClause.UnlockedLong(isOff = true, clause = Defaults.bookingUnlockedLong, value = EditableInt.fromTruth(Defaults.bookingUnlockedLong.numberOfWeeks)) else null
    fun bookingUnlockedShort(editable: Boolean) = if (editable) EditableClause.UnlockedShort(isOff = true, clause = Defaults.bookingUnlockedShort, value = EditableInt.fromTruth(Defaults.bookingUnlockedShort.numberOfWeeks)) else null
    fun bookingDurationLong(editable: Boolean) = if (editable) EditableClause.DurationLong(isOff = true, clause = Defaults.bookingDurationLong, value = EditableInt.fromTruth(Defaults.bookingDurationLong.numberOfDays)) else null
    fun bookingDurationShort(editable: Boolean) = if (editable) EditableClause.DurationShort(isOff = true, clause = Defaults.bookingDurationShort, value = EditableInt.fromTruth(Defaults.bookingDurationShort.numberOfDays)) else null
    fun bookingConcurrentLong(editable: Boolean) = if (editable) EditableClause.ConcurrentLong(isOff = true, clause = Defaults.bookingConcurrentLong, value = EditableInt.fromTruth(Defaults.bookingConcurrentLong.numberOfTimes)) else null
    fun bookingConcurrentShort(editable: Boolean) = if (editable) EditableClause.ConcurrentShort(isOff = true, clause = Defaults.bookingConcurrentShort, value = EditableInt.fromTruth(Defaults.bookingConcurrentShort.numberOfTimes)) else null
    fun bookingConcurrentLongHighValue(editable: Boolean) = if (editable) EditableClause.ConcurrentLongHighValue(isOff = true, clause = Defaults.bookingConcurrentLongHighValue, value = EditableInt.fromTruth(Defaults.bookingConcurrentLongHighValue.numberOfTimes)) else null
    fun bookingConcurrentShortHighValue(editable: Boolean) = if (editable) EditableClause.ConcurrentShortHighValue(isOff = true, clause = Defaults.bookingConcurrentShortHighValue, value = EditableInt.fromTruth(Defaults.bookingConcurrentShortHighValue.numberOfTimes)) else null
}

private fun mergeClassification(result: List<EditableClause>, value: Term.Clause.Classification): List<EditableClause> {
    val other = (value.classification as? Classification.Other)?.description
    return result + EditableClause.Classification(isOff = false, clause = value, other = other)
}

private fun mergeProperty(result: List<EditableClause>, value: Term.Clause.Property): List<EditableClause> {
    return result + EditableClause.Property(isOff = false, clause = value, address = EditableAddress.fromTruth(truth = value.address))
}

private fun mergeOwnership(result: List<EditableClause>, ownership: Term.Clause.Ownership): List<EditableClause> {
    val existing = result.filterIsInstance<EditableClause.Ownership>().firstOrNull()
    val ownerships = (existing?.clause ?: emptyList()) + ownership
    return result.filter { it != existing } + EditableClause.Ownership(isOff = false, clause = ownerships, ownership = EditableOwnership.fromTruth(truth = ownerships.map { it.ownership }))
}

private fun mergeFinancing(result: List<EditableClause>, value: Term.Clause.Financing): List<EditableClause> {
    return result + EditableClause.Financing(isOff = false, clause = value, financing = EditableFinancing.fromTruth(truth = value.financing))
}

private fun mergeInsurance(classification: Classification, result: List<EditableClause>, value: Term.Clause.Insurance): List<EditableClause> {
    // We migrate Recommended values to Other if the recommendations has changed
    val recommendation = Recommendations.partnershipInsurance(classification)
    val paragraph = value.paragraph.migrate(recommendation = recommendation)
    return result + EditableClause.Insurance(isOff = false, clause = Term.Clause.Insurance(paragraph), paragraph = EditableParagraph.fromTruth(paragraph, recommendation))
}

private fun mergeService(result: List<EditableClause>, value: Term.Clause.Service): List<EditableClause> {
    // We migrate Recommended values to Other if the recommendations has changed
    val recommendation = Recommendations.partnershipService
    val paragraph = value.paragraph.migrate(recommendation = recommendation)
    return result + EditableClause.Service(isOff = false, clause = Term.Clause.Service(paragraph), paragraph = EditableParagraph.fromTruth(paragraph, recommendation))
}

private fun mergeStorage(classification: Classification, result: List<EditableClause>, value: Term.Clause.Storage): List<EditableClause> {
    // We migrate Recommended values to Other if the recommendations has changed
    val recommendation = Recommendations.partnershipStorage(classification)
    val paragraph = value.paragraph.migrate(recommendation = Recommendations.partnershipStorage(classification))
    return result + EditableClause.Storage(isOff = false, clause = Term.Clause.Storage(paragraph), paragraph = EditableParagraph.fromTruth(paragraph, recommendation))
}

private fun mergeDrivingRange(result: List<EditableClause>, value: Term.Clause.DrivingRange): List<EditableClause> {
    val other = (value.region as? Region.Other)?.description
    return result + EditableClause.DrivingRange(isOff = false, clause = value, other = other)
}

private fun mergeDrivingDistance(result: List<EditableClause>, value: Term.Clause.DrivingDistance): List<EditableClause> {
    return result + EditableClause.DrivingDistance(isOff = false, clause = value, value = EditableInt.fromTruth(value.kilometers))
}

private fun mergeUnexpectedExpense(result: List<EditableClause>, value: Term.Clause.UnexpectedExpense): List<EditableClause> {
    // We migrate Recommended values to Other if the recommendations has changed
    val recommendation = Recommendations.partnershipUnexpectedExpense
    val paragraph = value.paragraph.migrate(recommendation = recommendation)
    return result + EditableClause.UnexpectedExpense(isOff = false, clause = Term.Clause.UnexpectedExpense(paragraph), paragraph = EditableParagraph.fromTruth(paragraph, recommendation))
}

private fun mergeDamage(classification: Classification, result: List<EditableClause>, value: Term.Clause.Damage): List<EditableClause> {
    // We migrate Recommended values to Other if the recommendations has changed
    val recommendation = Recommendations.partnershipDamage(classification)
    val paragraph = value.paragraph.migrate(recommendation = recommendation)
    return result + EditableClause.Damage(isOff = false, clause = Term.Clause.Damage(paragraph), paragraph = EditableParagraph.fromTruth(paragraph, recommendation))
}

private fun mergeExpense(classification: Classification, result: List<EditableClause>, value: Term.Clause.Expense): List<EditableClause> {
    // We migrate Recommended values to Other if the recommendations has changed
    val recommendation = Recommendations.partnershipExpense(classification)
    val paragraph = value.paragraph.migrate(recommendation = Recommendations.partnershipExpense(classification))
    return result + EditableClause.Expense(isOff = false, clause = Term.Clause.Expense(paragraph), paragraph = EditableParagraph.fromTruth(paragraph, recommendation))
}

private fun mergeConduct(classification: Classification, result: List<EditableClause>, value: Term.Clause.Conduct): List<EditableClause> {
    // We migrate Recommended values to Other if the recommendations has changed
    val recommendation = Recommendations.partnershipConduct(classification)
    val paragraph = value.paragraph.migrate(recommendation = recommendation)
    return result + EditableClause.Conduct(isOff = false, clause = Term.Clause.Conduct(paragraph, emptyList()), paragraph = EditableParagraph.fromTruth(paragraph, recommendation))
}

private fun mergeRental(result: List<EditableClause>, value: Term.Clause.Rental): List<EditableClause> {
    val other = (value.approval as? Approval.Other)?.description
    return result + EditableClause.Rental(isOff = false, clause = value, other = other)
}

private fun mergeSale(result: List<EditableClause>, value: Term.Clause.Sale): List<EditableClause> {
    return result + EditableClause.Sale(isOff = false, clause = value, conditionsOfSale = EditableConditionsOfSale.fromTruth(value.conditionsOfSale))
}

private fun mergeProfit(result: List<EditableClause>, value: Term.Clause.Profit): List<EditableClause> {
    // We migrate Recommended values to Other if the recommendations has changed
    val recommendation = Recommendations.partnershipProfit
    val paragraph = value.paragraph.migrate(recommendation = recommendation)
    return result + EditableClause.Profit(isOff = false, clause = Term.Clause.Profit(paragraph), paragraph = EditableParagraph.fromTruth(paragraph, recommendation))
}

private fun mergePublicAuction(result: List<EditableClause>, value: Term.Clause.PublicAuction): List<EditableClause> {
    return result + EditableClause.PublicAuction(isOff = false, clause = value, adherence = EditableAdherence.fromTruth(value.adherence))
}

private fun mergeRisk(classification: Classification, result: List<EditableClause>, value: Term.Clause.Risk): List<EditableClause> {
    // We migrate Recommended values to Other if the recommendations has changed
    val recommendation = Recommendations.partnershipRisk(classification)
    val paragraph = value.paragraph.migrate(recommendation = Recommendations.partnershipRisk(classification))
    return result + EditableClause.Risk(isOff = false, clause = Term.Clause.Risk(paragraph, emptyList()), paragraph = EditableParagraph.fromTruth(paragraph, recommendation))
}

private fun mergeHighValue(result: List<EditableClause>, highValue: Term.Clause.HighValue): List<EditableClause> {
    val existing = result.filterIsInstance<EditableClause.HighValue>().firstOrNull()
    val updated = existing?.copy(clause = existing.clause + highValue) ?: EditableClause.HighValue(isOff = false, clause = listOf(highValue))
    return result.filter { it != existing } + updated
}

private fun mergeTermination(result: List<EditableClause>, value: Term.Clause.Termination): List<EditableClause> {
    // We migrate Recommended values to Other if the recommendations has changed
    val recommendation = Recommendations.partnershipTermination
    val paragraph = value.paragraph.migrate(recommendation = recommendation)
    return result + EditableClause.Termination(isOff = false, clause = Term.Clause.Termination(paragraph), paragraph = EditableParagraph.fromTruth(paragraph, recommendation))
}

fun List<Term.Clause>.makeEditable(): List<EditableClause> {
    val classification = filterIsInstance<Term.Clause.Classification>().firstOrNull()?.classification ?: Classification.None
    return fold<Term.Clause, List<EditableClause>>(emptyList()) { result, value ->
        when {
            value is Term.Clause.Classification -> mergeClassification(result, value)
            value is Term.Clause.Property -> mergeProperty(result, value)
            value is Term.Clause.Registration -> result + EditableClause.Registration(isOff = false, clause = value)
            value is Term.Clause.Insurance -> mergeInsurance(classification, result, value)
            value is Term.Clause.Ownership -> mergeOwnership(result, value)
            value is Term.Clause.Financing -> mergeFinancing(result, value)
            value is Term.Clause.Service -> mergeService(result, value)
            value is Term.Clause.Storage -> mergeStorage(classification, result, value)
            value is Term.Clause.DrivingRange -> mergeDrivingRange(result, value)
            value is Term.Clause.DrivingDistance -> mergeDrivingDistance(result, value)
            value is Term.Clause.UnexpectedExpense -> mergeUnexpectedExpense(result, value)
            value is Term.Clause.Damage -> mergeDamage(classification, result, value)
            value is Term.Clause.Expense -> mergeExpense(classification, result, value)
            value is Term.Clause.Conduct -> mergeConduct(classification, result, value)
            value is Term.Clause.Rental -> mergeRental(result, value)
            value is Term.Clause.Sale -> mergeSale(result, value)
            value is Term.Clause.Profit -> mergeProfit(result, value)
            value is Term.Clause.PublicAuction -> mergePublicAuction(result, value)
            value is Term.Clause.Other -> result + EditableClause.Other(isOff = false, clause = value)
            value is Term.Clause.Termination -> mergeTermination(result, value)
            value is Term.Clause.Risk -> mergeRisk(classification, result, value)
            value is Term.Clause.HighValue -> mergeHighValue(result, value)
            value is Term.Clause.Unlocked && value.style == Reservation.Style.Long -> result + EditableClause.UnlockedLong(isOff = false, clause = value, value = EditableInt.fromTruth(value.numberOfWeeks))
            value is Term.Clause.Unlocked && value.style == Reservation.Style.Short -> result + EditableClause.UnlockedShort(isOff = false, clause = value, value = EditableInt.fromTruth(value.numberOfWeeks))
            value is Term.Clause.Duration && value.style == Reservation.Style.Long -> result + EditableClause.DurationLong(isOff = false, clause = value, value = EditableInt.fromTruth(value.numberOfDays))
            value is Term.Clause.Duration && value.style == Reservation.Style.Short -> result + EditableClause.DurationShort(isOff = false, clause = value, value = EditableInt.fromTruth(value.numberOfDays))
            value is Term.Clause.Concurrent && value.style == Reservation.Style.Long && !value.highValue -> result + EditableClause.ConcurrentLong(isOff = false, clause = value, value = EditableInt.fromTruth(value.numberOfTimes))
            value is Term.Clause.Concurrent && value.style == Reservation.Style.Short && !value.highValue -> result + EditableClause.ConcurrentShort(isOff = false, clause = value, value = EditableInt.fromTruth(value.numberOfTimes))
            value is Term.Clause.Concurrent && value.style == Reservation.Style.Long && value.highValue -> result + EditableClause.ConcurrentLongHighValue(isOff = false, clause = value, value = EditableInt.fromTruth(value.numberOfTimes))
            value is Term.Clause.Concurrent && value.style == Reservation.Style.Short && value.highValue -> result + EditableClause.ConcurrentShortHighValue(isOff = false, clause = value, value = EditableInt.fromTruth(value.numberOfTimes))
            else -> result
        }
    }.sortedBy { it.order }
}

fun List<EditableClause>.removeEditable(): List<Term.Clause> =
    map { it.autoOff() }.filter { !it.isOff }.flatMap { it.clauses }

val List<EditableClause>.classification: EditableClause.Classification?
    get() =
        filterIsInstance<EditableClause.Classification>().firstOrNull()

val List<EditableClause>.property: EditableClause.Property?
    get() =
        filterIsInstance<EditableClause.Property>().firstOrNull()

val List<EditableClause>.registration: EditableClause.Registration?
    get() =
        filterIsInstance<EditableClause.Registration>().firstOrNull()

val List<EditableClause>.insurance: EditableClause.Insurance?
    get() =
        filterIsInstance<EditableClause.Insurance>().firstOrNull()

val List<EditableClause>.ownership: EditableClause.Ownership?
    get() =
        filterIsInstance<EditableClause.Ownership>().firstOrNull()

val List<EditableClause>.financing: EditableClause.Financing?
    get() =
        filterIsInstance<EditableClause.Financing>().firstOrNull()

val List<EditableClause>.service: EditableClause.Service?
    get() =
        filterIsInstance<EditableClause.Service>().firstOrNull()

val List<EditableClause>.storage: EditableClause.Storage?
    get() =
        filterIsInstance<EditableClause.Storage>().firstOrNull()

val List<EditableClause>.drivingRange: EditableClause.DrivingRange?
    get() =
        filterIsInstance<EditableClause.DrivingRange>().firstOrNull()

val List<EditableClause>.drivingDistance: EditableClause.DrivingDistance?
    get() =
        filterIsInstance<EditableClause.DrivingDistance>().firstOrNull()

val List<EditableClause>.unexpectedExpense: EditableClause.UnexpectedExpense?
    get() =
        filterIsInstance<EditableClause.UnexpectedExpense>().firstOrNull()

val List<EditableClause>.damage: EditableClause.Damage?
    get() =
        filterIsInstance<EditableClause.Damage>().firstOrNull()

val List<EditableClause>.expense: EditableClause.Expense?
    get() =
        filterIsInstance<EditableClause.Expense>().firstOrNull()

val List<EditableClause>.conduct: EditableClause.Conduct?
    get() =
        filterIsInstance<EditableClause.Conduct>().firstOrNull()

val List<EditableClause>.rental: EditableClause.Rental?
    get() =
        filterIsInstance<EditableClause.Rental>().firstOrNull()

val List<EditableClause>.sale: EditableClause.Sale?
    get() =
        filterIsInstance<EditableClause.Sale>().firstOrNull()

val List<EditableClause>.profit: EditableClause.Profit?
    get() =
        filterIsInstance<EditableClause.Profit>().firstOrNull()

val List<EditableClause>.publicAuction: EditableClause.PublicAuction?
    get() =
        filterIsInstance<EditableClause.PublicAuction>().firstOrNull()

val List<EditableClause>.other: EditableClause.Other?
    get() =
        filterIsInstance<EditableClause.Other>().firstOrNull()

val List<EditableClause>.termination: EditableClause.Termination?
    get() =
        filterIsInstance<EditableClause.Termination>().firstOrNull()

val List<EditableClause>.risk: EditableClause.Risk?
    get() =
        filterIsInstance<EditableClause.Risk>().firstOrNull()

val List<EditableClause>.highValue: EditableClause.HighValue?
    get() =
        filterIsInstance<EditableClause.HighValue>().firstOrNull()

val List<EditableClause>.unlockedLong: EditableClause.UnlockedLong?
    get() =
        filterIsInstance<EditableClause.UnlockedLong>().firstOrNull()

val List<EditableClause>.unlockedShort: EditableClause.UnlockedShort?
    get() =
        filterIsInstance<EditableClause.UnlockedShort>().firstOrNull()

val List<EditableClause>.durationLong: EditableClause.DurationLong?
    get() =
        filterIsInstance<EditableClause.DurationLong>().firstOrNull()

val List<EditableClause>.durationShort: EditableClause.DurationShort?
    get() =
        filterIsInstance<EditableClause.DurationShort>().firstOrNull()

val List<EditableClause>.concurrentLong: EditableClause.ConcurrentLong?
    get() =
        filterIsInstance<EditableClause.ConcurrentLong>().firstOrNull()

val List<EditableClause>.concurrentShort: EditableClause.ConcurrentShort?
    get() =
        filterIsInstance<EditableClause.ConcurrentShort>().firstOrNull()

val List<EditableClause>.concurrentLongHighValue: EditableClause.ConcurrentLongHighValue?
    get() =
        filterIsInstance<EditableClause.ConcurrentLongHighValue>().firstOrNull()

val List<EditableClause>.concurrentShortHighValue: EditableClause.ConcurrentShortHighValue?
    get() =
        filterIsInstance<EditableClause.ConcurrentShortHighValue>().firstOrNull()

object EditableClauses {
    fun update(classification: Classification, header: DesignSystem.Header, value: RuleValue, previous: List<EditableClause>, status: DesignSystem.Status): List<EditableClause> {
        return when (header) {
            TermHeader.partnershipClassification -> updatePartnershipClassification(value, previous)
            TermHeader.partnershipProperty -> updatePartnershipProperty(value, previous)
            TermHeader.partnershipRegistration -> updatePartnershipRegistration(value, previous)
            TermHeader.partnershipInsurance -> updatePartnershipInsurance(classification, value, previous)
            TermHeader.partnershipOwnership -> updatePartnershipOwnership(value, previous)
            TermHeader.partnershipFinancing -> updatePartnershipFinancing(value, previous)
            TermHeader.partnershipService -> updatePartnershipService(value, previous)
            TermHeader.partnershipStorage -> updatePartnershipStorage(classification, value, previous)
            TermHeader.partnershipDrivingRange -> updatePartnershipDrivingRange(value, previous)
            TermHeader.partnershipDrivingDistance -> updatePartnershipDrivingDistance(value, previous)
            TermHeader.partnershipUnexpectedExpense -> updatePartnershipUnexpectedExpense(value, previous)
            TermHeader.partnershipDamage -> updatePartnershipDamage(classification, value, previous)
            TermHeader.partnershipExpense -> updatePartnershipExpense(classification, value, previous)
            TermHeader.partnershipConduct -> updatePartnershipConduct(classification, value, previous)
            TermHeader.partnershipRental -> updatePartnershipRental(value, previous)
            TermHeader.partnershipSale -> updatePartnershipSale(value, previous)
            TermHeader.partnershipProfit -> updatePartnershipProfit(value, previous)
            TermHeader.partnershipPublicAuction -> updatePartnershipPublicAuction(value, previous)
            TermHeader.partnershipOther -> updatePartnershipOther(value, previous)
            TermHeader.partnershipTermination -> updatePartnershipTermination(value, previous)
            TermHeader.partnershipRisk -> updatePartnershipRisk(classification, value, previous)

            TermHeader.bookingHighValue -> updateBookingHighValue(value, previous, status = status)
            TermHeader.bookingUnlockedLong -> updateBookingUnlockedLong(value, previous)
            TermHeader.bookingUnlockedShort -> updateBookingUnlockedShort(value, previous)
            TermHeader.bookingDurationLong -> updateBookingDurationLong(value, previous)
            TermHeader.bookingDurationShort -> updateBookingDurationShort(value, previous)
            TermHeader.bookingConcurrentLong -> updateBookingConcurrentLong(value, previous)
            TermHeader.bookingConcurrentShort -> updateBookingConcurrentShort(value, previous)
            TermHeader.bookingConcurrentLongHighValue -> updateBookingConcurrentLongHighValue(value, previous)
            TermHeader.bookingConcurrentShortHighValue -> updateBookingConcurrentShortHighValue(value, previous)
            else -> previous
        }
    }

    fun validate(classification: Classification, previous: List<EditableClause>): List<EditableClause> {
        return previous.map {
            when (it) {
                is EditableClause.Registration -> validatePartnershipRegistration(it)
                is EditableClause.Ownership -> validatePartnershipOwnership(it)
                else -> it
            }
        }
    }

    private fun updatePartnershipClassification(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val optionValue = value as? RuleValue.OptionValue
        val other = (optionValue?.value as? DesignSystem.Option.Other)?.other
        val classification = optionValue?.value?.data as? Classification
        val existing = previous.classification
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && other != null -> existing.copy(isOff = false, clause = existing.clause.copy(classification = Classification.Other(other)), other = other)
            existing != null && classification != null -> existing.copy(isOff = false, clause = existing.clause.copy(classification = classification))
            existing == null && offValue != null -> EditableClause.Classification(isOff = true, clause = Defaults.partnershipClassification, other = other)
            existing == null && other != null -> EditableClause.Classification(isOff = false, clause = Term.Clause.Classification(classification = Classification.Other(other)), other = other)
            existing == null && classification != null -> EditableClause.Classification(isOff = false, clause = Term.Clause.Classification(classification = classification), other = other)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipProperty(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val addressValue = value as? RuleValue.AddressValue
        val address = addressValue?.value
        val clause = address?.truth?.let { Term.Clause.Property(it) }
        val existing = previous.property
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, address = address)
            existing == null && offValue != null -> EditableClause.Property(isOff = true, clause = Defaults.partnershipProperty, address = EditableAddress.fromTruth(truth = Defaults.partnershipProperty.address))
            existing == null && clause != null -> EditableClause.Property(isOff = false, clause = clause, address = address)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipRegistration(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val stringValue = value as? RuleValue.StringValue
        val existing = previous.registration
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && stringValue != null -> existing.copy(isOff = false, clause = existing.clause.copy(registration = stringValue.value))
            existing == null && offValue != null -> EditableClause.Registration(isOff = true, clause = Defaults.partnershipRegistration)
            existing == null && stringValue != null -> EditableClause.Registration(isOff = false, clause = Term.Clause.Registration(registration = stringValue.value))
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun validatePartnershipRegistration(previous: EditableClause.Registration): EditableClause.Registration {
        return when {
            // previous.clause.registration.isEmpty() -> previous.copy(status = DesignSystem.Status.Invalid("Registreringsnummer måste anges"))
            else -> previous.copy(status = DesignSystem.Status.Valid)
        }
    }

    private fun updatePartnershipInsurance(classification: Classification, value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val paragraphValue = value as? RuleValue.ParagraphValue
        val paragraph = paragraphValue?.value
        val clause = paragraph?.truth?.let { Term.Clause.Insurance(it) }
        val existing = previous.insurance
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, paragraph = paragraph)
            existing == null && offValue != null -> EditableClause.Insurance(isOff = true, clause = Defaults.partnershipInsurance(classification), paragraph = EditableParagraph.fromTruth(truth = Defaults.partnershipInsurance(classification).paragraph))
            existing == null && clause != null -> EditableClause.Insurance(isOff = false, clause = clause, paragraph = paragraph)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipOwnership(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val ownershipValue = value as? RuleValue.OwnershipValue
        val ownership = ownershipValue?.value
        val clause = ownership?.truth?.map { Term.Clause.Ownership(it) }
        val existing = previous.ownership
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, ownership = ownership)
            existing == null && offValue != null -> EditableClause.Ownership(isOff = true, clause = emptyList(), ownership = EditableOwnership.fromTruth(truth = emptyList()))
            existing == null && clause != null -> EditableClause.Ownership(isOff = false, clause = clause, ownership = ownership)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun validatePartnershipOwnership(previous: EditableClause.Ownership): EditableClause.Ownership {
        return when {
            !previous.isOff && previous.ownership.style == EditableOwnership.Style.SHARES && previous.ownership.shares.isEmpty() -> previous.copy(status = DesignSystem.Status.Invalid("Minst en andel måste anges"))
            // !previous.isOff && previous.ownership.style == EditableOwnership.Style.PERCENTAGE && previous.ownership.percentages.isEmpty() -> previous.copy(status = DesignSystem.Status.Invalid("Minst en andel måste anges"))
            !previous.isOff && previous.ownership.style == EditableOwnership.Style.OTHER && previous.ownership.description.isEmpty() -> previous.copy(status = DesignSystem.Status.Invalid("Ange vilken regel som gäller eller använd ögat för att lämna detta oreglerat"))
            else -> previous.copy(status = DesignSystem.Status.Valid)
        }
    }

    private fun updatePartnershipFinancing(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val financingValue = value as? RuleValue.FinancingValue
        val financing = financingValue?.value
        val clause = financing?.truth?.let { Term.Clause.Financing(it) }
        val existing = previous.financing
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, financing = financing)
            existing == null && offValue != null -> EditableClause.Financing(isOff = true, clause = Defaults.partnershipFinancing, financing = EditableFinancing.fromTruth(truth = Defaults.partnershipFinancing.financing))
            existing == null && clause != null -> EditableClause.Financing(isOff = false, clause = clause, financing = financing)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipService(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val paragraphValue = value as? RuleValue.ParagraphValue
        val paragraph = paragraphValue?.value
        val clause = paragraph?.truth?.let { Term.Clause.Service(it) }
        val existing = previous.service
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, paragraph = paragraph)
            existing == null && offValue != null -> EditableClause.Service(isOff = true, clause = Defaults.partnershipService, paragraph = EditableParagraph.fromTruth(truth = Defaults.partnershipService.paragraph))
            existing == null && clause != null -> EditableClause.Service(isOff = false, clause = clause, paragraph = paragraph)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipStorage(classification: Classification, value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val paragraphValue = value as? RuleValue.ParagraphValue
        val paragraph = paragraphValue?.value
        val clause = paragraph?.truth?.let { Term.Clause.Storage(it) }
        val existing = previous.storage
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, paragraph = paragraph)
            existing == null && offValue != null -> EditableClause.Storage(isOff = true, clause = Defaults.partnershipStorage(classification), paragraph = EditableParagraph.fromTruth(truth = Defaults.partnershipStorage(classification).paragraph))
            existing == null && clause != null -> EditableClause.Storage(isOff = false, clause = clause, paragraph = paragraph)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipDrivingRange(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val optionValue = value as? RuleValue.OptionValue
        val other = (optionValue?.value as? DesignSystem.Option.Other)?.other?.let { Region.Other(description = it) }
        val region = optionValue?.value?.data as? Region
        val existing = previous.drivingRange
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && other != null -> existing.copy(isOff = false, clause = existing.clause.copy(region = other), other = other.description)
            existing != null && region != null -> existing.copy(isOff = false, clause = existing.clause.copy(region = region))
            existing == null && offValue != null -> EditableClause.DrivingRange(isOff = true, clause = Term.Clause.DrivingRange(region = Region.None), other = other?.description)
            existing == null && other != null -> EditableClause.DrivingRange(isOff = false, clause = Term.Clause.DrivingRange(region = other), other = other.description)
            existing == null && region != null -> EditableClause.DrivingRange(isOff = false, clause = Term.Clause.DrivingRange(region = region), other = other?.description)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipDrivingDistance(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val intValue = value as? RuleValue.IntValue
        val clause = intValue?.value?.truth?.let { Term.Clause.DrivingDistance(it) }
        val existing = previous.drivingDistance
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, value = intValue.value)
            existing == null && offValue != null -> EditableClause.DrivingDistance(isOff = true, clause = Defaults.partnershipDrivingDistance, value = EditableInt.fromTruth(Defaults.partnershipDrivingDistance.kilometers))
            existing == null && clause != null -> EditableClause.DrivingDistance(isOff = false, clause = clause, value = intValue.value)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipUnexpectedExpense(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val paragraphValue = value as? RuleValue.ParagraphValue
        val paragraph = paragraphValue?.value
        val clause = paragraph?.truth?.let { Term.Clause.UnexpectedExpense(it) }
        val existing = previous.unexpectedExpense
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, paragraph = paragraph)
            existing == null && offValue != null -> EditableClause.UnexpectedExpense(isOff = true, clause = Defaults.partnershipUnexpectedExpense, paragraph = EditableParagraph.fromTruth(truth = Defaults.partnershipUnexpectedExpense.paragraph))
            existing == null && clause != null -> EditableClause.UnexpectedExpense(isOff = false, clause = clause, paragraph = paragraph)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipDamage(classification: Classification, value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val paragraphValue = value as? RuleValue.ParagraphValue
        val paragraph = paragraphValue?.value
        val clause = paragraph?.truth?.let { Term.Clause.Damage(it) }
        val existing = previous.damage
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, paragraph = paragraph)
            existing == null && offValue != null -> EditableClause.Damage(isOff = true, clause = Defaults.partnershipDamage(classification), paragraph = EditableParagraph.fromTruth(truth = Defaults.partnershipDamage(classification).paragraph))
            existing == null && clause != null -> EditableClause.Damage(isOff = false, clause = clause, paragraph = paragraph)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipExpense(classification: Classification, value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val paragraphValue = value as? RuleValue.ParagraphValue
        val paragraph = paragraphValue?.value
        val clause = paragraph?.truth?.let { Term.Clause.Expense(it) }
        val existing = previous.expense
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, paragraph = paragraph)
            existing == null && offValue != null -> EditableClause.Expense(isOff = true, clause = Defaults.partnershipExpense(classification), paragraph = EditableParagraph.fromTruth(truth = Defaults.partnershipExpense(classification).paragraph))
            existing == null && clause != null -> EditableClause.Expense(isOff = false, clause = clause, paragraph = paragraph)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipConduct(classification: Classification, value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val paragraphValue = value as? RuleValue.ParagraphValue
        val paragraph = paragraphValue?.value
        val clause = paragraph?.truth?.let { Term.Clause.Conduct(it, emptyList()) }
        val existing = previous.conduct
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, paragraph = paragraph)
            existing == null && offValue != null -> EditableClause.Conduct(isOff = true, clause = Defaults.partnershipConduct(classification), paragraph = EditableParagraph.fromTruth(truth = Defaults.partnershipConduct(classification).paragraph))
            existing == null && clause != null -> EditableClause.Conduct(isOff = false, clause = clause, paragraph = paragraph)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipRental(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val optionValue = value as? RuleValue.OptionValue
        val other = (optionValue?.value as? DesignSystem.Option.Other)?.other
        val approval = optionValue?.value?.data as? Approval
        val existing = previous.rental
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && other != null -> existing.copy(isOff = false, clause = existing.clause.copy(approval = Approval.Other(other)), other = other)
            existing != null && approval != null -> existing.copy(isOff = false, clause = existing.clause.copy(approval = approval))
            existing == null && offValue != null -> EditableClause.Rental(isOff = true, clause = Term.Clause.Rental(approval = Approval.Approved), other = other)
            existing == null && other != null -> EditableClause.Rental(isOff = false, clause = Term.Clause.Rental(approval = Approval.Other(other)), other = other)
            existing == null && approval != null -> EditableClause.Rental(isOff = false, clause = Term.Clause.Rental(approval = approval), other = other)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipSale(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val conditionsOfSaleValue = value as? RuleValue.ConditionsOfSaleValue
        val conditionsOfSale = conditionsOfSaleValue?.value
        val clause = conditionsOfSale?.truth?.let { Term.Clause.Sale(it) }
        val existing = previous.sale
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, conditionsOfSale = conditionsOfSale)
            existing == null && offValue != null -> EditableClause.Sale(isOff = true, clause = Defaults.partnershipSale, conditionsOfSale = EditableConditionsOfSale.fromTruth(truth = Defaults.partnershipSale.conditionsOfSale))
            existing == null && clause != null -> EditableClause.Sale(isOff = false, clause = clause, conditionsOfSale = conditionsOfSale)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipProfit(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val paragraphValue = value as? RuleValue.ParagraphValue
        val paragraph = paragraphValue?.value
        val clause = paragraph?.truth?.let { Term.Clause.Profit(it) }
        val existing = previous.profit
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, paragraph = paragraph)
            existing == null && offValue != null -> EditableClause.Profit(isOff = true, clause = Defaults.partnershipProfit, paragraph = EditableParagraph.fromTruth(truth = Defaults.partnershipProfit.paragraph))
            existing == null && clause != null -> EditableClause.Profit(isOff = false, clause = clause, paragraph = paragraph)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipPublicAuction(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val adherenceValue = value as? RuleValue.AdherenceValue
        val adherence = adherenceValue?.value
        val clause = adherence?.truth?.let { Term.Clause.PublicAuction(it) }
        val existing = previous.publicAuction
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, adherence = adherence)
            existing == null && offValue != null -> EditableClause.PublicAuction(isOff = true, clause = Defaults.partnershipPublicAuction, adherence = EditableAdherence.fromTruth(truth = Defaults.partnershipPublicAuction.adherence))
            existing == null && clause != null -> EditableClause.PublicAuction(isOff = false, clause = clause, adherence = adherence)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipOther(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val stringValue = value as? RuleValue.StringValue
        val existing = previous.other
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && stringValue != null -> existing.copy(isOff = false, clause = existing.clause.copy(description = stringValue.value))
            existing == null && offValue != null -> EditableClause.Other(isOff = true, clause = Term.Clause.Other(description = ""))
            existing == null && stringValue != null -> EditableClause.Other(isOff = false, clause = Term.Clause.Other(description = stringValue.value))
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipTermination(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val paragraphValue = value as? RuleValue.ParagraphValue
        val paragraph = paragraphValue?.value
        val clause = paragraph?.truth?.let { Term.Clause.Termination(it) }
        val existing = previous.termination
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, paragraph = paragraph)
            existing == null && offValue != null -> EditableClause.Termination(isOff = true, clause = Defaults.partnershipTermination, paragraph = EditableParagraph.fromTruth(truth = Defaults.partnershipTermination.paragraph))
            existing == null && clause != null -> EditableClause.Termination(isOff = false, clause = clause, paragraph = paragraph)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updatePartnershipRisk(classification: Classification, value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val paragraphValue = value as? RuleValue.ParagraphValue
        val paragraph = paragraphValue?.value
        val clause = paragraph?.truth?.let { Term.Clause.Risk(it, emptyList()) }
        val existing = previous.risk
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, paragraph = paragraph)
            existing == null && offValue != null -> EditableClause.Risk(isOff = true, clause = Defaults.partnershipRisk(classification), paragraph = EditableParagraph.fromTruth(truth = Defaults.partnershipRisk(classification).paragraph))
            existing == null && clause != null -> EditableClause.Risk(isOff = false, clause = clause, paragraph = paragraph)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updateBookingHighValue(value: RuleValue, previous: List<EditableClause>, status: DesignSystem.Status): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val doubleOptionsValue = value as? RuleValue.DoubleOptionsValue
        val highValues = doubleOptionsValue?.value?.mapNotNull {
            val first = it.first.data as? Int ?: return@mapNotNull null
            val second = it.second.data as? Int ?: return@mapNotNull null
            Term.Clause.HighValue(fromWeekOfYear = first, toWeekOfYear = second)
        }
        val existing = previous.highValue
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true, status = status)
            existing != null && highValues != null -> existing.copy(isOff = false, clause = highValues, status = status)
            existing == null && offValue != null -> EditableClause.HighValue(isOff = true, clause = emptyList(), status = status)
            existing == null && highValues != null -> EditableClause.HighValue(isOff = false, clause = highValues, status = status)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updateBookingUnlockedLong(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val intValue = value as? RuleValue.IntValue
        val clause = intValue?.value?.truth?.let { Term.Clause.Unlocked(numberOfWeeks = it, style = Reservation.Style.Long) }
        val existing = previous.unlockedLong
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, value = intValue.value)
            existing == null && offValue != null -> EditableClause.UnlockedLong(isOff = true, clause = Defaults.bookingUnlockedLong, value = EditableInt.fromTruth(Defaults.bookingUnlockedLong.numberOfWeeks))
            existing == null && clause != null -> EditableClause.UnlockedLong(isOff = false, clause = clause, value = intValue.value)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updateBookingUnlockedShort(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val intValue = value as? RuleValue.IntValue
        val clause = intValue?.value?.truth?.let { Term.Clause.Unlocked(numberOfWeeks = it, style = Reservation.Style.Short) }
        val existing = previous.unlockedShort
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, value = intValue.value)
            existing == null && offValue != null -> EditableClause.UnlockedShort(isOff = true, clause = Defaults.bookingUnlockedShort, value = EditableInt.fromTruth(Defaults.bookingUnlockedShort.numberOfWeeks))
            existing == null && clause != null -> EditableClause.UnlockedShort(isOff = false, clause = clause, value = intValue.value)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updateBookingDurationLong(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val intValue = value as? RuleValue.IntValue
        val clause = intValue?.value?.truth?.let { Term.Clause.Duration(numberOfDays = it, style = Reservation.Style.Long) }
        val existing = previous.durationLong
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, value = intValue.value)
            existing == null && offValue != null -> EditableClause.DurationLong(isOff = true, clause = Defaults.bookingDurationLong, value = EditableInt.fromTruth(Defaults.bookingDurationLong.numberOfDays))
            existing == null && clause != null -> EditableClause.DurationLong(isOff = false, clause = clause, value = intValue.value)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updateBookingDurationShort(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val intValue = value as? RuleValue.IntValue
        val clause = intValue?.value?.truth?.let { Term.Clause.Duration(numberOfDays = it, style = Reservation.Style.Short) }
        val existing = previous.durationShort
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, value = intValue.value)
            existing == null && offValue != null -> EditableClause.DurationShort(isOff = true, clause = Defaults.bookingDurationShort, value = EditableInt.fromTruth(Defaults.bookingDurationShort.numberOfDays))
            existing == null && clause != null -> EditableClause.DurationShort(isOff = false, clause = clause, value = intValue.value)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updateBookingConcurrentLong(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val intValue = value as? RuleValue.IntValue
        val clause = intValue?.value?.truth?.let { Term.Clause.Concurrent(numberOfTimes = it, style = Reservation.Style.Long, highValue = false) }
        val existing = previous.concurrentLong
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, value = intValue.value)
            existing == null && offValue != null -> EditableClause.ConcurrentLong(isOff = true, clause = Defaults.bookingConcurrentLong, value = EditableInt.fromTruth(Defaults.bookingConcurrentLong.numberOfTimes))
            existing == null && clause != null -> EditableClause.ConcurrentLong(isOff = false, clause = clause, value = intValue.value)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updateBookingConcurrentShort(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val intValue = value as? RuleValue.IntValue
        val clause = intValue?.value?.truth?.let { Term.Clause.Concurrent(numberOfTimes = it, style = Reservation.Style.Short, highValue = false) }
        val existing = previous.concurrentShort
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, value = intValue.value)
            existing == null && offValue != null -> EditableClause.ConcurrentShort(isOff = true, clause = Defaults.bookingConcurrentShort, value = EditableInt.fromTruth(Defaults.bookingConcurrentShort.numberOfTimes))
            existing == null && clause != null -> EditableClause.ConcurrentShort(isOff = false, clause = clause, value = intValue.value)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updateBookingConcurrentLongHighValue(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val intValue = value as? RuleValue.IntValue
        val clause = intValue?.value?.truth?.let { Term.Clause.Concurrent(numberOfTimes = it, style = Reservation.Style.Long, highValue = true) }
        val existing = previous.concurrentLongHighValue
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, value = intValue.value)
            existing == null && offValue != null -> EditableClause.ConcurrentLongHighValue(isOff = true, clause = Defaults.bookingConcurrentLongHighValue, value = EditableInt.fromTruth(Defaults.bookingConcurrentLongHighValue.numberOfTimes))
            existing == null && clause != null -> EditableClause.ConcurrentLongHighValue(isOff = false, clause = clause, value = intValue.value)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }

    private fun updateBookingConcurrentShortHighValue(value: RuleValue, previous: List<EditableClause>): List<EditableClause> {
        val offValue = value as? RuleValue.Off
        val intValue = value as? RuleValue.IntValue
        val clause = intValue?.value?.truth?.let { Term.Clause.Concurrent(numberOfTimes = it, style = Reservation.Style.Short, highValue = true) }
        val existing = previous.concurrentShortHighValue
        val updated = when {
            existing != null && offValue != null -> existing.copy(isOff = true)
            existing != null && clause != null -> existing.copy(isOff = false, clause = clause, value = intValue.value)
            existing == null && offValue != null -> EditableClause.ConcurrentShortHighValue(isOff = true, clause = Defaults.bookingConcurrentShortHighValue, value = EditableInt.fromTruth(Defaults.bookingConcurrentShortHighValue.numberOfTimes))
            existing == null && clause != null -> EditableClause.ConcurrentShortHighValue(isOff = false, clause = clause, value = intValue.value)
            else -> null
        }
        return if (updated != null) previous.filter { it != existing } + updated else previous
    }
}

private fun clause(classification: Classification, editableClause: EditableClause): String =
    when (editableClause) {
        is EditableClause.Classification ->
            if (editableClause.clause.classification is Classification.None)
                "Objektet använder inte Della8's avtal för att reglera samägande"
            else
                "### ${Titles.partnershipClassification}\n${editableClause.clause.classification.displayName}"

        is EditableClause.Property ->
            "### ${Titles.partnershipProperty(classification)}\n${editableClause.clause.address.displayName}"

        is EditableClause.Registration ->
            "### ${Titles.partnershipRegistration(classification)}\n${editableClause.clause.registration}"

        is EditableClause.Insurance ->
            "### ${Titles.partnershipInsurance}\n${editableClause.clause.paragraph.displayName}"

        is EditableClause.Ownership ->
            "### ${Titles.partnershipOwnership}\n${editableClause.clause.displayName}"

        is EditableClause.Financing ->
            "### ${Titles.partnershipFinancing}\n${editableClause.clause.displayName}"

        is EditableClause.Service ->
            "### ${Titles.partnershipService}\n${editableClause.clause.paragraph.displayName}"

        is EditableClause.Storage ->
            "### ${Titles.partnershipStorage(classification)}\n${editableClause.clause.paragraph.displayName}"

        is EditableClause.DrivingRange ->
            "### ${Titles.partnershipDrivingRange}\n${editableClause.clause.region.displayName}"

        is EditableClause.DrivingDistance ->
            "### ${Titles.partnershipDrivingDistance}\n${editableClause.clause.kilometers}"

        is EditableClause.UnexpectedExpense ->
            "### ${Titles.partnershipUnexpectedExpense}\n${editableClause.clause.paragraph.displayName}"

        is EditableClause.Damage ->
            "### ${Titles.partnershipDamage(classification)}\n${editableClause.clause.paragraph.displayName}"

        is EditableClause.Expense ->
            "### ${Titles.partnershipExpense(classification)}\n${editableClause.clause.paragraph.displayName}"

        is EditableClause.Conduct ->
            "### ${Titles.partnershipConduct}\n${editableClause.clause.paragraph.displayName}"

        is EditableClause.Rental ->
            "### ${Titles.partnershipRental}\n${editableClause.clause.approval.displayName}"

        is EditableClause.Sale ->
            "### ${Titles.partnershipSale}\n${editableClause.clause.conditionsOfSale.displayName}"

        is EditableClause.Profit ->
            "### ${Titles.partnershipProfit}\n${editableClause.clause.paragraph.displayName}"

        is EditableClause.PublicAuction ->
            "### ${Titles.partnershipPublicAuction(classification)}\n${editableClause.clause.adherence.displayName}"

        is EditableClause.Other ->
            "### ${Titles.partnershipOther(classification)}\n${editableClause.clause.description}"

        is EditableClause.Termination ->
            "### ${Titles.partnershipTermination}\n${editableClause.clause.paragraph.displayName}"

        is EditableClause.Risk ->
            "### ${Titles.partnershipRisk(classification)}\n${editableClause.clause.paragraph.displayName}"

        is EditableClause.HighValue ->
            "### ${Titles.bookingHighValue}\n${editableClause.clause.joinToString(", ") { "Vecka ${it.fromWeekOfYear} - ${it.toWeekOfYear}" }}"

        is EditableClause.UnlockedLong ->
            "### ${Titles.bookingUnlockedLong}\n${editableClause.clause.numberOfWeeks}"

        is EditableClause.UnlockedShort ->
            "### ${Titles.bookingUnlockedShort}\n${editableClause.clause.numberOfWeeks}"

        is EditableClause.DurationLong ->
            "### ${Titles.bookingDurationLong}\n${editableClause.clause.numberOfDays}"

        is EditableClause.DurationShort ->
            "### ${Titles.bookingDurationShort}\n${editableClause.clause.numberOfDays}"

        is EditableClause.ConcurrentLong ->
            "### ${Titles.bookingConcurrentLong}\n${editableClause.clause.numberOfTimes}"

        is EditableClause.ConcurrentShort ->
            "### ${Titles.bookingConcurrentShort}\n${editableClause.clause.numberOfTimes}"

        is EditableClause.ConcurrentLongHighValue ->
            "### ${Titles.bookingConcurrentLongHighValue}\n${editableClause.clause.numberOfTimes}"

        is EditableClause.ConcurrentShortHighValue ->
            "### ${Titles.bookingConcurrentShortHighValue}\n${editableClause.clause.numberOfTimes}"
    }

fun List<Term>.buildContent(): String {
    val terms = this
    val classification = terms.flatMap { it.clauses }.filterIsInstance<Term.Clause.Classification>().map { it.classification }.firstOrNull() ?: Classification.None
    return buildString {
        terms.map { term ->
            append("## ${term.name}\n")
            term.clauses.makeEditable().forEach {
                if (!it.isOff) {
                    val clause = clause(classification, it)
                    append("$clause\n")
                }
            }
        }
    }
}


suspend fun Store.editAgreementContent(obj: Object): ActionOutcome<Unit> =
    successfulOf(true).noActions()
        .flatMap { (actions, _) ->
            reduce(actions).listTerms(obj, obj.currentAgreement.id)
                .accumulate(actions)
        }
        .flatMap { (actions, terms) ->
            val edit = Agreement.Edit(
                content = modifiedOf(terms.buildContent())
            )
            reduce(actions).editAgreement(obj, obj.currentAgreement.id, edit)
                .accumulate(actions)
        }
        .map { (actions, _) ->
            val action = Store.Action.ReplaceObject(obj.id, obj.minimal)
            tupleOf(actions + action, Unit)
        }
