package della8.core.screens


import della8.core.services.editNarrative
import della8.core.services.myNarrative
import della8.core.support.*
import techla.base.*
import techla.personal.Narrative

object ProfileScreen {
    object Header {
        val firstName = DesignSystem.Header(id = "firstName")
        val lastName = DesignSystem.Header(id = "lastName")
        val email = DesignSystem.Header(id = "email")
        val phone = DesignSystem.Header(id = "phone")
    }

    data class Texts(
        val back: String,
        val title: String,
        val body: String,
        val firstName: String,
        val lastName: String,
        val email: String,
        val phone: String,
        val save: String,
        val emailFormat: String,
        val emailRequired: String,
        val firstNameRequired: String,
        val lastNameRequired: String,
        val phoneIllegalCharacters: String,
        val saved: String,
        val info: String,
        val done: String,
        val cancelAccount: String,
        override val progressInfo: String,
        override val failureTitle: String,
    ) : ProgressTexts, FailureTexts {
        companion object
    }

    data class State(
        val firstName: String?,
        val lastName: String?,
        val email: String?,
        val phone: String?,
        val narrative: Narrative?,
    )

    sealed class ViewModel(open val texts: Texts, open val state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(null, null, null, null, null),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Saving(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Loading(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Saved(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
            val icon: DesignSystem.Icon,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val done: DesignSystem.Button,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Ready(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val body: DesignSystem.Text,
            val firstName: DesignSystem.TextInput,
            val lastName: DesignSystem.TextInput,
            val email: DesignSystem.TextInput,
            val phone: DesignSystem.TextInput,
            val save: DesignSystem.Button,
            val status: DesignSystem.Status,
            val cancelAccount: DesignSystem.Text,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Failed(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        fun loading(texts: Texts) =
            Loading(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(texts = texts),
            )

        fun saving(state: State) =
            Saving(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(texts = texts),
            )

        fun saved() =
            Saved(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(texts = texts),
                icon = DesignSystem.Icon(image = DesignSystem.Image.CHECKED, background = DesignSystem.Color.OCEAN),
                title = DesignSystem.Text(
                    text = texts.saved,
                    style = DesignSystem.TextStyle.MEGA,
                    background = DesignSystem.Background.LIGHT
                ),
                info = DesignSystem.Text(
                    text = texts.info,
                    style = DesignSystem.TextStyle.BODY,
                    background = DesignSystem.Background.LIGHT
                ),
                done = DesignSystem.Button(title = texts.done, style = DesignSystem.ButtonStyle.PRIMARY),
            )

        fun ready(
            state: State,
            status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()
        ) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing),
                title = DesignSystem.Text(
                    text = texts.title,
                    style = DesignSystem.TextStyle.TITLE1,
                    background = DesignSystem.Background.LIGHT
                ),
                body = DesignSystem.Text(text = texts.body,),
                firstName = DesignSystem.TextInput(
                    Header.firstName,
                    value = state.firstName,
                    title = texts.firstName,
                    status = status.statusOf(Header.firstName)
                ),
                lastName = DesignSystem.TextInput(
                    Header.lastName,
                    value = state.lastName,
                    title = texts.lastName,
                    status = status.statusOf(Header.lastName)
                ),
                email = DesignSystem.TextInput(
                    Header.email,
                    value = state.email,
                    title = texts.email,
                    status = status.statusOf(Header.email)
                ),
                phone = DesignSystem.TextInput(
                    Header.phone,
                    value = state.phone,
                    title = texts.phone,
                    status = status.statusOf(Header.phone)
                ),
                save = DesignSystem.Button(title = texts.save),
                status = status.overallStatus(),
                cancelAccount = DesignSystem.Text(text= texts.cancelAccount, isMarkdown = true, alignment = DesignSystem.TextAlignment.CENTER)
        )

        fun failed(failure: Either<List<Warning>, Throwable>) =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoad get() = this as? Loading
        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                val texts = Texts(
                    back = "Mina objekt",
                    title = "Profil",
                    body = "Här kan du se och ändra din info",
                    progressInfo = "Laddar...",
                    failureTitle = "Oj, ett fel har uppstått",
                    email = "E-post",
                    phone = "Telefonnummer",
                    firstName = "Förnamn",
                    lastName = "Efternamn",
                    emailFormat = "Ange en giltig e-post",
                    emailRequired = "E-post är obligatoriskt",
                    firstNameRequired = "Förnamn är obligatoriskt",
                    lastNameRequired = "Efternamn är obligatoriskt",
                    save = "Spara",
                    saved = "Tada!",
                    info = "Din profil är nu sparad",
                    done = "Klar",
                    phoneIllegalCharacters = "Telefonnummer kan bara innehålla siffror, + och -",
                    cancelAccount = """Vill du avsluta ditt konto? Kontakta oss på [hej@della8.se](mailto:hej@della8.se)""".trimIndent()
                )
                sceneOf<ViewModel>(viewModel.loading(texts = texts))
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun load(scene: Scene.Input<ViewModel>, ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(true)
            .flatMap {
                store.myNarrative()
                    .map { tupleOf(it.first,  it.second) }
            }
            .map { (actions,  narrative) ->
                val state = viewModel.state.copy(
                    narrative = narrative,
                    firstName = narrative?.firstName,
                    lastName = narrative?.lastName,
                    email = narrative?.email,
                    phone = narrative?.phone,
                )
                sceneOf<ViewModel>(viewModel.ready(state = state), actions)
            }
            .failed { scene.failed(result = it) }
    }

    fun validate(scene: Scene.Input<ViewModel>, firstName: String?, lastName: String?, email: String?, phone: String?): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val status: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()
        var state = viewModel.state

        Validator.text(
            text = firstName,
            isEmpty = {
                status.add(
                    Header.firstName to DesignSystem.Status.Invalid(
                        warning = viewModel.texts.firstNameRequired
                    )
                )
            },
            formatted = { state = state.copy(firstName = it) },
            passed = { status.add(Header.firstName to DesignSystem.Status.Valid) }
        )

        Validator.text(
            text = lastName,
            isEmpty = {
                status.add(
                    Header.lastName to DesignSystem.Status.Invalid(
                        warning = viewModel.texts.lastNameRequired
                    )
                )
            },
            formatted = { state = state.copy(lastName = it) },
            passed = { status.add(Header.lastName to DesignSystem.Status.Valid) }
        )

        Validator.email(
            email = email,
            isWrongFormat = {
                status.add(
                    Header.email to DesignSystem.Status.Invalid(
                        warning = viewModel.texts.emailFormat
                    )
                )
            },
            isEmpty = {
                status.add(
                    Header.email to DesignSystem.Status.Invalid(
                        warning = viewModel.texts.emailRequired
                    )
                )
            },
            formatted = { state = state.copy(email = it) },
            passed = { status.add(Header.email to DesignSystem.Status.Valid) }
        )

        Validator.phone(
            phone = phone,
            illegalCharacters = { status.add(Header.phone to DesignSystem.Status.Invalid(warning = viewModel.texts.phoneIllegalCharacters)) },
            isEmpty = {},
            formatted = { state = state.copy(phone = it) },
            passed = { status.add(Header.phone to DesignSystem.Status.Valid) }
        )

        if (status.overallStatus() !is DesignSystem.Status.Valid)
            return sceneOf<ViewModel>(viewModel.ready(state = state, status = status))

        return sceneOf<ViewModel>(viewModel.saving(state = state))
    }

    suspend fun save(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state

        val edit = Narrative.Edit(
            firstName = modifiedOf(state.firstName),
            lastName = modifiedOf(state.lastName),
            email = modifiedOf(state.email),
            phone = modifiedOf(state.phone),
        )

        return successfulOf(true)
            .flatMap { store.editNarrative(state.narrative?.id ?: Identifier(), edit) }
            .map {
                sceneOf<ViewModel>(viewModel.saved())
            }
            .failed {
                sceneOf<ViewModel>(viewModel.failed("Unknown reason"))
            }
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }
}