package bootstrap


import csstype.ClassName
import dom.html.HTMLSelectElement
import org.w3c.dom.events.Event
import react.*

@JsNonModule
@JsModule("react-bootstrap/Form")
private external val formModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bFormSelect: ComponentType<FormSelectProps> = formModule.default.Select

external interface FormSelectProps : PropsWithChildren {
    var className: ClassName
    var disabled: Boolean
    var htmlSize: Number
    var isInvalid: Boolean
    var isValid: Boolean
    var onChange: (Event) -> Unit
    var size: String /* 'sm' | 'lg' */
    var value: dynamic /* string | arrayOf | number */
    var defaultValue: dynamic /* string | arrayOf | number */
    var bsPrefix: String
    var ref: RefObject<HTMLSelectElement>
}
