package della8.web.items

import bootstrap.bCard
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.items.RuleItem
import della8.core.screens.RuleValue
import della8.core.support.DesignSystem
import della8.web.components.*
import della8.web.support.className
import react.*
import techla.base.Identifier

external interface RuleItemImageInputProps : PropsWithChildren {
    var className: String?
    var viewModel: RuleItem.ViewModel.ImageInput
    var onChange: (Identifier<RuleItem>, RuleValue) -> Unit
    var onInfo: (String, Boolean) -> Unit
}

val D8RuleItemImageInput = FC<RuleItemImageInputProps> { props ->
    fun onChange(header: DesignSystem.Header?, value: DesignSystem.Option) {
        props.onChange(props.viewModel.id, RuleValue.OptionValue(value))
    }

    fun onChange(value: String) {
        props.viewModel.edit.options.filterIsInstance<DesignSystem.Option.Other>().firstOrNull()?.let {
            props.onChange(props.viewModel.id, RuleValue.OptionValue(it.copy(other = value)))
        }
    }

    bCard {
        className = className("radius-10 border-0 card-shadow my-3 px-4 py-3")
        D8RuleItemHeader {
            viewModel = props.viewModel
            onChange = props.onChange
            onInfo = props.onInfo
        }
        if (props.viewModel.value !is RuleValue.Off) {
            bContainer {
                className = className("justify-content-center p-0")
                d8ImageInput(props.viewModel.edit, className = "row-cols-lg-3",  onClick = ::onChange)
                bRow {
                    if (props.viewModel.editable) {
                        d8TextInput(design = props.viewModel.other, onChange = ::onChange)
                    } else {
                        D8Element { design = props.viewModel.show }
                    }
                }
            }
        }
        D8RuleItemFooter {
            viewModel = props.viewModel
        }
    }
}
