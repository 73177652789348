const data = {
  "v": "5.9.1",
  "fr": 29.9700012207031,
  "ip": 0,
  "op": 30.0000012219251,
  "w": 752,
  "h": 752,
  "nm": "heart",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "heart",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            351.088,
            379.138,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            247.749,
            211.642,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 0,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 1,
              "s": [
                6.478,
                6.478,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 2,
              "s": [
                13.829,
                13.829,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 3,
              "s": [
                21.495,
                21.495,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 4,
              "s": [
                29.02,
                29.02,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 5,
              "s": [
                36.049,
                36.049,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 6,
              "s": [
                42.327,
                42.327,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 7,
              "s": [
                47.69,
                47.69,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 8,
              "s": [
                52.055,
                52.055,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 9,
              "s": [
                55.407,
                55.407,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 10,
              "s": [
                57.785,
                57.785,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 11,
              "s": [
                59.271,
                59.271,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 12,
              "s": [
                59.975,
                59.975,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 13,
              "s": [
                60.024,
                60.024,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 14,
              "s": [
                59.551,
                59.551,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 15,
              "s": [
                58.689,
                58.689,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 16,
              "s": [
                57.563,
                57.563,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 17,
              "s": [
                56.286,
                56.286,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 18,
              "s": [
                54.953,
                54.953,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 19,
              "s": [
                53.646,
                53.646,
                100
              ]
            },
            {
              "t": 20.0000008146167,
              "s": [
                52.424,
                52.424,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      49.325,
                      49.324
                    ],
                    [
                      49.324,
                      -49.325
                    ],
                    [
                      10.799,
                      10.8
                    ],
                    [
                      49.325,
                      -49.325
                    ],
                    [
                      -49.324,
                      -49.324
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -4.114,
                      4.115
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      -49.324,
                      -49.325
                    ],
                    [
                      -10.8,
                      10.8
                    ],
                    [
                      -49.325,
                      -49.325
                    ],
                    [
                      -49.324,
                      49.324
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      4.114,
                      4.115
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      49.325,
                      -49.324
                    ]
                  ],
                  "v": [
                    [
                      198.174,
                      -162.067
                    ],
                    [
                      19.555,
                      -162.067
                    ],
                    [
                      -19.555,
                      -162.067
                    ],
                    [
                      -198.175,
                      -162.067
                    ],
                    [
                      -198.175,
                      16.552
                    ],
                    [
                      -178.619,
                      36.107
                    ],
                    [
                      -7.45,
                      207.276
                    ],
                    [
                      7.449,
                      207.276
                    ],
                    [
                      178.619,
                      36.107
                    ],
                    [
                      198.174,
                      16.552
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  247.749,
                  211.642
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 1,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gf",
          "o": {
            "a": 0,
            "k": 100,
            "ix": 10
          },
          "r": 1,
          "bm": 0,
          "g": {
            "p": 3,
            "k": {
              "a": 0,
              "k": [
                0,
                0.671,
                0.464,
                1,
                0.5,
                0.501,
                0.27,
                0.955,
                1,
                0.331,
                0.076,
                0.909
              ],
              "ix": 9
            }
          },
          "s": {
            "a": 0,
            "k": [
              0,
              0
            ],
            "ix": 5
          },
          "e": {
            "a": 0,
            "k": [
              302,
              0
            ],
            "ix": 6
          },
          "t": 1,
          "nm": "Gradient Fill 1",
          "mn": "ADBE Vector Graphic - G-Fill",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 30.0000012219251,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 4,
      "nm": "line",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": -44,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 9,
              "s": [
                351,
                242.938,
                0
              ],
              "to": [
                0,
                -30.667,
                0
              ],
              "ti": [
                0,
                30.667,
                0
              ]
            },
            {
              "t": 19.0000007738859,
              "s": [
                351,
                58.938,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            69.466,
            39.451,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            36.682,
            36.682,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      111.841,
                      -3.174
                    ],
                    [
                      27.091,
                      82.076
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.319884598255,
                  0.095673963428,
                  0.849509775639,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 32,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "tm",
          "s": {
            "a": 0,
            "k": 0,
            "ix": 1
          },
          "e": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 9,
                "s": [
                  100
                ]
              },
              {
                "t": 19.0000007738859,
                "s": [
                  0
                ]
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 2",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false
        }
      ],
      "ip": 9.00000036657752,
      "op": 19.0000007738859,
      "st": -1.00000004073083,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "line2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 91,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 9,
              "s": [
                221,
                251.438,
                0
              ],
              "to": [
                -19,
                -17.667,
                0
              ],
              "ti": [
                19,
                17.667,
                0
              ]
            },
            {
              "t": 19.0000007738859,
              "s": [
                107,
                145.438,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            69.466,
            39.451,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            37.229,
            38.046,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      111.841,
                      -3.174
                    ],
                    [
                      27.091,
                      82.076
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.321568638086,
                  0.0941176489,
                  0.850980401039,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 32,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "tm",
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 9,
                "s": [
                  0
                ]
              },
              {
                "t": 19.0000007738859,
                "s": [
                  100
                ]
              }
            ],
            "ix": 1
          },
          "e": {
            "a": 0,
            "k": 100,
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false
        }
      ],
      "ip": 9.00000036657752,
      "op": 19.0000007738859,
      "st": -1.00000004073083,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "line3",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 47,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 9,
              "s": [
                187,
                381.938,
                0
              ],
              "to": [
                -24,
                0,
                0
              ],
              "ti": [
                24,
                0,
                0
              ]
            },
            {
              "t": 19.0000007738859,
              "s": [
                43,
                381.938,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            69.466,
            39.451,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            36.682,
            36.682,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      111.841,
                      -3.174
                    ],
                    [
                      27.091,
                      82.076
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.321568638086,
                  0.0941176489,
                  0.850980401039,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 32,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "tm",
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 9,
                "s": [
                  0
                ]
              },
              {
                "t": 19.0000007738859,
                "s": [
                  100
                ]
              }
            ],
            "ix": 1
          },
          "e": {
            "a": 0,
            "k": 100,
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false
        }
      ],
      "ip": 9.00000036657752,
      "op": 19.0000007738859,
      "st": -1.00000004073083,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 4,
      "nm": "line4",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 45,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 9,
              "s": [
                514.5,
                381.938,
                0
              ],
              "to": [
                33,
                0,
                0
              ],
              "ti": [
                -33,
                0,
                0
              ]
            },
            {
              "t": 19.0000007738859,
              "s": [
                712.5,
                381.938,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            69.466,
            39.451,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            36.682,
            36.682,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      111.841,
                      -3.174
                    ],
                    [
                      27.091,
                      82.076
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.321568638086,
                  0.0941176489,
                  0.850980401039,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 32,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "tm",
          "s": {
            "a": 0,
            "k": 0,
            "ix": 1
          },
          "e": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 9,
                "s": [
                  100
                ]
              },
              {
                "t": 19.0000007738859,
                "s": [
                  0
                ]
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false
        }
      ],
      "ip": 9.00000036657752,
      "op": 19.0000007738859,
      "st": -1.00000004073083,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 4,
      "nm": "line5",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 134,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 9,
              "s": [
                351,
                538.438,
                0
              ],
              "to": [
                0,
                32.333,
                0
              ],
              "ti": [
                0,
                -32.333,
                0
              ]
            },
            {
              "t": 19.0000007738859,
              "s": [
                351,
                732.438,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            69.466,
            39.451,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            36.682,
            36.682,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      111.841,
                      -3.174
                    ],
                    [
                      27.091,
                      82.076
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.321568638086,
                  0.0941176489,
                  0.850980401039,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 32,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "tm",
          "s": {
            "a": 0,
            "k": 0,
            "ix": 1
          },
          "e": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 9,
                "s": [
                  100
                ]
              },
              {
                "t": 19.0000007738859,
                "s": [
                  0
                ]
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false
        }
      ],
      "ip": 9.00000036657752,
      "op": 19.0000007738859,
      "st": -1.00000004073083,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 4,
      "nm": "line6",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 182,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 9,
              "s": [
                260.5,
                471.438,
                0
              ],
              "to": [
                -25,
                28.333,
                0
              ],
              "ti": [
                25,
                -28.333,
                0
              ]
            },
            {
              "t": 19.0000007738859,
              "s": [
                110.5,
                641.438,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            69.466,
            39.451,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            36.682,
            36.682,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      111.841,
                      -3.174
                    ],
                    [
                      27.091,
                      82.076
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.321568638086,
                  0.0941176489,
                  0.850980401039,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 32,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "tm",
          "s": {
            "a": 0,
            "k": 0,
            "ix": 1
          },
          "e": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 9,
                "s": [
                  100
                ]
              },
              {
                "t": 19.0000007738859,
                "s": [
                  0
                ]
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false
        }
      ],
      "ip": 9.00000036657752,
      "op": 19.0000007738859,
      "st": -1.00000004073083,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 4,
      "nm": "line7",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 96,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 9,
              "s": [
                443,
                472.938,
                0
              ],
              "to": [
                23.667,
                31,
                0
              ],
              "ti": [
                -23.667,
                -31,
                0
              ]
            },
            {
              "t": 19.0000007738859,
              "s": [
                585,
                658.938,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            69.466,
            39.451,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            36.682,
            36.682,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      111.841,
                      -3.174
                    ],
                    [
                      27.091,
                      82.076
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.321568638086,
                  0.0941176489,
                  0.850980401039,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 32,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "tm",
          "s": {
            "a": 0,
            "k": 0,
            "ix": 1
          },
          "e": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 9,
                "s": [
                  100
                ]
              },
              {
                "t": 19.0000007738859,
                "s": [
                  0
                ]
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false
        }
      ],
      "ip": 9.00000036657752,
      "op": 19.0000007738859,
      "st": -1.00000004073083,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 4,
      "nm": "line8",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.667,
                "y": 1
              },
              "o": {
                "x": 0.333,
                "y": 0
              },
              "t": 9,
              "s": [
                485.5,
                252.438,
                0
              ],
              "to": [
                19,
                -20.667,
                0
              ],
              "ti": [
                -19,
                20.667,
                0
              ]
            },
            {
              "t": 19.0000007738859,
              "s": [
                599.5,
                128.438,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            69.466,
            39.451,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            36.682,
            36.682,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ]
                  ],
                  "v": [
                    [
                      111.841,
                      -3.174
                    ],
                    [
                      27.091,
                      82.076
                    ]
                  ],
                  "c": false
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [
                  0.321568638086,
                  0.0941176489,
                  0.850980401039,
                  1
                ],
                "ix": 3
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 4
              },
              "w": {
                "a": 0,
                "k": 32,
                "ix": 5
              },
              "lc": 2,
              "lj": 1,
              "ml": 4,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Shape 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "tm",
          "s": {
            "a": 0,
            "k": 0,
            "ix": 1
          },
          "e": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 9,
                "s": [
                  100
                ]
              },
              {
                "t": 19.0000007738859,
                "s": [
                  0
                ]
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false
        }
      ],
      "ip": 9.00000036657752,
      "op": 19.0000007738859,
      "st": -1.00000004073083,
      "bm": 0
    }
  ],
  "markers": []
}
module.exports = data;