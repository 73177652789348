package della8.core.screens

import della8.core.items.*
import della8.core.services.*
import della8.core.support.*
import techla.agreement.Agreement
import techla.base.*
import techla.conversation.Feed
import techla.conversation.Message
import techla.conversation.Reaction
import techla.storage.Asset

object MessageScreen {

    object Header {
        val heading = DesignSystem.Header(id = "heading")
        val body = DesignSystem.Header(id = "body")
        val image = DesignSystem.Header(id = "image")
        val movie = DesignSystem.Header(id = "movie")
        val assetType = DesignSystem.Header(id = "assetType")
    }

    data class Texts(
        val back: String,
        val createTitle: String,
        val editTitle: String,
        val create: String,
        val edit: String,
        val heading: String,
        val text: String,
        val headingRequired: String,
        val bodyRequired: String,
        val assetImage: String,
        val removeImages: String,
        val addAsset: String,
        val commentTitle: String,
        val commentBtn: String,
        val commentSuccessTitle: String,
        val reactionsTitle: String,
        val editSuccessTitle: String,
        val successTitleMovie: String,
        val noReactions: String,
        val assetMovie: String,
        val removeMovie: String,
        val fileValidationMaxAmount: String,
        val amount: String,
        override val progressInfo: String,
        override val failureTitle: String,
        override val successTitle: String,
        override val successNext: String,
        override val successInfo: String,
    ) : ProgressTexts, FailureTexts, SuccessTexts {
        companion object
    }

    data class State(
        val obj: Object = Object.None,
        val heading: String = "",
        val body: String = "",
        val asset: Asset? = null,
        val edit: Boolean = false,
        val assets: List<Asset> = emptyList(),
        val messages: List<Message> = emptyList(),
        val messageAssets: List<Asset> = emptyList(),
        val assetType: DesignSystem.FileType = DesignSystem.FileType.IMAGE,
        val maxImages: Int = 10,
        val processingAssets: Boolean = false,
    )


    sealed class ViewModel(open val texts: Texts, open val state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(Object.None),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Ready(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val createTitle: DesignSystem.Text,
            val editTitle: DesignSystem.Text,
            val create: DesignSystem.Button,
            val edit: DesignSystem.Button,
            val heading: DesignSystem.TextInput,
            val images: List<DesignSystem.ImageView>,
            val movie: DesignSystem.ImageView,
            val text: DesignSystem.TextInput,
            val imageUpload: DesignSystem.FileInput,
            val movieUpload: DesignSystem.FileInput,
            val removeAsset: DesignSystem.Button,
            val assetLabel: DesignSystem.Text,
            val assetType: DesignSystem.SelectInput,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Publishing(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Comment(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val commentTitle: DesignSystem.Text,
            val text: DesignSystem.TextInput,
            val commentBtn: DesignSystem.Button,
            val items: List<MessageItem.ViewModel>
        ) : ViewModel(texts, state, navigation) {
            companion object
        }
        data class Commented(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val success: DesignSystem.Success,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Reactions(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val reactionsTitle: DesignSystem.Text,
            val commentBtn: DesignSystem.Button,
            val items: List<MessageItem.ViewModel>,
            val noReactions: DesignSystem.Text,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Published(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val success: DesignSystem.Success,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Removed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Failed(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        fun loading(texts: Texts) =
            Loading(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(texts = texts),
            )

        fun ready(state: State, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing),
                createTitle = DesignSystem.Text(text = texts.createTitle, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.LIGHT, visible = !state.edit),
                editTitle = DesignSystem.Text(text = texts.editTitle, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.LIGHT, visible = state.edit),
                create = DesignSystem.Button(title = texts.create, visible = !state.edit),
                edit = DesignSystem.Button(title = texts.edit, visible = state.edit),
                heading = DesignSystem.TextInput(
                    header = Header.heading,
                    title = texts.heading,
                    value = state.heading,
                    status = status.statusOf(Header.heading)
                ),
                text = DesignSystem.TextInput(
                    header = Header.body,
                    title = texts.text,
                    input = DesignSystem.Input.NOVEL,
                    value = state.body,
                    status = status.statusOf(Header.body)
                ),
                assetType = DesignSystem.SelectInput(
                    header = Header.assetType,
                    options = listOf(
                        DesignSystem.Option.item(title = "Bilder", value = DesignSystem.FileType.IMAGE.name),
                        DesignSystem.Option.item(title = "Video", value = DesignSystem.FileType.VIDEO.name),
                    ),
                    style = DesignSystem.SelectStyle.SWITCH,
                    visible = !state.edit
                ),
                imageUpload = DesignSystem.FileInput(header = Header.image, title = texts.assetImage, status = status.statusOf(Header.image), invisible = true, visible = !state.edit && state.assetType == DesignSystem.FileType.IMAGE, multiple = true, fileType = DesignSystem.FileType.IMAGE),
                movieUpload = DesignSystem.FileInput(header = Header.movie, title = texts.assetMovie, status = status.statusOf(Header.movie), invisible = true, visible = !state.edit && state.assetType == DesignSystem.FileType.VIDEO, fileType = DesignSystem.FileType.VIDEO),
                images = state.assets.map { DesignSystem.ImageView(href = it.limitedUrl.toString(), visible = it.limitedUrl != null) },
                movie = DesignSystem.ImageView(image = DesignSystem.Image.VIDEO, visible = state.asset != null && state.asset.limitedUrl == null),
                removeAsset = DesignSystem.Button(title = if (state.assetType == DesignSystem.FileType.IMAGE) texts.removeImages else texts.removeMovie, style = DesignSystem.ButtonStyle.TRANSPARENT, visible = !state.edit),
                assetLabel = DesignSystem.Text(text = texts.addAsset, style = DesignSystem.TextStyle.SUBHEAD, visible = !state.edit),
                progress = progress(texts = texts),
            )

        fun comment(state: State, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList(), items: List<MessageItem.ViewModel>) =
            Comment(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing, backLocation = Location.feed(state.obj)),
                commentTitle = DesignSystem.Text(text = texts.commentTitle, style = DesignSystem.TextStyle.TITLE3, background = DesignSystem.Background.LIGHT),
                text = DesignSystem.TextInput(
                    header = Header.body,
                    title = texts.text,
                    input = DesignSystem.Input.NOVEL,
                    value = state.body,
                    status = status.statusOf(Header.body)
                ),
                commentBtn = DesignSystem.Button(title = texts.commentBtn),
                items = items

            )

        fun reactions(state: State, items: List<MessageItem.ViewModel>) =
            Reactions(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing, backLocation = Location.feed(state.obj)),
                reactionsTitle = DesignSystem.Text(text = texts.reactionsTitle, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.LIGHT),
                commentBtn = DesignSystem.Button(style = DesignSystem.ButtonStyle.IMAGE, image = DesignSystem.Image.FEED_EDIT, background = DesignSystem.Color.CLEAR),
                items = items,
                noReactions = DesignSystem.Text(text = texts.noReactions, visible = items.isEmpty(), alignment = DesignSystem.TextAlignment.CENTER)
            )

        fun publishing(state: State) =
            Publishing(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(texts = texts),
            )

        fun published(state: State, texts: Texts) =
            Published(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.success,
                success = success(texts = texts),
            )

        fun commented(state: State, texts: Texts) =
            Commented(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.success,
                success = success(texts = texts),
            )

        fun removed(state: State) =
            Removed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.success,
            )


        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false) =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoad get() = this as? Loading
        val asReady get() = this as? Ready
        val asRemoved get() = this as? Removed
        val asFailed get() = this as? Failed

    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))


    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        val texts = Texts(
            back = "Tillbaka",
            createTitle = "Skapa ett inlägg",
            assetImage = "Lägg till bilder",
            assetMovie = "Lägg till video",
            create = "Publicera",
            progressInfo = "Laddar...",
            failureTitle = "Oj, ett fel har uppstått",
            successTitle = "Inlägg upplagt",
            successTitleMovie = "Det kan ta ett tag innan videon är tillgänglig",
            successInfo = "",
            successNext = "Ok",
            heading = "Titel",
            text = "Text",
            headingRequired = "Titel är obligatorisk",
            bodyRequired = "Text är obligatorisk",
            edit = "Ändra",
            editTitle = "Ändra inlägget",
            removeImages = "Ta bort bilder",
            removeMovie = "Ta bort video",
            commentTitle = "Kommentera",
            commentBtn = "Publicera",
            commentSuccessTitle = "Kommentaren är tillagd",
            editSuccessTitle = "Inlägg är ändrat",
            reactionsTitle = "Reaktioner",
            addAsset = "Bild eller video",
            noReactions = "Här var det tomt! Starta konversationen.",
            fileValidationMaxAmount = "För många bilder valda. Max antal är",
            amount = "st"
        )
        return sceneOf<ViewModel>(viewModel.loading(texts = texts))

    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.refreshObject(objectId = objectId, agreementId = agreementId)
            .map { (actions, obj) ->
                val state = viewModel.state.copy(obj = obj)
                sceneOf<ViewModel>(viewModel.ready(state = state), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>, messageId: Identifier<Message>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.refreshObject(objectId = objectId, agreementId = agreementId)
            .flatMap { (actions, obj) ->
                store.getMessage(obj, messageId)
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj, it.second) }
            }
            .map { (actions, obj, message) ->

                val newState = viewModel.state.copy(obj = obj, edit = true, heading = message.articleTitle, body = message.feedBody)
                sceneOf<ViewModel>(viewModel.ready(state = newState), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>, messageId: Identifier<Message>, type: String): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return when (type) {
            "comment" -> store.refreshObject(objectId = objectId, agreementId = agreementId)
                .flatMap { (actions, obj) ->
                    store.reduce(actions).getMessage(obj, messageId).accumulate(actions)
                        .map { tupleOf(it.first, obj, it.second) }
                }
                .flatMap { (actions, obj, message) ->
                    val assetIds = message.attachments.mapNotNull { attachment ->
                        when (attachment) {
                            is Message.Attachment.AssetImage -> attachment.assetId
                            is Message.Attachment.AssetMovie -> attachment.assetId
                            else -> null
                        }
                    }

                    store.reduce(actions).getAssets(obj, batch = Asset.Batch(ids = assetIds))
                        .accumulate(actions)
                        .map { tupleOf(it.first, obj, message, it.second) }
                }

                .map { (actions, obj, message, assets) ->
                    val newState = viewModel.state.copy(obj = obj, messageAssets = assets, messages = listOf(message))
                    sceneOf<ViewModel>(viewModel.comment(state = newState, items = buildItems(store, newState)), actions)
                }
                .failed { scene.failed(result = it) }

            else -> store.refreshObject(objectId = objectId, agreementId = agreementId)
                .flatMap { (actions, obj) ->
                    store.reduce(actions).getReactionsWithMessage(obj, messageId)
                        .accumulate(actions)
                        .map { tupleOf(it.first, obj, it.second) }
                }
                .map { (actions, obj, reactions) ->
                    val newState = viewModel.state.copy(obj = obj)
                    sceneOf<ViewModel>(viewModel.reactions(state = newState, buildReactionItems(newState, reactions)), actions)
                }
                .failed { scene.failed(result = it) }
        }
    }

    suspend fun post(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val title = Key<Message.Attachment>("TITLE")
        val text = Key<Message.Attachment>("TEXT")

        val post = if (viewModel.state.assets.isNotEmpty() && viewModel.state.assets.firstOrNull()?.contentType?.contains("image") != null) Feed.Entry.BlogImages(
            title = Message.Attachment.InlineTitle(viewModel.state.heading, title),
            text = Message.Attachment.InlineText(viewModel.state.body, text),
            status = Message.Status.Published,
            images = viewModel.state.assets.mapIndexed { index, asset ->
                Message.Attachment.AssetImage(assetId = asset.id, key = Key(index.toString()))
            }
        )
        else if (viewModel.state.asset?.contentType?.contains("video") == true) Feed.Entry.BlogMovie(
            title = Message.Attachment.InlineTitle(viewModel.state.heading, title),
            text = Message.Attachment.InlineText(viewModel.state.body, text),
            movie = Message.Attachment.AssetMovie(assetId = viewModel.state.asset?.id!!, key = Key("1")),
            status = Message.Status.WaitingAssetsReady,
        )
        else
            Feed.Entry.BlogText(
                title = Message.Attachment.InlineTitle(viewModel.state.heading, title),
                text = Message.Attachment.InlineText(viewModel.state.body, text),
                status = Message.Status.Published,
            )

        return successfulOf(true)
            .flatMap { store.post(viewModel.state.obj, post, to = listOf(Key(viewModel.state.obj.key.rawValue))) }
            .map { (actions) ->
                val successTitle = if (viewModel.state.asset?.contentType?.contains("video") == true) viewModel.texts.successTitleMovie else viewModel.texts.successTitle

                sceneOf<ViewModel>(viewModel.published(texts = viewModel.texts.copy(successTitle = successTitle), state = viewModel.state.copy(heading = "", body = "")), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun edit(scene: Scene.Input<ViewModel>, messageId: Identifier<Message>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val message = Message.Modify(
            title = modifiedOf(viewModel.state.heading),
            text = modifiedOf(viewModel.state.body),
        )

        return successfulOf(true)
            .flatMap { store.modify(viewModel.state.obj, messageId, message) }
            .map { (actions) ->
                sceneOf<ViewModel>(viewModel.published(texts = viewModel.texts.copy(successTitle = viewModel.texts.editSuccessTitle), state = viewModel.state.copy(heading = "", body = "")), actions)
            }
            .failed { scene.failed(result = it) }
    }


    suspend fun upload(scene: Scene.Input<ViewModel>, asset: Asset.Create?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        asset ?: return sceneOf(viewModel.failed("No asset"))
        val create = Asset.Create(
            name = asset.name,
            contentType = asset.contentType,
            data = asset.data,
        )
        return store.createAsset(viewModel.state.obj, create = create)
            .flatMap { (actions, asset) ->
                store.reduce(actions).getAsset(viewModel.state.obj, asset.id)
            }
            .map { (actions, asset) ->
                val state = viewModel.state.copy(asset = asset, assets = emptyList(), processingAssets = true)

                sceneOf<ViewModel>(viewModel.ready(state = state), actions)
            }.failed { scene.failed(result = it) }
    }

    suspend fun upload(scene: Scene.Input<ViewModel>, assets: List<Asset.Create>?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        assets?.isEmpty() ?: return sceneOf(viewModel.failed("No asset"))
        if ((assets.size + viewModel.state.assets.size) > viewModel.state.maxImages) return sceneOf<ViewModel>(viewModel.ready(state = viewModel.state.copy(assets = viewModel.state.assets, asset = null), status = listOf(Header.image to DesignSystem.Status.Invalid(warning = listOf(viewModel.texts.fileValidationMaxAmount, viewModel.state.maxImages.toString(), viewModel.texts.amount).joinToString(" ")))))

        val uploads = mutableListOf<Asset>()

        assets.forEach { asset ->
            val create = Asset.Create(
                name = asset.name,
                contentType = asset.contentType,
                data = asset.data,
            )
            store.createAsset(viewModel.state.obj, create = create)
                .map { (actions, asset) ->
                    store.reduce(actions).getAsset(viewModel.state.obj, asset.id).accumulate(actions)
                        .map { uploads.add(it.second) }
                }
        }
        return sceneOf<ViewModel>(viewModel.ready(state = viewModel.state.copy(assets = viewModel.state.assets + uploads, asset = null, processingAssets = true)))
    }

    suspend fun deleteAsset(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return successfulOf(true)
            .map {
                val state = viewModel.state.copy(asset = null, assets = emptyList())
                sceneOf<ViewModel>(viewModel.ready(state = state))
            }.failed { scene.failed(result = it) }
    }

    suspend fun resetProcessingAssets(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return successfulOf(true)
            .map {
                val state = viewModel.state.copy(processingAssets = false)
                sceneOf<ViewModel>(viewModel.ready(state = state))
            }.failed { scene.failed(result = it) }
    }

    suspend fun setValue(scene: Scene.Input<ViewModel>, heading: String?, texts: String?, option: DesignSystem.Option?): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return successfulOf(true)
            .map {
                val assetType = when (option?.value) {
                    DesignSystem.FileType.VIDEO.name -> DesignSystem.FileType.VIDEO
                    DesignSystem.FileType.IMAGE.name -> DesignSystem.FileType.IMAGE
                    else -> viewModel.state.assetType
                }

                val state = viewModel.state.copy(heading = heading ?: viewModel.state.heading, body = texts ?: viewModel.state.body, assetType = assetType)
                sceneOf<ViewModel>(viewModel.ready(state = state))
            }.failed { scene.failed(result = it) }
    }


    fun validate(scene: Scene.Input<ViewModel>, heading: String?, texts: String?): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val status: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()
        var state = viewModel.state

        Validator.text(
            text = heading ?: state.heading,
            isEmpty = { status.add(Header.heading to DesignSystem.Status.Invalid(warning = viewModel.texts.headingRequired)) },
            formatted = { state = state.copy(heading = it) },
            passed = { status.add(Header.heading to DesignSystem.Status.Valid) }
        )

        Validator.text(
            text = texts ?: state.body,
            isEmpty = { status.add(Header.body to DesignSystem.Status.Invalid(warning = viewModel.texts.bodyRequired)) },
            formatted = { state = state.copy(body = it) },
            passed = { status.add(Header.body to DesignSystem.Status.Valid) }
        )

        if (status.overallStatus() !is DesignSystem.Status.Valid)
            return sceneOf<ViewModel>(viewModel.ready(state = state, status = status))

        return sceneOf<ViewModel>(viewModel.publishing(state = state))
    }

    suspend fun success(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return load(scene = scene, objectId = viewModel.state.obj.id, agreementId = viewModel.state.obj.currentAgreement.id)
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }


    suspend fun validateComment(scene: Scene.Input<ViewModel>, texts: String?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val status: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()
        var state = viewModel.state

        Validator.text(
            text = texts,
            isEmpty = { status.add(Header.body to DesignSystem.Status.Invalid(warning = viewModel.texts.bodyRequired)) },
            formatted = { state = state.copy(body = it) },
            passed = { status.add(Header.body to DesignSystem.Status.Valid) }
        )

        if (status.overallStatus() !is DesignSystem.Status.Valid)
            return sceneOf<ViewModel>(viewModel.comment(state = state, status = status, items = buildItems(store, state)))

        return successfulOf(true)
            .map {
                sceneOf<ViewModel>(viewModel.publishing(state = state))
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun comment(scene: Scene.Input<ViewModel>, messageId: Identifier<Message>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.react(
            viewModel.state.obj, messageId, Reaction.Create(kind = Reaction.Kind.Comment(text = viewModel.state.body))
        )
            .map {
                sceneOf<ViewModel>(viewModel.commented(state = viewModel.state.copy(body = ""), texts = viewModel.texts.copy(successTitle = viewModel.texts.commentSuccessTitle)))
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun removeMessage(scene: Scene.Input<ViewModel>, messageId: Identifier<Message>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.flag(viewModel.state.obj, messageId)
            .map { (actions) ->
                val state = viewModel.state.copy(messages = emptyList())
                sceneOf<ViewModel>(viewModel.removed(state = state), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun likeMessage(scene: Scene.Input<ViewModel>, messageId: Identifier<Message>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.react(
            viewModel.state.obj, messageId, Reaction.Create(
                kind = Reaction.Kind.Like,
                mode = Reaction.Mode.Toggle
            )
        )
            .flatMap { (actions, _) ->
                store.reduce(actions).getMessage(viewModel.state.obj, messageId)
                    .accumulate(actions)
            }
            .map { (actions, message) ->
                val state = viewModel.state.copy(messages = listOf(message))
                sceneOf<ViewModel>(viewModel.comment(state = state, items = buildItems(store, state)), actions)
            }
            .failed { scene.failed(result = it) }
    }


    private fun buildReactionItems(state: State, reactions: List<Reaction>): List<MessageItem.ViewModel> {
        val items = reactions.map { reaction ->
            val bio = state.obj.bios.firstOrNull { bio -> bio.profileId.rawValue == reaction.reactor.rawValue }

            MessageItem.reaction(
                reaction = reaction,
                obj = state.obj,
                profile = bio?.initials ?: "??",
                author = "${bio?.firstName} ${bio?.lastName}",
            )
        }
        return items
    }

    private fun buildItems(store: Store, state: State): List<MessageItem.ViewModel> {
        val items = state.messages.mapNotNull { message ->
            when (message.kind) {
                is Message.Kind.Blog -> {
                    val bio = state.obj.bios.firstOrNull { bio -> bio.profileId.rawValue == message.author?.rawValue }
                    val movie = state.messageAssets.firstOrNull { it.id == message.movieId }

                    MessageItem.blog(
                        message = message,
                        obj = state.obj,
                        profile = bio?.initials ?: "??",
                        author = "${bio?.firstName} ${bio?.lastName}",
                        canEdit = store.profileId == message.author,
                        images = message.attachments.filterIsInstance<Message.Attachment.AssetImage>().flatMap { m -> state.messageAssets.filter { it.id == m.assetId }.mapNotNull { it.thumbnailUrl } },
                        muxMovie = Triple(getMuxToken(movie?.limitedUrl), movie?.playbackId?.rawValue, getMuxToken(movie?.thumbnailUrl)),
                    )
                }

                is Message.Kind.News -> null
                else -> null
            }
        }
        return items
    }
}