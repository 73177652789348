package della8.core.services

import della8.core.support.*
import techla.base.*
import techla.guard.*

suspend fun Store.acceptInvite(accept: Invite.Accept): ActionOutcome<Unit> =
    GuardEndpoint.acceptInvite(this, accept)

suspend fun Store.createInvite(create: Invite.Create): ActionOutcome<Invite> =
    GuardEndpoint.createInvite(this, create)

suspend fun Store.createInvite(obj: Object, create: Invite.Create): ActionOutcome<Invite> =
    GuardEndpoint.createInvite(this, create)
        .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))

suspend fun Store.createGroup(create: Group.Create): ActionOutcome<Group> =
    GuardEndpoint.createGroup(this, create)

suspend fun Store.getGroup(id: Identifier<Group>): ActionOutcome<Group> =
    GuardEndpoint.getGroup(this, id)

suspend fun Store.editGroup(id: Identifier<Group>, edit: Group.Edit): ActionOutcome<Group> =
    GuardEndpoint.editGroup(this, id, edit)

suspend fun Store.editGroup(obj: Object, edit: Group.Edit): ActionOutcome<Group> =
    GuardEndpoint.editGroup(this, obj.group.id, edit)
        .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))

suspend fun Store.deleteGroup(obj: Object): ActionOutcome<Unit> =
    GuardEndpoint.deleteGroup(this, obj.group.id)
        .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))

suspend fun Store.me(): ActionOutcome<Me> =
    GuardEndpoint.me(this)

suspend fun Store.myGroups(): ActionOutcome<List<Group>> =
    GuardEndpoint.myGroups(this)

suspend fun Store.createApplicationAuthentication(): ActionOutcome<Unit> {
    val api = guardAPI
    val create = ApplicationAuthentication.Create(
        applicationKey = deployment.applicationKey,
        applicationSecret = deployment.applicationSecret,
        device = device,
    )
    return GuardEndpoint.createApplicationAuthentication(api, create)
        .map { listOf<Store.Action>(Store.Action.ApplicationAuthenticationComplete(it.token!!)) to Unit }
}

suspend fun Store.createUserAuthentication(govId: String?): Outcome<UserAuthentication> {
    val api = guardAPI
    api.token = applicationToken
    val create = UserAuthentication.Create(govId = govId, device = device)
    return measureAPI(GuardResource.CreateUserAuthentication(create), api) {
        api.createUserAuthentication(create)
            .onNotSuccess { techla_log("WARN: $it") }
    }
}

suspend fun Store.createUserAuthentication(): Outcome<UserAuthentication> {
    val api = guardAPI
    api.token = applicationToken
    val create = UserAuthentication.Create()
    return measureAPI(GuardResource.CreateUserAuthentication(create), api) {
        api.createUserAuthentication(create)
            .onNotSuccess { techla_log("WARN: $it") }
    }
}

suspend fun Store.getUserAuthentication(): Outcome<UserAuthentication> {
    if (demoMode) return Demo.completedUserAuthentication
    if (userAuthenticationId == null) return failedOf(TechlaError.BadRequest("userAuthenticationId missing (getUserAuthentication)"))
    val api = guardAPI
    api.token = applicationToken
    return measureAPI(GuardResource.GetUserAuthentication(userAuthenticationId), api) {
        api.getUserAuthentication(userAuthenticationId)
            .onNotSuccess { techla_log("WARN: $it") }
    }
}

suspend fun Store.deleteUserAuthentication(): Outcome<List<Store.Action>> {
    if (demoMode) return Demo.deleteUserAuthentication(this)
    if (userAuthenticationId == null) return failedOf(TechlaError.BadRequest("userAuthenticationId missing (deleteUserAuthentication)"))
    val api = guardAPI
    api.token = applicationToken
    return measureAPI(GuardResource.DeleteUserAuthentication(userAuthenticationId), api) {
        api.deleteUserAuthentication(userAuthenticationId)
            .fold(
                onSuccess = { successfulOf(listOf(Store.Action.Logout)) },
                onNotSuccess = { successfulOf(listOf(Store.Action.Logout)) },
            )
    }
}

suspend fun Store.refreshUserAuthentication(): Outcome<UserAuthentication> {
    val id = userAuthenticationId ?: return failedOf(TechlaError.BadRequest("userAuthenticationId needed to refresh token"))
    val api = guardAPI
    api.token = applicationToken
    return api.getUserAuthentication(id)
        .fold(
            {
                when (it.status) {
                    is UserAuthentication.Status.Complete ->
                        successfulOf(it)

                    is UserAuthentication.Status.Expired ->
                        failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is expired"))

                    is UserAuthentication.Status.Cancelled ->
                        failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is cancelled"))

                    is UserAuthentication.Status.Failed ->
                        failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is failed"))

                    is UserAuthentication.Status.Outstanding ->
                        failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is outstanding"))

                    is UserAuthentication.Status.Verified ->
                        failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is verified"))
                }
            },
            { failedOf(TechlaError.BadRequest("WARN: $it")) },
            { failedOf(it) }
        )
}

suspend fun <T> Store.withUserToken(block: suspend (Store) -> Outcome<T>): ActionOutcome<T> {
    val expired = tokenExpiresAt?.hasPassed() ?: true
    return if (expired) {
        techla_log("STORE: Token expired (${tokenExpiresAt}), refreshing")
        refreshUserAuthentication()
            .flatMap { userAuthentication ->
                val action = Store.Action.TokenRefresh(userAuthentication.tokens)
                val updated = reduce(action)
                block(updated).map { listOf(action) to it }
            }
    } else {
        block(this).noActions()
    }
}

val Store.guardAPI
    get() =
        GuardAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) GuardAPI.sandbox else GuardAPI.shared
        }

object GuardEndpoint {
    var createApplicationAuthentication: suspend (api: GuardAPI, create: ApplicationAuthentication.Create) -> Outcome<ApplicationAuthentication> = { api, create ->
        measureAPI(GuardResource.CreateApplicationAuthentication(create), api) {
            api.createApplicationAuthentication(create)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }

    var acceptInvite: suspend (store: Store, accept: Invite.Accept) -> ActionOutcome<Unit> = { store, accept ->
        if (store.demoMode)
            successfulOf(Unit).map { emptyList<Store.Action>() to it }
        else {
            store.withUserToken { updated ->
                val api = updated.guardAPI
                api.token = updated.userToken
                measureAPI(GuardResource.AcceptInvite(accept), api) {
                    api.acceptInvite(accept)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var createInvite: suspend (store: Store, create: Invite.Create) -> ActionOutcome<Invite> = { store, create ->
        if (store.demoMode)
            Demo.invite.map { emptyList<Store.Action>() to it }
        else {
            store.withUserToken { updated ->
                val api = updated.guardAPI
                api.token = updated.userToken
                measureAPI(GuardResource.CreateInvite(create), api) {
                    api.createInvite(create)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var createGroup: suspend (store: Store, create: Group.Create) -> ActionOutcome<Group> = { store, create ->
        if (store.demoMode)
            Demo.group.map { emptyList<Store.Action>() to it }
        else {
            store.withUserToken { updated ->
                val api = updated.guardAPI
                api.token = updated.userToken
                measureAPI(GuardResource.CreateGroup(create), api) {
                    api.createGroup(create)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var getGroup: suspend (store: Store, id: Identifier<Group>) -> ActionOutcome<Group> = { store, create ->
        if (store.demoMode)
            Demo.group.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.guardAPI
                api.token = updated.userToken
                measureAPI(GuardResource.GetGroup(create), api) {
                    api.getGroup(create)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var editGroup: suspend (store: Store, id: Identifier<Group>, edit: Group.Edit) -> ActionOutcome<Group> = { store, id, edit ->
        if (store.demoMode)
            Demo.group.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.guardAPI
                api.token = updated.userToken
                measureAPI(GuardResource.EditGroup(id, edit), api) {
                    api.editGroup(id, edit)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var deleteGroup: suspend (store: Store, id: Identifier<Group>) -> ActionOutcome<Unit> = { store, id ->
        if (store.demoMode) successfulOf(Unit).noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.guardAPI
                api.token = updated.userToken
                measureAPI(GuardResource.DeleteGroup(id), api) {
                    api.deleteGroup(id)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var me: suspend (store: Store) -> ActionOutcome<Me> = { store ->
        if (store.demoMode)
            Demo.me.map { emptyList<Store.Action>() to it }
        else {
            store.withUserToken { updated ->
                val api = updated.guardAPI
                api.token = updated.userToken
                measureAPI(GuardResource.Me, api) {
                    api.me()
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var myGroups: suspend (store: Store) -> ActionOutcome<List<Group>> = { store ->
        if (store.demoMode)
            Demo.listAllGroups.map { emptyList<Store.Action>() to it }
        else {
            store.withUserToken { updated ->
                val api = updated.guardAPI
                api.token = updated.userToken
                measureAPI(GuardResource.MyGroups, api) {
                    api.myGroups()
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }
}