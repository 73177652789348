package della8.web.views

import StoreContext
import bootstrap.bCol
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.screens.ShopScreen
import della8.core.support.DesignSystem
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.*
import kotlinx.coroutines.isActive
import kotlinx.js.get
import react.*
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.router.useParams
import techla.base.Identifier
import techla.base.techla_log

val Shop = FC<PropsWithChildren>("Shop") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(ShopScreen.ViewModel.None as ShopScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is ShopScreen.ViewModel.None ->
                ShopScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }
            is ShopScreen.ViewModel.Loading -> {
                val id = params["objectId"]
                if (id != null) {
                    ShopScreen.load(sceneInputOf(store, viewModel), Identifier(id))
                        .also { (viewModel, actions) ->
                            if (coroutineScope.isActive) {
                                setViewModel(viewModel)
                                dispatch(actions)
                            }
                        }
                }
            }
            is ShopScreen.ViewModel.Ready -> {
            }
            is ShopScreen.ViewModel.Failed ->
                techla_log("failed ")
        }
    }


    main {
        when(viewModel){
        is ShopScreen.ViewModel.Ready -> d8Navigation(
        design = viewModel.navigation,
        className = "nav-top",
        onClick = { action ->
            when (action) {
                DesignSystem.Action.SHOP -> navigate("/object/${params["objectId"]}/${params["agreementId"]}/shop")
                DesignSystem.Action.SETTINGS -> navigate("/object/${params["objectId"]}/${params["agreementId"]}/settings")
                DesignSystem.Action.AGREEMENT -> navigate("/object/${params["objectId"]}/${params["agreementId"]}/agreement")
                DesignSystem.Action.INVITE -> navigate("/object/${params["objectId"]}/${params["agreementId"]}/invite")
                else -> {}
            }
        })
        else -> d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
    }
        if (viewModel is ShopScreen.ViewModel.Ready) {
            bContainer {
                className =  className(
                    listOfNotNull("px-5 position-relative n-bottom-space n-top-space")
                )
                bRow {
                    className =    className(
                        listOfNotNull("pb-4 justify-content-center")
                    )
                    bCol {
                        xs = 12; md = 5
                        d8Text(className = "text-center", design = viewModel.title)
                    }
                }
            }
        }

        if (viewModel is ShopScreen.ViewModel.Failed) {
            bContainer {
                className =  className(
                    listOfNotNull("px-5 n-top-space")
                )
                d8failure(viewModel.failure)
            }
        }
    }
}
