package della8.web.items

import bootstrap.*
import della8.core.items.ModuleItem
import della8.web.components.d8Button
import della8.web.components.d8Image
import della8.web.components.d8Text
import della8.web.support.className
import react.*

external interface ModuleItemStartedProps : PropsWithChildren {
    var classes: String?
    var viewModel: ModuleItem.ViewModel
    var action: (String, String, String) -> Unit
}


val ModuleItemStarted = FC<ModuleItemStartedProps>("ModuleItemStarted") { props ->
    when (val viewModel = props.viewModel) {
        is ModuleItem.ViewModel.Partnership -> {
            bCard {
                className = className(listOfNotNull("radius-25 border-0 card-shadow my-5 text-center", if (props.viewModel.disabled) "disabledCard" else ""))
                bCardBody {
                    d8Image(design = viewModel.image, className = "position-absolute top-0 start-50 translate-middle")
                    bRow {
                        className = className("px-4 pt-3 justify-content-center row")
                        bCol {
                            xs = 12; md = 7
                            d8Text(className = "d-block mb-0 pt-2", design = viewModel.title)
                            d8Text(className = "d-block", design = viewModel.info)
                            d8Button(className = "mt-2", design = viewModel.more, onClick = { props.action("partnership", "", "") })
                        }
                    }
                }
            }
        }

        is ModuleItem.ViewModel.Booking -> {
            bCard {
                className = className(listOfNotNull("radius-25 border-0 card-shadow my-5 text-center", if (props.viewModel.disabled) "disabledCard" else ""))
                bCardBody {
                    d8Image(design = viewModel.image, className = "position-absolute top-0 start-50 translate-middle")
                    bRow {
                        className = className("px-4 pt-3 justify-content-center row")
                        bCol {
                            xs = 12; md = 7
                            d8Text(className = "d-block mb-0 pt-2", design = viewModel.title)
                            d8Text(className = "d-block", design = viewModel.info)
                            d8Button(className = "mt-2", design = viewModel.more, onClick = { props.action("availability", "", "") })
                        }
                    }
                }
            }
        }

        else -> {}
    }
}


fun ChildrenBuilder.moduleItemStarted(
    viewModel: ModuleItem.ViewModel,
    classes: String? = null,
    action: (String, String, String) -> Unit
) =
    ModuleItemStarted {
        this.classes = classes
        this.viewModel = viewModel
        this.action = action
    }