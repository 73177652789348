package della8.core.screens

import della8.core.support.*
import techla.base.*

object DraftScreen {

    data class Texts(
        val title: String,
        val body: String,
        val back: String,
        val done: String,
        val save: String,
        val saved: String,
        override val progressInfo: String,
        override val failureTitle: String,
        override val successTitle: String,
        override val successInfo: String,
        override val successNext: String,
    ) : ProgressTexts, FailureTexts, SuccessTexts {
        companion object
    }

    data class State(
        val obj: Object = Object.None,
    )

    sealed class ViewModel(open val texts: Texts, open val state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Ready(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val body: DesignSystem.Text,
            val image: DesignSystem.ImageView,
            val create: DesignSystem.Button,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Creating(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Created(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val success: DesignSystem.Success,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Failed(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        fun loading(texts: Texts) = Loading(
            texts = texts,
            state = state,
            navigation = DesignSystem.Navigation.progress(),
            progress = progress(texts = texts)
        )

        fun ready(state: State, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()): ViewModel =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing, backLocation = Location.Landing),
                image = DesignSystem.ImageView(image = DesignSystem.Image.CONTRACT_DRAFT, alt = texts.title),
                title = DesignSystem.Text(text = texts.title, style = DesignSystem.TextStyle.TITLE1),
                body = DesignSystem.Text(text = texts.body, style = DesignSystem.TextStyle.BODY, isMarkdown = true),
                create = DesignSystem.Button(title = texts.save),
            )

        fun creating(): ViewModel = Creating(
            texts = texts,
            state = state,
            navigation = DesignSystem.Navigation.progress(),
            progress = progress(texts = texts)
        )

        fun created(): ViewModel =
            Created(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                success = success(texts = texts),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String): ViewModel =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asReady get() = this as? Ready
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        val texts = Texts(
            title = "Skapa avtalsutkast",
            body = """
            Vill ni göra förändringar i era överenskomna avtalsregler, då skapar du ett nytt avtalsutkast här som sedan kan skickas ut för godkännande av objektets samägare. När samtliga godkänt kommer de nya reglerna gälla och det skapa en ny version av avtalet.
            
            Tills dess att alla godkänt så gäller redan överenskommet avtal.  
            """.trimIndent(),
            back = "Tillbaka",
            progressInfo = "Laddar...",
            failureTitle = "Oj, ett fel har uppstått",
            done = "Klar",
            save = "Skapa",
            saved = "Skapat!",
            successTitle = "Ett nytt avtalsutkast är nu skapat",
            successInfo = "Det nya avtalsutkastet har just nu samma innehåll som det tidigare avtalet, men det går bra att gå in och ändra de delarna som ni vill uppdatera. Ert objekt går att använda med det gamla avtalet parallellt med att ni utformar ert nya avtal. Det finns nu två knappar på objektet, ett för att fortsätta enligt det gamla avtalet och ett för att utforma det nya avtalet. Så fort ni har godkänt det nya avtalet så går objektet tillbaka till att bara ha en knapp",
            successNext = "OK",
        )

        return sceneOf<ViewModel>(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(true).noActions()
            .flatMap { (actions, _) ->
                store.reduce(actions).refreshMinimalObject(objectId = objectId)
                    .accumulate(actions)
            }
            .map { (actions, obj) ->
                val state = State(obj = obj)
                sceneOf(viewModel.ready(state = state), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun create(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state

        return successfulOf(true)
            .flatMap { store.createDraft(obj = state.obj) }
            .map { (actions, _) ->
                sceneOf(viewModel.created(), actions = actions)
            }
            .failed { scene.failed(result = it) }
    }
}
