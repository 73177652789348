package della8.core.items


import della8.core.support.DesignSystem
import techla.base.Identifier
import techla.reservation.Reservation

object BookingItem {

    sealed class ViewModel(open val id: Identifier<BookingItem>) {
        data class Reservation(
             override val id: Identifier<BookingItem>,
            val from: DesignSystem.Text,
            val to: DesignSystem.Text,
            val person: DesignSystem.Text,
            val bookedBy: DesignSystem.Text,
            val delete: DesignSystem.Button,
        ) : ViewModel(id = id) {
            companion object
        }
    }

    fun reservation(id: Identifier<Reservation>, from: String, to: String, image: DesignSystem.Image, visible: Boolean, bookedBy: String, person: String) =
        ViewModel.Reservation(
            id = Identifier(id.rawValue ),
            from = DesignSystem.Text(text = from, image = DesignSystem.Image.ARRIVAL, iconAlignment = DesignSystem.IconAlignment.LEFT),
            to = DesignSystem.Text(text = to, image = DesignSystem.Image.DEPARTURE,  iconAlignment = DesignSystem.IconAlignment.LEFT ),
            person = DesignSystem.Text(text = person, image = image,  iconAlignment = DesignSystem.IconAlignment.LEFT),
            delete = DesignSystem.Button(
                image = DesignSystem.Image.TRASH,
                background = DesignSystem.Color.CLEAR,
                style = DesignSystem.ButtonStyle.TRANSPARENT,
                data = id,
                visible = visible,
            ),
            bookedBy = DesignSystem.Text(text = bookedBy, style = DesignSystem.TextStyle.SUBHEAD),
        )
}

