package della8.core.support

import techla.agreement.Agreement

sealed class Module {
    object None : Module()
    object Partnership : Module()
    object Booking : Module()

    val rawValue: String get() =
        when (this) {
            is None -> "none"
            is Partnership -> "partnership"
            is Booking -> "booking"
        }

    sealed class State {
        object Start : State()
        object Pending : State()
        object Ready : State()
    }

    fun stateFor(obj: Object) =
        when {
            this is Partnership && obj.partnershipTerm == null -> State.Start
            this is Partnership && obj.currentAgreement.status !is Agreement.Status.Approved -> State.Pending
            this is Partnership -> State.Ready

            this is Booking && obj.bookingTerm == null -> State.Start
            this is Booking && obj.currentAgreement.status !is Agreement.Status.Approved -> State.Pending
            this is Booking -> State.Ready

            else -> null
        }

    companion object {
        fun fromRawValue(rawValue: String) =
            when (rawValue) {
                None.rawValue -> None
                Booking.rawValue -> Booking
                Partnership.rawValue -> Partnership
                else -> None
            }
    }
}


fun List<Module>.stateForPartnership(obj: Object) =
    filterIsInstance<Module.Partnership>().firstOrNull()?.stateFor(obj)