package della8.core.screens

import della8.core.items.MessageItem
import della8.core.support.*
import techla.base.*

object StartScreen {
    data class Texts(
        val welcome: String,
        val tag: String,
        val promise: String,
        val learn: String,
        val title: String,
        val info: String,
        val interested: String,
        val interestedCta: String,
        val social: String,
        val copyright: String,
        val cookiesTitle: String,
        val terms: String,
        val cookiesBody: String,
        val ok: String,
        val articles: String,
        val signUp: String,
        val signUpTitle: String,
        val friend: String,
        val findFriend: String,
        val infoTitle: String,
        val infoBody: String,
        val service: String,
        val calculatorTitle: String,
        val calculatorBtn: String,
        val oliviaTalks: String,
        val priceValue: String,
        val owners: String,
        val weeklyPrice: String,
        val rentalIncome: String,
        val startCoeOwn: String,
        val toObjects: String,
        val partners: String,
        override val failureTitle: String,
        override val metaTitle: String,
        override val metaDescription: String,
    ) : FailureTexts, MetaTexts


    sealed class ViewModel(open var texts: Texts, open val navigation: DesignSystem.Navigation, open val meta: DesignSystem.Meta) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            navigation = DesignSystem.Navigation.minimal,
            meta = DesignSystem.Meta.minimal,
        )

        data class Loading(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            override val meta: DesignSystem.Meta,
        ) : ViewModel(texts, navigation, meta)

        data class Ready(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            override val meta: DesignSystem.Meta,
            val welcome: DesignSystem.Text,
            val tag: DesignSystem.Text,
            val more: DesignSystem.ImageView,
            val promise: DesignSystem.Text,
            val learn: DesignSystem.Text,
            val articles: List<MessageItem.ViewModel.Article>,
            val interested: DesignSystem.Button,
            val interestedCta: DesignSystem.Button,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val infoTitle: DesignSystem.Text,
            val infoBody: DesignSystem.Text,
            val image: DesignSystem.ImageView,
            val headerImage: DesignSystem.ImageView,
            val footer: DesignSystem.Footer,
            val cookies: DesignSystem.Modal,
            val btnArticles: DesignSystem.Button,
            val signUp: DesignSystem.Button,
            val signUpTitle: DesignSystem.Text,
            val friend: DesignSystem.Text,
            val findFriend: DesignSystem.Button,
            val friendImage: DesignSystem.ImageView,
            val service: DesignSystem.Button,
            val calculatorTitle: DesignSystem.Text,
            val calculatorBtn: DesignSystem.Button,
            val imageCalculator: DesignSystem.ImageView,
            val income: DesignSystem.Property,
            val price: DesignSystem.Property,
            val olivia: DesignSystem.Text,
            val play: DesignSystem.Button,
            val close: DesignSystem.Button,
            val intro: DesignSystem.MovieView,
            val mock: DesignSystem.ImageView,
            val logoBengt: DesignSystem.ImageView,
            val logoResurs: DesignSystem.ImageView,
            val logoProjectWinterVillage: DesignSystem.ImageView,
            val oliviaImage: DesignSystem.ImageView,
            val imageObjectsOwn: DesignSystem.ImageView,
            val startCoeOwn: DesignSystem.Text,
            val toObjects: DesignSystem.Button,
            val partners: DesignSystem.Text,
        ) : ViewModel(texts, navigation, meta)

        data class Failed(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            override val meta: DesignSystem.Meta,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, navigation, meta)

        fun loading(): ViewModel =
            Loading(
                texts = texts,
                navigation = navigation,
                meta = meta(texts)
            )

        fun ready(texts: Texts, articles: List<MessageItem.ViewModel.Article>): ViewModel =
            Ready(
                texts = texts,
                navigation = DesignSystem.Navigation.front(),
                meta = meta(texts),
                welcome = DesignSystem.Text(text = texts.welcome, style = DesignSystem.TextStyle.LARGE_TITLE, background = DesignSystem.Background.DARK),
                tag = DesignSystem.Text(text = texts.tag, style = DesignSystem.TextStyle.TITLE2, background = DesignSystem.Background.DARK),
                more = DesignSystem.ImageView(image = DesignSystem.Image.ARROWSCROLLDOWN),
                promise = DesignSystem.Text(text = texts.promise, style = DesignSystem.TextStyle.TITLE3, background = DesignSystem.Background.DARK),
                learn = DesignSystem.Text(text = texts.learn, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.DARK),
                articles = articles,
                interested = DesignSystem.Button(title = texts.interested, style = DesignSystem.ButtonStyle.PRIMARY, location = Location.Interested),
                interestedCta = DesignSystem.Button(title = texts.interestedCta, style = DesignSystem.ButtonStyle.PRIMARY, location = Location.Interested),
                title = DesignSystem.Text(
                    text = texts.title,
                    style = DesignSystem.TextStyle.TITLE1,
                    background = DesignSystem.Background.DARK
                ),
                info = DesignSystem.Text(
                    text = texts.info,
                    style = DesignSystem.TextStyle.BODY,
                    background = DesignSystem.Background.DARK
                ),
                image = DesignSystem.ImageView(image = DesignSystem.Image.DELLA_TOOL),
                headerImage = DesignSystem.ImageView(href = "img/img_header.jpg"),
                footer = DesignSystem.Footer.front(),
                infoTitle = DesignSystem.Text(text = texts.infoTitle, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.DARK),
                infoBody = DesignSystem.Text(text = texts.infoBody, isMarkdown = true, background = DesignSystem.Background.DARK, image = DesignSystem.Image.CHECKED),
                cookies = DesignSystem.Modal(
                    title = DesignSystem.Text(text = texts.cookiesBody, style = DesignSystem.TextStyle.BODY, background = DesignSystem.Background.DARK),
                    firstButton = DesignSystem.Button(title = texts.ok, style = DesignSystem.ButtonStyle.PRIMARY),
                ),
                btnArticles = DesignSystem.Button(title = texts.articles, style = DesignSystem.ButtonStyle.PRIMARY, location = Location.Articles),
                friend = DesignSystem.Text(text = texts.friend, style = DesignSystem.TextStyle.TITLE2, background = DesignSystem.Background.DARK, alignment = DesignSystem.TextAlignment.CENTER),
                signUp = DesignSystem.Button(title = texts.signUp, style = DesignSystem.ButtonStyle.PRIMARY, location = Location.Subscribe),
                signUpTitle = DesignSystem.Text(text = texts.signUpTitle, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.DARK),
                findFriend = DesignSystem.Button(title = texts.findFriend, style = DesignSystem.ButtonStyle.PRIMARY, location = Location.Interested), ///
                friendImage = DesignSystem.ImageView(image = DesignSystem.Image.MATCH),
                service = DesignSystem.Button(title = texts.service, style = DesignSystem.ButtonStyle.PRIMARY, location = Location.Login),
                calculatorTitle = DesignSystem.Text(text = texts.calculatorTitle, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.DARK, alignment = DesignSystem.TextAlignment.CENTER),
                calculatorBtn = DesignSystem.Button(title = texts.calculatorBtn, style = DesignSystem.ButtonStyle.PRIMARY, location = Location.Calculator),
                imageCalculator = DesignSystem.ImageView(image = DesignSystem.Image.WHY_DELLA8_BANNER),
                price = DesignSystem.Property(
                    value = DesignSystem.Text(text = texts.priceValue, background = DesignSystem.Background.DARK, style = DesignSystem.TextStyle.TITLE1, alignment = DesignSystem.TextAlignment.CENTER),
                    prefix = DesignSystem.Text(text = "", background = DesignSystem.Background.DARK, image = DesignSystem.Image.WHY_DELLA8_PRICE, iconAlignment = DesignSystem.IconAlignment.LEFT, alignment = DesignSystem.TextAlignment.CENTER),
                    suffix = DesignSystem.Text(text = texts.owners, background = DesignSystem.Background.DARK, style = DesignSystem.TextStyle.HEADLINE, alignment = DesignSystem.TextAlignment.CENTER),
                    style = DesignSystem.PropertyStyle.VERTICAL
                ),

                income = DesignSystem.Property(
                    value = DesignSystem.Text(text = texts.weeklyPrice, background = DesignSystem.Background.DARK, style = DesignSystem.TextStyle.TITLE1, alignment = DesignSystem.TextAlignment.CENTER),
                    prefix = DesignSystem.Text(text = texts.rentalIncome, background = DesignSystem.Background.DARK, style = DesignSystem.TextStyle.HEADLINE, image = DesignSystem.Image.WHY_DELLA8_STARS, iconAlignment = DesignSystem.IconAlignment.LEFT, alignment = DesignSystem.TextAlignment.CENTER),
                    style = DesignSystem.PropertyStyle.VERTICAL
                ),
                olivia = DesignSystem.Text(text = texts.oliviaTalks, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.DARK),
                oliviaImage = DesignSystem.ImageView(image = DesignSystem.Image.CLIPPY),
                play = DesignSystem.Button(style = DesignSystem.ButtonStyle.IMAGE, image = DesignSystem.Image.PLAY, background = DesignSystem.Color.CLEAR),
                close = DesignSystem.Button(style = DesignSystem.ButtonStyle.IMAGE, image = DesignSystem.Image.CLOSE, background = DesignSystem.Color.CLEAR),
                mock = DesignSystem.ImageView(image = DesignSystem.Image.MOCK),
                intro = DesignSystem.MovieView(
                    movie = DesignSystem.Movie.PITCH,
                    loop = false,
                    muted = false,
                    showControls = true,
                    player = DesignSystem.MoviePlayer.MUX,
                ),
                logoBengt = DesignSystem.ImageView(image = DesignSystem.Image.LOGO_BENGT),
                logoProjectWinterVillage = DesignSystem.ImageView(image = DesignSystem.Image.LOGO_PROJECT_WINTER_VILLAGE),
                logoResurs = DesignSystem.ImageView(image = DesignSystem.Image.LOGO_RESURS),
                imageObjectsOwn = DesignSystem.ImageView(image = DesignSystem.Image.OBJECTS_OWN),
                startCoeOwn = DesignSystem.Text(text = texts.startCoeOwn, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.DARK),
                toObjects = DesignSystem.Button(title = texts.toObjects, style = DesignSystem.ButtonStyle.PRIMARY, location = Location.Storefront),
                partners = DesignSystem.Text(text = texts.partners, style = DesignSystem.TextStyle.BODY, background = DesignSystem.Background.DARK),
            )

        fun failed(failure: Either<List<Warning>, Throwable>): ViewModel =
            Failed(
                texts = texts,
                navigation = DesignSystem.Navigation.failure,
                meta = meta(texts),
                failure = failure(texts = texts, failure = failure),
            )

        fun failed(message: String): ViewModel =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asReady get() = this as? Ready
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return sceneOf(viewModel.loading())
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.refreshFront()
            .map { (actions, _) ->
                val updated = store.reduce(actions = actions)
                val texts = Texts(
                    welcome = updated.get(media = Key("screen:start"), content = Key("welcome")),
                    tag = updated.get(media = Key("screen:start"), content = Key("tag")),
                    promise = updated.get(media = Key("screen:start"), content = Key("promise")),
                    learn = updated.get(media = Key("screen:start"), content = Key("learn")),
                    title = updated.get(media = Key("screen:start"), content = Key("title")),
                    info = updated.get(media = Key("screen:start"), content = Key("info")),
                    interested = updated.get(media = Key("screen:start"), content = Key("interested")),
                    interestedCta = updated.get(media = Key("screen:start"), content = Key("interestedCta")),
                    social = updated.get(media = Key("screen:start"), content = Key("social")),
                    copyright = updated.get(media = Key("screen:start"), content = Key("copyright")),
                    cookiesTitle = updated.get(media = Key("screen:start"), content = Key("cookiesTitle")),
                    terms = updated.get(media = Key("screen:start"), content = Key("terms")),
                    failureTitle = updated.get(media = Key("screen:start"), content = Key("failureTitle")),
                    ok = updated.get(media = Key("screen:start"), content = Key("ok")),
                    cookiesBody = updated.get(media = Key("screen:start"), content = Key("cookiesBody")),
                    metaTitle = updated.get(media = Key("screen:start"), content = Key("metaTitle")),
                    metaDescription = updated.get(media = Key("screen:start"), content = Key("metaDescription")),
                    articles = updated.get(media = Key("screen:start"), content = Key("articles")),
                    signUp = updated.get(media = Key("screen:start"), content = Key("signUp")),
                    signUpTitle = updated.get(media = Key("screen:start"), content = Key("signUpTitle")),
                    friend = updated.get(media = Key("screen:start"), content = Key("friend")),
                    findFriend = updated.get(media = Key("screen:start"), content = Key("findFriend")),
                    infoTitle = updated.get(media = Key("screen:start"), content = Key("infoTitle")),
                    infoBody = updated.get(media = Key("screen:start"), content = Key("infoBody")),
                    service = updated.get(media = Key("screen:start"), content = Key("service")),
                    calculatorTitle = updated.get(media = Key("screen:start"), content = Key("calculatorTitle")),
                    calculatorBtn = updated.get(media = Key("screen:start"), content = Key("calculatorBtn")),
                    oliviaTalks = updated.get(media = Key("screen:start"), content = Key("oliviaTalks")),
                    priceValue = updated.get(media = Key("screen:start"), content = Key("priceValue")),
                    owners = updated.get(media = Key("screen:start"), content = Key("owners")),
                    weeklyPrice = updated.get(media = Key("screen:start"), content = Key("weeklyPrice")),
                    rentalIncome = updated.get(media = Key("screen:start"), content = Key("rentalIncome")),
                    startCoeOwn = "Kom igång och samäg!",
                    toObjects = "Till objekten",
                    partners = "I samarbete med:",
                )
                // We have to create a new temporary store here so that articles is set

                sceneOf(viewModel.ready(texts, buildItems(updated, viewModel.texts)), actions)
            }
            .failed { scene.failed(result = it) }
    }

    private fun buildItems(store: Store, texts: Texts): List<MessageItem.ViewModel.Article> {
        val all = store.articles?.map {
            MessageItem.article(message = it)
        } ?: emptyList()
        return all.take(3)
    }

    fun saveInLocalStorage(scene: Scene.Input<ViewModel>, cookie: Boolean): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val action = Store.Action.AgreedCookies(cookie = cookie)
        return sceneOf(viewModel.ready(viewModel.texts, buildItems(store, viewModel.texts)), action)
    }
}

