package della8.web.items

import bootstrap.bCard
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.items.RuleItem
import della8.core.screens.RuleValue
import della8.core.support.DesignSystem
import della8.core.support.EditableAdherence
import della8.web.components.*
import della8.web.support.className
import react.*
import techla.base.Identifier

external interface RuleItemAdherenceProps : PropsWithChildren {
    var className: String?
    var viewModel: RuleItem.ViewModel.Adherence
    var onChange: (Identifier<RuleItem>, RuleValue) -> Unit
    var onInfo: (String, Boolean) -> Unit
}


val D8RuleItemAdherence = FC<RuleItemAdherenceProps> { props ->
    fun onStyle(value: DesignSystem.Option) {
        (props.viewModel.value as? RuleValue.AdherenceValue)?.let { adherenceValue ->
            (value.data as? EditableAdherence.Style)?.let {
                props.onChange(props.viewModel.id, RuleValue.AdherenceValue(adherenceValue.value.copy(style = it)))
            }
        }
    }

    fun onOther(value: String) {
        (props.viewModel.value as? RuleValue.AdherenceValue)?.let { adherenceValue ->
            props.onChange(props.viewModel.id, RuleValue.AdherenceValue(adherenceValue.value.copy(description = value)))
        }
    }

    bCard {
        className = className("radius-10 border-0 card-shadow my-3 px-4 py-3")
        bContainer {
            className = className("p-0")
            D8RuleItemHeader {
                viewModel = props.viewModel
                onChange = props.onChange
                onInfo = props.onInfo
            }
            if (props.viewModel.value !is RuleValue.Off) {
                if (props.viewModel.editable) {
                    bRow {
                        className = className("mb-3")
                        d8SelectInput(classNameInput = "d-inline mb-2", design = props.viewModel.edit, onClick = ::onStyle)
                    }
                }
                bRow {
                    if (props.viewModel.editable) {
                        d8TextInput(design = props.viewModel.other, onChange = ::onOther)
                    } else {
                        d8Text(design = props.viewModel.show)
                    }
                }
            }
            D8RuleItemFooter {
                viewModel = props.viewModel
            }
        }
    }
}