package della8.web.items

import bootstrap.bCard
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.items.RuleItem
import della8.core.screens.RuleValue
import della8.core.support.DesignSystem
import della8.web.components.*
import della8.web.support.className
import react.*
import techla.base.Identifier

external interface RuleItemSelectInputProps : PropsWithChildren {
    var className: String?
    var viewModel: RuleItem.ViewModel.SelectInput
    var onChange: (Identifier<RuleItem>, RuleValue) -> Unit
    var onInfo: (String, Boolean) -> Unit
}


val D8RuleItemSelectInput = FC<RuleItemSelectInputProps> { props ->
    fun onChange(value: DesignSystem.Option) {
        props.onChange(props.viewModel.id, RuleValue.OptionValue(value))
    }

    fun onChange(value: String) {
        props.viewModel.edit.options.filterIsInstance<DesignSystem.Option.Other>().firstOrNull()?.let {
            props.onChange(props.viewModel.id, RuleValue.OptionValue(it.copy(other = value)))
        }
    }

    bCard {
        className = className("radius-10 border-0 card-shadow my-3 px-4 py-3")
        bContainer {
            className = className("p-0")
            D8RuleItemHeader {
                viewModel = props.viewModel
                onChange = props.onChange
                onInfo = props.onInfo
            }
            if (props.viewModel.value !is RuleValue.Off) {
                if (props.viewModel.editable) {
                    bRow {
                        d8SelectInput(classNameInput = "d-inline mb-2", design = props.viewModel.edit, onClick = ::onChange)
                    }
                }
                bRow {
                    if (props.viewModel.editable) {
                        d8TextInput(design = props.viewModel.other, onChange = ::onChange)
                    } else {
                        d8Text(design = props.viewModel.show)
                    }
                }
            }
            D8RuleItemFooter {
                viewModel = props.viewModel
            }
        }
    }
}