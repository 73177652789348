package della8.web.views

import StoreContext
import bootstrap.bCol
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.screens.RedeemCodeScreen
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.*
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import techla.base.techla_log

val RedeemCode = FC<PropsWithChildren>("RedeemCode") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(RedeemCodeScreen.ViewModel.None as RedeemCodeScreen.ViewModel)
    val navigate = useNavigate()

    val scopedCall = scopedCall<RedeemCodeScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }
    val call = call<RedeemCodeScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }
    val mainCall = mainCall<RedeemCodeScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is RedeemCodeScreen.ViewModel.None ->
                scopedCall(coroutineScope) { RedeemCodeScreen.start(sceneInputOf(store, viewModel)) }

            is RedeemCodeScreen.ViewModel.Loading ->
                scopedCall(coroutineScope) { RedeemCodeScreen.load(sceneInputOf(store, viewModel)) }

            is RedeemCodeScreen.ViewModel.Ready -> {
            }

            is RedeemCodeScreen.ViewModel.Accepted -> {
            }

            is RedeemCodeScreen.ViewModel.Failed ->
                techla_log("failed ")
        }
    }

    suspend fun handleSubmit() {
        RedeemCodeScreen.check(sceneInputOf(store, viewModel))
            .also { (viewModel, actions) ->
                when (viewModel) {
                    is RedeemCodeScreen.ViewModel.Accepted -> {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }

                    else -> {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }
            }
    }

    fun inputChange(value: String) {
        mainCall { RedeemCodeScreen.update(sceneInputOf(store, viewModel), value) }
    }

    fun recover() {
        mainCall { RedeemCodeScreen.success(sceneInputOf(store, viewModel)) }
    }

    fun logout() {
        mainCall { RedeemCodeScreen.logout(sceneInputOf(store, viewModel)) }
        navigate("/")
    }

    main {
        d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
        if (viewModel is RedeemCodeScreen.ViewModel.Ready) {
            bContainer {
                className = className("px-5 position-relative n-bottom-space n-top-space")
                bRow {
                    className = className("pb-4 justify-content-center")

                    bCol {
                        xs = 12; md = 5
                        d8Image(className = "d-block mx-auto", design = viewModel.image)
                        d8Text(className = "text-center", design = viewModel.title)
                        d8Text(className = "text-center", design = viewModel.body)
                        d8TextInput(
                            classNameInput = "text-center",
                            design = viewModel.code,
                            onChange = ::inputChange
                        )
                        div {
                            className = className("d-grid")
                            d8Button(
                                className = "btn-lg mt-3",
                                design = viewModel.submit,
                                onClick = { handleSubmit() }
                            )
                        }
                    }
                }
            }
        }

        if (viewModel is RedeemCodeScreen.ViewModel.Accepted) {
            Della8.Success {
                design = viewModel.success
                onSuccess = { navigate("/landing") }
            }
        }


        if (viewModel is RedeemCodeScreen.ViewModel.Failed) {
            bContainer {
                className = className("px-5 n-top-space")
                d8failure(viewModel.failure, onLogout = ::logout, onRecover = ::recover)
            }
        }
    }
}

