package della8.web.items

import bootstrap.*
import della8.core.items.MessageItem
import della8.web.components.*
import della8.web.support.className
import react.*
import react.dom.html.ReactHTML.div

external interface MessageItemReactionProps : PropsWithChildren {
    var viewModel: MessageItem.ViewModel.Reaction
}

val MessageItemReaction = FC<MessageItemReactionProps>("MessageItemReaction") { props ->

    div {
        className = className("py-2 py-md-2")
        div {
            className = className("px-2 d-flex align-items-center ")
            d8Element(className = "", design = props.viewModel.initials)
            d8Text(className = "px-1 d-block m-0", design = props.viewModel.author)
        }
        div {
            className = className("px-1 py-2 px-md-4 py-md-2 align-items-stretch")
            div {
                className = className("d-flex flex-column")
                bCard {
                    className = className("radius-25 border-0 card-shadow")

                    bCardBody {
                        className = className("p-0")

                        div {
                            className = className("p-3 d-flex flex-column align-items-center")
                            d8Image(design = props.viewModel.reaction)

                            div {
                                className = className("w-100 pt-1")
                                d8Text(design = props.viewModel.comment)
                                d8Text(className = "d-block m-0", design = props.viewModel.date)
                            }
                        }
                    }
                }
            }
        }
    }
}

fun ChildrenBuilder.messageItemReaction(viewModel: MessageItem.ViewModel.Reaction) =
    MessageItemReaction {
        this.viewModel = viewModel
    }
