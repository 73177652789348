package della8.web.items

import bootstrap.bCard
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.items.RuleItem
import della8.core.screens.RuleValue
import della8.core.support.DesignSystem
import della8.web.components.*
import della8.web.support.className
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import react.useState
import techla.base.Identifier

external interface RuleItemListProps : PropsWithChildren {
    var className: String
    var viewModel: RuleItem.ViewModel.List
    var onChange: (Identifier<RuleItem>, RuleValue) -> Unit
    var onInfo: (String, Boolean) -> Unit
}


val D8RuleItemList = FC<RuleItemListProps> { props ->
    val (new, setNew) = useState<DesignSystem.Option>(DesignSystem.Option.None)

    fun onDelete(item: DesignSystem.ListItem) {
        (props.viewModel.value as? RuleValue.OptionsValue)?.let { existing ->
            val all = existing.value.filterIndexed { index, _ -> index != item.index }
            props.onChange(props.viewModel.id, RuleValue.OptionsValue(all))
        }
    }

    fun onAdd() {
        (props.viewModel.value as? RuleValue.OptionsValue)?.let { existing ->
            val all = existing.value + new
            props.onChange(props.viewModel.id, RuleValue.OptionsValue(all))
        }
    }

    bCard {
        className = className("radius-10 border-0 card-shadow my-3 px-4 py-3")
        bContainer {
            className = className("p-0")
            D8RuleItemHeader {
                viewModel = props.viewModel
                onChange = props.onChange
                onInfo = props.onInfo
            }
            if (props.viewModel.value !is RuleValue.Off) {
                bRow {
                    if (props.viewModel.editable) {
                        div {
                            className = className("w-100 d-flex justify-content-between")
                            d8SelectInput(classNameInput = "d-inline mb-2", design = props.viewModel.edit) { setNew(it) }
                            d8Button(className = "float-end", design = props.viewModel.add, onClick = ::onAdd)
                        }
                        d8ListView(className = "px-0", design = props.viewModel.list, onClick = ::onDelete)
                    } else {
                        d8Text(className = "d-inline mb-2", design = props.viewModel.show)
                    }
                }
            }
            D8RuleItemFooter {
                viewModel = props.viewModel
            }
        }
    }
}