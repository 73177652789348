package della8.core.screens

import della8.core.support.*
import techla.agreement.Agreement
import techla.base.*

object ReservationHelpScreen {
    data class Texts(
        val title: String,
        val back: String,
        val body: String,
        override val progressInfo: String,
        override val failureTitle: String,
    ) : ProgressTexts, FailureTexts {
        companion object
    }


    sealed class ViewModel(open var texts: Texts, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", ""),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, navigation) {
            companion object
        }

        data class Ready(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val body: DesignSystem.Text,
        ) : ViewModel(texts, navigation) {
            companion object
        }

        data class Failed(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, navigation) {
            companion object
        }

        fun loading(texts: Texts) = Loading(
            texts = texts,
            navigation = DesignSystem.Navigation.progress(),
            progress = progress(texts = texts),
        )

        fun ready(texts: Texts) =
            Ready(
                texts = texts,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing),
                title = DesignSystem.Text(
                    text = texts.title,
                    style = DesignSystem.TextStyle.TITLE1,
                ),
                body = DesignSystem.Text(text = texts.body, isMarkdown = true),

                )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false) =
            Failed(
                texts = texts,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val ready get() = this as? Ready
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        return successfulOf(Unit)
            .map {
                val texts = Texts(
                    title = "Della8 informerar",
                    back = "Tillbaka",
                    body = """
            Här kan ni göra era bokningar för ett enkelt och rättvist samägande. Ni har gemensamt kommit överens om vad en kort- respektive långbokning är samt vilka regler som gäller för dessa. Ni har även kommit överens om era gemensamma guldveckor och dessa är utmarkerade i kalendern. Guldveckor är det vi kallar attraktiva veckor som kan vara mer populära och som det är viktigt att ni känner fördelas rättvist. 
            
            Ni kan när som helst ändra i ert avtal, men kom ihåg att det nya avtalet inte gäller förrän alla samägare har signerat på nytt. 
            
            Kom ihåg att ingen bokning är omöjlig. Det går att boka utanför ert regelverk men det är viktigt att ni samägare är överens när det sker en avvikelse. 
            
            In- och utcheckning på en bokning är förinställt på klockan 12.00. Ni kan alltid avtala om en annan tid om det passar er bättre, så länge ni är överens. 
            
            Har ni någon fråga eller feedback får ni gärna maila oss på [hej@della8.se](mailto:hej@della8.se) 
            """.trimIndent(),
                    progressInfo = "Laddar...",
                    failureTitle = "Oj, ett fel har uppstått",

                    )

                sceneOf<ViewModel>(viewModel.loading(texts = texts))
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun load(
        scene: Scene.Input<ViewModel>,
        objectId: Identifier<Object>,
        agreementId: Identifier<Agreement>
    ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.refreshObject(objectId = objectId, agreementId = agreementId)
            .map { (actions, obj) ->

                sceneOf<ViewModel>(viewModel.ready(viewModel.texts), actions)
            }
            .failed { scene.failed(result = it) }
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }
}


