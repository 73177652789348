package della8.core.support

import techla.base.*

typealias ActionOutcome<T> = Outcome<Pair<List<Store.Action>, T>>

fun <T> ActionOutcome<T>.accumulate(action: Store.Action) =
    map { tupleOf(listOf(action) + it.first, it.second) }

fun <T> ActionOutcome<T>.accumulate(actions: List<Store.Action>) =
    map { tupleOf(actions + it.first, it.second) }

fun <T1, T2> ActionOutcome<T1>.accumulate(actions: List<Store.Action>, other: T2) =
    map { tupleOf(actions + it.first, other, it.second) }

fun <T> List<ActionOutcome<T>>.all2(predicate: (T) -> Boolean = { true }): ActionOutcome<List<T>> =
    all { predicate(it.second) }
        .flatMap { list ->
            val actions = list.fold(emptyList<Store.Action>()) { r, e -> r + e.first }
            val values = list.map { it.second }
            successfulOf(tupleOf(actions, values))
        }

fun <T> Outcome<T>.noActions(): ActionOutcome<T> =
    map { emptyList<Store.Action>() to it }

sealed class Scene {
    data class Input<ViewModel>(val store: Store, val viewModel: ViewModel): Scene()
    data class Output<out ViewModel>(val viewModel: ViewModel, val actions: List<Store.Action>): Scene()
}

fun <ViewModel> sceneInputOf(store: Store, viewModel: ViewModel) =
    Scene.Input(store, viewModel)

fun <ViewModel> sceneOf(viewModel: ViewModel, action: Store.Action) =
    Scene.Output(viewModel, listOf(action))

fun <ViewModel> sceneOf(viewModel: ViewModel, actions: List<Store.Action> = emptyList()) =
    Scene.Output(viewModel, actions)

inline fun <ViewModel> Outcome<Scene.Output<ViewModel>>.failed(
    onNotSuccess: (Either<List<Warning>, Throwable>) -> Scene.Output<ViewModel>
): Scene.Output<ViewModel> {
    return when (this) {
        is Outcome.Successful -> output
        is Outcome.Invalid -> onNotSuccess(Either.Left(warnings))
        is Outcome.Failed -> onNotSuccess(Either.Right(exception))
    }
}