package della8.web.items

import bootstrap.bCard
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.items.RuleItem
import della8.core.screens.RuleValue
import della8.core.support.DesignSystem
import della8.web.components.*
import della8.web.support.className
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import react.useState
import techla.base.Identifier

external interface RuleItemDoubleListProps : PropsWithChildren {
    var className: String
    var viewModel: RuleItem.ViewModel.DoubleList
    var onChange: (Identifier<RuleItem>, RuleValue) -> Unit
    var onInfo: (String, Boolean) -> Unit
}


val D8RuleItemDoubleList = FC<RuleItemDoubleListProps> { props ->
    val (first, setFirst) = useState<DesignSystem.Option>(DesignSystem.Option.None)
    val (second, setSecond) = useState<DesignSystem.Option>(DesignSystem.Option.None)

    fun onDelete(item: DesignSystem.ListItem) {
        (props.viewModel.value as? RuleValue.DoubleOptionsValue)?.let { existing ->
            val all = existing.value.filterIndexed { index, _ -> index != item.index }
            props.onChange(props.viewModel.id, RuleValue.DoubleOptionsValue(all))
        }
    }

    fun onAdd() {
        (props.viewModel.value as? RuleValue.DoubleOptionsValue)?.let { existing ->
            val new = first to second
            val all = existing.value + new
            props.onChange(props.viewModel.id, RuleValue.DoubleOptionsValue(all))
        }
    }

    bCard {
        className = className("radius-10 border-0 card-shadow my-3 px-4 py-3")
        bContainer {
            className = className("p-0")
            D8RuleItemHeader {
                viewModel = props.viewModel
                onChange = props.onChange
                onInfo = props.onInfo
            }
            if (props.viewModel.value !is RuleValue.Off) {
                div {
                    className = className("flex-row")
                    if (props.viewModel.editable) {
                        bRow {
                            className = className("row-cols-1 row-cols-md-2 row-cols-lg-2 mb-0 mb-md-1")
                            div {
                                className = className("pt-2")
                                d8SelectInput(
                                    classNameInput = "d-inline mb-2",
                                    design = props.viewModel.edit1,
                                    overrideValue = first
                                ) { setFirst(it) }
                            }
                            div {
                                className = className("pt-2")
                                d8SelectInput(
                                    classNameInput = "d-inline mb-2",
                                    design = props.viewModel.edit2,
                                    overrideValue = second) { setSecond(it) }
                            }
                        }
                        div{
                            className = className("d-flex flex-row mb-3 mt-2")
                            d8Button(className = "ms-auto", design = props.viewModel.add, onClick = ::onAdd)
                        }
                        d8ListView(className = "px-0", design = props.viewModel.list, onClick = ::onDelete)
                    } else {
                        d8Text(className = "d-inline mb-2", design = props.viewModel.show)
                    }
                }
            }
            D8RuleItemFooter {
                viewModel = props.viewModel
            }
        }
    }
}
