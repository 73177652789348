package della8.core.support

import techla.agreement.Term
import techla.base.Address
import techla.base.Amount
import techla.base.Financing

val Financing.displayName
    get() =
        when (this) {
            is Financing.None -> "Ingen"
            is Financing.Transfer -> "Överföring: ${amount.amount} ${amount.currency}, $due, $account"
            is Financing.Other -> description
            else -> ""
        }

val Term.Clause.Financing.displayName
    get() =
        financing.displayName

data class EditableFinancing(
    val style: Style,
    val amount: Pair<String, Double?> = "" to 0.0,
    val due: String = "",
    val account: String = "",
    val description: String = "",
) {
    enum class Style { NONE, TRANSFER, OTHER }

    companion object {
        fun fromTruth(truth: Financing) =
            when (truth) {
                is Financing.None ->
                    EditableFinancing(
                        style = Style.NONE,
                    )
                is Financing.Transfer ->
                    EditableFinancing(
                        style = Style.TRANSFER,
                        amount = truth.amount.amount.toString() to truth.amount.amount,
                        due = truth.due,
                        account = truth.account,
                    )
                is Financing.Other ->
                    EditableFinancing(
                        style = Style.OTHER,
                        description = truth.description
                    )
                else ->
                    EditableFinancing(
                        style = Style.OTHER,
                        description = ""
                    )
            }
    }

    val truth
        get() =
            when {
                style == Style.NONE -> Financing.None
                style == Style.OTHER -> Financing.Other(description = description)
                style == Style.TRANSFER && amount.second != null -> Financing.Transfer(amount = Amount(amount = amount.second!!, currency = "SEK"), due = due, account = account)
                else -> null
            }
}

val EditableFinancing.Style.option
    get() =
        when (this) {
            EditableFinancing.Style.NONE -> DesignSystem.Option.item(title = "Ingen", value = name, data = this)
            EditableFinancing.Style.TRANSFER -> DesignSystem.Option.item(title = "Överföring", value = name, data = this)
            EditableFinancing.Style.OTHER -> DesignSystem.Option.other(title = "Annat", value = name, data = this)
        }
