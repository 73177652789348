package della8.web.components

import bootstrap.bContainer
import della8.core.support.DesignSystem
import della8.web.support.className
import react.ChildrenBuilder
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.hr

external interface D8ListViewProps : PropsWithChildren {
    var className: String
    var design: DesignSystem.ListView
    var onClick: suspend (DesignSystem.ListItem) -> Unit
}

val D8ListView = FC<D8ListViewProps>("D8ListViewProps ") { props ->
    if (props.design.visible) {
        bContainer {
            className = className(listOfNotNull(props.className))
            props.design.title
            props.design.items?.map { item ->
                div {
                    className = className("d-flex justify-content-between")
                    item.text?.let { d8Text(className = "mt-3", design = it) }
                    item.text2?.let { d8Text(className = "mt-3 ms-auto d-inline", design = it) }
                    item.action?.let { d8Button(design = it, onClick = { props.onClick(item) }) }
                }
                hr {
                    className = className("mt-0 mb-0")
                }
            }
        }
    }
}

fun ChildrenBuilder.d8ListView(
    className: String = "",
    design: DesignSystem.ListView,
    onClick: suspend (DesignSystem.ListItem) -> Unit,
) = D8ListView {
    this.className = className
    this.design = design
    this.onClick = onClick
}