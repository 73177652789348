package della8.core.support

import della8.core.services.*
import techla.base.*
import kotlin.time.ExperimentalTime

suspend fun Store.refreshFront(): ActionOutcome<Unit> =
    successfulOf(true).noActions()
        .flatMap { (actions, _) ->
            if (applicationToken != null) return@flatMap successfulOf(Unit).noActions()
            reduce(actions).createApplicationAuthentication()
                .accumulate(actions)
        }
        .flatMap { (actions, _) ->
            if (medias != null) return@flatMap successfulOf(Unit).noActions()
            reduce(actions).findMedias()
                .accumulate(actions)
                .map { it.first to Unit }
        }
       /* .flatMap { (actions, _) ->
            if (articles != null) return@flatMap successfulOf(Unit).noActions()
            reduce(actions).listMessages()
                .accumulate(actions)
                .map { it.first to Unit }
        }
        */
       /* .flatMap { (actions, _) ->
            if (shortLinks != null) return@flatMap successfulOf(Unit).noActions()
            reduce(actions).listShortLinks()
                .accumulate(actions)
                .map { it.first to Unit }
        }
        */