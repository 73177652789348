package della8.web.support

import StoreContext
import csstype.ClassName
import della8.core.support.Scene
import della8.core.support.sceneInputOf
import kotlinx.coroutines.*
import react.*
import techla.base.techla_log
import kotlin.reflect.KProperty

data class JobDelegate(
    private val requestCoroutineScopeFromState: () -> CoroutineScope
) {
    operator fun getValue(nothing: Nothing?, property: KProperty<*>): CoroutineScope =
        requestCoroutineScopeFromState()
}

fun useReactScope(): JobDelegate {
    var reactJob: Job? by useState(null)

    useEffectOnce {
        techla_log("REACT SCOPE: CREATE")
        reactJob = SupervisorJob()
        cleanup {
            techla_log("REACT SCOPE: CANCEL")
            reactJob?.cancel()
            reactJob = null
        }
    }

    return JobDelegate {
        if (reactJob != null)
            CoroutineScope(reactJob!! + Dispatchers.Main.immediate)
        else {
            techla_log("REACT SCOPE: Job is null, falling back on MainScope...")
            MainScope()
        }
    }
}

class Executor<T>(
    val coroutineScope: CoroutineScope,
    val dispatch: (Scene.Output<T>) -> Unit,
) {
    fun call(call: suspend () -> Scene.Output<T>) {
        coroutineScope.launch {
            dispatch(call())
        }
    }

    fun call(call: suspend () -> Scene.Output<T>, effect: (T) -> Unit) {
        coroutineScope.launch {
            val scene = call()
            dispatch(scene)
            effect(scene.viewModel)
        }
    }
}

data class ExecutorDelegate<T>(
    private val requestExecutor: () -> Executor<T>
) {
    operator fun getValue(nothing: Nothing?, property: KProperty<*>): Executor<T> =
        requestExecutor()
}

fun <T> useExecutor(dispatch: (Scene.Output<T>) -> Unit): ExecutorDelegate<T> {
    val reactScope by useReactScope()
    return ExecutorDelegate { Executor(coroutineScope = reactScope, dispatch = dispatch) }
}


fun <ViewModel> scopedCall(assign: (Scene.Output<ViewModel>) -> Unit): suspend (CoroutineScope, suspend () -> Scene.Output<ViewModel>) -> Unit =
    { coroutineScope, call ->
        call().let {
            if (coroutineScope.isActive) assign(it)
        }
    }


fun <ViewModel> call(assign: (Scene.Output<ViewModel>) -> Unit): suspend (suspend () -> Scene.Output<ViewModel>) -> Unit =
    { call ->
        assign(call())
    }


fun <ViewModel> mainCall(assign: (Scene.Output<ViewModel>) -> Unit): (suspend () -> Scene.Output<ViewModel>) -> Unit =
    { call ->
        MainScope().launch {
            call().let {
                if (isActive) assign(it)
            }
        }
    }


fun ChildrenBuilder.useAsyncEffect(vararg dependencies: Any?, effect: suspend (coroutineScope: CoroutineScope) -> Unit) {
    useEffect(dependencies) {
        val job = MainScope().launch {
            effect(this)
        }
        cleanup {
            job.cancel()
        }
    }
}


fun ChildrenBuilder.useDelayedAsyncEffect(timeMillis: Long, vararg dependencies: Any?, effect: suspend (coroutineScope: CoroutineScope) -> Unit) {
    useEffect(dependencies) {
        val job = MainScope().launch {
            delay(timeMillis)
            if (isActive) {
                effect(this)
            }
        }
        cleanup {
            job.cancel()
        }
    }
}


fun <T> ChildrenBuilder.useSceneEffect(viewModel: T, setViewModel: StateSetter<T>, effect: suspend (Scene.Input<T>) -> Scene.Output<T>?) {
    val (context, dispatch) = useContext(StoreContext)
    useAsyncEffect(viewModel) { coroutineScope ->
        effect(sceneInputOf(context, viewModel))?.also { (viewModel, actions) ->
            if (coroutineScope.isActive) {
                actions.run { dispatch(this) }
                viewModel.run { setViewModel(this) }
            }
        }
    }
}


fun <T> ChildrenBuilder.useDelayedSceneEffect(viewModel: T, setViewModel: StateSetter<T>, timeMillis: Long, effect: suspend (Scene.Input<T>) -> Scene.Output<T>?) {
    val (context, dispatch) = useContext(StoreContext)
    useDelayedAsyncEffect(timeMillis = timeMillis, viewModel) { coroutineScope ->
        effect(sceneInputOf(context, viewModel))?.also { (viewModel, actions) ->
            if (coroutineScope.isActive) {
                actions.run { dispatch(this) }
                viewModel.run { setViewModel(this) }
            }
        }
    }
}

fun className(classNames: List<String>): ClassName {
    return ClassName(classNames.joinToString(" "))
}

fun className(vararg className: String?): ClassName {
    return ClassName(className.filterNotNull().joinToString(" "))
}
