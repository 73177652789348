package della8.core.support

import techla.agreement.Term
import techla.base.Adherence

val Adherence.displayName
    get() =
        when (this) {
            is Adherence.AccordingToLaw -> "Ska ske enligt lag och då kan samägaren begära det när som helst. Då sker det via auktion."
            is Adherence.Derogation -> "Vi vill avtala bort denna helt"
            is Adherence.Other -> description
            else -> ""
        }

val Term.Clause.PublicAuction.displayName
    get() =
        adherence.displayName

data class EditableAdherence(
    val style: Style,
    val description: String = "",
) {
    enum class Style { ACCORDING_TO_LAW, DEROGATION, OTHER }

    companion object {
        fun fromTruth(truth: Adherence) =
            when (truth) {
                is Adherence.AccordingToLaw ->
                    EditableAdherence(
                        style = Style.ACCORDING_TO_LAW,
                    )
                is Adherence.Derogation ->
                    EditableAdherence(
                        style = Style.DEROGATION,
                    )
                is Adherence.Other ->
                    EditableAdherence(
                        style = Style.OTHER,
                        description = truth.description
                    )
                else ->
                    EditableAdherence(
                        style = Style.OTHER,
                        description = ""
                    )
            }
    }

    val truth
        get() =
            when {
                style == Style.ACCORDING_TO_LAW -> Adherence.AccordingToLaw
                style == Style.DEROGATION -> Adherence.Derogation
                style == Style.OTHER -> Adherence.Other(description = description)
                else -> null
            }
}

val EditableAdherence.Style.option
    get() =
        when (this) {
            EditableAdherence.Style.ACCORDING_TO_LAW -> DesignSystem.Option.item(title = Adherence.AccordingToLaw.displayName, value = name, data = this)
            EditableAdherence.Style.DEROGATION -> DesignSystem.Option.item(title = Adherence.Derogation.displayName, value = name, data = this)
            EditableAdherence.Style.OTHER -> DesignSystem.Option.other(title = "Annat", value = name, data = this)
        }
