package della8.web.components

import della8.core.support.DesignSystem
import della8.core.support.Location
import della8.web.support.className
import della8.web.theme.Design
import della8.web.theme.Design.imageAltText
import kotlinx.browser.window
import react.*
import react.dom.aria.ariaCurrent
import react.dom.aria.ariaHidden
import react.dom.aria.ariaLabel
import react.dom.html.ButtonType
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.span
import react.router.useNavigate

external interface D8ImageProps : PropsWithChildren {
    var className: String?
    var design: DesignSystem.ImageView
}

val D8Image = FC<D8ImageProps>("D8Image") { props ->
    val navigate = useNavigate()

    fun onClick() {
        props.design.location?.let {
            when (it) {
                is Location.External -> window.open(url = it.href, target = "_blank")
                else -> navigate(it.route)
            }
        }
    }

    if (props.design.visible) {
        val it = when {
            props.design.href != null -> props.design.href
            props.design.image != null -> Design.image(props.design.image)
            else -> null
        }
        if (it != null) {
            img {
                className = className(props.className)
                src = it
                alt = props.design.alt ?: imageAltText(props.design.image)
                onClick = { onClick() }
            }
        }
    }
}

fun ChildrenBuilder.d8Image(design: DesignSystem.ImageView, className: String? = null) =
    D8Image {
        this.className = className
        this.design = design
    }


external interface D8IconProps : PropsWithChildren {
    var className: String?
    var design: DesignSystem.Icon
}

val D8Icon = FC<D8IconProps>("D8Icon") { props ->
    when (props.design.visible) {
        true -> {
            val it = when {
                props.design.image != null -> Design.image(props.design.image)
                else -> null
            }
            img {
                className = className(
                    listOfNotNull(props.className)
                )
                src = it
            }
        }

        else -> {
        }
    }
}

fun ChildrenBuilder.d8Icon(design: DesignSystem.Icon, className: String? = null) =
    D8Icon {
        this.className = className
        this.design = design
    }


external interface D8CarouselProps : PropsWithChildren {
    var className: String?
    var design: DesignSystem.Carousel
}

val D8Carousel = FC<D8CarouselProps>("D8Carousel") { props ->
    val (selectedImage, setSelectedImage) = useState(0)
    val design = props.design
    if (design.visible) {
        div {
            id = "carouselExampleSlidesOnly"
            className = className("carousel slide")
            asDynamic()["data-bs-ride"] = true

            if (design.images.size != 1)
                div {
                    className = className("carousel-indicators")
                    List(design.images.size) { index ->
                        val active = if (index == selectedImage) "active" else ""
                        button {
                            className = className(active)
                            type = ButtonType.button
                            ariaLabel = "Slide ${index + selectedImage}"
                            ariaCurrent = ariaCurrent

                            asDynamic()["data-bs-slide-to"] = index.toString()
                            asDynamic()["data-bs-target"] = "#carousel"
                            onClick = { setSelectedImage(index) }
                        }
                    }
                }
            div {
                className = className("carousel-inner")
                design.images.mapIndexed { index, image ->
                    val active = if (index == selectedImage) "carousel-item box box2 active" else "carousel-item box box2"
                    div {
                        className = className(active)
                        d8Image(className = "img-fluid radius-25", design = image)
                    }
                }
            }
            if (design.images.size != 1) {
                button {
                    className = className("carousel-control-prev")
                    type = ButtonType.button
                    ariaLabel = "Föregående"
                    asDynamic()["data-bs-slide"] = "prev"
                    asDynamic()["data-bs-target"] = "#carousel"
                    onClick = { if (selectedImage == 0) setSelectedImage(design.images.size - 1) else setSelectedImage(selectedImage - 1) }
                    span {
                        className = className("carousel-control-prev-icon")
                        ariaHidden = true
                    }
                    span {
                        className = className("visually-hidden")
                        +"Previous"
                    }
                }
                button {
                    className = className("carousel-control-next")
                    type = ButtonType.button
                    ariaLabel = "Nästa"
                    asDynamic()["data-bs-slide"] = "next"
                    asDynamic()["data-bs-target"] = "#carousel"
                    onClick = { if (selectedImage == (design.images.size - 1)) setSelectedImage(0) else setSelectedImage(selectedImage + 1) }
                    span {
                        className = className("carousel-control-next-icon")
                        ariaHidden = true
                    }
                    span {
                        className = className("visually-hidden")
                        +"Next"
                    }
                }
            }
        }
    }
}