package della8.web.admin

import StoreContext
import bootstrap.bCard
import bootstrap.bCol
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.admin.AdvancedScreen
import della8.core.support.ClippyTexts
import della8.core.support.DesignSystem
import della8.core.support.DesignSystem.None.visible
import della8.core.support.clippy
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.className
import della8.web.support.standardNavigation
import della8.web.support.useAsyncEffect
import della8.web.theme.Design
import kotlinx.coroutines.isActive
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.useContext
import react.useState
import techla.agreement.Agreement
import kotlin.enums.enumEntries

val D8Advanced = FC<PropsWithChildren>("ADVANCED") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(AdvancedScreen.ViewModel.None as AdvancedScreen.ViewModel)
    val navigate = useNavigate()
    val images = listOf(
        "ARTICLE_LOGO",
        "ARRIVAL",
        "BACK",
        "BACK_WHITE",
        "BACKGROUND",
        "BANK_ID",
        "CHECKED",
        "CHECK",
        "INVITE",
        "LOGO",
        "LOGO_LIGHT",
        "CODE",
        "DEPARTURE",
        "NEXT",
        "PREVIOUS",
        "SETTINGS",
        "SHOP",
        "START",
        "INVITES",
        "COPY",
        "AGREEMENT",
        "NO_AGREEMENT",
        "AGREEMENT_SM",
        "BOOKED_OTHER",
        "BOOKED_ME",
        "LOCKED",
        "COMMON",
        "RULEBASED",
        "DOWNLOAD",
        "VERSION",
        "INFO",
        "RULES",
        "CALENDAR",
        "GOLD",
        "BLOCK_BOOKING",
        "BLOCK_APPROVE",
        "BLOCK_CONNECTED",
        "BLOCK_INVITE",
        "BLOCK_OWNERSHIP",
        "TRASH",
        "RELATED",
        "CROWN",
        "GAME_RULES",
        "HAPPINESS",
        "COPIED",
        "FAILURE",
        "LINKEDIN",
        "INSTAGRAM",
        "ARROWSCROLLDOWN",
        "LAUNCH",
        "MOTOR_HOME",
        "HOLIDAY_HOME",
        "BOOKING_GOLD",
        "BOOKING_LOCK",
        "BOOKING_TODAY",
        "TIP",
        "NOOBJECT",
        "BANNERTESTPILOTS",
        "BOOKING_GOLD_LOCKED",
        "RULE_ON_BTN",
        "RULE_OFF_BTN",
        "RULE_ON_BLACK",
        "RULE_OFF_BLACK",
        "OTHER_OBJECT",
        "PRESENT_AGREEMENT",
        "OTHER_OBJECT_XL",
        "HOLIDAY_HOME_XL",
        "PRESENT_AGREEMENT_XL",
        "MOTOR_HOME_XL",
        "UPDATES",
        "AGREEMENT_UPDATES",
        "HIGHLIGHT",
        "CONTRACT_DRAFT",
        "STEP1",
        "STEP2",
        "STEP3",
        "STEP4",
        "STEP5",
        "STEP6",
        "STEP7",
        "STEP8",
        "STEP9",
        "STEP10",
        "FEED",
        "FEED_EDIT",
        "FEED_LIKE_FILLED",
        "FEED_LIKE_OUTLINED",
        "FEED_REACTIONS",
        "FEED_UPDATES",
        "VOTE",
        "VOTE_SUCCESS",
        "CLIPPY",
        "CALCULATOR_BOAT_CHEAP",
        "CALCULATOR_BOAT_EXPENSIVE",
        "CALCULATOR_HOLIDAYHOME_CHEAP",
        "CALCULATOR_HOLIDAYHOME_EXPENSIVE",
        "CALCULATOR_MOTORHOME_EXPENSIVE",
        "CALCULATOR_MOTORHOME_CHEAP",
        "CALCULATOR_OWN_SETTINGS",
        "DELLA8_EMELIE",
        "DELLA8_MAGNUS",
        "DELLA8_OLIVIA",
        "DELLA8_MICKE",
        "DELLA8_JOHANNA",
        "DELLA8_NIKLAS",
        "DELLA8_NAOMI",
        "DELLA_TOOL",
        "WHY_DELLA8_BANNER",
        "WHY_DELLA8_PRICE",
        "WHY_DELLA8_STARS",
        "MATCH",
        "PLAY",
        "CLOSE",
        "MOCK",
        "PRICEHINT",
        "RESUSR",
        "OBJECTS_TO_COWN",
        "LOGO_BENGT",
        "LOGO_RESURS",
        "LOGO_PROJECT_WINTER_VILLAGE",
        "OBJECTS_OWN",
        "VIDEO",
        "DELLA_AVATAR_HAPPY",
        "DELLA_AVATAR_GREETING",
        "EMPTY_CONTRACT",
        "YACHT_HOME",
    )


    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is AdvancedScreen.ViewModel.None ->
                AdvancedScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }

            is AdvancedScreen.ViewModel.Loading -> {
                AdvancedScreen.load(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }
            }

            else -> {
            }
        }
    }

    suspend fun onAction() {
        AdvancedScreen.convertPercentage(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    main {
        d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
        if (viewModel is AdvancedScreen.ViewModel.Ready) {
            bContainer {
                className = className("px-5 position-relative n-bottom-space n-top-space")
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        xs = 12; md = 7
                        bCard {
                            className = className("radius-10 border-0 card-shadow my-3 px-4 py-3")
                            bContainer {
                                bRow {
                                    d8Button(design = viewModel.action, onClick = ::onAction)
                                }
                            }
                        }

                        bCard {
                            className = className("radius-10 border-0 card-shadow my-3 px-4 py-3")
                            bContainer {
                                bRow {
                                    images.map { image ->
                                        d8Text(className = "mt-5", design = DesignSystem.Text(text = "Alt text:  ${Design.imageAltText(DesignSystem.Image.valueOf(image))}", alignment = DesignSystem.TextAlignment.CENTER))
                                        d8Text(className = "", design = DesignSystem.Text(text = "Bild är döpt till:  $image", alignment = DesignSystem.TextAlignment.CENTER))
                                        d8Image(className = "mb-5", design = DesignSystem.ImageView(image = DesignSystem.Image.valueOf(image), alt = Design.imageAltText(DesignSystem.Image.valueOf(image))))

                                    }
                                }
                            }
                        }



                        D8Clippy {
                            this.design = DesignSystem.Clippy(
                                visible = true,
                                title = DesignSystem.Text(text = "Olivia hjälper dig komma igång", style = DesignSystem.TextStyle.HEADLINE, background = DesignSystem.Background.DARK),
                                image = DesignSystem.ImageView(image = DesignSystem.Image.DELLA_AVATAR_HAPPY),
                                minimize = DesignSystem.Button(style = DesignSystem.ButtonStyle.IMAGE, image = DesignSystem.Image.MINIMIZE, background = DesignSystem.Color.CLEAR),
                                maximize = DesignSystem.Button(style = DesignSystem.ButtonStyle.IMAGE, image = DesignSystem.Image.DELLA_INFO, background = DesignSystem.Color.CLEAR)
                            )
                            className = "n-clippy-bottom"
                        }
                    }

                    if (viewModel is AdvancedScreen.ViewModel.Failed) {
                        bContainer {
                            className = className("px-5 n-top-space")
                            d8failure(viewModel.failure)
                        }
                    }
                }
            }
        }
    }
}