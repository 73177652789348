package bootstrap

import csstype.ClassName
import react.*

@JsNonModule
@JsModule("react-bootstrap/DropdownButton")
private external val dropdownButtonModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bDropdownButton: ComponentType<DropdownButtonProps> = dropdownButtonModule.default

external interface DropdownButtonProps : PropsWithChildren {
    var className: ClassName
    var title: String // ReactElement
    var drop: String // 'up', 'down', 'start', 'end'
    var variant: String
}
