package bootstrap

import csstype.ClassName
import org.w3c.dom.events.Event
import react.*

@JsNonModule
@JsModule("react-bootstrap/Dropdown")
private external val dropdownModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bDropdown: ComponentType<DropdownProps> = dropdownModule.default

external interface DropdownProps : PropsWithChildren {
    var `as`: dynamic /* 'input' | elementType */
    var id: String
    var active: Boolean
    var block: Boolean
    var disabled: Boolean
    var href: String
    var size: String /* 'sm' | 'lg' */
    var target: Any
    var type: String? /* 'button' | 'reset' | 'submit' | null */
    var variant: String
    var bsPrefix: String
    var childBsPrefix: String
    var className: ClassName
    var eventKey: String

    var onSelect: ((event: Event) -> Unit)
}
/*
class DropdownElementBuilder internal constructor(
    private val type: ComponentType<DropdownProps>, props: DropdownProps = jsObject { }
) : RElementBuilderImpl<DropdownProps>(props) {

    fun create() = createElement(type, attrs, *childList.toTypedArray())
}

fun RBuilder.bDropdown(
    id: String? = null,
    bsPrefix: String? = null,
    childBsPrefix: String? = null,
    disabled: Boolean? = null,
    active: Boolean? = null,
    onSelect: ((event: Event) -> Unit)? = null,
    eventKey: String? = null,
    href: String? = null,
    block: DropdownElementBuilder.() -> Unit = {},

    ) =
    child(DropdownElementBuilder(dropdownComponent)
        .apply {
            id?.let { attrs.id = it }
            bsPrefix?.let { attrs.bsPrefix = it }
            childBsPrefix?.let { attrs.bsPrefix = it }
            disabled?.let { attrs.disabled = it }
            active?.let { attrs.active = it }
            onSelect?.let { attrs.onSelect = it }
            eventKey?.let { attrs.eventKey = it }
            href?.let { attrs.href = it }
            block()
        }.create()
    )

fun <T: Any> RBuilder.bDropdown(
    id: String? = null,
    bsPrefix: String? = null,
    childBsPrefix: String? = null,
    disabled: Boolean? = null,
    active: Boolean? = null,
    onClick: ((event: Event) -> Unit)? = null,
    eventKey: String? = null,
    href: String? = null,
    block: DropdownElementBuilder.() -> Unit = {},
) =
    child(DropdownElementBuilder(dropdownComponent)
        .apply {
            id?.let { attrs.id = it }
            bsPrefix?.let { attrs.bsPrefix = it }
            childBsPrefix?.let { attrs.bsPrefix = it }
            disabled?.let { attrs.disabled = it }
            active?.let { attrs.active = it }
            onClick?.let { attrs.onSelect = it }
            eventKey?.let { attrs.eventKey = it }
            href?.let { attrs.href = it }
            block()
        }.create()
    )
*/