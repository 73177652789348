package della8.core.admin

import della8.core.services.editAllTerm
import della8.core.services.listAllTerms
import della8.core.support.*
import techla.agreement.Term
import techla.base.*

object AdvancedScreen {
    data class Texts(
        val back: String,
    ) {
        companion object
    }

    sealed class ViewModel(open var texts: Texts, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts(""),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, navigation) {
            companion object
        }

        data class Ready(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val action: DesignSystem.Button,
            val footer: DesignSystem.Footer,
        ) : ViewModel(texts, navigation) {
            companion object
        }

        data class Failed(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, navigation) {
            companion object
        }

        fun loading(): ViewModel =
            Loading(texts = texts, navigation = navigation)

        fun ready(texts: Texts): ViewModel =
            Ready(
                texts = texts,
                navigation = DesignSystem.Navigation.backLight(
                    title = texts.back,
                    location = Location.Landing
                ),
                action = DesignSystem.Button(title = "Tryck inte på denna", visible = false),
                footer = DesignSystem.Footer.front(),
            )

        fun failed(message: String): ViewModel =
            Failed(
                texts = texts,
                failure = DesignSystem.Failure(
                    title = DesignSystem.Text(text = "Något gick fel", style = DesignSystem.TextStyle.TITLE1),
                    details = DesignSystem.Text(text = message, style = DesignSystem.TextStyle.BODY)
                ),
                navigation = DesignSystem.Navigation.failure,
            )

        fun failed(result: Either<List<Warning>, Throwable>): ViewModel =
            failed(
                message = when (result) {
                    is Either.Left -> "(${result.value.joinToString(", ") { it.message }})"
                    is Either.Right -> "(${result.value.message})"
                }
            )

        val asLoad get() = this as? Loading
        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return sceneOf(viewModel.loading())
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return successfulOf(1).noActions()
            .map { (actions, _) ->
                val updated = store.reduce(actions = actions)
                val texts = Texts(
                    back = updated.get(media = Key("screen:policy"), content = Key("back")),
                )
                sceneOf(viewModel.ready(texts = texts))
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun convertPercentage(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.listAllTerms(agreementId = Identifier(""))
            .map { (actions, terms) ->
                val updated = store.reduce(actions = actions)
                val texts = Texts(
                    back = updated.get(media = Key("screen:policy"), content = Key("back")),
                )
                terms.forEach { term ->
                    val ownerships = term.clauses.filterIsInstance<Term.Clause.Ownership>()
                    val other = when {
                        ownerships.isEmpty() -> null
                        ownerships.first().ownership is Ownership.Other -> null
                        else -> Term.Clause.Ownership(Ownership.Other(description = ownerships.displayName))
                    }
                    if (other != null) {
                        val existing = term.clauses.filter { it !is Term.Clause.Ownership }
                        val edit = Term.Edit(clauses = modifiedOf(existing + other))
                        store.editAllTerm(id = term.id, edit = edit)
                        techla_log("EDIT: $edit")
                    }
                }
                sceneOf(viewModel.ready(texts = texts))
            }
            .failed { scene.failed(result = it) }
    }
}