package della8.web.support

import della8.core.support.DesignSystem
import della8.core.support.Store
import kotlinx.browser.window
import react.router.NavigateFunction

fun standardNavigation(navigate: NavigateFunction, store: Store? = null): (DesignSystem.Action) -> Unit =
    { action ->
        when (action) {
            DesignSystem.Action.NONE -> {}
            DesignSystem.Action.BACK -> navigate(-1)
            DesignSystem.Action.NEXT -> {}
            DesignSystem.Action.PREVIOUS -> {}
            DesignSystem.Action.LOGOUT -> {}
            DesignSystem.Action.DELLA8_HOME -> {
                store?.deployment?.della8Home?.let { window.location.assign(it) }
            }

            DesignSystem.Action.PRESS_KIT -> {
                window.open(
                    "https://storage.googleapis.com/techla-4ababd96-f873-4ba0-9bca-800870c9f601/della8/della8_presskit.zip"
                )
            }

            else -> {}
        }
    }

fun externalNavigation(link: DesignSystem.Action?): String =
    when (link) {
        DesignSystem.Action.LINKEDIN -> "https://www.linkedin.com/company/della8/"
        DesignSystem.Action.INSTAGRAM -> "https://www.instagram.com/della8.se/"
        DesignSystem.Action.CONTACT -> "mailto:hej@della8.se"
        DesignSystem.Action.PRESS_KIT -> {
            "https://storage.googleapis.com/techla-4ababd96-f873-4ba0-9bca-800870c9f601/della8/della8_presskit.zip"
        }

        else -> ""
    }
