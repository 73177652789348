package della8.core.items

import della8.core.support.DesignSystem
import techla.base.Identifier

object ModuleItem {
    sealed class ViewModel(open val id: Identifier<ModuleItem>, open val disabled: Boolean) {
        data class PartnershipStart(
            override val id: Identifier<ModuleItem>,
            override val disabled: Boolean,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val more: DesignSystem.Button,
        ) : ViewModel(id = id, disabled = disabled) {
            companion object
        }

        data class PartnershipPending(
            override val id: Identifier<ModuleItem>,
            override val disabled: Boolean,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val more: DesignSystem.Button,
        ) : ViewModel(id = id, disabled = disabled) {
            companion object
        }

        data class Partnership(
            override val id: Identifier<ModuleItem>,
            override val disabled: Boolean,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val more: DesignSystem.Button,
        ) : ViewModel(id = id, disabled = disabled) {
            companion object
        }

        data class BookingStart(
            override val id: Identifier<ModuleItem>,
            override val disabled: Boolean,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val more: DesignSystem.Button,
        ) : ViewModel(id = id, disabled = disabled) {
            companion object
        }

        data class BookingPending(
            override val id: Identifier<ModuleItem>,
            override val disabled: Boolean,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val more: DesignSystem.Button,
        ) : ViewModel(id = id, disabled = disabled) {
            companion object
        }

        data class Booking(
            override val id: Identifier<ModuleItem>,
            override val disabled: Boolean,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val more: DesignSystem.Button,
        ) : ViewModel(id = id, disabled = disabled) {
            companion object
        }

        data class Approve(
            override val id: Identifier<ModuleItem>,
            override val disabled: Boolean,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val more: DesignSystem.Button,
        ) : ViewModel(id = id, disabled = disabled) {
            companion object
        }

        data class Invite(
            override val id: Identifier<ModuleItem>,
            override val disabled: Boolean,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val more: DesignSystem.Button,
        ) : ViewModel(id = id, disabled = disabled) {
            companion object
        }

        data class Status(
            override val id: Identifier<ModuleItem>,
            override val disabled: Boolean,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val approved: DesignSystem.Text,
            val pending: DesignSystem.Text,
            val approvedList: List<DesignSystem.Profile>,
            val pendingList: List<DesignSystem.Profile>,
            val more: DesignSystem.Button,
        ) : ViewModel(id = id, disabled = disabled) {
            companion object
        }
    }

    fun partnershipStart(id: Identifier<ModuleItem>, image: DesignSystem.Image, title: String, info: String, disabled: Boolean) =
        ViewModel.PartnershipStart(
            id = id,
            disabled = disabled,
            image = DesignSystem.ImageView(image = image),
            title = DesignSystem.Text(text = title, style = DesignSystem.TextStyle.HEADLINE),
            info = DesignSystem.Text(text = info, style = DesignSystem.TextStyle.BODY),
            more = DesignSystem.Button(title = "Ställ in avtalsregler - Samägande", disabled = disabled),
        )

    fun partnershipPending(id: Identifier<ModuleItem>, image: DesignSystem.Image, title: String, info: String, disabled: Boolean) =
        ViewModel.PartnershipPending(
            id = id,
            disabled = disabled,
            image = DesignSystem.ImageView(image = image),
            title = DesignSystem.Text(text = title, style = DesignSystem.TextStyle.HEADLINE),
            info = DesignSystem.Text(text = info, style = DesignSystem.TextStyle.BODY),
            more = DesignSystem.Button(title = "Visa avtalsregler - Samägande", disabled = disabled),
        )

    fun partnership(id: Identifier<ModuleItem>, image: DesignSystem.Image, title: String, info: String, disabled: Boolean) =
        ViewModel.Partnership(
            id = id,
            disabled = disabled,
            image = DesignSystem.ImageView(image = image),
            title = DesignSystem.Text(text = title, style = DesignSystem.TextStyle.HEADLINE),
            info = DesignSystem.Text(text = info, style = DesignSystem.TextStyle.BODY),
            more = DesignSystem.Button(title = "Till samägande", disabled = disabled),
        )

    fun bookingStart(id: Identifier<ModuleItem>, image: DesignSystem.Image, title: String, info: String, disabled: Boolean) =
        ViewModel.BookingStart(
            id = id,
            disabled = disabled,
            image = DesignSystem.ImageView(image = image),
            title = DesignSystem.Text(text = title, style = DesignSystem.TextStyle.HEADLINE),
            info = DesignSystem.Text(text = info, style = DesignSystem.TextStyle.BODY),
            more = DesignSystem.Button(title = "Ställ in avtalsregler - Bokning", disabled = disabled),
        )

    fun bookingPending(id: Identifier<ModuleItem>, image: DesignSystem.Image, title: String, info: String, disabled: Boolean) =
        ViewModel.BookingPending(
            id = id,
            disabled = disabled,
            image = DesignSystem.ImageView(image = image),
            title = DesignSystem.Text(text = title, style = DesignSystem.TextStyle.HEADLINE),
            info = DesignSystem.Text(text = info, style = DesignSystem.TextStyle.BODY),
            more = DesignSystem.Button(title = "Visa avtalsregler - Bokning", disabled = disabled),
        )

    fun booking(id: Identifier<ModuleItem>, image: DesignSystem.Image, title: String, info: String,disabled: Boolean) =
        ViewModel.Booking(
            id = id,
            disabled = disabled,
            image = DesignSystem.ImageView(image = image),
            title = DesignSystem.Text(text = title, style = DesignSystem.TextStyle.HEADLINE),
            info = DesignSystem.Text(text = info, style = DesignSystem.TextStyle.BODY),
            more = DesignSystem.Button(title = "Till bokning", disabled = disabled),
        )

    fun approve(id: Identifier<ModuleItem>, image: DesignSystem.Image, title: String, info: String, disabled: Boolean) =
        ViewModel.Approve(
            id = id,
            disabled = disabled,
            image = DesignSystem.ImageView(image = image),
            title = DesignSystem.Text(text = title, style = DesignSystem.TextStyle.HEADLINE),
            info = DesignSystem.Text(text = info, style = DesignSystem.TextStyle.BODY),
            more = DesignSystem.Button(title = "Till avtalet", disabled = disabled),
        )

    fun invite(id: Identifier<ModuleItem>, image: DesignSystem.Image, title: String, info: String, disabled: Boolean) =
        ViewModel.Invite(
            id = id,
            disabled = disabled,
            image = DesignSystem.ImageView(image = image),
            title = DesignSystem.Text(text = title, style = DesignSystem.TextStyle.HEADLINE),
            info = DesignSystem.Text(text = info, style = DesignSystem.TextStyle.BODY),
            more = DesignSystem.Button(title = "Bjud in", disabled = disabled),
        )

    fun status(id: Identifier<ModuleItem>, image: DesignSystem.Image, title: String, info: String, approved: String, pending: String, approvedList: List<String>, pendingList: List<String>, disabled: Boolean) =
        ViewModel.Status(
            id = id,
            disabled = disabled,
            image = DesignSystem.ImageView(image = image),
            title = DesignSystem.Text(text = title, style = DesignSystem.TextStyle.HEADLINE),
            info = DesignSystem.Text(text = info, style = DesignSystem.TextStyle.BODY),
            approved = DesignSystem.Text(text = approved, style = DesignSystem.TextStyle.BODY),
            pending = DesignSystem.Text(text = pending, style = DesignSystem.TextStyle.BODY),
            approvedList = approvedList.sorted().map {
                DesignSystem.Profile(initials = it, approved = true)
            },
            pendingList = pendingList.sorted().map {
                DesignSystem.Profile(initials = it, pending = true)
            },
            more = DesignSystem.Button(title = "Kör igång!", background = DesignSystem.Color.NEON, disabled = disabled),
        )
}