package della8.web.views

import StoreContext
import bootstrap.bCol
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.screens.DraftScreen
import della8.core.support.Location
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.*
import kotlinx.coroutines.isActive
import kotlinx.js.get
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.router.useParams
import techla.base.Identifier
import kotlin.time.ExperimentalTime



val Draft = FC<PropsWithChildren>("Draft") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(DraftScreen.ViewModel.None as DraftScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()

    val scopedCall = scopedCall<DraftScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    val mainCall = mainCall<DraftScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    suspend fun onCreate() {
        mainCall { DraftScreen.create(sceneInputOf(store, viewModel)) }
    }

    fun onCreated() {
        navigate(Location.Landing.route)
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is DraftScreen.ViewModel.None ->
                DraftScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }
            is DraftScreen.ViewModel.Loading -> {
                params["objectId"]?.let { objectId ->
                    DraftScreen.load(sceneInputOf(store, viewModel), Identifier(objectId))
                        .also { (viewModel, actions) ->
                            if (coroutineScope.isActive) {
                                setViewModel(viewModel)
                                dispatch(actions)
                            }
                        }
                }
            }
            else -> {}
        }
    }

    main {
        d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
        if (viewModel is DraftScreen.ViewModel.Ready) {
            bContainer {
                className = className(
                    listOfNotNull("px-5 position-relative n-bottom-space n-top-space")
                )
                bRow {
                    className = className(
                        listOfNotNull("pb-4 justify-content-center")
                    )
                    bCol {
                        xs = 12; md = 5
                        d8Image(className = "d-block mx-auto", design = viewModel.image)
                        d8Text(className = "text-center", design = viewModel.title)
                        d8Text(className = "", design = viewModel.body)
                        div {
                            className = className("d-grid")
                            d8Button(className = "d8-ruby-bg btn-lg", design = viewModel.create, onClick = ::onCreate)
                        }
                    }
                }
            }
        }

        if (viewModel is DraftScreen.ViewModel.Created) {
            d8Success(design = viewModel.success, onSuccess = ::onCreated)
        }

        if (viewModel is DraftScreen.ViewModel.Creating) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is DraftScreen.ViewModel.Failed) {
            bContainer {
                className = className("px-5 n-top-space")
                d8failure(viewModel.failure)
            }
        }
    }
}
