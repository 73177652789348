package della8.web.components

import bootstrap.*
import della8.core.support.DesignSystem
import della8.web.support.className
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.*
import react.dom.html.ReactHTML.div

external interface D8ModalProps : PropsWithChildren {
    var className: String?
    var onClickFirst: suspend () -> Unit
    var onClickSecond: suspend () -> Unit
    var design: DesignSystem.Modal
    var show: Boolean
    var onHide: suspend () -> Unit
    var size: String
    var overrideBody: DesignSystem.Text?
}

val D8Modal = FC<D8ModalProps>() { props ->
    bModal {
        show = props.show
        onHide = { MainScope().launch { props.onHide() } }
        centered = true
        size = props.size
        scrollable = true

        bModalHeader {
            closeButton = true
            +""
        }
        bModalBody {
            bContainer {
                className = className("")
                bRow {
                    className = className("text-center")
                    props.design.title?.let { d8Text(design = it) }
                }
                bRow {
                    className = className("p-0 py-2")
                    (props.overrideBody ?: props.design.body)?.let {
                        d8Text(className = props.className ,design = it)
                    }
                }
                if (props.design.centerButton) {
                    bRow {
                        className = className("mb-0 mb-md-1")
                        props.design.firstButton?.let { d8Button(design = it, onClick = props.onClickFirst) }
                    }

                } else {
                    bRow {
                        className = className("row-cols-2 row-cols-md-2 row-cols-lg-2 mb-0 mb-md-1")
                        div {
                            className = className("d-grid gap-2")
                            props.design.firstButton?.let { d8Button(design = it, onClick = props.onClickFirst) }
                        }
                        div {
                            className = className("d-grid gap-2")
                            props.design.secondButton?.let { d8Button(design = it, onClick = props.onClickSecond) }
                        }
                    }
                }
            }
        }
    }
}

fun ChildrenBuilder.d8Modal(
    design: DesignSystem.Modal,
    className: String = "",
    onClickFirst: suspend () -> Unit,
    onClickSecond: suspend () -> Unit = {},
    show: Boolean,
    onHide: suspend () -> Unit,
    size: String = "md",
    overrideBody: DesignSystem.Text? = null,
) = D8Modal {
    this.className = className
    this.design = design
    this.onClickFirst = onClickFirst
    this.onClickSecond = onClickSecond
    this.show = show
    this.onHide = onHide
    this.size = size
    this.overrideBody = overrideBody
}

