import della8.core.screens.StartScreen
import della8.core.support.Store
import della8.web.admin.D8Advanced
import della8.web.admin.D8Dashboard
import della8.web.support.deployment
import della8.web.views.*
import kotlinx.browser.localStorage
import kotlinx.browser.window
import react.*
import react.router.Route
import react.router.Routes
import react.router.dom.*
import techla.base.Device

val storeReducer: Reducer<Store, List<Store.Action>> = { store, actions ->
    actions.filterIsInstance<Store.Action.AgreedCookies>().firstOrNull()?.let {
        localStorage.setItem("cookie", it.cookie.toString())
    }
    actions.filterIsInstance<Store.Action.RememberMe>().firstOrNull()?.let {
        localStorage.setItem("della8.rememberMe", it.rememberMe.toString())
    }


    store.reduce(actions)
}


var StoreContext = createContext<Pair<Store, Dispatch<List<Store.Action>>>>()


val startReducer: Reducer<StartScreen.ViewModel, StartScreen.ViewModel> = { _, viewModel ->
    viewModel
}


var StartContext = createContext<Pair<StartScreen.ViewModel, Dispatch<StartScreen.ViewModel>>>()


val App = FC<PropsWithChildren>("APP") {
    val (store, storeDispatch) = useReducer(
        storeReducer, initialState = Store(
            deployment = deployment,
            device = Device.Web(window.navigator.userAgent),
            bankIdInstalled = false,
            applicationContext = null,
            // We don't store the application token in local storage since we want an application auth every time the app is loaded for statistics
            applicationToken = null,
            cookie = localStorage.getItem("cookie").toBoolean(),
            rememberMe = localStorage.getItem("della8.rememberMe") ?: "",
            medias = null,
        )
    )
    val (start, startDispatch) = useReducer(startReducer, StartScreen.ViewModel.None)
    StrictMode {
        StoreContext.Provider(store to storeDispatch) {
            StartContext.Provider(value = start to startDispatch) {
                BrowserRouter {
                    Routes {
                        //Route { path = "/"; element = Start.create() }
                        Route { path = "/"; element = Login.create() }
                        //Route { path = "/article/:id"; element = Article.create() }
                        //Route { path = "/articles"; element = Articles.create() }
                        Route { path = "/storefront"; element = StorefrontView.create() }
                        Route { path = "/landing"; element = Landing.create() }
                        Route { path = "/create"; element = Create.create() }
                        //Route { path = "/interest"; element = InterestView.create() }
                        //Route { path = "/subscribe"; element = SubscribeView.create() }
                        Route { path = "/redeem"; element = RedeemCode.create() }
                        Route { path = "/profile"; element = ProfileView.create() }
                        //Route { path = "/about"; element = AboutUsView.create() }
                        //Route { path = "/calculator"; element = CalculatorView.create() }
                        Route { path = "/object/:objectId/:agreementId"; element = ObjectView.create() }
                        Route { path = "/object/:objectId/:agreementId/reservation"; element = ReservationView.create() }
                        Route { path = "/object/:objectId/:agreementId/reservation/help"; element = ReservationHelp.create() }
                        Route { path = "/object/:objectId/:agreementId/availability";element = AvailabilityView.create() }
                        Route { path = "/object/:objectId/:agreementId/partnership";element = PartnershipView.create() }
                        Route { path = "/object/:objectId/:agreementId/partnership/help";element = PartnershipHelp.create() }
                        Route { path = "/object/:objectId/:agreementId/invite"; element = Invite.create() }
                        Route { path = "/object/:objectId/:agreementId/agreement"; element = AgreementView.create() }
                        Route { path = "/object/:objectId/:agreementId/shop"; element = Shop.create() }
                        Route { path = "/object/:objectId/:agreementId/:mode/:module/rules"; element = RulesView.create() }
                        Route { path = "/object/:objectId/settings"; element = Settings.create() }
                        Route { path = "/object/:objectId/draft"; element = Draft.create() }
                        Route { path = "/object/:objectId/:agreementId/feed"; element = FeedView.create() }
                        Route { path = "/object/:objectId/:agreementId/feed/post"; element = MessageView.create() }
                        Route { path = "/object/:objectId/:agreementId/feed/:messageId/:type"; element = MessageView.create() }
                        Route { path = "/object/:objectId/:agreementId/feed/:messageId"; element = MessageView.create() }
                        // Route { path = "/policy"; element = Policy.create() }
                        //Route { path = "/cookies"; element = Cookies.create() }

                        // Admin
                        Route { path = "/dashboard"; element = D8Dashboard.create() }
                        Route { path = "/advanced"; element = D8Advanced.create() }
                        //Route { path = "/preview"; element = D8Advanced.create() }
                    }
                }
            }
        }
    }
}
