package della8.core.screens

import della8.core.services.listAgreements
import della8.core.support.*
import techla.agreement.Agreement
import techla.base.*

object AgreementHistoryScreen {
    data class Texts(
        val title: String,
        val agreementTitle: String,
        val back: String,
        val version: String,
        val download: String,
        val loading: String,
        override val failureTitle: String,

        ) : FailureTexts {
        companion object
    }

    data class State(
        val obj: Object = Object.None,
    )

    data class AgreementHistory(
        val id: Identifier<Agreement>,
        val title: DesignSystem.Text,
        val version: DesignSystem.Text,
        val download: DesignSystem.Button,

        )

    sealed class ViewModel(open val texts: Texts, open val state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }


        data class Ready(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val items: List<AgreementHistory>
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Failed(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        fun loading(texts: Texts) =
            Loading(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(
                    texts = object : ProgressTexts {
                        override val progressInfo get() = texts.loading
                    }
                ),
            )


        fun ready(state: State, items: List<AgreementHistory>) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(
                    title = texts.back,
                    location = Location.Landing
                ),
                title = DesignSystem.Text(text = texts.title, style = DesignSystem.TextStyle.TITLE2),
                items = items,
            )


        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asReady get() = this as? Ready
        val asLoading get() = this as? Loading
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))
    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        val texts = Texts(
            title = "Avtalshistorik",
            agreementTitle = "Avtal för",
            back = "Tillbaka",
            version = "Version:",
            download = "Ladda hem",
            loading = "Laddar...",
            failureTitle = "Oj, ett fel har uppstått",
        )

        return sceneOf<ViewModel>(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.refreshObject(objectId = objectId, agreementId = agreementId)
            .flatMap { (actions, obj) ->
                store.reduce(actions).listAgreements(obj)
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj, it.second) }
            }
            .map { (actions, obj, agreements) ->

                val params = agreements.sortedByDescending { it.version }.drop(1)
                    .map {
                        AgreementHistory(
                            id = it.id,
                            title = DesignSystem.Text(text = "${viewModel.texts.agreementTitle} ${obj.currentAgreement.name}"),
                            version = DesignSystem.Text(text = "${viewModel.texts.version} ${it.version} ${it.createdAt.dateTime.date}-${it.editedAt.dateTime.date}", textColor = DesignSystem.Color.BATTLESHIP),
                            download = DesignSystem.Button(
                                title = viewModel.texts.download,
                                image = DesignSystem.Image.DOWNLOAD,
                                background = DesignSystem.Color.CLEAR,
                                style = DesignSystem.ButtonStyle.TRANSPARENT,
                                iconAlignment = DesignSystem.IconAlignment.RIGHT,
                                visible = false
                            ),
                        )
                    }

                val state = viewModel.state.copy(
                    obj = obj,
                )

                sceneOf<ViewModel>(viewModel.ready(state = state, items = params), actions)
            }
            .failed { scene.failed(result = it) }
    }


    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }

}

