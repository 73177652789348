package della8.web.views

import StoreContext
import bootstrap.bCol
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.screens.ReservationHelpScreen
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.*
import kotlinx.js.get
import react.*
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.router.useParams
import techla.base.Identifier
import techla.base.techla_log

val ReservationHelp = FC<PropsWithChildren>("ReservationHelp") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(ReservationHelpScreen.ViewModel.None as ReservationHelpScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()

    val scopedCall = scopedCall<ReservationHelpScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    val mainCall = mainCall<ReservationHelpScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is ReservationHelpScreen.ViewModel.None ->
                scopedCall(coroutineScope) { ReservationHelpScreen.start(sceneInputOf(store, viewModel)) }

            is ReservationHelpScreen.ViewModel.Loading -> {
                params["objectId"]?.let { objectId ->
                    params["agreementId"]?.let { agreementId ->
                        scopedCall(coroutineScope) { ReservationHelpScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId)) }
                    }
                }
            }
            is ReservationHelpScreen.ViewModel.Ready -> {
            }
            is ReservationHelpScreen.ViewModel.Failed ->
                techla_log("failed ")
        }
    }
    fun logout() {
        mainCall { ReservationHelpScreen.logout(sceneInputOf(store, viewModel)) }
        navigate("/")
    }


    main {
        d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
        if (viewModel is ReservationHelpScreen.ViewModel.Ready) {

            bContainer {
                className = className("px-5 position-relative n-bottom-space n-top-space")

                bRow {
                    className = className("pb-4 justify-content-center")

                    bCol {
                        xs = 12; md = 5
                        d8Text(className = "text-center", design = viewModel.title)
                        d8Text(className = "d-inline", design = viewModel.body)
                    }
                }
            }
        }

        if (viewModel is ReservationHelpScreen.ViewModel.Failed) {
            bContainer {
                className = className("px-5 n-top-space")

                d8failure(design = viewModel.failure, ::logout)
            }
        }
    }
}
