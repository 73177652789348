package della8.core.screens

import della8.core.native.clipboard
import della8.core.services.createInvite
import della8.core.support.*
import techla.agreement.Agreement
import techla.base.*
import techla.guard.Invite

object InviteScreen {
    data class Texts(
        val title: String,
        val back: String,
        val copy: String,
        val body: String,
        val code: String,
        val titleCode: String,
        val titleInvite: String,
        val invite: String,
        val successCopy: String,
        override val progressInfo: String,
        override val failureTitle: String,
    ) : ProgressTexts, FailureTexts {
        companion object
    }

    data class State(
        val copiedCode: Copied,
        val obj: Object = Object.None
    ) {
        companion object
    }

    enum class Copied { NONE, FIRST, SECOND }


    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", ""),
            state = State( Copied.NONE),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val body: DesignSystem.Text,
            val code: DesignSystem.Text,
            val titleCode: DesignSystem.Text,
            val titleInvite: DesignSystem.Text,
            val invite: DesignSystem.Text,
            val successCopyCode: DesignSystem.Text,
            val successCopyMessage: DesignSystem.Text,
            val copyCode: DesignSystem.Button,
            val copyMessage: DesignSystem.Button,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        fun loading(texts: Texts) = Loading(
            texts = texts,
            state = state,
            navigation = DesignSystem.Navigation.progress(),
            progress = progress(texts = texts)
        )

        fun ready(texts: Texts, state: State) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.objectLight(obj = state.obj, title = texts.back, logoLocation = Location.Landing, active = Location.invite(state.obj)),
                image = DesignSystem.ImageView(image = DesignSystem.Image.INVITES, alt = texts.title),
                title = DesignSystem.Text(text = texts.title, style = DesignSystem.TextStyle.TITLE1),
                body = DesignSystem.Text(text = texts.body, style = DesignSystem.TextStyle.BODY),
                code = DesignSystem.Text(text = texts.code, style = DesignSystem.TextStyle.TITLE2),
                titleCode = DesignSystem.Text(text = texts.titleCode, style = DesignSystem.TextStyle.SUBHEAD),
                titleInvite = DesignSystem.Text(text = texts.titleInvite, style = DesignSystem.TextStyle.SUBHEAD),
                invite = DesignSystem.Text(text = texts.invite, style = DesignSystem.TextStyle.BODY),
                copyCode = DesignSystem.Button(title = texts.copy, image = DesignSystem.Image.COPY, background = DesignSystem.Color.CLEAR, style = DesignSystem.ButtonStyle.TRANSPARENT, data = Copied.FIRST),
                copyMessage = DesignSystem.Button(title = texts.copy, image = DesignSystem.Image.COPY, background = DesignSystem.Color.CLEAR, style = DesignSystem.ButtonStyle.TRANSPARENT, data = Copied.SECOND),
                successCopyCode = DesignSystem.Text(text = texts.successCopy, style = DesignSystem.TextStyle.SUBHEAD, image = DesignSystem.Image.COPIED, iconAlignment = DesignSystem.IconAlignment.LEFT, visible = state.copiedCode == Copied.FIRST),
                successCopyMessage = DesignSystem.Text(text = texts.successCopy, style = DesignSystem.TextStyle.SUBHEAD, image = DesignSystem.Image.COPIED, iconAlignment = DesignSystem.IconAlignment.LEFT, visible = state.copiedCode == Copied.SECOND),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false) =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val ready get() = this as? Ready
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        return successfulOf(Unit)
            .map {
                val texts = Texts(
                    title = store.get(media = Key("screen:invite"), content = Key("title")),
                    back = store.get(media = Key("screen:invite"), content = Key("back")),
                    body = store.get(media = Key("screen:invite"), content = Key("body")),
                    titleCode = store.get(media = Key("screen:invite"), content = Key("titleCode")),
                    copy = store.get(media = Key("screen:invite"), content = Key("copy")),
                    code = "",
                    titleInvite = store.get(media = Key("screen:invite"), content = Key("titleInvite")),
                    invite = store.get(media = Key("screen:invite"), content = Key("invite")),
                    progressInfo = "Laddar...",
                    failureTitle = "Oj, ett fel har uppstått",
                    successCopy = "Kopierad",
                )
                sceneOf<ViewModel>(viewModel.loading(texts = texts))
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.refreshObject(objectId = objectId, agreementId = agreementId)
            .flatMap { (actions, obj) ->
                val create = Invite.Create(group = obj.group.key)
                store.reduce(actions).createInvite(obj = obj, create = create)
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj, it.second) }
            }
            .map { (actions, obj, invite) ->

                val texts = viewModel.texts.copy(
                    code = invite.key.rawValue,
                )
                val newState = viewModel.state.copy(
                    obj = obj
                )

                sceneOf<ViewModel>(viewModel.ready(texts, state = newState), actions)
            }
            .failed { scene.failed(result = it) }
    }

    fun copyClipboard(scene: Scene.Input<ViewModel>, text: String, data: Copied): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                clipboard(text)
                val newState = viewModel.state.copy(copiedCode = data)
                sceneOf<ViewModel>(viewModel.ready(viewModel.texts, state = newState))
            }
            .failed { scene.failed(result = it) }
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }
}


