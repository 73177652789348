package della8.web.views

import StoreContext
import bootstrap.*
import della8.core.screens.StorefrontScreen
import della8.core.support.Location
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.items.articleItem
import della8.web.support.className
import della8.web.support.standardNavigation
import della8.web.support.useExecutor
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.fetch.Response.Companion.redirect
import react.*
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import techla.base.Identifier

val StorefrontView = FC<PropsWithChildren>("StorefrontView") {
    val (store, dispatch) = useContext(StoreContext)
    val navigate = useNavigate()
    var viewModel: StorefrontScreen.ViewModel by useState(StorefrontScreen.ViewModel.None)
    val executor by useExecutor<StorefrontScreen.ViewModel> { (updated, actions) ->
        viewModel = updated
        dispatch(actions)
    }

    useEffect(viewModel) {
        window.location.assign(store.deployment.della8Finder)
    }
}
/*
            executor.call { StorefrontScreen.start(sceneInputOf(store, viewModel)) }
        if (viewModel is StorefrontScreen.ViewModel.Loading)
            executor.call { StorefrontScreen.load(sceneInputOf(store, viewModel)) }
    }
    /*

    main {
        d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))

        viewModel.asReady?.run {
            bContainer {
                className = className("pt-5 p-0 position-relative")

                ReactHTML.div {
                    className =
                        className("pt-5 text-center w-100 d-flex justify-content-center")
                    bCol {
                        xs = 12; md = 8
                        className = className(
                            "pt-5 mt-5 position-relative d-flex align-items-center flex-column justify-content-center"
                        )
                        d8Image(className = "", design = headerImage)
                        d8Text(className = "px-5 px-md-1", design = welcome)
                        d8Text(className = "px-5 px-md-1", design = body)
                        d8Button(className = "", design = findFriend)
                    }
                }
            }

            bContainer {
                className = className("px-4 py-5")
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        className = className("px-4 px-md-0 pe-md-3 d-flex flex-column")
                        xs = 12; lg = 8
                        d8Text(design = projectWinterVillageTitle)
                        d8Text(className = "n-markdown", design = projectWinterVillageBody)
                    }
                }

                bRow {
                    className = className(
                        listOfNotNull("row-cols-1 row-cols-md-2 row-cols-lg-3 mb-0 mb-md-1 justify-content-center")
                    )
                    articlesProjectWinterVillage.map { articleItem(viewModel = it) }
                }
            }

            ReactHTML.div {
                className = className("text-center pb-5")
                d8Button(className = "btn-lg px-5 mt-2", design = toProjectWinterVillage)
            }

            bContainer {
                className = className("px-4 py-5")
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        className = className("px-4 px-md-0 pe-md-3 d-flex flex-column")
                        xs = 12; lg = 8
                        d8Text(design = bengtTitle)
                        d8Text(className = "n-markdown", design = bengtBody)

                    }
                }

                bRow {
                    className = className(
                        listOfNotNull("row-cols-1 row-cols-md-2 row-cols-lg-3 mb-0 mb-md-1 justify-content-center")
                    )
                    articlesBengt.map { articleItem(viewModel = it) }
                }
            }

            ReactHTML.div {
                className = className("text-center pb-5")
                //+"BUTTON - FLER OBJEKT"
                d8Button(className = "btn-lg px-5 mt-2", design = toBengt)
            }


            bContainer {
                className = className("px-4 py-5")
                bRow {
                    className = className("pb-4 justify-content-center align-items-center")
                    bCol {
                        className = className("ps-md-0 pe-md-3")
                        xs = 11; md = 6; lg = 5
                        d8Text(className = "mb-3", design = resursTitle)
                        d8Text(className = "n-markdown", design = resursBody)
                        d8Button(className = "btn-lg px-5 mt-2", design = toResurs)
                    }
                    bCol {
                        className = className("d-flex justify-content-center")
                        xs = 11; md = 6; lg = 3
                        d8Image(className = "img-fluid", design = resurs)
                    }
                }
            }

            d8Footer(design = footer, onClick = standardNavigation(navigate))

        }

        viewModel.asFailed?.run {
            bContainer {
                className = className("px-5 n-top-space")
                d8failure(failure)
            }
        }
    }
}
     */