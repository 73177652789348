package bootstrap

import csstype.ClassName
import org.w3c.dom.events.Event
import react.*

@JsNonModule
@JsModule("react-bootstrap/Nav")
private external val navModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bNav: ComponentType<NavProps> = navModule.default

external interface NavProps : PropsWithChildren {
    var activeKey: Any /* string | number */
    var `as`: Any
    var cardHeaderBsPrefix: String
    var defaultActiveKey: Any /* string | number */
    var fill: Boolean
    var justify: Boolean
    var navbar: Boolean
    var navbarBsPrefix: String
    var navbarScroll: Boolean
    var onKeyDown: (Event) -> Unit
    var onSelect: (eventKey: Any /* string | number */, Event) -> Unit
    var role: String
    var variant: String /* 'tabs' | 'pills' */
    var bsPrefix: String
    var className: ClassName
}