package bootstrap

import csstype.ClassName
import react.ComponentType
import react.PropsWithChildren

external interface ContainerProps : PropsWithChildren {
    operator fun set(s: String, value: String)

    var className: ClassName
    var fluid: dynamic /* true | "sm" | "md" | "lg" | "xl" */
    var bsPrefix: String
}

@JsNonModule
@JsModule("react-bootstrap/Container")
private external val containerModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bContainer: ComponentType<ContainerProps> = containerModule.default

/*
import kotlinext.js.jsObject
import react.*

@JsModule("react-bootstrap/Container")
private external val containerModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val containerComponent: ComponentType<ContainerProps> = containerModule.default

external interface ContainerProps : PropsWithChildren {
    operator fun set(s: String, value: String)

    var className: String
    var fluid: dynamic /* true | "sm" | "md" | "lg" | "xl" */
    var bsPrefix: String
}

class ContainerElementBuilder internal constructor(
    private val type: ComponentType<ContainerProps>, props: ContainerProps = jsObject { }
) : RElementBuilderImpl<ContainerProps>(props) {

    fun create() = createElement(type, attrs, *childList.toTypedArray())
}

fun RBuilder.bContainer(
    className: String? = null,
    fluid: Boolean? = null,
    block: ContainerElementBuilder.() -> Unit = {},
) =
    child(ContainerElementBuilder(containerComponent)
        .apply {
            className?.let { attrs.className = it }
            fluid?.let { attrs.fluid = it }
            block()
        }.create()
    )
*/