package della8.core.screens

import della8.core.services.bios
import della8.core.services.createSignature
import della8.core.services.getSignature
import della8.core.support.*
import techla.agreement.Agreement
import techla.agreement.Signature
import techla.base.*
import techla.personal.Bio

object AgreementScreen {
    object Header {
        val agreement = DesignSystem.Header(id = "agreement")
    }

    data class Texts(
        val title: String,
        val back: String,
        val empty: String,
        val info: String,
        val proposal: String,
        val current: String,
        val from: String,
        val signedBy: String,
        val version: String,
        val prevVersion: String,
        val download: String,
        val remove: String,
        val approve: String,
        val loading: String,
        val approving: String,
        val scanQr: String,
        val cancel: String,
        override val failureTitle: String,
        override val successTitle: String,
        override val successInfo: String,
        override val successNext: String,
    ) : FailureTexts, SuccessTexts {
        companion object
    }

    data class State(
        val obj: Object = Object.None,
        val bios: List<Bio> = emptyList(),
        val currentAgreement: Agreement = Agreement(Identifier(), Key(""), "", "", 0, Agreement.Status.Unknown, Agreement.ApprovalMethod.Manual, Date.now(), Date.now()),
        val approvedAgreement: Agreement? = null,
        val outstandingAgreement: Agreement? = null,
        val currentSignatures: List<Signature> = emptyList(),
        val approvedSignatures: List<Signature>? = null,
        val outstandingSignatures: List<Signature>? = null,
        val signature: Signature? = null,
        val qrCode: String? = null,
    )

    data class ReadyParams(
        val approved: Boolean,
        val title: String,
        val version: String,
        val from: String,
        val content: String,
        val profiles: List<String>,
        val leader: Boolean,
    )

    sealed class ViewModel(open val texts: Texts, open val state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Empty(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Ready(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val params: ReadyParams,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val agreements: DesignSystem.SelectInput,
            val from: DesignSystem.Text,
            val signedBy: DesignSystem.Text,
            val version: DesignSystem.Text,
            val download: DesignSystem.Button,
            val remove: DesignSystem.Button,
            val approve: DesignSystem.Button,
            val prevVersion: DesignSystem.Button,
            val profiles: List<DesignSystem.Profile>,
            val terms: DesignSystem.Text,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Approving(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,

            ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Waiting(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val scanQr: DesignSystem.Text,
            val cancel: DesignSystem.Button,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Approved(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val success: DesignSystem.Success,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Failed(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        fun loading(texts: Texts): ViewModel =
            Loading(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(
                    texts = object : ProgressTexts {
                        override val progressInfo get() = texts.loading
                    }
                ),
            )

        fun empty(state: State): ViewModel =
            Empty(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.objectLight(obj = state.obj, title = texts.back, logoLocation = Location.Landing, active = Location.agreement(state.obj)),
                image = DesignSystem.ImageView(image = DesignSystem.Image.NO_AGREEMENT, alt = texts.title),
                title = DesignSystem.Text(text = texts.empty, style = DesignSystem.TextStyle.TITLE1),
                info = DesignSystem.Text(text = texts.info, style = DesignSystem.TextStyle.BODY),
            )

        fun ready(state: State, params: ReadyParams): ViewModel =
            Ready(
                texts = texts,
                state = state,
                params = params,
                navigation = DesignSystem.Navigation.objectLight(obj = state.obj, title = texts.back, logoLocation = Location.Landing, active = Location.agreement(state.obj)),
                image = DesignSystem.ImageView(image = DesignSystem.Image.AGREEMENT, alt = texts.title),
                title = DesignSystem.Text(text = params.title, style = DesignSystem.TextStyle.TITLE1),
                agreements = DesignSystem.SelectInput(
                    header = Header.agreement,
                    options = listOf(
                        DesignSystem.Option.item(title = "Förslag", image = if (state.obj.currentAgreement.status !is Agreement.Status.Approved || state.obj.currentAgreement.version == 1) DesignSystem.Image.UPDATES else null, value = "outstanding", disabled = state.obj.currentAgreement.status is Agreement.Status.Approved),
                        DesignSystem.Option.item(title = "Gällande", value = "approved", disabled = state.obj.currentAgreement.version == 1),
                    ),
                    style = DesignSystem.SelectStyle.SWITCH,
                ),
                from = DesignSystem.Text(text = params.from, textColor = DesignSystem.Color.BATTLESHIP),
                signedBy = DesignSystem.Text(text = texts.signedBy, style = DesignSystem.TextStyle.SUBHEAD),
                version = DesignSystem.Text(text = params.version, textColor = DesignSystem.Color.BATTLESHIP),
                approve = DesignSystem.Button(
                    title = texts.approve,
                    image = DesignSystem.Image.BANK_ID,
                    style = DesignSystem.ButtonStyle.PRIMARY,
                    disabled = params.approved,
                ),
                download = DesignSystem.Button(
                    title = texts.download,
                    image = DesignSystem.Image.DOWNLOAD,
                    background = DesignSystem.Color.CLEAR,
                    style = DesignSystem.ButtonStyle.TRANSPARENT,
                    iconAlignment = DesignSystem.IconAlignment.RIGHT,
                    visible = false,
                ),
                remove = DesignSystem.Button(
                    title = texts.remove,
                    image = DesignSystem.Image.TRASH,
                    background = DesignSystem.Color.CLEAR,
                    style = DesignSystem.ButtonStyle.TRANSPARENT,
                    iconAlignment = DesignSystem.IconAlignment.RIGHT,
                    visible = state.currentAgreement.status is Agreement.Status.Pending,
                ),
                prevVersion = DesignSystem.Button(
                    title = texts.prevVersion,
                    image = DesignSystem.Image.VERSION,
                    background = DesignSystem.Color.CLEAR,
                    style = DesignSystem.ButtonStyle.TRANSPARENT,
                    iconAlignment = DesignSystem.IconAlignment.RIGHT,
                    visible = state.currentAgreement.version > 1
                ),
                profiles = params.profiles.map { DesignSystem.Profile(initials = it, approved = true) },
                terms = DesignSystem.Text(text = params.content, isMarkdown = true),

                )

        fun approving(): ViewModel =
            Approving(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(
                    texts = object : ProgressTexts {
                        override val progressInfo get() = texts.loading
                    }
                ),
            )

        fun waiting(state: State? = null): ViewModel =
            Waiting(
                texts = texts,
                state = (state ?: this.state),
                navigation = DesignSystem.Navigation.progress(),
                scanQr = DesignSystem.Text(text = texts.scanQr, style = DesignSystem.TextStyle.TITLE1, textColor = DesignSystem.Color.MARINE),
                cancel = DesignSystem.Button(title = texts.cancel, style = DesignSystem.ButtonStyle.TRANSPARENT)
            )

        fun approved(state: State? = null): ViewModel =
            Approved(
                texts = texts,
                state = (state ?: this.state),
                navigation = DesignSystem.Navigation.success,
                success = success(texts = texts),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false) =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String): ViewModel =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asReady get() = this as? Ready
        val asWaiting get() = this as? Waiting
        val asApproved get() = this as? Approved
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(message: String) =
        sceneOf(viewModel.failed(message))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        val texts = Texts(
            title = "Avtal för",
            back = "Tillbaka",
            empty = "Inget avtal uppsatt",
            info = "Gå in och ställ in objektets avtalsregler så kommer de första delarna i avtalet att dyka upp här.",
            proposal = "Förslag",
            current = "Gällande",
            from = "Gäller sedan",
            signedBy = "Underskrivet av:",
            version = "Version",
            prevVersion = "Se tidigare versioner",
            download = "Ladda hem",
            remove = "Radera förslag",
            approve = "Godkänn",
            loading = "Laddar...",
            approving = "Öppna och skriv under med BankID",
            failureTitle = "Oj, ett fel har uppstått",
            successTitle = "Avtalet är nu godkänt",
            successInfo = "Finns avtalet ute för signering av flera samägare inväntas deras godkännande",
            successNext = "OK",
            scanQr = "Skriv under avtalet med BankID",
            cancel = "Avbryt"
        )

        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.refreshObject(objectId = objectId, agreementId = agreementId)
            .flatMap { (actions, obj) ->
                val batch = Bio.Batch(ids = obj.group.members)
                store.reduce(actions).bios(batch)
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj, it.second) }
            }
            .map { (actions, obj, bios) ->
                val approvedAgreement = obj.agreements.firstOrNull { it.status is Agreement.Status.Approved }
                val outstandingAgreement = obj.agreements.firstOrNull { it.status !is Agreement.Status.Approved }
                val state = viewModel.state.copy(
                    obj = obj,
                    bios = bios,
                    currentAgreement = obj.currentAgreement,
                    approvedAgreement = approvedAgreement,
                    outstandingAgreement = if (obj.currentAgreement == outstandingAgreement) outstandingAgreement else null,
                    currentSignatures = obj.signatures,
                    approvedSignatures = obj.signatures,
                    outstandingSignatures = null,
                )
                val params = ReadyParams(
                    approved = if (obj.isGroupLeader() && !state.currentSignatures.haveIApproved(store = store)) {
                        false
                    } else !(obj.hasGroupLeaderSigned().isNotEmpty() && !state.currentSignatures.haveIApproved(store = store)),
                    title = "${viewModel.texts.title} ${state.currentAgreement.name}",
                    version = "${viewModel.texts.version} ${state.currentAgreement.version}",
                    from = "${viewModel.texts.from} ${state.currentAgreement.createdAt.dateTime.date}",
                    content = state.currentAgreement.content,
                    profiles = buildMembers(state = state, signatures = state.currentSignatures),
                    leader = obj.isGroupLeader()
                )
                val next = if (obj.isAgreementEmpty)
                    viewModel.empty(state = state)
                else
                    viewModel.ready(state = state, params = params)
                sceneOf(next, actions)
            }
            .failed { scene.failed(result = it) }
    }

    fun update(scene: Scene.Input<ViewModel>, agreement: DesignSystem.Option?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        viewModel as ViewModel.Ready

        val (currentAgreement, currentSignatures) = when (agreement?.value) {
            "outstanding" -> viewModel.state.outstandingAgreement to viewModel.state.outstandingSignatures
            "approved" -> viewModel.state.approvedAgreement to viewModel.state.approvedSignatures
            else -> viewModel.state.currentAgreement to viewModel.state.currentSignatures
        }

        val state = viewModel.state.copy(
            currentAgreement = currentAgreement ?: viewModel.state.currentAgreement,
            currentSignatures = currentSignatures ?: viewModel.state.currentSignatures,
        )
        val params = viewModel.params.copy(
            approved = if (state.obj.isGroupLeader() && !state.currentSignatures.haveIApproved(store = store)) {
                false
            } else !(state.obj.hasGroupLeaderSigned().isNotEmpty() && !state.currentSignatures.haveIApproved(store = store)),
            title = "${viewModel.texts.title} ${state.currentAgreement.name}",
            version = "${viewModel.texts.version} ${state.currentAgreement.version}",
            from = "${viewModel.texts.from} ${state.currentAgreement.createdAt.dateTime.date}",
            content = state.currentAgreement.content,
            profiles = buildMembers(state = state, signatures = state.currentSignatures),
        )

        return sceneOf(viewModel.ready(state = state, params = params))
    }

    fun validate(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return sceneOf(viewModel.approving())
    }

    suspend fun approve(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val obj = viewModel.state.obj
        val create = Signature.Create(
            agreementId = viewModel.state.currentAgreement.id,
        )
        return store.createSignature(obj = obj, create = create)
            .map { (actions, signature) ->
                val state = viewModel.state.copy(
                    signature = signature,
                )

                when (val signed = signature.signed) {
                    is Signature.Signed.Pending ->
                        sceneOf(viewModel.waiting(state = state.copy(qrCode = (signed as Signature.Signed.Pending).qrCode)), actions)

                    is Signature.Signed.Agreement, is Signature.Signed.Policy ->
                        sceneOf(viewModel.approved(state = state), actions)

                    is Signature.Signed.Cancelled ->
                        sceneOf(viewModel.loading(texts = viewModel.texts))

                    is Signature.Signed.Failed ->
                        sceneOf(viewModel.failed(message = signed.reason), actions)

                    is Signature.Signed.Unknown ->
                        sceneOf(viewModel.failed(message = "Okänt fel"), actions)
                }
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun check(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val obj = viewModel.state.obj
        val signature = viewModel.state.signature ?: return scene.failed("Signature must be present")

        return store.getSignature(obj = obj, id = signature.index.id)
            .map { (actions, signature) ->
                when (val signed = signature.signed) {
                    is Signature.Signed.Pending ->
                        sceneOf(viewModel.waiting(state = viewModel.state.copy(qrCode = (signed as Signature.Signed.Pending).qrCode)), actions)

                    is Signature.Signed.Agreement, is Signature.Signed.Policy ->
                        sceneOf(viewModel.approved(), actions)

                    is Signature.Signed.Cancelled ->
                        sceneOf(viewModel.loading(texts = viewModel.texts))

                    is Signature.Signed.Failed ->
                        sceneOf(viewModel.failed(message = signed.reason), actions)

                    is Signature.Signed.Unknown ->
                        sceneOf(viewModel.failed(message = "Okänt fel"), actions)
                }
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun success(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return load(scene = scene, objectId = viewModel.state.obj.id, agreementId = viewModel.state.obj.currentAgreement.id)
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }

    private fun buildMembers(state: State, signatures: List<Signature>): List<String> {
        return signatures.map { signature ->
            val bio = state.bios.firstOrNull { it.profileId == signature.profileId }
            bio?.firstName?.take(1)?.uppercase()?.let { f -> bio.lastName?.take(1)?.uppercase()?.let { l -> "$f$l" } }
                ?: "??"
        }
    }
}

