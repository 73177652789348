package della8.core.screens


import della8.core.support.*
import techla.agreement.Agreement
import techla.base.*

object PartnershipScreen {
    data class Texts(
        val back: String,
        val title: String,
        val body: String,
        override val progressInfo: String,
        override val failureTitle: String,
    ) : ProgressTexts, FailureTexts {
        companion object
    }

    data class State(
        val obj: Object = Object.None,
    )

    sealed class ViewModel(open val texts: Texts, open val state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Ready(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val name: DesignSystem.Text,
            val body: DesignSystem.Text,
            val help: DesignSystem.Button,
            val settings: DesignSystem.Button,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }


        data class Failed(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        fun loading(texts: Texts) =
            Loading(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(texts = texts),
            )

        fun ready(state: State) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing),
                image = DesignSystem.ImageView(
                    image = classificationType(state.obj.classification),
                ),
                title = DesignSystem.Text(
                    text = texts.title,
                    style = DesignSystem.TextStyle.TITLE1,
                    background = DesignSystem.Background.LIGHT
                ),
                name = DesignSystem.Text(
                    text = state.obj.resource.name,
                    style = DesignSystem.TextStyle.TITLE1,
                    background = DesignSystem.Background.LIGHT
                ),
                body = DesignSystem.Text(text = texts.body),
                settings = DesignSystem.Button(
                    image = DesignSystem.Image.RULES,
                    background = DesignSystem.Color.CLEAR,
                    style = DesignSystem.ButtonStyle.TRANSPARENT,
                ),
                help = DesignSystem.Button(
                    image = DesignSystem.Image.INFO,
                    background = DesignSystem.Color.CLEAR,
                    style = DesignSystem.ButtonStyle.TRANSPARENT,
                ),
            )


        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false) =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoad get() = this as? Loading
        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        return successfulOf(Unit)
            .map {
                val texts = Texts(
                    back = "Tillbaka",
                    title = "Samägande",
                    body = "Här kommer du kunna hitta mer information om ditt samägande och ditt objekt. Vill du titta på de regler som satts upp för samägande klicka på reglageikonen uppe till höger.",
                    progressInfo = "Laddar...",
                    failureTitle = "Oj, ett fel har uppstått",
                )
                sceneOf<ViewModel>(viewModel.loading(texts = texts))
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.refreshObject(objectId = objectId, agreementId = agreementId)
            .map { (actions, obj) ->
                val state = viewModel.state.copy(
                    obj = obj
                )
                sceneOf<ViewModel>(viewModel.ready(state = state), actions)
            }
            .failed { scene.failed(result = it) }
    }

    private fun classificationType(type: Classification?): DesignSystem.Image? =
        when (type) {
            is Classification.None -> DesignSystem.Image.PRESENT_AGREEMENT_XL
            is Classification.MotorHome -> DesignSystem.Image.MOTOR_HOME_XL
            is Classification.HolidayHome -> DesignSystem.Image.HOLIDAY_HOME_XL
            is Classification.Other -> DesignSystem.Image.OTHER_OBJECT_XL
            else -> null
        }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }
}