package della8.web.components

import bootstrap.*
import csstype.ClassName
import della8.core.support.DesignSystem
import della8.web.support.className
import della8.web.theme.Della8Theme
import della8.web.theme.Design
import della8.web.theme.background
import della8.web.views.ClippyView
import helmet.Helmet
import kotlinx.datetime.internal.JSJoda.use
import react.*
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p

external interface D8ElementProps : PropsWithChildren {
    var className: String?
    var classNameLabel: String?
    var design: DesignSystem
}

val D8Draft = FC<D8ElementProps>("D8Draft") { props ->
    val design = props.design as DesignSystem.Draft
    if (props.design.visible) {
        div {
            className = className("rounded px-2 py-1 n-tag d-inline")
            p {
                className = className(props.className, Della8Theme.typography.body, Della8Theme.colors.snow, "text-uppercase d-inline")
                +(design.title ?: "")
            }
        }
    }
}

val D8Profile = FC<D8ElementProps>("D8Profile") { props ->
    val design = props.design as DesignSystem.Profile
    if (props.design.visible) {
        div {
            val background = when {
                design.approved -> Della8Theme.colors.neon.background
                design.pending -> Della8Theme.colors.peach.background
                else -> Della8Theme.colors.lavender.background
            }
            className = className("rounded-circle n-avatar-small text-center pt-1 me-1 position-relative", background)
            p {
                className = className("n-profile-text", props.className, Della8Theme.typography.body, Della8Theme.colors.snow)
                +design.initials
            }
            if (design.leader) {
                img {
                    className = className("n-profile-crown")
                    src = Design.image(DesignSystem.Image.CROWN)
                }
            }
        }
    }
}

val D8Property = FC<D8ElementProps>("D8Property") { props ->
    val design = props.design as DesignSystem.Property

    design.header?.let {
        div {
            D8Text { this.design = it; className = "mb-1" }
        }
    }
    when (design.style) {
        DesignSystem.PropertyStyle.VERTICAL -> {
            design.value?.let {
                div {
                    className = className(props.className)
                    design.prefix?.let { D8Text { className = "pe-1 mb-1 mt-2";this.design = it } }
                    D8Text { this.design = it; className = props.classNameLabel }
                    design.suffix?.let { D8Text { className = "ps-1 "; this.design = it } }
                }
            }
        }

        DesignSystem.PropertyStyle.HORIZONTAL -> {
            design.value?.let {
                div {
                    className = className("d-inline-flex w-100 align-items-baseline justify-content-center justify-content-md-start")
                    design.prefix?.let { D8Text { className = "px-1 mb-1 mt-2"; this.design = it } }
                    D8Text { this.design = it; className = props.classNameLabel }
                    design.suffix?.let { D8Text { className = "px-1"; this.design = it } }
                }
            }
        }

    }
}


val D8Clippy = FC<D8ElementProps>("D8Clippy") { props ->
    var showModal: Boolean by useState(false)
    var minimize: Boolean by useState(false)

    val design = props.design as DesignSystem.Clippy
    if (props.design.visible) {
        bCard {
            className = ClassName("${if (minimize) "n-clippy-closed" else "n-clippy"} n-pointer ms-auto ${props.className}")

            if (minimize)
                d8Button(className = "d-flex", classNameImage = "rounded-circle p-2 n-clippy-gradient", design = design.maximize, onClick = { minimize = !minimize })
            else
                d8Button(className = "position-absolute end-0",  classNameImage = "w-75", design = design.minimize, onClick = { minimize = !minimize })

            if (!minimize) {
                div {
                    onClick = { showModal = true }
                    bCardBody {
                        className = ClassName("d-flex align-self-center")

                        D8Image { this.className = "n-olivia-sm"; this.design = design.image }

                        div {
                            className = className("d-flex align-self-center")
                            D8Text { this.design = design.title }
                        }
                    }
                }
            }
        }
        bModal {
            show = showModal
            onHide = { showModal = false }
            centered = true
            size = "lg"
            scrollable = true

            bModalHeader {
                closeButton = true
                +""
            }
            bModalBody {
                ClippyView {}
            }
        }
    }
}

val D8FAQ = FC<D8ElementProps>("D8FAQ") { props ->
    var selected: Int by useState(0)
    val design = props.design as DesignSystem.FAQ
    if (design.visible) {
        bAccordion {
            activeKey = "$selected"
            className = className(props.className)
            design.contents.mapIndexed { index, faq ->
                bAccordionItem {
                    eventKey = "$index"
                    bAccordionHeader {
                        onClick = { selected = index }
                        +"${index + 1}. ${faq.first}"
                    }
                    bAccordionBody {
                        +faq.second
                    }
                }
            }
        }
    }
}

val D8Meta = FC<D8ElementProps>("D8Meta") { props ->
    val design = props.design as DesignSystem.Meta
    Helmet {
        ReactHTML.title { +design.title }
        ReactHTML.meta { name = "description"; content = design.description }
        ReactHTML.meta { name = "title"; property = "og:title"; content = design.title }
        ReactHTML.meta { name = "description"; property = "og:description"; content = design.description }
        ReactHTML.meta { name = "type"; property = "og:type"; content = design.type }
        ReactHTML.meta { name = "url"; property = "og:url"; content = design.url }
        ReactHTML.meta { name = "image"; property = "og:image"; content = design.image }
    }
}

val D8Element = FC<D8ElementProps>("D8Element") { props ->
    if (props.design.visible) {
        when (props.design) {
            is DesignSystem.Draft -> D8Draft { design = props.design; className = props.className; children = props.children }
            is DesignSystem.Profile -> D8Profile { design = props.design; className = props.className; children = props.children }
            is DesignSystem.Property -> D8Property { design = props.design; className = props.className; children = props.children; classNameLabel = props.classNameLabel; }
            is DesignSystem.FAQ -> D8FAQ { design = props.design; className = props.className; children = props.children }
            is DesignSystem.Meta -> D8Meta { design = props.design; className = props.className; children = props.children }
            else -> {}
        }
    }
}

fun ChildrenBuilder.d8Element(design: DesignSystem, className: String? = null, classNameLabel: String? = null) =
    D8Element {
        this.className = className
        this.classNameLabel = classNameLabel
        this.design = design
    }