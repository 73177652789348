package bootstrap

import csstype.ClassName
import react.*

@JsNonModule
@JsModule("react-bootstrap/NavItem")
private external val navItemModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bNavItem: ComponentType<NavItemProps> = navItemModule.default

external interface NavItemProps : PropsWithChildren {
    var `as`: Any
    var role: String
    var bsPrefix: String
    var className: ClassName
}