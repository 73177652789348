package della8.core.screens

import della8.core.items.ArticleItem
import della8.core.services.listArticles
import della8.core.support.*
import techla.base.*
import techla.content.ShortLink
import techla.order.Article

/*fun shortLink(shortLink: List<ShortLink>, name: String): String {
  return shortLink.firstOrNull { it.name == name }?.short.toString()
}
 */

object StorefrontScreen {
    data class Texts(
        val welcome: String,
        val body: String,
        val titleProjectWinterVillage: String,
        val bodyProjectWinterVillage: String,
        val titleBengt: String,
        val bodyBengt: String,
        val titleResurs: String,
        val bodyResurs: String,
        val findFriend: String,
        val toProjectWinterVillage: String,
        val toBengt: String,
        val toResurs: String,
        override val failureTitle: String,
        override val metaTitle: String,
        override val metaDescription: String,
    ) : FailureTexts, MetaTexts


    data class ShortLinks(
        val projectWinterVillage: String,
        val bengt: String,
        val resurs: String,
    )

    data class State(
        val articles: List<Article> = emptyList(),
        val shortLinks: List<ShortLink> = emptyList()
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation, open val meta: DesignSystem.Meta, open var shortLinks: ShortLinks) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimal,
            meta = DesignSystem.Meta.minimal,
            shortLinks = ShortLinks("", "", "")
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            override val meta: DesignSystem.Meta,
            override var shortLinks: ShortLinks,
        ) : ViewModel(texts, state, navigation, meta, shortLinks)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            override val meta: DesignSystem.Meta,
            override var shortLinks: ShortLinks,
            val welcome: DesignSystem.Text,
            val articlesBengt: List<ArticleItem.ViewModel.Article>,
            val articlesProjectWinterVillage: List<ArticleItem.ViewModel.Article>,
            val footer: DesignSystem.Footer,
            val headerImage: DesignSystem.ImageView,
            val body: DesignSystem.Text,
            val projectWinterVillageTitle: DesignSystem.Text,
            val projectWinterVillageBody: DesignSystem.Text,
            val bengtTitle: DesignSystem.Text,
            val bengtBody: DesignSystem.Text,
            val resursTitle: DesignSystem.Text,
            val resursBody: DesignSystem.Text,
            val findFriend: DesignSystem.Button,
            val toBengt: DesignSystem.Button,
            val toProjectWinterVillage: DesignSystem.Button,
            val toResurs: DesignSystem.Button,
            val resurs: DesignSystem.ImageView,
        ) : ViewModel(texts, state, navigation, meta, shortLinks)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            override val meta: DesignSystem.Meta,
            override var shortLinks: ShortLinks,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation, meta, shortLinks)

        fun loading(): ViewModel =
            Loading(
                texts = texts,
                state = state,
                navigation = navigation,
                shortLinks = shortLinks,
                meta = meta(texts)
            )

        fun ready(texts: Texts, state: State, articles: List<ArticleItem.ViewModel.Article>): ViewModel =
            Ready(
                texts = texts,
                state = state,
                shortLinks = shortLinks,
                navigation = DesignSystem.Navigation.front(location = Location.Storefront),
                meta = meta(texts),
                welcome = DesignSystem.Text(text = texts.welcome, style = DesignSystem.TextStyle.LARGE_TITLE, background = DesignSystem.Background.DARK),
                body = DesignSystem.Text(text = texts.body, style = DesignSystem.TextStyle.BODY, background = DesignSystem.Background.DARK),
                headerImage = DesignSystem.ImageView(image = DesignSystem.Image.OBJECTS_TO_COWN),
                projectWinterVillageTitle = DesignSystem.Text(text = texts.titleProjectWinterVillage, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.DARK),
                projectWinterVillageBody = DesignSystem.Text(text = texts.bodyProjectWinterVillage, style = DesignSystem.TextStyle.BODY, background = DesignSystem.Background.DARK, isMarkdown = true),
                bengtTitle = DesignSystem.Text(text = texts.titleBengt, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.DARK),
                bengtBody = DesignSystem.Text(text = texts.bodyBengt, style = DesignSystem.TextStyle.BODY, background = DesignSystem.Background.DARK, isMarkdown = true),
                resursTitle = DesignSystem.Text(text = texts.titleResurs, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.DARK),
                resursBody = DesignSystem.Text(text = texts.bodyResurs, style = DesignSystem.TextStyle.BODY, background = DesignSystem.Background.DARK, isMarkdown = true),
                findFriend = DesignSystem.Button(title = texts.findFriend, style = DesignSystem.ButtonStyle.PRIMARY, location = Location.Interested),
                toBengt = DesignSystem.Button(title = texts.toBengt, style = DesignSystem.ButtonStyle.PRIMARY, location = Location.External(shortLinks.bengt)),
                toProjectWinterVillage = DesignSystem.Button(title = texts.toProjectWinterVillage, style = DesignSystem.ButtonStyle.PRIMARY, location = Location.External(shortLinks.projectWinterVillage)),
                toResurs = DesignSystem.Button(title = texts.toResurs, style = DesignSystem.ButtonStyle.PRIMARY, location = Location.External(shortLinks.resurs)),
                resurs = DesignSystem.ImageView(image = DesignSystem.Image.RESUSR),
                footer = DesignSystem.Footer.front(),
                articlesBengt = articles.filter { it.category == "BENGT" },
                articlesProjectWinterVillage = articles.filter { it.category == "VINTERBY" },
            )

        fun failed(failure: Either<List<Warning>, Throwable>): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                meta = meta(texts),
                shortLinks = shortLinks,
                failure = failure(texts = texts, failure = failure),
            )

        fun failed(message: String): ViewModel =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return sceneOf(viewModel.loading())
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.refreshFront()
            .map { (actions, _) ->
                val updated = store.reduce(actions = actions)
                val texts = Texts(
                    welcome = updated.get(media = Key("screen:storefront"), content = Key("welcome")),
                    failureTitle = updated.get(media = Key("screen:start"), content = Key("failureTitle")),
                    metaTitle = updated.get(media = Key("screen:storefront"), content = Key("metaTitle")),
                    metaDescription = updated.get(media = Key("screen:storefront"), content = Key("metaDescription")),
                    body = updated.get(media = Key("screen:storefront"), content = Key("body")),
                    titleProjectWinterVillage = updated.get(media = Key("screen:storefront"), content = Key("titleProjectWinterVillage")),
                    bodyProjectWinterVillage = updated.get(media = Key("screen:storefront"), content = Key("bodyProjectWinterVillage")),
                    titleBengt = updated.get(media = Key("screen:storefront"), content = Key("titleBengt")),
                    bodyBengt = updated.get(media = Key("screen:storefront"), content = Key("bodyBengt")),
                    titleResurs = updated.get(media = Key("screen:storefront"), content = Key("titleResurs")),
                    bodyResurs = updated.get(media = Key("screen:storefront"), content = Key("bodyResurs")),
                    findFriend = updated.get(media = Key("screen:storefront"), content = Key("findFriend")),
                    toProjectWinterVillage = updated.get(media = Key("screen:storefront"), content = Key("toProjectWinterVillage")),
                    toBengt = updated.get(media = Key("screen:storefront"), content = Key("toBengt")),
                    toResurs = updated.get(media = Key("screen:storefront"), content = Key("toResurs")),
                )
                tupleOf(actions, texts)
            }
            .flatMap { (actions, texts) ->
                store.reduce(actions = actions).listArticles()
                    .accumulate(actions)
                    .map { tupleOf(it.first, texts, it.second) }
            }
            .map { (actions, texts, articles) ->
                val state = viewModel.state.copy(
                    articles = articles
                )

                /*val shortLinks = ShortLinks(
                    bengt = store.shortLinks?.let { shortLink(it, "Bengt") } ?: "",
                    resurs = store.shortLinks?.let { shortLink(it, "Resurs") } ?: "",
                    projectWinterVillage = store.shortLinks?.let { shortLink(it, "ProjectWinterVillage") } ?: ""
                )
                 */

                sceneOf(viewModel.ready(texts, state, buildItems(articles, texts)), actions)
            }
            .failed { scene.failed(result = it) }
    }

    private fun buildItems(articles: List<Article>, texts: Texts): List<ArticleItem.ViewModel.Article> {
        return articles.map {
            ArticleItem.article(article = it)
        }
    }
}

