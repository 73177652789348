package della8.core.support

import techla.base.Identifier
import techla.base.Key
import techla.conversation.Message

sealed class Location {
    object None : Location()
    object Start : Location()
    object Landing : Location()
    object Login : Location()
    object Interested : Location()
    object Subscribe : Location()
    object Calculator : Location()
    object About : Location()
    object Create : Location()
    object Articles : Location()
    object Storefront : Location()
    object RedeemCode : Location()
    object Profile : Location()
    object Policy : Location()
    object Cookies : Location()
    object Dashboard : Location()
    object Advanced : Location()
    data class Overview(val objectId: Identifier<Object>, val agreementId: Identifier<techla.agreement.Agreement>) : Location()
    data class Agreement(val objectId: Identifier<Object>, val agreementId: Identifier<techla.agreement.Agreement>) : Location()
    data class Invite(val objectId: Identifier<Object>, val agreementId: Identifier<techla.agreement.Agreement>) : Location()
    data class Feed(val objectId: Identifier<Object>, val agreementId: Identifier<techla.agreement.Agreement>) : Location()
    data class Settings(val objectId: Identifier<Object>) : Location()
    data class Draft(val objectId: Identifier<Object>) : Location()
    data class Article(val articleId: Identifier<Message>) : Location()

    data class Reservation(val objectId: Identifier<Object>, val agreementId: Identifier<techla.agreement.Agreement>) : Location()
    data class ReservationHelp(val objectId: Identifier<Object>, val agreementId: Identifier<techla.agreement.Agreement>) : Location()
    data class Availability(val objectId: Identifier<Object>, val agreementId: Identifier<techla.agreement.Agreement>) : Location()
    data class Partnership(val objectId: Identifier<Object>, val agreementId: Identifier<techla.agreement.Agreement>) : Location()
    data class PartnershipHelp(val objectId: Identifier<Object>, val agreementId: Identifier<techla.agreement.Agreement>) : Location()

    data class Shop(val objectId: Identifier<Object>, val agreementId: Identifier<techla.agreement.Agreement>) : Location()

    data class Rules(val objectId: Identifier<Object>, val agreementId: Identifier<techla.agreement.Agreement>) : Location()
    data class Post(val objectId: Identifier<Object>, val agreementId: Identifier<techla.agreement.Agreement>) : Location()
    data class External(val href: String) : Location()

    val route
        get() =
            when (this) {
                is None -> ""
                is Start -> "/"
                is Login -> "/login"
                is Article -> "/article/${articleId.rawValue}"
                is Articles -> "/articles"
                is Storefront -> "/storefront"
                is Landing -> "/landing"
                is Create -> "/create"
                is Interested -> "/interest"
                is Subscribe -> "/subscribe"
                is RedeemCode -> "/redeem"
                is Profile -> "/profile"
                is About -> "/about"
                is Calculator -> "/calculator"
                is Overview -> "/object/${objectId.rawValue}/${agreementId.rawValue}"
                is Reservation -> "/object/${objectId}/${agreementId}/reservation"
                is ReservationHelp -> "/object/${objectId}/${agreementId}/reservation/help"
                is Availability -> "/object/${objectId}/${agreementId}/availability"
                is Partnership -> "/object/${objectId}/${agreementId}/partnership"
                is PartnershipHelp -> "/object/${objectId}/${agreementId}/partnership/help"
                is Invite -> "/object/${objectId.rawValue}/${agreementId.rawValue}/invite"
                is Agreement -> "/object/${objectId.rawValue}/${agreementId.rawValue}/agreement"
                is Shop -> "/object/${objectId.rawValue}/${agreementId.rawValue}/shop"
                is Rules -> "/object/${objectId.rawValue}/${agreementId.rawValue}/:mode/:module/rules"
                is Settings -> "/object/${objectId.rawValue}/settings"
                is Draft -> "/object/${objectId.rawValue}/draft"
                is Feed -> "/object/${objectId.rawValue}/${agreementId.rawValue}/feed"
                is Post -> "/object/${objectId.rawValue}/${agreementId.rawValue}/feed/post"
                is Policy -> "/policy"
                is Cookies -> "/cookies"
                is Dashboard -> "/dashboard"
                is Advanced -> "/advanced"
                is External -> href
            }

    companion object {
        fun overview(obj: Object) = Overview(objectId = obj.id, agreementId = obj.currentAgreement.id)
        fun agreement(obj: Object) = Agreement(objectId = obj.id, agreementId = obj.currentAgreement.id)
        fun invite(obj: Object) = Invite(objectId = obj.id, agreementId = obj.currentAgreement.id)
        fun feed(obj: Object) = Feed(objectId = obj.id, agreementId = obj.currentAgreement.id)
        fun reservation(obj: Object) = Reservation(objectId = obj.id, agreementId = obj.currentAgreement.id)
        fun reservationHelp(obj: Object) = ReservationHelp(objectId = obj.id, agreementId = obj.currentAgreement.id)
        fun availability(obj: Object) = Availability(objectId = obj.id, agreementId = obj.currentAgreement.id)
        fun partnership(obj: Object) = Partnership(objectId = obj.id, agreementId = obj.currentAgreement.id)
        fun partnershipHelp(obj: Object) = PartnershipHelp(objectId = obj.id, agreementId = obj.currentAgreement.id)
        fun post(obj: Object) = Post(objectId = obj.id, agreementId = obj.currentAgreement.id)
        fun shop(obj: Object) = Shop(objectId = obj.id, agreementId = obj.currentAgreement.id)
        fun rules(obj: Object) = Rules(objectId = obj.id, agreementId = obj.currentAgreement.id)
        fun settings(obj: Object) = Settings(objectId = obj.id)
        fun draft(obj: Object) = Draft(objectId = obj.id)
        fun guess(externalLink: Message.Attachment.ExternalLink): Location =
            when (externalLink.key.rawValue) {
                "ABOUT" -> About
                "ARTICLES" -> Articles
                "CALCULATOR" -> Calculator
                "COOKIES" -> Cookies
                "INTERESTED" -> Interested
                "LOGIN" -> Login
                "POLICY" -> Policy
                "SUBSCRIBE" -> Subscribe
                else -> External(externalLink.url.toString())
            }
    }
}