package della8.core.screens

import della8.core.services.*
import della8.core.support.*
import techla.base.*
import techla.guard.UserAuthentication
import techla.personal.Narrative

object LoginScreen {
    object Header {
        val email = DesignSystem.Header(id = "email")
        val govId = DesignSystem.Header(id = "govId")
        val remember = DesignSystem.Header(id = "remember")
    }

    data class Texts(
        val ready: String,
        val starting: String,
        val additional: String,
        val next: String,
        val cancel: String,
        val other: String,
        val email: String,
        val start: String,
        val terms: String,
        val open: String,
        val first: String,
        val cancelled: String,
        val expired: String,
        val back: String,
        val restriction: String,
        val unauthorizedTitle: String,
        val unauthorizedBody: String,
        val follow: String,
        val accept: String,
        val govId: String,
        val infoTitle: String,
        val infoBody: String,
        val emailFormat: String,
        val emailRequired: String,
        val govIdFormat: String,
        val govIdRequired: String,
        val govIdLength: String,
        val remember: String,
        val reloadLoginTitle: String,
        val reloadLoginBody: String,
        override val failureTitle: String,
    ) : FailureTexts {
        companion object
    }

    data class State(
        val version: String,
        val email: String?,
        val govId: String?,
        val narrative: Narrative?,
        val remember: Boolean,
        val della8Home: String,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State("", null, null, null, false, ""),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val version: DesignSystem.Text,
            val start: DesignSystem.Button,
            val other: DesignSystem.Button,
            val terms: DesignSystem.Button,
            val accept: DesignSystem.Text,
            val status: DesignSystem.Status,
            val govId: DesignSystem.TextInput,
            val remember: DesignSystem.BooleanInput,
            val infoTitle: DesignSystem.Text,
            val infoBody: DesignSystem.Text,
            val dellaPrice: DesignSystem.ImageView,
        ) : ViewModel(texts, state, navigation)

        data class Enter(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val start: DesignSystem.Button,
            val status: DesignSystem.Status,
        ) : ViewModel(texts, state, navigation)

        data class Starting(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val cancel: DesignSystem.Button
        ) : ViewModel(texts, state, navigation)

        class Waiting(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        class Finished(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        data class Additional(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val email: DesignSystem.TextInput,
            val next: DesignSystem.Button,
            val cancel: DesignSystem.Button,
        ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        fun loading() = Loading(texts = texts, state = state, navigation = navigation)

        fun ready(texts: Texts, state: State, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backDark(title = texts.back, location = Location.Start, action = DesignSystem.Action.DELLA8_HOME),
                title = DesignSystem.Text(text = texts.ready, style = DesignSystem.TextStyle.TITLE2, background = DesignSystem.Background.DARK),
                version = DesignSystem.Text(text = state.version, style = DesignSystem.TextStyle.FOOTNOTE, background = DesignSystem.Background.DARK),
                start = DesignSystem.Button(title = texts.start, image = DesignSystem.Image.BANK_ID, style = DesignSystem.ButtonStyle.PRIMARY),
                other = DesignSystem.Button(title = texts.other, style = DesignSystem.ButtonStyle.TRANSPARENT),
                accept = DesignSystem.Text(text = texts.accept, style = DesignSystem.TextStyle.BODY),
                terms = DesignSystem.Button(title = texts.terms, style = DesignSystem.ButtonStyle.TRANSPARENT),
                status = status.overallStatus(),
                govId = DesignSystem.TextInput(
                    header = Header.govId,
                    title = texts.govId,
                    value = state.govId,
                    input = DesignSystem.Input.GOV_ID,
                    status = status.statusOf(Header.govId)
                ),
                remember = DesignSystem.BooleanInput(header = Header.remember, title = texts.remember, value = state.remember, status = status.statusOf(Header.remember)),
                infoTitle = DesignSystem.Text(text = texts.infoTitle, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.DARK),
                infoBody = DesignSystem.Text(text = texts.infoBody, isMarkdown = true, background = DesignSystem.Background.DARK, image = DesignSystem.Image.CHECKED),
                dellaPrice = DesignSystem.ImageView(image = DesignSystem.Image.PRICEHINT),
            )

        fun enter(
            status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()
        ) =
            Enter(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.minimal,
                title = DesignSystem.Text(text = texts.ready, style = DesignSystem.TextStyle.TITLE1),
                start = DesignSystem.Button(
                    title = texts.start,
                    image = DesignSystem.Image.BANK_ID,
                    style = DesignSystem.ButtonStyle.PRIMARY
                ),
                status = status.overallStatus(),
            )

        fun starting(state: State? = null) =
            (state ?: this.state).run {
                Starting(
                    texts = texts,
                    state = this,
                    navigation = DesignSystem.Navigation.minimal,
                    title = DesignSystem.Text(
                        text = texts.starting,
                        style = DesignSystem.TextStyle.TITLE1
                    ),
                    info = DesignSystem.Text(
                        text = texts.open,
                        style = DesignSystem.TextStyle.BODY
                    ),
                    cancel = DesignSystem.Button(
                        title = texts.cancel,
                        style = DesignSystem.ButtonStyle.TRANSPARENT
                    )
                )
            }

        fun waiting() =
            Waiting(texts = texts, state = state, navigation = navigation)

        fun finished() =
            Finished(texts = texts, state = state, navigation = navigation)

        fun additional(state: State? = null, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()) =
            (state ?: this.state).run {
                Additional(
                    texts = texts,
                    state = this,

                    navigation = DesignSystem.Navigation.minimal,
                    title = DesignSystem.Text(
                        text = texts.additional,
                        style = DesignSystem.TextStyle.TITLE1
                    ),
                    info = DesignSystem.Text(
                        text = texts.first,
                        style = DesignSystem.TextStyle.BODY
                    ),
                    email = DesignSystem.TextInput(
                        header = Header.email,
                        title = texts.email,
                        value = email,
                        input = DesignSystem.Input.EMAIL,
                        status = status.statusOf(Header.email)
                    ),
                    next = DesignSystem.Button(
                        title = texts.next,
                        style = DesignSystem.ButtonStyle.PRIMARY
                    ),
                    cancel = DesignSystem.Button(
                        title = texts.cancel,
                        style = DesignSystem.ButtonStyle.TRANSPARENT
                    ),
                )
            }

        fun failed(failure: Either<List<Warning>, Throwable>): ViewModel {

            val f = when (failure) {
                is Either.Left -> failure
                is Either.Right ->
                    when (failure.value) {
                        is TechlaError.ServiceUnavailable -> failure.copy(value = Throwable(texts.reloadLoginBody))
                        else -> failure
                    }
            }

            val t = when (failure) {
                is Either.Left -> texts
                is Either.Right ->
                    when (failure.value) {
                        is TechlaError.ServiceUnavailable -> texts.copy(failureTitle = texts.reloadLoginTitle)
                        else -> texts
                    }
            }

            return Failed(
                texts = t,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = t, failure = f),
            )
        }

        fun missing(key: String) =
            failed(message = "Missing key '$key'")

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val ready get() = this as? Ready
        val enter get() = this as? Enter
        val starting get() = this as? Starting
        val additional get() = this as? Additional
        val failed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.missing(key: String) =
        sceneOf(viewModel.missing(key))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))


    private fun Scene.Input<ViewModel>.failed(message: String) =
        sceneOf(viewModel.failed(message))


    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene

        return sceneOf<ViewModel>(viewModel.loading())
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.refreshFront()
            .flatMap { (actions, _) ->
                store.reduce(actions).createUserAuthentication().noActions().accumulate(actions)
            }
            .map { (actions, userAuthentication) ->
                val action = Store.Action.UserAuthenticationStarted(
                    userAuthenticationId = userAuthentication.index.id,
                    tokens = userAuthentication.tokens,
                )
                val updated = store.reduce(actions = actions + action)

                val texts = Texts(
                    ready = updated.get(media = Key("screen:login"), content = Key("signIn")),
                    starting = updated.get(media = Key("screen:login"), content = Key("starting")),
                    additional = updated.get(media = Key("screen:login"), content = Key("additional")),
                    next = updated.get(media = Key("screen:login"), content = Key("next")),
                    cancel = updated.get(media = Key("screen:login"), content = Key("cancel")),
                    other = updated.get(media = Key("screen:login"), content = Key("other")),
                    email = updated.get(media = Key("screen:login"), content = Key("email")),
                    start = updated.get(media = Key("screen:login"), content = Key("start")),
                    terms = updated.get(media = Key("screen:login"), content = Key("terms")),
                    accept = updated.get(media = Key("screen:login"), content = Key("accept")),
                    open = updated.get(media = Key("screen:login"), content = Key("open")),
                    first = updated.get(media = Key("screen:login"), content = Key("first")),
                    cancelled = updated.get(media = Key("screen:login"), content = Key("cancelled")),
                    expired = updated.get(media = Key("screen:login"), content = Key("expired")),
                    back = updated.get(media = Key("screen:login"), content = Key("back")),
                    restriction = updated.get(media = Key("screen:login"), content = Key("restriction")),
                    unauthorizedTitle = updated.get(media = Key("screen:login"), content = Key("unauthorizedTitle")),
                    unauthorizedBody = updated.get(media = Key("screen:login"), content = Key("unauthorizedBody")),

                    emailFormat = updated.get(media = Key("screen:login"), content = Key("emailFormat")),
                    emailRequired = updated.get(media = Key("screen:login"), content = Key("emailRequired")),
                    failureTitle = "Oj, ett fel har uppstått",
                    follow = updated.get(media = Key("screen:login"), content = Key("follow")),
                    govIdFormat = updated.get(media = Key("screen:login"), content = Key("govIdFormat")),
                    govIdRequired = updated.get(media = Key("screen:login"), content = Key("govIdRequired")),
                    govIdLength = updated.get(media = Key("screen:login"), content = Key("govIdLength")),
                    remember = updated.get(media = Key("screen:login"), content = Key("remember")),
                    govId = updated.get(media = Key("screen:login"), content = Key("govId")),
                    infoTitle = updated.get(media = Key("screen:login"), content = Key("infoTitle")),
                    infoBody = updated.get(media = Key("screen:login"), content = Key("infoBody")),
                    reloadLoginTitle = updated.get(media = Key("screen:login"), content = Key("reloadLoginTitle")),
                    reloadLoginBody = updated.get(media = Key("screen:login"), content = Key("reloadLoginBody")),
                )

                val version = if (store.deployment.isSandbox)
                    "${store.deployment.version.replace("-SNAPSHOT", "")}(${store.deployment.build})"
                else
                    store.deployment.version.replace("-SNAPSHOT", "")

                val state = viewModel.state.copy(
                    version = version,
                    della8Home = store.deployment.della8Home
                )
                sceneOf<ViewModel>(viewModel.ready(texts = texts, state = state), actions + action)
            }
            .failed { scene.failed(result = it) }
    }


    suspend fun login(scene: Scene.Input<ViewModel>, govId: String, remember: Boolean): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val status: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()
        var state = viewModel.state.copy(
            govId = govId,
            remember = remember,
        )

        Validator.govId(
            govId = state.govId,
            illegalCharacters = { status.add(Header.govId to DesignSystem.Status.Invalid(warning = viewModel.texts.govIdFormat)) },
            isEmpty = { status.add(Header.govId to DesignSystem.Status.Invalid(warning = viewModel.texts.govIdRequired)) },
            isWrongLength = { status.add(Header.govId to DesignSystem.Status.Invalid(warning = viewModel.texts.govIdLength)) },
            formatted = { state = state.copy(govId = it) },
            passed = { status.add(Header.govId to DesignSystem.Status.Valid) },
        )

        if (status.overallStatus() !is DesignSystem.Status.Valid)
            return sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state, status = status))

        return successfulOf(true)
            .flatMap { store.createUserAuthentication(govId = state.govId) }
            .map {
                val action = Store.Action.UserAuthenticationStarted(
                    userAuthenticationId = it.index.id,
                    tokens = it.tokens,
                    demoMode = false
                )
                sceneOf<ViewModel>(viewModel.starting(state = state), action)
            }
            .failed {
                scene.failed(result = it)
            }
    }


    suspend fun cancel(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return successfulOf(true)
            .flatMap { store.deleteUserAuthentication() }
            .map {
                val state = viewModel.state

                sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state))
            }
            .failed { scene.failed(result = it) }
    }


    suspend fun other(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene

        return successfulOf(true)
            .map {
                sceneOf<ViewModel>(viewModel.enter())
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun check(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(true)
            .flatMap { store.getUserAuthentication() }
            .map { userAuthentication ->
                when (userAuthentication.status) {
                    is UserAuthentication.Status.Complete -> {
                        val action = Store.Action.UserAuthenticationCompleted(
                            tokens = userAuthentication.tokens,
                            profileId = userAuthentication.profileId!!,
                        )
                        sceneOf<ViewModel>(viewModel.finished(), action)
                    }

                    is UserAuthentication.Status.Cancelled ->
                        sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = viewModel.state))// scene.failed(message = viewModel.texts.cancelled)
                    is UserAuthentication.Status.Expired ->
                        scene.failed(message = viewModel.texts.expired)

                    is UserAuthentication.Status.Failed, is UserAuthentication.Status.Verified ->
                        scene.failed(message = userAuthentication.reason ?: "Unknown reason")

                    is UserAuthentication.Status.Outstanding -> {
                        val action = Store.Action.UserAuthenticationStarted(
                            userAuthenticationId = userAuthentication.index.id,
                            tokens = userAuthentication.tokens
                        )
                        sceneOf<ViewModel>(viewModel.waiting(), action)
                    }
                }
            }
            .flatMap { (viewModel, actions) ->
                when (viewModel) {
                    is ViewModel.Finished -> {
                        store.reduce(actions).myNarrative()
                            .accumulate(actions)
                            .flatMap { (actions, narrative) ->
                                store.reduce(actions).refreshObjects()
                                    .accumulate(actions)
                                    .map { tupleOf(it.first, narrative) }
                            }
                            .map { (actions, narrative) ->
                                techla_log("Store me at check: ${store.reduce(actions).me}")

                                val state = viewModel.state.copy(narrative = narrative)
                                if (narrative?.email.isNullOrEmpty())
                                    sceneOf(viewModel.additional(state = state), actions)
                                else
                                    sceneOf(viewModel, actions)
                            }
                    }

                    //  is ViewModel.Failed -> successfulOf(sceneOf<ViewModel>(viewModel.unauthorized(), actions)) //TODO - Remove this
                    else -> successfulOf(sceneOf(viewModel, actions))
                }
            }
            .failed {
                scene.failed(result = it) //TODO - Remove this sceneOf<ViewModel>(viewModel.unauthorized())
            }
    }

    suspend fun update(scene: Scene.Input<ViewModel>, email: String): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val status: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()
        val profileId = store.profileId ?: return scene.missing("profileId")
        var state = viewModel.state

        Validator.email(
            email = email,
            isWrongFormat = { status.add(Header.email to DesignSystem.Status.Invalid(warning = viewModel.texts.emailFormat)) },
            isEmpty = { status.add(Header.email to DesignSystem.Status.Invalid(warning = viewModel.texts.emailRequired)) },
            formatted = { state = state.copy(email = it) },
            passed = { status.add(Header.email to DesignSystem.Status.Valid) },
        )

        if (status.overallStatus() !is DesignSystem.Status.Valid)
            return sceneOf<ViewModel>(viewModel.additional(state = state, status = status))

        techla_log("Store me at create/edit: ${store.me}")

        return successfulOf(viewModel.state.narrative)
            .flatMap { narrative ->
                if (narrative == null) {
                    val create = Narrative.Create(
                        profileId = profileId,
                        firstName = store.me?.firstName,
                        lastName = store.me?.lastName,
                        email = email,
                    )
                    store.createNarrative(create = create)
                } else {
                    val edit = Narrative.Edit(
                        firstName = if (narrative.firstName.isNullOrEmpty()) modifiedOf(store.me?.firstName) else Modification.Unmodified,
                        lastName = if (narrative.lastName.isNullOrEmpty()) modifiedOf(store.me?.lastName) else Modification.Unmodified,
                        email = modifiedOf(email),
                    )
                    store.editNarrative(id = narrative.id, edit = edit)
                }
            }
            .map {
                sceneOf<ViewModel>(viewModel.finished())
            }
            .failed { scene.failed(result = it) }
    }


    fun saveInLocalStorage(scene: Scene.Input<ViewModel>, govId: String, remember: Boolean): Scene.Output<ViewModel> {
        val (_, viewModel) = scene

        val saveMe = if (remember) govId else ""

        val action = Store.Action.RememberMe(rememberMe = saveMe)
        return sceneOf(viewModel.ready(texts = viewModel.texts, state = viewModel.state), action)
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }

    fun update(scene: Scene.Input<ViewModel>, govId: String?, email: String?, remember: Boolean?): Scene.Output<ViewModel> {
        val (_, viewModel) = scene

        val state = viewModel.state.copy(
            govId = govId ?: viewModel.state.govId,
            email = email ?: viewModel.state.email,
            remember = remember ?: viewModel.state.remember
        )

        return sceneOf(viewModel.ready(texts = viewModel.texts, state = state))
    }
}




