package della8.web.items

import bootstrap.bCard
import bootstrap.bCardBody
import della8.core.items.LandingItem
import della8.core.support.DesignSystem
import della8.core.support.Location
import della8.core.support.Object
import della8.web.components.*
import della8.web.support.className
import react.*
import react.dom.html.ReactHTML.div
import react.router.useNavigate
import techla.agreement.Agreement
import techla.base.Identifier

external interface LandingItemProps : PropsWithChildren {
    var className: String?
    var viewModel: LandingItem.ViewModel
    var onClick: suspend (id: Identifier<LandingItem>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>) -> Unit
}


val LandingItem = FC<LandingItemProps>("LandingItem") { props ->
    val navigate = useNavigate()

    suspend fun onClick(id: Identifier<LandingItem>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>) {
        props.onClick(id, objectId, agreementId)
    }

    suspend fun onMenu(option: DesignSystem.Option) {
        ((option.data as? Location) ?: option.locationValue)?.let { location ->
            navigate(location.route)
        }
    }

    when (val viewModel = props.viewModel) {
        is LandingItem.ViewModel.None -> {
        }
        is LandingItem.ViewModel.Group -> {
            div {
                className = className("px-1 py-4 px-md-4 py-md-4 d-flex align-items-stretch")
                bCard {
                    className = className("radius-25 border-0 card-shadow")
                    bCardBody {
                        className = className("p-0")
                        div {
                            className = className("p-0")
                            div {
                                className = className("p-0 position-relative overflow-hidden")
                                d8Movie(className = "img-fluid radius-25 n-video-card", design = viewModel.movie)
                            }
                            div {
                                className = className("p-4")
                                d8Text(className = "d-inline", design = viewModel.title)
                                d8Text(design = viewModel.owners)
                                div {
                                    className = className("d-flex justify-content-start pb-2 mt-3")
                                    viewModel.profiles.map { profile -> d8Element(design = profile) }
                                }
                                d8Button(className = "me-2 mt-2", design = viewModel.approved) { onClick(viewModel.id, viewModel.objectId, Identifier(viewModel.approved.data as String)) }
                                d8Button(className = "mt-2", design = viewModel.outstanding) { onClick(viewModel.id, viewModel.objectId, Identifier(viewModel.outstanding.data as String)) }
                                d8SelectInput(classNameInput = "d-inline float-end", design = viewModel.menu, onClick = ::onMenu)
                            }
                        }
                    }
                }
            }
        }
    }
}

fun ChildrenBuilder.landingItem(
    viewModel: LandingItem.ViewModel,
    className: String? = null,
    onClick: suspend (id: Identifier<LandingItem>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>) -> Unit = { _, _, _ -> }
) =
    LandingItem {
        this.className = className
        this.viewModel = viewModel
        this.onClick = onClick
    }
