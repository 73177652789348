package della8.web.items

import della8.core.items.RuleItem
import della8.core.screens.RuleValue
import della8.web.components.d8Text
import della8.web.support.className
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.div

external interface D8RuleItemFooterProps : PropsWithChildren {
    var className: String
    var viewModel: RuleItem.ViewModel
}

val D8RuleItemFooter = FC<D8RuleItemFooterProps>("D8RuleItemFooter") { props ->
    if (props.viewModel.value !is RuleValue.Off) {
        div {
            className = className("dashedBorder mt-2 mb-2")
        }
    }
    div {
        className = className("d-flex flex-row")
        if (props.viewModel.editable && props.viewModel.value !is RuleValue.Off) {

            d8Text(className = "mt-2", design = props.viewModel.footer.tips)
        }
    }
}
