package della8.core.screens

import della8.core.support.*
import techla.agreement.Agreement
import techla.base.*

object PartnershipHelpScreen {
    data class Texts(
        val title: String,
        val body: String,
        val back: String,
        override val progressInfo: String,
        override val failureTitle: String,
    ) : ProgressTexts, FailureTexts {
        companion object
    }


    sealed class ViewModel(open val texts: Texts, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", ""),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, navigation) {
            companion object
        }

        data class Ready(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val body: DesignSystem.Text,
        ) : ViewModel(texts, navigation) {
            companion object
        }

        data class Failed(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, navigation) {
            companion object
        }

        fun loading(texts: Texts) =
            Loading(
                texts = texts,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(texts = texts)
            )

        fun ready(texts: Texts) =
            Ready(
                texts = texts,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing),
                title = DesignSystem.Text(
                    text = texts.title,
                    style = DesignSystem.TextStyle.TITLE1
                ),
                body = DesignSystem.Text(text = texts.body, isMarkdown = true),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false) =
            Failed(
                texts = texts,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))
        val load get() = this as? Loading
        val ready get() = this as? Ready
        val failed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))
    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        return successfulOf(Unit)
            .map {
                val texts = Texts(
                    title = "Della8 informerar",
                    body = """
            Här kan ni ta del av ert avtal som ni utformat efter era behov och önskemål. Ni har alla signerat detta med BankID för ett tryggt samägande. Ni kan när som helst ändra i ert avtal, men kom ihåg att det nya avtalet inte gäller förrän alla samägare har signerat på nytt.  
            
            Har ni någon fråga eller feedback får ni gärna maila oss på [hej@della8.se](mailto:hej@della8.se) 
            """.trimIndent(),
                    back = "Tillbaka",
                    progressInfo = "Laddar...",
                    failureTitle = "Oj, ett fel har uppstått",
                )
                sceneOf<ViewModel>(viewModel.loading(texts = texts))
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.refreshObject(objectId = objectId, agreementId = agreementId)
            .map { (actions, obj) ->

                sceneOf<ViewModel>(viewModel.ready(viewModel.texts), actions)
            }
            .failed { scene.failed(result = it) }
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }
}



