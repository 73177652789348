package della8.web.views

import StoreContext
import bootstrap.*
import della8.core.items.MessageItem
import della8.core.screens.FeedScreen
import della8.core.support.DesignSystem
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.items.messageItemBlog
import della8.web.support.*
import kotlinx.js.get
import react.*
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.router.useParams
import techla.base.Identifier
import techla.base.techla_log

val FeedView = FC<PropsWithChildren>("FeedView") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(FeedScreen.ViewModel.None as FeedScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()

    val scopedCall = scopedCall<FeedScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }
    val call = call<FeedScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }
    val mainCall = mainCall<FeedScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    fun recover() {
        mainCall { FeedScreen.success(sceneInputOf(store, viewModel)) }
    }

    fun logout() {
        mainCall { FeedScreen.logout(sceneInputOf(store, viewModel)) }
        navigate("/")
    }


    fun handleOption(option: DesignSystem.Option) {
        when (option.actionValue) {
            DesignSystem.Action.REMOVE -> mainCall { FeedScreen.removeMessage(sceneInputOf(store, viewModel), Identifier(option.data.toString())) }
            DesignSystem.Action.EDIT -> navigate(option.data.toString())
            DesignSystem.Action.LIKE -> mainCall { FeedScreen.likeMessage(sceneInputOf(store, viewModel), Identifier(option.data.toString())) }
            DesignSystem.Action.COMMENT -> navigate("${option.data}/comment")
            DesignSystem.Action.REACTIONS -> navigate("${option.data}/reactions")

            else -> {}
        }
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is FeedScreen.ViewModel.None ->
                scopedCall(coroutineScope) { FeedScreen.start(sceneInputOf(store, viewModel)) }

            is FeedScreen.ViewModel.Loading -> {
                params["objectId"]?.let { objectId ->
                    params["agreementId"]?.let { agreementId ->
                        scopedCall(coroutineScope) { FeedScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId)) }
                    }
                }
            }

            else -> {}
        }
    }

    main {
        when (viewModel) {
            is FeedScreen.ViewModel.Ready -> d8Navigation(
                design = viewModel.navigation,
                className = "nav-top",
                onClick = standardNavigation(navigate)
            )

            else -> d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
        }

        if (viewModel is FeedScreen.ViewModel.Ready) {
            bContainer {
                className = className("px-5 position-relative n-bottom-space n-top-space")
                bRow {
                    className = className("pb-4 justify-content-center")
                    bCol {
                        xs = 12; md = 9
                        d8Text(className = "text-center", design = viewModel.title)

                        viewModel.items.map { item ->
                            if (item is MessageItem.ViewModel.Blog)
                                messageItemBlog(viewModel = item, onClick = ::handleOption)

                        }
                        if (viewModel.emptyFeedImage.visible)
                            bCard {
                                className = className("radius-25 p-lg-5 p-3 mt-4 border-0 card-shadow d-flex align-items-center")
                                d8Image(className = "img-fluid radius-25 pb-4", design = viewModel.emptyFeedImage)
                                d8Text(className = "pt-1", design = viewModel.emptyFeed)
                            }
                    }
                }
            }
            bContainer {
                fluid = true
                className = className("n-booking-summary d-flex py-4 justify-content-center")
                bRow {
                    className = className("justify-content-center")
                    d8Button(className = "d-block px-5 mx-auto", design = viewModel.next, onClick = { navigate("/object/${params["objectId"]}/${params["agreementId"]}/feed/post") })
                }
            }
        }

        if (viewModel is FeedScreen.ViewModel.Loading) {
            d8Progress(viewModel.progress)
        }


        if (viewModel is FeedScreen.ViewModel.Failed) {
            d8failure(viewModel.failure, onLogout = ::logout, onRecover = ::recover)
        }
    }
}


