package della8.web.views

import StoreContext
import bootstrap.*
import della8.core.items.BookingItem
import della8.core.screens.BookingScreen
import della8.core.support.Object
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.items.bookingItem
import della8.web.support.className
import della8.web.support.useAsyncEffect
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import react.*
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import techla.agreement.Agreement
import techla.base.Identifier
import techla.base.techla_log
import techla.reservation.Reservation

external interface BookingViewProps : PropsWithChildren {
    var cellReservations: List<Identifier<Reservation>>
    var objectId: Identifier<Object>
    var agreementId: Identifier<Agreement>
    var showModal: Boolean
    var handleModal: () -> Unit

}


val BookingView = FC<BookingViewProps>("BookingView") { props ->
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(BookingScreen.ViewModel.None as BookingScreen.ViewModel)
    val navigate = useNavigate()
    val (showModal, setShowModal) = useState(false)
    val (cancelId, setCancelId) = useState<Identifier<BookingItem>>()

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {

            is BookingScreen.ViewModel.None ->
                BookingScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                        techla_log("BookingScreen START")
                    }
                }
            is BookingScreen.ViewModel.Loading -> {
                BookingScreen.load(sceneInputOf(store, viewModel), props.objectId, props.agreementId, props.cellReservations)
                    .also { (viewModel, actions) ->
                        if (coroutineScope.isActive) {
                            setViewModel(viewModel)
                            dispatch(actions)

                            techla_log("BookingScreen LOAD")
                        }
                    }
            }


            is BookingScreen.ViewModel.Ready -> {
                techla_log(" BookingScreen READY")
            }
            is BookingScreen.ViewModel.Failed ->
                techla_log("BookingScreen failed ")
        }
    }


    fun handleLogout() {
        MainScope().launch {
            BookingScreen.logout(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
                navigate("/")
            }
        }
    }

    fun handleCancelBooking(id: Identifier<BookingItem>) {
        setCancelId(id)
        setShowModal(true)
    }

    suspend fun handleConfirmCancel() {
        MainScope().launch {
            if (cancelId != null) {
                BookingScreen.cancelBooking(sceneInputOf(store, viewModel), Identifier(cancelId.rawValue)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                    setShowModal(false)
                }
            }
        }
    }

    main {
        if (viewModel is BookingScreen.ViewModel.Ready) {

            bModal {
                show = (props.showModal)
                onHide = { props.handleModal() }
                centered = true
                size = "md"
                scrollable = true

                bModalHeader {
                    closeButton = true
                    +""
                }
                bModalBody {
                    bContainer {
                        bContainer {
                            bRow {
                                className = className("justify-content-center")
                                d8Text(className = "text-center mb-0", design = viewModel.title)
                            }
                            d8Text(design = viewModel.noBooking)
                            viewModel.items.map {
                                bookingItem(viewModel = it, onClick = ::handleCancelBooking)

                            }
                        }

                    }
                }
            }
            d8Modal(design = viewModel.modal, show = showModal, onHide = { setShowModal(false) }, onClickFirst = { setShowModal(false) }, onClickSecond = ::handleConfirmCancel, size="sm")
        }

        if (viewModel is BookingScreen.ViewModel.Loading) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is BookingScreen.ViewModel.Failed) {
            bContainer {
                className = className(listOfNotNull("px-5 n-top-space"))
                d8failure(viewModel.failure, ::handleLogout)
            }
        }
    }
}



fun ChildrenBuilder.bookingView(
    cellReservations: List<Identifier<Reservation>>,
    objectId: Identifier<Object>,
    agreementId: Identifier<Agreement>,
    showModal: Boolean,
    handleModal: () -> Unit
) =
    BookingView {
        this.cellReservations = cellReservations
        this.objectId = objectId
        this.agreementId = agreementId
        this.showModal = showModal
        this.handleModal = handleModal
    }


