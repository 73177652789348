package della8.web.components

import bootstrap.*
import della8.core.support.DesignSystem
import della8.core.support.Location
import della8.web.support.className
import della8.web.support.useReactScope
import della8.web.theme.Della8Colors
import della8.web.theme.Design
import della8.web.theme.background
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import lottie.lPlayer
import org.w3c.dom.events.Event
import react.*
import react.dom.html.ButtonType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.router.useNavigate
import kotlin.time.ExperimentalTime

external interface D8ButtonProps : PropsWithChildren {
    var className: String?
    var classNameImage: String?
    var design: DesignSystem.Button
    var type: ButtonType
    var onClick: suspend () -> Unit
}

val D8Button = FC<D8ButtonProps>("D8BUTTON") { props ->
    val navigate = useNavigate()
    val onClickAction: (Event) -> Unit = {
        if (props.design.location?.route != null)
            props.design.location?.let {
                when (it) {
                    is Location.External -> window.open(url = it.href, target = "_blank")
                    else -> navigate(it.route)
                }
            }
        else
            MainScope().launch { props.onClick() }
    }
    if (props.design.visible) {
        val background = Della8Colors.designColor(props.design.background).background
        when (props.design.style) {
            DesignSystem.ButtonStyle.PRIMARY ->
                bButton {
                    variant = "primary"
                    className = className(props.className, background)
                    active = props.design.visible
                    onClick = onClickAction
                    disabled = props.design.disabled
                    type = props.type

                    +(props.design.title ?: "")

                    props.design.image?.let {
                        img {
                            src = Design.image(it)
                            alt = Design.imageAltText(it)
                        }
                    }
                }

            DesignSystem.ButtonStyle.SECONDARY ->
                bButton {
                    variant = "secondary"
                    className = className(
                        props.className,
                        "btn-secondary",
                        Della8Colors.snow.background,
                        Della8Colors.sea
                    )
                    active = props.design.visible
                    onClick = onClickAction
                    disabled = props.design.disabled
                    type = props.type

                    +(props.design.title ?: "")
                }

            DesignSystem.ButtonStyle.TERTIARY ->
                bButton {
                    variant = "primary"
                    className = className(
                        props.className,
                        "btn-tertiary",
                        Della8Colors.snow.background,
                        Della8Colors.sea
                    )
                    active = props.design.visible
                    onClick = onClickAction
                    disabled = props.design.disabled
                    type = props.type

                    +(props.design.title ?: "")
                }

            DesignSystem.ButtonStyle.TRANSPARENT ->
                bButton {
                    variant = "none"
                    className = className(props.className, "btn-transparent")
                    active = props.design.visible
                    onClick = onClickAction
                    disabled = props.design.disabled
                    type = props.type
                    when (props.design.iconAlignment) {
                        DesignSystem.IconAlignment.LEFT -> {
                            props.design.image?.let { img { src = Design.image(it); className = className("me-2"); alt = Design.imageAltText(it) } }
                            +(props.design.title ?: "")
                        }

                        DesignSystem.IconAlignment.RIGHT -> {
                            +(props.design.title ?: "")
                            props.design.image?.let { img { src = Design.image(it); alt = Design.imageAltText(it) } }

                        }

                        DesignSystem.IconAlignment.TOP -> {
                            div {
                                className = className("d-flex flex-column justify-content-center")
                                props.design.image?.let { img { src = Design.image(it); alt = Design.imageAltText(it) } }
                                +(props.design.title ?: "")
                            }

                        }

                        else -> +(props.design.title ?: "")
                    }
                }

            DesignSystem.ButtonStyle.IMAGE ->
                bButton {
                    variant = "none"
                    className = className(props.className, background)
                    active = props.design.visible
                    onClick = onClickAction
                    disabled = props.design.disabled
                    type = props.type

                    if (props.design.iconAlignment == DesignSystem.IconAlignment.LEFT) {
                        props.design.image?.let {
                            img {
                                className = className(props.classNameImage)
                                src = Design.image(it)
                                alt = Design.imageAltText(it)
                            }
                        }
                    }

                    +(props.design.title ?: "")

                    if (props.design.iconAlignment == DesignSystem.IconAlignment.RIGHT) {
                        props.design.image?.let {
                            img {
                                src = Design.image(it)
                                alt = Design.imageAltText(it)
                            }
                        }
                    }
                }
        }
    }
}

fun ChildrenBuilder.d8Button(design: DesignSystem.Button, className: String? = null, classNameImage: String? = null, type: ButtonType = ButtonType.button, onClick: suspend () -> Unit = {}) =
    D8Button {
        this.design = design
        this.className = className
        this.classNameImage = classNameImage
        this.onClick = onClick
        this.type = type
    }

external interface D8RadioButtonProps : PropsWithChildren {
    var className: String?
    var design: DesignSystem.RadioButton
    var onClick: suspend () -> Unit
    var selected: Boolean
}

val D8RadioButton = FC<D8RadioButtonProps>("D8_RADIO_BUTTON") { props ->
    if (props.design.visible) {
        bButton {
            variant = "light"
            className = className(
                listOfNotNull(
                    "mb-2 n-radio-btn",
                    props.className
                )
            )
            onClick = { MainScope().launch { props.onClick() } }
            props.design.title?.let { +it }

            if (props.selected) {
                className = className(
                    listOfNotNull(
                        "mb-2 n-radio-btn-selected",
                        props.className
                    )
                )
            }
        }
    }
}

fun ChildrenBuilder.d8RadioButton(
    design: DesignSystem.RadioButton,
    className: String? = null,
    onClick: suspend () -> Unit,
    selected: Boolean
) =
    D8RadioButton {
        this.design = design
        this.className = className
        this.onClick = onClick
        this.selected = selected

    }


external interface D8MenuButtonProps : PropsWithChildren {
    var className: String?
    var classNameLabel: String?
    var design: DesignSystem.MenuButton
    var onClick: suspend (DesignSystem.Option) -> Unit
}


val D8MenuButton = FC<D8MenuButtonProps>("D8MenuButton") { props ->
    val first = props.design.menu?.items?.firstOrNull()
    var selectedValue: String? by useState(null)
    val reactScope by useReactScope()

    useEffect(props.design.selected) {
        selectedValue = props.design.selected?.title
    }

    fun onClick(item: DesignSystem.Option) {
        reactScope.launch {
            props.onClick(item)
        }
    }
    if (props.design.visible) {
        d8Text(className = props.classNameLabel, design = DesignSystem.Text(text = props.design.title, background = DesignSystem.Background.DARK))
        bDropdownButton {
            className = className(props.className)
            title = selectedValue ?: first?.title ?: ""
            bDropdown {
                props.design.menu?.items?.filter { it.visible }?.map { item ->
                    bDropdownItem {
                        onClick = { onClick(item) }
                        p { className = className("n-dropdown-button-p"); +"${item.title}" }
                    }
                }
            }
        }
    }
}


fun ChildrenBuilder.d8MenuButton(
    design: DesignSystem.MenuButton,
    className: String? = null,
    classNameLabel: String = "",
    onClick: suspend (DesignSystem.Option) -> Unit,
) =
    D8MenuButton {
        this.design = design
        this.className = className
        this.classNameLabel = classNameLabel
        this.onClick = onClick
    }
