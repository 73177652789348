package bootstrap

import csstype.ClassName
import react.ComponentType
import react.PropsWithChildren

external interface RowProps : PropsWithChildren {
    var className: ClassName
    var lg: dynamic /* number | { cols: number } */
    var md: dynamic /* number | { cols: number } */
    var sm: dynamic /* number | { cols: number } */
    var xl: dynamic /* number | { cols: number } */
    var xs: dynamic /* number | { cols: number } */
    var noGutters: Boolean?
    var bsPrefix: String
}

@JsNonModule
@JsModule("react-bootstrap/Row")
private external val rowModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bRow: ComponentType<RowProps> = rowModule.default
