package della8.core.screens

import della8.core.support.*
import techla.base.*

object SettingsScreen {

    object Header {
        val name = DesignSystem.Header(id = "name")
    }

    data class Texts(
        val title: String,
        val back: String,
        val name: String,
        val nameRequired: String,
        val done: String,
        val save: String,
        val saved: String,
        val saveInfo: String,
        val delete: String,
        val deleted: String,
        val deleteInfo: String,
        override val progressInfo: String,
        override val failureTitle: String,
    ) : ProgressTexts, FailureTexts {
        companion object
    }

    data class State(
        val name: String,
        val obj: Object,
    )


    sealed class ViewModel(open val texts: Texts, open val state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(obj = Object.None, name = ""),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Saving(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Ready(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val name: DesignSystem.TextInput,
            val save: DesignSystem.Button,
            val delete: DesignSystem.Button,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Saved(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
            val icon: DesignSystem.Icon,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val done: DesignSystem.Button,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Deleted(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
            val icon: DesignSystem.Icon,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val done: DesignSystem.Button,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Failed(
            override val texts: Texts,
            override val state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        fun loading(texts: Texts) = Loading(
            texts = texts,
            state = state,
            navigation = DesignSystem.Navigation.progress(),
            progress = progress(texts = texts)
        )

        fun saving(state: State) = Saving(
            texts = texts,
            state = state,
            navigation = DesignSystem.Navigation.progress(),
            progress = progress(texts = texts)
        )

        fun saved(): ViewModel =
            Saved(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(texts = texts),
                icon = DesignSystem.Icon(image = DesignSystem.Image.CHECKED, background = DesignSystem.Color.OCEAN),
                title = DesignSystem.Text(
                    text = texts.saved,
                    style = DesignSystem.TextStyle.MEGA,
                    background = DesignSystem.Background.LIGHT
                ),
                info = DesignSystem.Text(
                    text = texts.saveInfo,
                    style = DesignSystem.TextStyle.BODY,
                    background = DesignSystem.Background.LIGHT
                ),
                done = DesignSystem.Button(title = texts.done, style = DesignSystem.ButtonStyle.PRIMARY),
            )

        fun deleted(): ViewModel =
            Deleted(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(texts = texts),
                icon = DesignSystem.Icon(image = DesignSystem.Image.CHECKED, background = DesignSystem.Color.OCEAN),
                title = DesignSystem.Text(
                    text = texts.deleted,
                    style = DesignSystem.TextStyle.MEGA,
                    background = DesignSystem.Background.LIGHT
                ),
                info = DesignSystem.Text(
                    text = texts.deleteInfo,
                    style = DesignSystem.TextStyle.BODY,
                    background = DesignSystem.Background.LIGHT
                ),
                done = DesignSystem.Button(title = texts.done, style = DesignSystem.ButtonStyle.PRIMARY),
            )

        fun ready(state: State, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()): ViewModel =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing),
                title = DesignSystem.Text(
                    text = texts.title,
                    style = DesignSystem.TextStyle.TITLE1
                ),
                name = DesignSystem.TextInput(
                    header = Header.name,
                    title = texts.name,
                    value = state.name,
                    status = status.statusOf(Header.name),
                ),
                save = DesignSystem.Button(title = texts.save),
                delete = DesignSystem.Button(
                    title = texts.delete,
                    visible = state.obj.isGroupLeader()
                ),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String): ViewModel =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asReady get() = this as? Ready
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        val texts = Texts(
            title = "Inställningar",
            back = "Tillbaka",
            name = "Namn",
            nameRequired = "Namn är obligatoriskt",
            progressInfo = "Laddar...",
            failureTitle = "Oj, ett fel har uppstått",
            done = "Klar",
            save = "Spara",
            saved = "Sparat!",
            saveInfo = "Du har nu bytt namn på ditt objekt",
            delete = "Radera objekt",
            deleted = "Raderat!",
            deleteInfo = "Ditt objekt är nu raderat"
        )

        return sceneOf<ViewModel>(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(true).noActions()
            .flatMap { (actions, _) ->
                store.reduce(actions).refreshMinimalObject(objectId = objectId)
                    .accumulate(actions)
            }
            .map { (actions, obj) ->
                val state = State(name = obj.group.name, obj = obj)
                sceneOf(viewModel.ready(state = state), actions)
            }
            .failed { scene.failed(result = it) }
    }

    fun update(scene: Scene.Input<ViewModel>, name: String? = null): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val state = viewModel.state.copy(
            name = name ?: viewModel.state.name,
        )

        return sceneOf(viewModel.ready(state = state))
    }

    fun validate(scene: Scene.Input<ViewModel>, name: String): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val status: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()
        var state = viewModel.state

        Validator.text(
            text = name,
            isEmpty = {
                status.add(
                    Header.name to DesignSystem.Status.Invalid(
                        warning = viewModel.texts.nameRequired
                    )
                )
            },
            formatted = { state = state.copy(name = it) },
            passed = { status.add(Header.name to DesignSystem.Status.Valid) }
        )

        if (status.overallStatus() !is DesignSystem.Status.Valid)
            return sceneOf(viewModel.ready(state = state, status = status))

        return sceneOf<ViewModel>(viewModel.saving(state = state))
    }

    suspend fun save(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state

        val edit = Object.Edit(
            name = modifiedOf(state.name)
        )

        return successfulOf(true)
            .flatMap { store.editObject(state.obj, edit) }
            .map { (actions, _) ->
                sceneOf(viewModel.saved(), actions = actions)
            }
            .failed {
                sceneOf(viewModel.failed("Unknown reason"))
            }
    }

    suspend fun delete(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state

        return successfulOf(true)
            .flatMap { store.deleteObject(state.obj) }
            .map { (actions, _) ->
                sceneOf(viewModel.deleted(), actions = actions)
            }
            .failed {
                sceneOf(viewModel.failed("Unknown reason"))
            }
    }
}
