package della8.core.screens

import della8.core.support.*
import techla.agreement.Agreement
import techla.base.*
import techla.guard.Group

object CreateScreen {
    object Header {
        val name = DesignSystem.Header(id = "name")
        val visualization = DesignSystem.Header(id = "visualization")
    }

    data class Texts(
        val ready: String,
        val done: String,
        val back: String,
        val next: String,
        val cancel: String,
        val name: String,
        val visualization: String,
        val nameRequired: String,
        val visualizationRequired: String,
        val created: String,
        val info: String,
        override val progressInfo: String,
        override val failureTitle: String,
    ) : ProgressTexts, FailureTexts, ClippyTexts

    data class State(
        val name: String?,
        val visualization: Group.Visualization?
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(null, null),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val visualization: DesignSystem.MovieInput,
            val name: DesignSystem.TextInput,
            val next: DesignSystem.Button,
            val cancel: DesignSystem.Button,
            val clippy: DesignSystem.Clippy,
        ) : ViewModel(texts, state, navigation)

        data class Creating(
            override var texts: Texts,
            override var state: State,
            val progress: DesignSystem.Progress,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, state, navigation)

        class Created(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val objectId: Identifier<Object>,
            val agreementId: Identifier<Agreement>,
            val icon: DesignSystem.Icon,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val done: DesignSystem.Button,
        ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        fun loading(texts: Texts) =
            Loading(texts = texts, state = state, navigation = DesignSystem.Navigation.progress(), progress = progress(texts = texts))

        fun ready(state: State? = null, status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()) =
            (state ?: this.state).run {
                Ready(
                    texts = texts,
                    state = this,
                    navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing),
                    title = DesignSystem.Text(
                        text = texts.ready,
                        style = DesignSystem.TextStyle.TITLE1,
                        background = DesignSystem.Background.LIGHT
                    ),
                    name = DesignSystem.TextInput(
                        header = Header.name,
                        title = texts.name,
                        value = name,
                        status = status.statusOf(Header.name)
                    ),
                    visualization = DesignSystem.MovieInput(
                        title = texts.visualization,
                        options = listOf(
                            DesignSystem.Movie.MOUNTAINS,
                            DesignSystem.Movie.POOL,
                            DesignSystem.Movie.CAMPING,
                            DesignSystem.Movie.SNOW
                        )
                            .map {
                                DesignSystem.Option.item(movie = it, data = it.visualization)
                            },
                        selected = DesignSystem.Option.item(data = this.visualization),
                        status = status.statusOf(Header.visualization)
                    ),
                    next = DesignSystem.Button(title = texts.next, style = DesignSystem.ButtonStyle.PRIMARY),
                    cancel = DesignSystem.Button(title = texts.cancel, style = DesignSystem.ButtonStyle.TRANSPARENT),
                    clippy = clippy(texts),
                )
            }

        fun creating(state: State) =
            Creating(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(texts = texts),
            )

        fun created(obj: Object) =
            Created(
                texts = texts,
                state = state,
                objectId = obj.id,
                agreementId = obj.agreements.first().id,
                navigation = DesignSystem.Navigation.fireworks,
                icon = DesignSystem.Icon(image = DesignSystem.Image.CHECKED, background = DesignSystem.Color.OCEAN),
                title = DesignSystem.Text(
                    text = texts.created,
                    style = DesignSystem.TextStyle.MEGA,
                    background = DesignSystem.Background.DARK
                ),
                info = DesignSystem.Text(
                    text = texts.info,
                    style = DesignSystem.TextStyle.BODY,
                    background = DesignSystem.Background.DARK
                ),
                done = DesignSystem.Button(title = texts.done, style = DesignSystem.ButtonStyle.PRIMARY),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val load get() = this as? Loading
        val ready get() = this as? Ready
        val create get() = this as? Created
        val failed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()
        return successfulOf(Unit)
            .map {
                val texts = Texts(
                    ready = "Information om objektet",
                    done = "Klar",
                    back = "Tillbaka",
                    next = "Nästa",
                    cancel = "Avbryt",
                    name = "Visningsnamn",
                    visualization = "Välj bakgrund",
                    nameRequired = "Visningsnamn måste anges",
                    visualizationRequired = "Bakgrund måste anges",
                    created = "Tada!",
                    info = "Ditt objekt är nu upplagt, nästa steg är att gå in på det, ställa in regler samt bjuda in de du vill samäga tillsammans med.",
                    progressInfo = "Laddar...",
                    failureTitle = "Oj, ett fel har uppstått",
                )
                sceneOf<ViewModel>(viewModel.loading(texts = texts))
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(true)
            .map {
                sceneOf<ViewModel>(viewModel.ready())
            }
            .failed { scene.failed(result = it) }
    }

    fun update(scene: Scene.Input<ViewModel>, name: String?, visualization: DesignSystem.Option?): Scene.Output<ViewModel> {
        val (_, viewModel) = scene

        val state = viewModel.state.copy(
            name = name ?: viewModel.state.name,
            visualization = (visualization?.data as? Group.Visualization) ?: viewModel.state.visualization
        )

        return sceneOf(viewModel.ready(state = state))
    }

    suspend fun validate(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val status: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()
        var state = viewModel.state

        Validator.text(
            text = state.name,
            isEmpty = { status.add(Header.name to DesignSystem.Status.Invalid(warning = viewModel.texts.nameRequired)) },
            formatted = { state = state.copy(name = it) },
            passed = { status.add(Header.name to DesignSystem.Status.Valid) },
        )
        Validator.notNull(
            value = state.visualization,
            isEmpty = { status.add(Header.visualization to DesignSystem.Status.Invalid(warning = viewModel.texts.visualizationRequired)) },
            formatted = { state = state.copy(visualization = it) },
            passed = { status.add(Header.visualization to DesignSystem.Status.Valid) },
        )

        if (status.overallStatus() !is DesignSystem.Status.Valid)
            return sceneOf<ViewModel>(viewModel.ready(state = state, status = status))

        return sceneOf<ViewModel>(viewModel.creating(state = state))
    }

    suspend fun create(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val name = viewModel.state.name!!
        val visualization = viewModel.state.visualization!!
        return successfulOf(true)
            .flatMap { store.createObject(name = name, visualization = visualization) }
            .map { (actions, obj) ->
                sceneOf<ViewModel>(viewModel.created(obj = obj), actions)
            }
            .failed { scene.failed(result = it) }
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }
}


