package della8.core.services

import della8.core.support.*
import techla.agreement.*
import techla.base.*

private suspend fun Store.findLatestAgreement(key: Key<Agreement>, status: Agreement.Status? = null) =
    findAgreements(listOf(key), status?.let { listOf(it) })
        .map { (actions, agreements) ->
            tupleOf(actions, agreements.maxByOrNull { it.version })
        }

suspend fun Store.findLatestAgreement(obj: Object, key: Key<Agreement>, status: Agreement.Status? = null) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).findLatestAgreement(key, status)
    }

suspend fun Store.findOutstandingAgreements(obj: Object, key: Key<Agreement>) =
    successfulOf(emptyList<Agreement>()).noActions()
        .flatMap { (actions, agreements) ->
            reduce(actions).findLatestAgreement(obj = obj, key = key, status = Agreement.Status.Approved)
                .accumulate(actions)
                .map { tupleOf(it.first, agreements + it.second) }
        }
        .flatMap { (actions, agreements) ->
            reduce(actions).findLatestAgreement(obj = obj, key = key, status = Agreement.Status.Draft)
                .accumulate(actions)
                .map { tupleOf(it.first, agreements + it.second) }
        }
        .flatMap { (actions, agreements) ->
            reduce(actions).findLatestAgreement(obj = obj, key = key, status = Agreement.Status.Pending)
                .accumulate(actions)
                .map { tupleOf(it.first, agreements + it.second) }
        }
        .flatMap { (actions, agreements) ->
            val approvedAgreement = agreements.firstOrNull { it?.status is Agreement.Status.Approved }
            val draftAgreement = agreements.firstOrNull { it?.status is Agreement.Status.Draft }
            val pendingAgreement = agreements.firstOrNull { it?.status is Agreement.Status.Pending }
            if (approvedAgreement == null && draftAgreement == null && pendingAgreement == null) return@flatMap failedOf(TechlaError.PreconditionFailed("There must be either a draft, pending or approved agreement"))

            val outstandingAgreement = when {
                approvedAgreement != null && pendingAgreement != null && approvedAgreement.version > pendingAgreement.version -> null
                approvedAgreement != null && draftAgreement != null && approvedAgreement.version > draftAgreement.version -> null
                pendingAgreement != null -> pendingAgreement
                draftAgreement != null -> draftAgreement
                else -> null
            }

            successfulOf(tupleOf(actions, listOfNotNull(approvedAgreement, outstandingAgreement)))
        }

suspend fun Store.findAgreements(keys: List<Key<Agreement>>, status: List<Agreement.Status>?) =
    AgreementEndpoint.findAgreements(this, keys, status)

private suspend fun Store.listAgreements() =
    AgreementEndpoint.listAgreements(this)

suspend fun Store.listAgreements(obj: Object) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).listAgreements()
    }

private suspend fun Store.createAgreement(create: Agreement.Create) =
    AgreementEndpoint.createAgreement(this, create)

suspend fun Store.createAgreement(obj: Object, create: Agreement.Create) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).createAgreement(create)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

suspend fun Store.getAgreement(id: Identifier<Agreement>) =
    AgreementEndpoint.getAgreement(this, id)

private suspend fun Store.editAgreement(id: Identifier<Agreement>, edit: Agreement.Edit) =
    AgreementEndpoint.editAgreement(this, id, edit)

suspend fun Store.editAgreement(obj: Object, id: Identifier<Agreement>, edit: Agreement.Edit) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        AgreementEndpoint.editAgreement(reduce(action), id, edit)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.deleteAgreement(id: Identifier<Agreement>) =
    AgreementEndpoint.deleteAgreement(this, id)

suspend fun Store.deleteAgreement(obj: Object, id: Identifier<Agreement>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        AgreementEndpoint.deleteAgreement(reduce(action), id)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.findSignatures(agreementId: Identifier<Agreement>, approvedOnly: Boolean) =
    findSignatures(agreementIds = listOf(agreementId), approvedOnly = approvedOnly)

suspend fun Store.findSignatures(obj: Object, agreementId: Identifier<Agreement>, approvedOnly: Boolean) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).findSignatures(agreementIds = listOf(agreementId), approvedOnly = approvedOnly)
    }

suspend fun Store.findSignatures(agreementIds: List<Identifier<Agreement>>, approvedOnly: Boolean) =
    AgreementEndpoint.findSignatures(this, agreementIds)
        .map { (actions, signatures) ->
            if (approvedOnly)
                tupleOf(actions, signatures.filter { it.signed is Signature.Signed.Agreement })
            else
                tupleOf(actions, signatures)
        }

private suspend fun Store.createSignature(create: Signature.Create) =
    AgreementEndpoint.createSignature(this, create)

suspend fun Store.createSignature(obj: Object, create: Signature.Create) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).createSignature(create)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.getSignature(id: Identifier<Signature>) =
    AgreementEndpoint.getSignature(this, id)

suspend fun Store.getSignature(obj: Object, id: Identifier<Signature>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).getSignature(id)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.createTerm(create: Term.Create) =
    AgreementEndpoint.createTerm(this, create)

suspend fun Store.createTerm(obj: Object, create: Term.Create) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).createTerm(create)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.editTerm(id: Identifier<Term>, edit: Term.Edit) =
    AgreementEndpoint.editTerm(this, id, edit)

suspend fun Store.editTerm(obj: Object, id: Identifier<Term>, edit: Term.Edit) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        AgreementEndpoint.editTerm(reduce(action), id, edit)
            .accumulate(Store.Action.ReplaceObject(id = obj.id, obj = obj.minimal))
    }

private suspend fun Store.listTerms(agreement: Identifier<Agreement>) =
    AgreementEndpoint.listTerms(this, agreement)

suspend fun Store.listTerms(obj: Object, agreementId: Identifier<Agreement>) =
    Store.Action.ChangeGroup(obj.group.key).let { action ->
        reduce(action).listTerms(agreementId)
    }

suspend fun Store.listAllTerms(agreementId: Identifier<Agreement>) =
    AgreementEndpoint.listAllTerms(this, agreementId)

suspend fun Store.editAllTerm(id: Identifier<Term>, edit: Term.Edit) =
    AgreementEndpoint.editAllTerm(this, id, edit)

val Store.agreementAPI
    get() =
        AgreementAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) AgreementAPI.sandbox else AgreementAPI.shared
            api.token = userToken ?: applicationToken
        }

object AgreementEndpoint {
    var findAgreements: suspend (store: Store, keys: List<Key<Agreement>>, status: List<Agreement.Status>?) -> ActionOutcome<List<Agreement>> = { store, keys, status ->
        if (store.demoMode)
            Demo.listAllAgreements.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.agreementAPI
                measureAPI(AgreementResource.FindAgreements(keys, status), api) {
                    api.findAgreements(keys = keys, status = status)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var listAgreements: suspend (store: Store) -> ActionOutcome<List<Agreement>> = { store ->
        if (store.demoMode)
            Demo.listAllAgreements.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.agreementAPI
                measureAPI(AgreementResource.ListAgreements, api) {
                    api.listAgreements()
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var createAgreement: suspend (store: Store, create: Agreement.Create) -> ActionOutcome<Agreement> = { store, create ->
        if (store.demoMode)
            Demo.agreement.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.agreementAPI
                measureAPI(AgreementResource.CreateAgreement(create), api) {
                    api.createAgreement(create)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var getAgreement: suspend (store: Store, id: Identifier<Agreement>) -> ActionOutcome<Agreement> = { store, id ->
        if (store.demoMode)
            Demo.agreement.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.agreementAPI
                measureAPI(AgreementResource.GetAgreement(id = id), api) {
                    api.getAgreement(id = id)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var editAgreement: suspend (store: Store, id: Identifier<Agreement>, edit: Agreement.Edit) -> ActionOutcome<Agreement> = { store, id, edit ->
        if (store.demoMode)
            Demo.agreement.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.agreementAPI
                measureAPI(AgreementResource.EditAgreement(id, edit), api) {
                    api.editAgreement(id, edit)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var deleteAgreement: suspend (store: Store, id: Identifier<Agreement>) -> ActionOutcome<Unit> = { store, id ->
        if (store.demoMode) successfulOf(Unit).noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.agreementAPI
                measureAPI(AgreementResource.DeleteAgreement(id), api) {
                    api.deleteAgreement(id)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var findSignatures: suspend (store: Store, agreementIds: List<Identifier<Agreement>>) -> ActionOutcome<List<Signature>> = { store, agreementIds ->
        if (store.demoMode)
            Demo.listAllSignatures.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.agreementAPI
                measureAPI(AgreementResource.FindSignatures(agreementIds), api) {
                    api.findSignatures(agreementIds = agreementIds)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var createSignature: suspend (store: Store, create: Signature.Create) -> ActionOutcome<Signature> = { store, create ->
        if (store.demoMode)
            Demo.signature.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.agreementAPI
                measureAPI(AgreementResource.CreateSignature(create), api) {
                    api.createSignature(create)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var getSignature: suspend (store: Store, id: Identifier<Signature>) -> ActionOutcome<Signature> = { store, id ->
        if (store.demoMode)
            Demo.signature.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.agreementAPI
                measureAPI(AgreementResource.GetSignature(id), api) {
                    api.getSignature(id)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var listTerms: suspend (store: Store, agreementId: Identifier<Agreement>) -> ActionOutcome<List<Term>> = { store, agreementId ->
        if (store.demoMode)
            Demo.listAllTerms.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.agreementAPI
                measureAPI(AgreementResource.ListTerms(agreementId = agreementId, agreement = null), api) {
                    api.listTerms(agreement = leftOf(agreementId))
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var createTerm: suspend (store: Store, create: Term.Create) -> ActionOutcome<Term> = { store, create ->
        if (store.demoMode)
            Demo.term.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.agreementAPI
                measureAPI(AgreementResource.CreateTerm(create), api) {
                    api.createTerm(create)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var editTerm: suspend (store: Store, id: Identifier<Term>, edit: Term.Edit) -> ActionOutcome<Term> = { store, id, edit ->
        if (store.demoMode)
            Demo.term.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.agreementAPI
                measureAPI(AgreementResource.EditTerm(id, edit), api) {
                    api.editTerm(id, edit)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var listAllTerms: suspend (store: Store, agreementId: Identifier<Agreement>) -> ActionOutcome<List<Term>> = { store, agreementId ->
        if (store.demoMode)
            Demo.listAllTerms.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.agreementAPI
                api.token = store.adminToken
                measureAPI(AgreementResource.ListTerms(agreementId = agreementId, agreement = null), api) {
                    api.listTerms(agreement = leftOf(agreementId))
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var editAllTerm: suspend (store: Store, id: Identifier<Term>, edit: Term.Edit) -> ActionOutcome<Term> = { store, id, edit ->
        if (store.demoMode)
            Demo.term.noActions()
        else {
            store.withUserToken { updated ->
                val api = updated.agreementAPI
                api.token = store.adminToken
                measureAPI(AgreementResource.EditTerm(id, edit), api) {
                    api.editTerm(id, edit)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }
}