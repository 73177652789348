package della8.core.support

import techla.agreement.Term
import techla.base.*

val Ownership.displayName
    get() =
        when (this) {
            is Ownership.Share -> "+ $firstName $lastName, $govId, $share andelar"
            is Ownership.Percentage -> "+ $firstName $lastName, $govId, $percentage%"
            is Ownership.Other -> description
            else -> ""
        }

val List<Term.Clause.Ownership>.displayName
    get() =
        joinToString(separator = "\n") { it.ownership.displayName }

data class EditableOwnership(
    val style: Style,
    val shares: List<Quadruple<String, String, String, Pair<String, Int?>>> = emptyList(),
    // val percentages: List<Quadruple<String, String, String, Pair<String, Double?>>> = emptyList(),
    val description: String = "",
) {
    enum class Style { SHARES, /*PERCENTAGE,*/ OTHER }

    companion object {
        fun fromTruth(truth: List<Ownership>) =
            when (val first = truth.firstOrNull()) {
                is Ownership.Share ->
                    EditableOwnership(
                        style = Style.SHARES,
                        shares = truth.filterIsInstance<Ownership.Share>().map { tupleOf(it.firstName, it.lastName, it.govId, it.share.toString() to it.share) }
                    )

                is Ownership.Percentage -> // Transform Percentages to Other
                    EditableOwnership(
                        style = Style.OTHER,
                        description = truth.filterIsInstance<Ownership.Percentage>().joinToString("\n") {
                            "${it.firstName} ${it.lastName}, ${it.govId}, ${it.percentage}%"
                        }
                    )

                is Ownership.Other ->
                    EditableOwnership(
                        style = Style.OTHER,
                        description = first.description
                    )

                else ->
                    EditableOwnership(
                        style = Style.SHARES,
                    )
            }
    }

    val truth: List<Ownership>
        get() =
            when (style) {
                Style.OTHER -> listOf(Ownership.Other(description = description))
                Style.SHARES -> shares.mapNotNull { if (it.forth.second != null) Ownership.Share(firstName = it.first, lastName = it.second, govId = it.third, share = it.forth.second!!) else null }
            }
}

val EditableOwnership.Style.option
    get() =
        when (this) {
            EditableOwnership.Style.SHARES -> DesignSystem.Option.item(title = "Andelar", value = name, data = this)
            EditableOwnership.Style.OTHER -> DesignSystem.Option.other(title = "Annat", value = name, data = this)
        }
