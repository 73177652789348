package della8.web.views


import StoreContext
import bootstrap.*
import della8.core.screens.AgreementHistoryScreen
import della8.core.support.Object
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.className
import della8.web.support.standardNavigation
import della8.web.support.useAsyncEffect
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import react.*
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import techla.agreement.Agreement
import techla.base.Identifier
import techla.base.techla_log

external interface AgreementHistoryProps : PropsWithChildren {
    var objectId: Identifier<Object>
    var agreementId: Identifier<Agreement>
}


val AgreementHistoryView = FC<AgreementHistoryProps>("AgreementHistoryView ") { props ->
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(AgreementHistoryScreen.ViewModel.None as AgreementHistoryScreen.ViewModel)
    val navigate = useNavigate()

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {

            is AgreementHistoryScreen.ViewModel.None ->
                AgreementHistoryScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                        techla_log("AgreementHistoryProps START")
                    }
                }
            is AgreementHistoryScreen.ViewModel.Loading -> {
                AgreementHistoryScreen.load(sceneInputOf(store, viewModel), props.objectId, props.agreementId)
                    .also { (viewModel, actions) ->
                        if (coroutineScope.isActive) {
                            setViewModel(viewModel)
                            dispatch(actions)
                            techla_log("AgreementHistoryProps LOAD")

                        }
                    }

            }


            is AgreementHistoryScreen.ViewModel.Ready -> {
                techla_log(" AgreementHistoryProps READY")
                techla_log("ITEMS -> ${viewModel.items.size}")
            }
            is AgreementHistoryScreen.ViewModel.Failed ->
                techla_log("AgreementHistoryProps failed ")
        }
    }


    fun handleLogout() {
        MainScope().launch {
            AgreementHistoryScreen.logout(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
                navigate("/")
            }
        }
    }

    main {
        when (viewModel) {
            is AgreementHistoryScreen.ViewModel.Failed -> d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
            else -> {}
        }

        if (viewModel is AgreementHistoryScreen.ViewModel.Ready) {

            bContainer {
                className = className("p-0")
                bRow {
                    className = className("justify-content-center")
                    d8Text(design = viewModel.title, className = "p-0")
                }
                viewModel.items.map {
                    bRow {
                        key = it.id.rawValue
                        bCol {
                            className = className("p-0 pt-2")
                            xs = 6; md = 6
                            d8Text(design = it.title, className = "mb-0")
                            d8Text(design = it.version, className = "d8-battleship mb-0")

                        }
                        bCol {
                            className = className("p-0 pt-2 text-end")
                            xs = 6; md = 6
                            d8Button(design = it.download, onClick = {})
                        }
                    }
                    bRow {
                        ReactHTML.hr { className = className("w-100") }
                    }
                }
            }
        }
        if (viewModel is AgreementHistoryScreen.ViewModel.Loading) {
            d8Progress(viewModel.progress)

        }

        if (viewModel is AgreementHistoryScreen.ViewModel.Failed) {
            bContainer {
                className = className("px-5 n-top-space")
                d8failure(viewModel.failure, ::handleLogout)
            }
        }
    }
}


fun ChildrenBuilder.agreementHistoryView(
    objectId: Identifier<Object>,
    agreementId: Identifier<Agreement>,
) =
    AgreementHistoryView {
        this.objectId = objectId
        this.agreementId = agreementId
    }
