package della8.web.components

import della8.core.support.DesignSystem
import della8.web.support.className
import della8.web.theme.Della8Theme
import della8.web.theme.Design
import della8.web.theme.textAlignment
import kotlinx.js.jso
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import remarkable.Remarkable

external interface D8TextProps : PropsWithChildren {
    var className: String?
    var design: DesignSystem.Text
}

val remarkable = Remarkable(null, null)

val D8Text = FC<D8TextProps>("D8Text") { props ->
    if (props.design.visible) {
        if (props.design.isMarkdown) {
            div {
                className = className(props.className)
                dangerouslySetInnerHTML = jso {
                    __html = remarkable.render(props.design.text ?: "", null)
                }
                /*                            unsafe {
                                                +remarkable.render(props.design.text ?: "", null)
                                            }*/
            }
        } else {
            val color = Design.color(props.design.textColor) ?: when (props.design.background) {
                DesignSystem.Background.LIGHT -> Della8Theme.colors.marine
                DesignSystem.Background.DARK -> Della8Theme.colors.snow
            }
            when (props.design.style) {
                DesignSystem.TextStyle.MEGA -> {
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.LEFT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                    h1 {
                        className = className(
                            props.className,
                            props.design.alignment.textAlignment,
                            Della8Theme.typography.mega,
                            color
                        )

                        +(props.design.text ?: "")

                    }
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.RIGHT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                }

                DesignSystem.TextStyle.LARGE_TITLE -> {
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.LEFT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                    h1 {
                        className = className(
                            props.className,
                            props.design.alignment.textAlignment,
                            Della8Theme.typography.largetitle,
                            color
                        )

                        +(props.design.text ?: "")

                    }
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.RIGHT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                }

                DesignSystem.TextStyle.TITLE1 -> {
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.LEFT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                    h1 {
                        className = className(
                            props.className,
                            props.design.alignment.textAlignment,
                            Della8Theme.typography.title1,
                            color
                        )

                        +(props.design.text ?: "")

                    }
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.RIGHT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                }

                DesignSystem.TextStyle.TITLE2 -> {
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.LEFT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                    h2 {
                        className = className(
                            props.className,
                            props.design.alignment.textAlignment,
                            Della8Theme.typography.title2,
                            color
                        )
                        +(props.design.text ?: "")

                    }
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.RIGHT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                }

                DesignSystem.TextStyle.TITLE3 -> {
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.LEFT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                    h2 {
                        className = className(
                            props.className,
                            props.design.alignment.textAlignment,
                            Della8Theme.typography.title3,
                            color
                        )

                        +(props.design.text ?: "")

                    }
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.RIGHT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                }

                DesignSystem.TextStyle.HEADLINE -> {
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.LEFT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                    h3 {
                        className = className(
                            props.className,
                            props.design.alignment.textAlignment,
                            Della8Theme.typography.headline,
                            color
                        )

                        +(props.design.text ?: "")
                    }
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.RIGHT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                }

                DesignSystem.TextStyle.SUBHEAD -> {
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.LEFT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                    h3 {
                        className = className(
                            props.className,
                            props.design.alignment.textAlignment,
                            Della8Theme.typography.subhead,
                            color
                        )
                        +(props.design.text ?: "")
                    }
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.RIGHT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                }

                DesignSystem.TextStyle.BODY -> {
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.LEFT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                    p {
                        className = className(
                            props.className,
                            props.design.alignment.textAlignment,
                            Della8Theme.typography.body,
                            color
                        )


                        +(props.design.text ?: "")
                    }
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.RIGHT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                }

                DesignSystem.TextStyle.FOOTNOTE -> {
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.LEFT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                    h4 {
                        className = className(
                            props.className,
                            props.design.alignment.textAlignment,
                            Della8Theme.typography.footnote,
                            color
                        )
                        +(props.design.text ?: "")

                    }
                    if (props.design.iconAlignment == DesignSystem.IconAlignment.RIGHT) {
                        props.design.image?.let {
                            img {
                                className = className("px-2")
                                src = Design.image(it)
                            }
                        }
                    }
                }
            }
        }
    }
}

fun ChildrenBuilder.d8Text(design: DesignSystem.Text, className: String? = null) =
    D8Text {
        this.design = design
        this.className = className
    }
