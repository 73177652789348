package della8.web.items

import bootstrap.bCard
import bootstrap.bContainer
import della8.core.items.BookingItem
import della8.web.components.d8Button
import della8.web.components.d8Text
import della8.web.support.className
import react.ChildrenBuilder
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import techla.base.Identifier

external interface BookingItemProps : PropsWithChildren {
    var viewModel: BookingItem.ViewModel
    var onClick: suspend (id: Identifier<BookingItem>) -> Unit
}


val BookingItem = FC<BookingItemProps>("BookingItem"){ props ->

    suspend fun handleOnClick(id: Identifier<BookingItem>){
        props.onClick(id)
    }

    when (val viewModel = props.viewModel) {
        is BookingItem.ViewModel.Reservation -> {
            bCard {
                className = className("radius-10 border-0 card-shadow my-3 px-4 py-3 d8-marine-bg-opacity-2 mb-4")
                bContainer {
                    className = className("p-0")
                    div {
                        className = className("mb-3")
                        d8Text(className = "mb-0 ml-3 d-inline", design = viewModel.from)
                    }
                    div {
                        className = className("mb-3")
                        d8Text(className = "mb-0 ml-3 d-inline", design = viewModel.to)
                    }
                    div {
                        className = className("mb-3")
                        d8Text(className = "mb-0 d-inline", design = viewModel.bookedBy)
                        d8Text(className = "mb-0 d-inline", design = viewModel.person)
                        d8Button(className = "float-end d-inline p-0", design = viewModel.delete, onClick = { viewModel.delete.data?.let { handleOnClick(props.viewModel.id) } })
                    }
                }
            }
        }
    }

}

fun ChildrenBuilder.bookingItem(
    viewModel: BookingItem.ViewModel,
    onClick: suspend (id: Identifier<BookingItem>) -> Unit
) =
    BookingItem{
        this.viewModel = viewModel
        this.onClick = onClick
    }