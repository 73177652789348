package della8.web.items

import bootstrap.*
import della8.core.items.ModuleItem
import della8.core.support.Module
import della8.web.components.d8Button
import della8.web.components.d8Element
import della8.web.components.d8Image
import della8.web.components.d8Text
import della8.web.support.className
import react.*
import react.dom.html.ReactHTML.div

external interface ModuleItemOnboardingProps : PropsWithChildren {
    var classes: String?
    var viewModel: ModuleItem.ViewModel
    var action: (String, String, String) -> Unit
}


val ModuleItemOnboarding = FC<ModuleItemOnboardingProps>("ModuleItemOnboarding") { props ->
    if ((props.viewModel !is ModuleItem.ViewModel.Booking) && (props.viewModel !is ModuleItem.ViewModel.Partnership)) {
        bCard {
            className = className(
                listOfNotNull(
                    "radius-25 border-0 card-shadow my-3 ${if (props.viewModel.disabled) "disabledCard" else ""}"
                )
            )
            bCardBody {
                className = className("p-0")

                div {
                    className = className("p-4")

                    when (val viewModel = props.viewModel) {
                        is ModuleItem.ViewModel.PartnershipStart -> {
                            bRow {
                                className = className("px-0")
                                bCol {
                                    xs = 12; md = 8
                                    div {
                                        className = className("d-flex align-items-start")

                                        d8Image(design = viewModel.image, className = "pe-3")
                                        div {
                                            d8Text(className = "d-block mb-0 pt-2", design = viewModel.title)
                                            d8Text(className = "d-block", design = viewModel.info)
                                        }
                                    }
                                }
                                bCol {
                                    xs = 12; md = 4
                                    d8Button(className = "float-end mt-2", design = viewModel.more, onClick = { props.action("", "edit", Module.Partnership.rawValue) })
                                }
                            }
                        }
                        is ModuleItem.ViewModel.PartnershipPending -> {
                            bRow {
                                className = className("px-0")

                                bCol {
                                    xs = 12; md = 8
                                    div {
                                        className = className("d-flex align-items-start")

                                        d8Image(design = viewModel.image, className = "pe-3")
                                        div {
                                            d8Text(className = "d-block mb-0 pt-2", design = viewModel.title)
                                            d8Text(className = "d-block", design = viewModel.info)

                                        }
                                    }
                                }
                                bCol {
                                    xs = 12; md = 4
                                    d8Button(className = "float-end mt-2", design = viewModel.more, onClick = { props.action("", "show", Module.Partnership.rawValue) })
                                }
                            }
                        }
                        is ModuleItem.ViewModel.BookingStart -> {
                            bRow {
                                className = className("px-0")

                                bCol {
                                    xs = 12; md = 8
                                    div {
                                        className = className("d-flex align-items-start")

                                        d8Image(design = viewModel.image, className = "pe-3")
                                        div {
                                            d8Text(className = "d-block mb-0 pt-2", design = viewModel.title)
                                            d8Text(className = "d-block", design = viewModel.info)
                                        }
                                    }
                                }
                                bCol {
                                    xs = 12; md = 4
                                    d8Button(className = "float-end mt-2", design = viewModel.more, onClick = { props.action("", "edit", Module.Booking.rawValue) })
                                }
                            }
                        }
                        is ModuleItem.ViewModel.BookingPending -> {
                            bRow {
                                className = className("px-0")

                                bCol {
                                    xs = 12; md = 8
                                    div {
                                        className = className("d-flex align-items-start")

                                        d8Image(design = viewModel.image, className = "pe-3")
                                        div {
                                            d8Text(className = "d-block mb-0 pt-2", design = viewModel.title)
                                            d8Text(className = "d-block", design = viewModel.info)
                                        }
                                    }
                                }
                                bCol {
                                    xs = 12; md = 4
                                    d8Button(className = "float-end mt-2", design = viewModel.more, onClick = { props.action("", "show", Module.Booking.rawValue) })
                                }
                            }
                        }
                        is ModuleItem.ViewModel.Approve -> {
                            bRow {
                                className = className("px-0")

                                bCol {
                                    xs = 12; md = 8
                                    div {
                                        className = className("d-flex align-items-start")

                                        d8Image(design = viewModel.image, className = "pe-3")
                                        div {
                                            d8Text(className = "d-block mb-0 pt-2", design = viewModel.title)
                                            d8Text(className = "d-block", design = viewModel.info)
                                        }
                                    }
                                }
                                bCol {
                                    xs = 12; md = 4
                                    d8Button(className = "float-end mt-2", design = viewModel.more, onClick = { props.action("agreement", "", "") })
                                }
                            }
                        }
                        is ModuleItem.ViewModel.Invite -> {
                            bRow {
                                className = className("px-0")

                                bCol {
                                    xs = 12; md = 8
                                    div {
                                        className = className("d-flex align-items-start")

                                        d8Image(design = viewModel.image, className = "pe-3")
                                        div {
                                            d8Text(className = "d-block mb-0 pt-2", design = viewModel.title)
                                            d8Text(className = "d-block", design = viewModel.info)
                                        }
                                    }
                                }
                                bCol {
                                    xs = 12; md = 4
                                    d8Button(className = "float-end mt-2", design = viewModel.more, onClick = { props.action("invite", "", "") })
                                }
                            }
                        }
                        is ModuleItem.ViewModel.Status -> {
                            bRow {
                                className = className("px-0")

                                bCol {
                                    xs = 12; md = 8
                                    div {
                                        className = className("d-flex align-items-start")

                                        d8Image(design = viewModel.image, className = "pe-3")
                                        div {
                                            d8Text(className = "d-block mb-0 pt-2", design = viewModel.title)
                                            d8Text(className = "d-block", design = viewModel.info)
                                        }
                                    }
                                }
                                bCol {
                                    xs = 12; md = 4
                                    d8Button(className = "float-end mt-2", design = viewModel.more, onClick = { props.action("launch", "", "") })
                                }
                            }
                            bRow {
                                bCol {
                                    d8Text(className = "d-inline", design = viewModel.approved)
                                    div {
                                        className = className("d-flex justify-content-start")

                                        viewModel.approvedList.map { d8Element(design = it) }
                                    }
                                }
                                bCol {
                                    d8Text(className = "d-inline", design = viewModel.pending)
                                    div {
                                        className = className("d-flex justify-content-start")

                                        viewModel.pendingList.map { d8Element(design = it) }
                                    }
                                }
                            }
                        }
                        else -> {}
                    }
                }
            }
        }
    }
}

fun ChildrenBuilder.moduleItemOnboarding(
    viewModel: ModuleItem.ViewModel,
    classes: String? = null,
    action: (String, String?, String?) -> Unit
) =
    ModuleItemOnboarding {
        this.classes = classes
        this.viewModel = viewModel
        this.action = action
    }