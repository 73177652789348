package della8.core.screens

import della8.core.services.findAvailability
import della8.core.support.*
import techla.agreement.Agreement
import techla.base.*
import techla.reservation.Availability
import techla.reservation.Reservation

object AvailabilityScreen {
    data class Texts(
        val title: String,
        val bookingByTitle: String,
        val back: String,
        val monday: String,
        val tuesday: String,
        val wednesday: String,
        val thursday: String,
        val friday: String,
        val saturday: String,
        val sunday: String,
        val reservation: String,
        val me: String,
        val other: String,
        val gold: String,
        val today: String,
        val helpText: String,
        val more: String,
        val scroll: String,
        override val january: String,
        override val february: String,
        override val mars: String,
        override val april: String,
        override val may: String,
        override val june: String,
        override val july: String,
        override val august: String,
        override val september: String,
        override val october: String,
        override val november: String,
        override val december: String,
        override val progressInfo: String,
        override val failureTitle: String,

        ) : AvailabilityHelper.Texts, ProgressTexts, FailureTexts {
        companion object
    }

    data class State(
        val obj: Object = Object.None,
        val monthLoaded: Int = 0,
        val availability: List<Availability> = emptyList()
    )

    data class WeekDay(
        val date: Date,
        val restriction: Availability.Restriction,
    ) {
        val year get() = date.dateTime.year
        val month get() = date.dateTime.monthNumber
        val dayOfMonth get() = date.dateTime.dayOfMonth
        val isToday get() = Date.now().dateTime.let { it.year == year && it.monthNumber == month && it.dayOfMonth == dayOfMonth }
        val id get() = Identifier<DesignSystem.Cell>(DateSerializer.serialize(date))
    }

    data class Week(
        val number: Int,
        val value: Availability.Value,
        val monday: WeekDay?,
        val tuesday: WeekDay?,
        val wednesday: WeekDay?,
        val thursday: WeekDay?,
        val friday: WeekDay?,
        val saturday: WeekDay?,
        val sunday: WeekDay?,
    ) {
        val days
            get() =
                listOf(monday, tuesday, wednesday, thursday, friday, saturday, sunday)
    }

    data class Month(
        val year: Int,
        val monthNumber: Int,
        val weeks: List<Week>,
    ) {
        fun month(texts: Texts) =
            when (monthNumber) {
                1 -> texts.january
                2 -> texts.february
                3 -> texts.mars
                4 -> texts.april
                5 -> texts.may
                6 -> texts.june
                7 -> texts.july
                8 -> texts.august
                9 -> texts.september
                10 -> texts.october
                11 -> texts.november
                12 -> texts.december
                else -> ""
            }
    }

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val items: List<DesignSystem.Month>,
            val weekdays: List<DesignSystem.Text>,
            val reservation: DesignSystem.Button,
            val help: DesignSystem.Button,
            val settings: DesignSystem.Button,
            val helpers: List<DesignSystem.Text>,
            val helpText: DesignSystem.Text,
            val more: DesignSystem.Button,
            val scroll: DesignSystem.Button,

            ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        fun loading(texts: Texts) = Loading(
            texts = texts,
            state = state,
            navigation = DesignSystem.Navigation.progress(),
            progress = progress(texts = texts),
        )

        fun ready(texts: Texts, state: State, items: List<DesignSystem.Month>) =
            Ready(
                texts = texts,
                state = state,
                title = DesignSystem.Text(text = texts.title, style = DesignSystem.TextStyle.TITLE1),
                navigation = DesignSystem.Navigation.backLight(
                    title = texts.back,
                    location = Location.Landing
                ),
                items = items,
                weekdays = listOf(
                    DesignSystem.Text(text = texts.monday),
                    DesignSystem.Text(text = texts.tuesday),
                    DesignSystem.Text(text = texts.wednesday),
                    DesignSystem.Text(text = texts.thursday),
                    DesignSystem.Text(text = texts.friday),
                    DesignSystem.Text(text = texts.saturday),
                    DesignSystem.Text(text = texts.sunday),
                ),
                reservation = DesignSystem.Button(title = texts.reservation, style = DesignSystem.ButtonStyle.PRIMARY),
                settings = DesignSystem.Button(image = DesignSystem.Image.RULES, background = DesignSystem.Color.CLEAR, style = DesignSystem.ButtonStyle.TRANSPARENT),
                help = DesignSystem.Button(image = DesignSystem.Image.INFO, background = DesignSystem.Color.CLEAR, style = DesignSystem.ButtonStyle.TRANSPARENT),
                helpers = listOf(
                    DesignSystem.Text(text = texts.me, image = DesignSystem.Image.BOOKED_ME, iconAlignment = DesignSystem.IconAlignment.LEFT),
                    DesignSystem.Text(text = texts.other, image = DesignSystem.Image.BOOKED_OTHER, iconAlignment = DesignSystem.IconAlignment.LEFT),
                    DesignSystem.Text(text = texts.today, image = DesignSystem.Image.BOOKING_TODAY, iconAlignment = DesignSystem.IconAlignment.LEFT),
                    DesignSystem.Text(text = texts.gold, image = DesignSystem.Image.GOLD, iconAlignment = DesignSystem.IconAlignment.LEFT)
                ),
                helpText = DesignSystem.Text(text = texts.helpText),
                more = DesignSystem.Button(title = texts.more, style = DesignSystem.ButtonStyle.PRIMARY),
                scroll = DesignSystem.Button(title = texts.scroll, style = DesignSystem.ButtonStyle.TRANSPARENT),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false) =
            Failed(
                texts = texts,
                state = state,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
                navigation = DesignSystem.Navigation.failure,
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val ready get() = this as? Ready
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        // TODO: Fetch from Store

        val texts = Texts(
            title = "Översikt",
            bookingByTitle = "Bokad av",
            back = "Tillbaka",
            monday = "M",
            tuesday = "T",
            wednesday = "O",
            thursday = "T",
            friday = "F",
            saturday = "S",
            sunday = "S",
            reservation = "Till bokning",
            january = "Januari",
            february = "Februari",
            mars = "Mars",
            april = "April",
            may = "Maj",
            june = "Juni",
            july = "Juli",
            august = "Augusti",
            september = "September",
            october = "Oktober",
            november = "November",
            december = "December",
            me = "Du",
            other = "Andra samägare",
            gold = "Guldvecka",
            today = "Dagens datum",
            progressInfo = "Laddar...",
            failureTitle = "Oj, ett fel har uppstått",
            helpText = "På översiktsvyn kan du se dina och andra samägares bokningar. Här kan du även radera en redan befintlig bokning genom att trycka på en av dina bokningar (blå markering) och sedan trycka på papperskorgen i pop up-rutan som dyker upp.",
            more = "Ladda mer",
            scroll = "Till toppen"
        )
        return sceneOf<ViewModel>(viewModel.loading(texts = texts))
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun success(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return load(scene = scene, objectId = viewModel.state.obj.id, agreementId = viewModel.state.obj.currentAgreement.id)
    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val interval = (0..5).map { Reservation.Filter.ForMonthOffset(it) }
        return store.refreshObject(objectId = objectId, agreementId = agreementId)
            .flatMap { (actions, obj) ->
                store.findAvailability(obj = obj, resource = obj.resource.key, filters = interval, style = null)
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj, it.second) }
            }
            .map { (actions, obj, availability) ->
                val state = viewModel.state.copy(
                    obj = obj,
                    monthLoaded = 5,
                    availability = availability
                )
                sceneOf<ViewModel>(
                    viewModel.ready(texts = viewModel.texts, state = state, items = buildItems(store = store, texts = viewModel.texts, state = state, availability = availability)),
                    actions
                )
            }
            .failed { scene.failed(result = it) }
    }


    suspend fun loadMore(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val interval = (viewModel.state.monthLoaded..(viewModel.state.monthLoaded + 5)).map { Reservation.Filter.ForMonthOffset(it) }
        return store.findAvailability(obj = viewModel.state.obj, resource = viewModel.state.obj.resource.key, filters = interval, style = null)
            .map { (actions, availability) ->

                val newState = viewModel.state.copy(monthLoaded = viewModel.state.monthLoaded + 5, availability = viewModel.state.availability + availability)

                sceneOf<ViewModel>(
                    viewModel.ready(texts = viewModel.texts, state = newState, items = buildItems(store = store, texts = viewModel.texts, state = newState, availability = newState.availability)),
                    actions
                )
            }
            .failed { scene.failed(result = it) }
    }


    private fun buildItems(store: Store, texts: Texts, state: State, availability: List<Availability>): List<DesignSystem.Month> =
        AvailabilityHelper.buildItems(texts = texts, obj = state.obj, availability = availability, style = Reservation.Style.None, startsAt = null, endsAt = null)
}

