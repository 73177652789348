package della8.core.screens

import della8.core.items.LandingItem
import della8.core.services.bios
import della8.core.support.*
import techla.base.*
import techla.guard.Token
import techla.personal.Bio

object LandingScreen {
    data class Texts(
        val back: String,
        val add: String,
        val owners: String,
        val emptyBody: String,
        val emptyTitle: String,
        val redeemCode: String,
        override val landingTitle: String,
        override val landingRedeem: String,
        override val landingProfile: String,
        override val landingDashboard: String,
        override val landingAdvanced: String,
        override val landingSignOut: String,
        override val progressInfo: String,
        override val failureTitle: String,
    ) : ProgressTexts, LandingTexts, FailureTexts, ClippyTexts

    sealed class ViewModel(open var texts: Texts, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, navigation)

        data class Empty(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val name: DesignSystem.Text,
            val image: DesignSystem.ImageView,
            val body: DesignSystem.Text,
            val create: DesignSystem.Button,
            val redeemCode: DesignSystem.Button,
        ) : ViewModel(texts, navigation)

        data class Ready(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val items: List<LandingItem.ViewModel>,
            val create: DesignSystem.Button,
            val clippy: DesignSystem.Clippy,
        ) : ViewModel(texts, navigation)

        data class Failed(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, navigation)

        fun loading(texts: Texts): ViewModel =
            Loading(
                texts = texts,
                navigation = DesignSystem.Navigation.progress(),
                progress = progress(texts = texts)
            )

        fun empty(isAdmin: Boolean): ViewModel =
            Empty(
                texts = texts,
                navigation = DesignSystem.Navigation.landing(texts = texts, isAdmin = isAdmin, backLocation = Location.Start),
                title = DesignSystem.Text(text = texts.landingTitle, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.DARK),
                create = DesignSystem.Button(title = texts.add, style = DesignSystem.ButtonStyle.SECONDARY),
                name = DesignSystem.Text(text = texts.emptyTitle, style = DesignSystem.TextStyle.TITLE2, background = DesignSystem.Background.LIGHT),
                image = DesignSystem.ImageView(image = DesignSystem.Image.NOOBJECT),
                body = DesignSystem.Text(text = texts.emptyBody, style = DesignSystem.TextStyle.BODY, background = DesignSystem.Background.LIGHT, isMarkdown = true),
                redeemCode = DesignSystem.Button(title = texts.redeemCode, style = DesignSystem.ButtonStyle.PRIMARY),
            )

        fun ready(isAdmin: Boolean, items: List<LandingItem.ViewModel>): ViewModel =
            Ready(
                texts = texts,
                navigation = DesignSystem.Navigation.landing(texts = texts, isAdmin = isAdmin, backLocation = Location.Start),
                title = DesignSystem.Text(text = texts.landingTitle, style = DesignSystem.TextStyle.TITLE1, background = DesignSystem.Background.DARK),
                items = items,
                create = DesignSystem.Button(title = texts.add, style = DesignSystem.ButtonStyle.PRIMARY),
                clippy = clippy(texts),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoading get() = this as? Loading
        val asEmpty get() = this as? Empty
        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        val texts = Texts(
            back = "Tillbaka",
            add = "Lägg till objekt",
            owners = "SAMÄGARE",
            landingRedeem = "Lös in kod",
            emptyBody = """Du har nu två val, antingen så har du fått en kod som du kan lösa in. Eller så kan du lägga till ett objekt för att sedan bjuda in andra samägare. Hör av dig till [hej@della8.se](mailto:hej@della8.se) om du har frågor. Lycka till!""".trimIndent(),
            emptyTitle = "Hej samägare, välkommen hit!",
            progressInfo = "Laddar...",
            landingTitle = "Mina objekt",
            landingProfile = "Profil",
            landingDashboard = "Dashboard",
            landingAdvanced = "Avancerat",
            landingSignOut = "Logga ut",
            failureTitle = "Oj, ett fel har uppstått",
            redeemCode = "Lös in kod",
        )
        return sceneOf(viewModel.loading(texts = texts))

    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return successfulOf(store.objects ?: emptyList())
            .flatMap { objects ->
                val batch = Bio.Batch(ids = objects.flatMap { it.group.members }.distinct())
                store.bios(batch)
                    .map { tupleOf(it.first, objects, it.second) }
            }
            .map { (actions, objects, bios) ->
                val isAdmin = store.reduce(actions).tokens?.filterIsInstance<Token.Admin>()?.isNotEmpty() ?: false
                when (objects.isEmpty()) {
                    true -> sceneOf(viewModel.empty(isAdmin = isAdmin), actions)
                    false -> sceneOf(viewModel.ready(isAdmin = isAdmin, items = buildItems(texts = viewModel.texts, objects = objects, bios = bios)), actions)
                }

            }
            .failed { scene.failed(result = it) }
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }

    private fun buildItems(texts: Texts, objects: List<Object>, bios: List<Bio>): List<LandingItem.ViewModel> {
        val items = objects.sortedBy { it.group.name }.map { obj ->
            LandingItem.group(
                obj = obj,
                owners = texts.owners,
                members = obj.group.members.map { profileId -> bios.firstOrNull { it.profileId == profileId } }
            )
        }
        return items
    }
}