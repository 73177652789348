package della8.web.support

import della8.core.support.Deployment

val deployment = Deployment(
    applicationKey = "DFCBC005-BA87-4CA5-8F10-28079450C55D",
    applicationSecret = "CDCEF800-1104-4B15-81ED-A20CEAC5ABE7",
    redirect = "",
    isSandbox = false,
    version = "2.0.5",
    della8Home =  "https://della8.se",
    della8Finder =  "https://della8.se/finder",
    build = 106,
)