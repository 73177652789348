package della8.core.items

import della8.core.support.*
import techla.agreement.Agreement
import techla.base.Identifier
import techla.personal.Bio

object LandingItem {
    object Header {
        val menu = DesignSystem.Header(id = "menu")
    }

    sealed class ViewModel(open val id: Identifier<LandingItem>, open val objectId: Identifier<Object>) {
        object None : ViewModel(id = Identifier(), objectId = Identifier())

        data class Group(
            override val id: Identifier<LandingItem>,
            override val objectId: Identifier<Object>,
            val title: DesignSystem.Text,
            val movie: DesignSystem.MovieView,
            val owners: DesignSystem.Text,
            val approved: DesignSystem.Button,
            val outstanding: DesignSystem.Button,
            val menu: DesignSystem.SelectInput,
            val profiles: List<DesignSystem.Profile>,
        ) : ViewModel(id = id, objectId = objectId) {
            companion object
        }
    }

    fun group(obj: Object, members: List<Bio?>, owners: String): ViewModel {
        val profiles = members.map { bio ->
            bio?.initials?.let {
                it to (obj.group.leader == bio.profileId)
            } ?: ("??" to false)
        }.sortedWith(compareBy({ !it.second }, { it.first }))

        val id = Identifier<LandingItem>(obj.id.rawValue)
        val name = obj.group.name
        val approvedId = obj.agreements.firstOrNull { it.status is Agreement.Status.Approved }?.id
        val outstandingId = obj.agreements.firstOrNull { it.status !is Agreement.Status.Approved }?.id
        return group(
            id = id,
            objectId = obj.id,
            approvedId = approvedId,
            outstandingId = outstandingId,
            name = name,
            movie = obj.group.visualization.movie,
            owners = owners,
            settings = Location.settings(obj = obj),
            draft = Location.draft(obj = obj),
            isGroupLeader = obj.isGroupLeader(),
            profiles = profiles
        )
    }

    fun group(
        id: Identifier<LandingItem>,
        objectId: Identifier<Object>,
        approvedId: Identifier<Agreement>?,
        outstandingId: Identifier<Agreement>?,
        name: String,
        movie: DesignSystem.Movie,
        owners: String,
        settings: Location,
        draft: Location,
        isGroupLeader: Boolean,
        profiles: List<Pair<String, Boolean>>,
    ): ViewModel =
        ViewModel.Group(
            id = id,
            objectId = objectId,
            title = DesignSystem.Text(text = name, style = DesignSystem.TextStyle.TITLE2, background = DesignSystem.Background.LIGHT),
            movie = DesignSystem.MovieView(movie = movie),
            owners = DesignSystem.Text(text = owners, style = DesignSystem.TextStyle.SUBHEAD, background = DesignSystem.Background.LIGHT),
            approved = DesignSystem.Button(title = "Gå till objekt", data = approvedId?.rawValue, visible = approvedId != null),
            outstanding = DesignSystem.Button(title = "Gå till utkast", style = DesignSystem.ButtonStyle.SECONDARY, data = outstandingId?.rawValue, visible = outstandingId != null),
            menu = DesignSystem.SelectInput(
                header = Header.menu,
                options = listOfNotNull(
                    DesignSystem.Option.item(title = "Inställningar...", value = "Settings", location = settings),
                    if (approvedId != null && outstandingId == null && isGroupLeader)
                        DesignSystem.Option.item(title = "Nytt avtalsutkast...", value = "Draft", location = draft)
                    else null,
                ),
                style = DesignSystem.SelectStyle.DROP_UP,
            ),
            profiles = profiles.map { DesignSystem.Profile(initials = it.first, leader = it.second) },
        )
}
