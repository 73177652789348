package della8.core.screens

import della8.core.native.formatDateTimeLong
import della8.core.services.createReservation
import della8.core.services.findAvailability
import della8.core.support.*
import kotlinx.datetime.*
import techla.agreement.Agreement
import techla.base.*
import techla.reservation.Availability
import techla.reservation.Reservation

val Reservation.Style.Companion.possibleValues
    get() =
        listOf(Reservation.Style.Short, Reservation.Style.Long)

fun Reservation.Style.Companion.possibleOptions(obj: Object, texts: ReservationScreen.Texts) =
    possibleValues.map { style ->
        val duration = when (style) {
            is Reservation.Style.Short -> obj.duration(Reservation.Style.Short)
            is Reservation.Style.Long -> obj.duration(Reservation.Style.Long)
            else -> null
        }
        DesignSystem.Option.item(
            title = when (style) {
                is Reservation.Style.Short -> Mustache.parse(texts.stayShort, mapOf("duration" to obj.duration(Reservation.Style.Short).toString()))
                is Reservation.Style.Long -> Mustache.parse(texts.stayLong, mapOf("duration" to obj.duration(Reservation.Style.Long).toString()))
                else -> ""
            },
            data = style,
            action = DesignSystem.Action.NONE,
            visible = duration != null
        )
    }

fun Reservation.Style.Companion.selectedOption(style: Reservation.Style, obj: Object, texts: ReservationScreen.Texts) =
    possibleOptions(obj, texts).firstOrNull { it.data == style }

object ReservationScreen {
    data class Texts(
        val title: String,
        val back: String,
        val previous: String,
        val next: String,
        val stayType: String,
        val stayShort: String,
        val stayLong: String,
        val monday: String,
        val tuesday: String,
        val wednesday: String,
        val thursday: String,
        val friday: String,
        val saturday: String,
        val sunday: String,
        val book: String,
        val currentBooking: String,
        val stayTypeTitle: String,
        val me: String,
        val other: String,
        val rules: String,
        val locked: String,
        val gold: String,
        val today: String,
        val timeTip: String,
        val createdTitle: String,
        val createdBody: String,
        val done: String,
        val from: String,
        val to: String,
        val errorBooking: String,
        val ok: String,
        val warningReversed: String,
        val warningPassed: String,
        val warningRestricted: String,
        val warningOverlaps: String,
        val warningConcurrentShortHigh: String,
        val warningConcurrentLongHigh: String,
        val warningConcurrentShortNormal: String,
        val warningConcurrentLongNormal: String,
        val warningDurationShort: String,
        val warningDurationLong: String,
        override val january: String,
        override val february: String,
        override val mars: String,
        override val april: String,
        override val may: String,
        override val june: String,
        override val july: String,
        override val august: String,
        override val september: String,
        override val october: String,
        override val november: String,
        override val december: String,
        override val progressInfo: String,
        override val failureTitle: String,
    ) : AvailabilityHelper.Texts, ProgressTexts, FailureTexts {
        companion object
    }

    data class State(
        val obj: Object = Object.None,
        val style: Reservation.Style = Reservation.Style.Short,
        val isGold: Boolean = false,
        val offset: Int = 0,
        val first: List<Availability> = emptyList(),
        val second: List<Availability> = emptyList(),
        val startsAt: Date? = null,
        val endsAt: Date? = null,
    )

    data class CreatedParams(
        val reservation: Reservation
    )

    data class ReadyParams(
        val styleDurations: List<DesignSystem.Option.Item>,
        val items: List<DesignSystem.Month>
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val params: ReadyParams,
            val title: DesignSystem.Text,
            val prev: DesignSystem.Button,
            val stayType: DesignSystem.MenuButton,
            val next: DesignSystem.Button,
            val first: DesignSystem.Month,
            val second: DesignSystem.Month,
            val weekdays: List<DesignSystem.Text>,
            val book: DesignSystem.Button,
            val help: DesignSystem.Button,
            val settings: DesignSystem.Button,
            val currentBooking: DesignSystem.Text,
            val bookingIcon: DesignSystem.Icon,
            val startDate: DesignSystem.Text,
            val endDate: DesignSystem.Text,
            val stayTypeTitle: DesignSystem.Text,
            val helpers: List<DesignSystem.Text>,
            val timeTip: DesignSystem.Option,
            val errorBooking: DesignSystem.Text,
            val warning: DesignSystem.Modal,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Creating(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val readyParams: ReadyParams,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Created(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val params: CreatedParams,
            val image: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val body: DesignSystem.Text,
            val from: DesignSystem.Text,
            val to: DesignSystem.Text,
            val done: DesignSystem.Button,
            val warning: DesignSystem.Modal,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        fun loading(texts: Texts) = Loading(
            texts = texts,
            state = state,
            navigation = DesignSystem.Navigation.progress(),
            progress = progress(texts = texts),
        )

        //items: List<DesignSystem.Month>,
        fun ready(state: State? = null, params: ReadyParams, warning: String? = ""): ViewModel =
            (state ?: this.state).let { s ->
                Ready(
                    texts = texts,
                    state = s,
                    params = params,
                    title = DesignSystem.Text(text = texts.title, style = DesignSystem.TextStyle.TITLE1),
                    navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing),
                    prev = DesignSystem.Button(
                        title = texts.previous,
                        image = DesignSystem.Image.PREVIOUS,
                        background = DesignSystem.Color.CLEAR,
                        style = DesignSystem.ButtonStyle.TRANSPARENT,
                    ),
                    stayType = DesignSystem.MenuButton(
                        title = texts.stayType,
                        menu = DesignSystem.Menu(items = params.styleDurations),
                        selected = Reservation.Style.selectedOption(style = s.style, texts = texts, obj = s.obj),
                        visible = params.styleDurations.any { it.visible }
                    ),
                    next = DesignSystem.Button(
                        title = texts.next,
                        image = DesignSystem.Image.NEXT,
                        background = DesignSystem.Color.CLEAR,
                        style = DesignSystem.ButtonStyle.TRANSPARENT,
                        iconAlignment = DesignSystem.IconAlignment.RIGHT
                    ),
                    first = params.items[0],
                    second = params.items[1],
                    weekdays = listOf(
                        DesignSystem.Text(text = texts.monday),
                        DesignSystem.Text(text = texts.tuesday),
                        DesignSystem.Text(text = texts.wednesday),
                        DesignSystem.Text(text = texts.thursday),
                        DesignSystem.Text(text = texts.friday),
                        DesignSystem.Text(text = texts.saturday),
                        DesignSystem.Text(text = texts.sunday),
                    ),
                    book = DesignSystem.Button(
                        title = texts.book,
                        style = DesignSystem.ButtonStyle.PRIMARY,
                    ),
                    settings = DesignSystem.Button(
                        image = DesignSystem.Image.RULES,
                        background = DesignSystem.Color.CLEAR,
                        style = DesignSystem.ButtonStyle.IMAGE,
                    ),
                    help = DesignSystem.Button(
                        image = DesignSystem.Image.INFO,
                        background = DesignSystem.Color.CLEAR,
                        style = DesignSystem.ButtonStyle.IMAGE,
                    ),
                    currentBooking = DesignSystem.Text(
                        text = texts.currentBooking,
                        style = DesignSystem.TextStyle.HEADLINE
                    ),
                    bookingIcon = DesignSystem.Icon(image = DesignSystem.Image.CALENDAR),
                    startDate = DesignSystem.Text(
                        text = if (s.startsAt != null) {
                            s.startsAt.dateTime.date.toString()
                        } else "",

                        ),
                    endDate = DesignSystem.Text(
                        text = if (s.endsAt != null) {
                            s.endsAt.dateTime.date.toString()
                        } else ""
                    ),
                    stayTypeTitle = DesignSystem.Text(text = texts.stayTypeTitle, style = DesignSystem.TextStyle.HEADLINE),
                    helpers = listOf(
                        DesignSystem.Text(text = texts.me, image = DesignSystem.Image.BOOKED_ME, iconAlignment = DesignSystem.IconAlignment.LEFT),
                        DesignSystem.Text(text = texts.other, image = DesignSystem.Image.BOOKED_OTHER, iconAlignment = DesignSystem.IconAlignment.LEFT),
                        DesignSystem.Text(text = texts.rules, image = DesignSystem.Image.RULEBASED, iconAlignment = DesignSystem.IconAlignment.LEFT),
                        DesignSystem.Text(text = texts.locked, image = DesignSystem.Image.BOOKING_LOCK, iconAlignment = DesignSystem.IconAlignment.LEFT),
                        DesignSystem.Text(text = texts.today, image = DesignSystem.Image.BOOKING_TODAY, iconAlignment = DesignSystem.IconAlignment.LEFT),
                        DesignSystem.Text(text = texts.gold, image = DesignSystem.Image.GOLD, iconAlignment = DesignSystem.IconAlignment.LEFT)
                    ),
                    timeTip = DesignSystem.Option.item(title = texts.timeTip, image = DesignSystem.Image.TIP),
                    errorBooking = DesignSystem.Text(text = texts.errorBooking, style = DesignSystem.TextStyle.SUBHEAD, textColor = DesignSystem.Color.PEARL),
                    warning = DesignSystem.Modal(
                        title = DesignSystem.Text(text = "Avvikelse", style = DesignSystem.TextStyle.TITLE1),
                        body = DesignSystem.Text(text = warning, isMarkdown = true, alignment = DesignSystem.TextAlignment.CENTER),
                        firstButton = DesignSystem.Button(title = "OK"),
                        centerButton = true,
                        visible = !warning.isNullOrEmpty(),
                    ),
                )
            }

        fun creating(texts: Texts, state: State, readyParams: ReadyParams) = Creating(
            texts = texts,
            state = state,
            navigation = DesignSystem.Navigation.minimalLight,
            readyParams = readyParams,
        )

        fun created(state: State? = null, params: CreatedParams, warning: String?) = Created(
            texts = texts,
            state = (state ?: this.state),
            navigation = DesignSystem.Navigation.minimalLight,
            params = params,
            image = DesignSystem.ImageView(image = DesignSystem.Image.HAPPINESS),
            title = DesignSystem.Text(text = texts.createdTitle, style = DesignSystem.TextStyle.TITLE1),
            body = DesignSystem.Text(text = texts.createdBody, style = DesignSystem.TextStyle.BODY),
            from = DesignSystem.Text(text = formatDateTimeLong(params.reservation.startsAt, params.reservation.resource.timeZone), image = DesignSystem.Image.ARRIVAL, iconAlignment = DesignSystem.IconAlignment.LEFT),
            to = DesignSystem.Text(text = formatDateTimeLong(params.reservation.endsAt, params.reservation.resource.timeZone), image = DesignSystem.Image.DEPARTURE, iconAlignment = DesignSystem.IconAlignment.LEFT),
            done = DesignSystem.Button(
                title = texts.done,
                style = DesignSystem.ButtonStyle.PRIMARY,
            ),
            warning = DesignSystem.Modal(
                title = DesignSystem.Text(text = "Avvikelse", style = DesignSystem.TextStyle.TITLE1),
                body = DesignSystem.Text(text = warning, isMarkdown = true, alignment = DesignSystem.TextAlignment.CENTER),
                firstButton = DesignSystem.Button(title = "OK"),
                centerButton = true,
                visible = !warning.isNullOrEmpty(),
            ),
        )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false) =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val ready get() = this as? Ready
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(message: String) =
        sceneOf(viewModel.failed(message))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        val texts = Texts(
            title = "Boka",
            back = "Tillbaka",
            previous = "Föregående",
            next = "Nästa",
            stayType = "Bokningstyp",
            stayShort = "Kort, max {{duration}} dagar",
            stayLong = "Lång, max {{duration}} dagar",
            monday = "M",
            tuesday = "T",
            wednesday = "O",
            thursday = "T",
            friday = "F",
            saturday = "S",
            sunday = "S",
            book = "Boka",
            january = "Januari",
            february = "Februari",
            mars = "Mars",
            april = "April",
            may = "Maj",
            june = "Juni",
            july = "Juli",
            august = "Augusti",
            september = "September",
            october = "Oktober",
            november = "November",
            december = "December",
            currentBooking = "Aktuell bokning:",
            stayTypeTitle = "Välj Bokningstyp",
            me = "Du",
            other = "Andra samägare",
            rules = "Regelstyrd",
            locked = "Låst",
            gold = "Guldvecka",
            today = "Dagens datum",
            progressInfo = "Laddar...",
            failureTitle = "Oj, ett fel har uppstått",
            timeTip = "Bokningar gäller med från och tillträde kl 12 så du kan boka på annan samägares start/slutdag ;).",
            createdTitle = "Din bokning är genomförd!",
            createdBody = "Tänk på att det är in- och utcheckning kl 12:00.",
            done = "Klar",
            from = "Från",
            to = "Till ",
            ok = "Ok",
            errorBooking = "Du måste välja start och slutdatum för att kunna boka",
            warningReversed = "Bokningens startdatum måste vara tidigare än bokningens slutdatum.",
            warningPassed = "Detta datum har passerat.",
            warningRestricted = "Valt datum för bokning är låst.",
            warningOverlaps = "Denna bokning krockar med en befintlig bokning.",
            warningConcurrentShortHigh = "Du har passerat max antal kortbokningar för guldveckorna enligt det ni kommit överens om.",
            warningConcurrentLongHigh = "Du har passerat max antal långbokningar för guldveckorna enligt det ni kommit överens om.",
            warningConcurrentShortNormal = "Du har passerat max antal kortbokningar enligt det ni kommit överens om.",
            warningConcurrentLongNormal = "Du har passerat max antal långbokningar enligt det ni kommit överens om.",
            warningDurationShort = "Kortbokningen är för lång enligt det ni kommit överens om.",
            warningDurationLong = "Långbokningen är för lång enligt det ni kommit överens om.",
            /*deviationWarning = """Enligt reglerna får {{rule}}. Bokningen kommer att läggas men vänligen kontrollera med dina samägare att det är ok att nytja fler kortbokningar.
                """.trimIndent(),
            deviationStrict = """Tyvärr, enligt reglerna får {{rule}}.
                """.trimIndent(),
            deviationPermission = """Enligt reglerna får {{rule}}.

Vill du göra en avvikelse kräver det att du har tillåtelse från dina samägare. Skicka ut förfrågan om avvikelse

**Skicka ut förfrågan om avvikelse**
                """.trimIndent(),*/
        )

        return sceneOf<ViewModel>(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val style = viewModel.state.style
        val offset = viewModel.state.offset
        val interval1 = listOf(Reservation.Filter.ForMonthOffset(offset))
        val interval2 = listOf(Reservation.Filter.ForMonthOffset(offset + 1))

        return store.refreshObject(objectId = objectId, agreementId = agreementId)
            .flatMap { (actions, obj) ->
                store.reduce(actions)
                    .findAvailability(obj = obj, resource = obj.resource.key, filters = interval1, style = style)
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj, it.second) }
            }
            .flatMap { (actions, obj, first) ->
                store.reduce(actions)
                    .findAvailability(obj = obj, resource = obj.resource.key, filters = interval2, style = style)
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj, first, it.second) }
            }
            .map { (actions, obj, first, second) ->
                val styleDurations = Reservation.Style.possibleOptions(texts = viewModel.texts, obj = obj).filter { it.visible }

                val state = viewModel.state.copy(
                    obj = obj,
                    first = first,
                    second = second,
                    style = if (styleDurations.firstOrNull() == null) Reservation.Style.Short else styleDurations.first().data as Reservation.Style
                )

                val params = ReadyParams(items = buildItems(store = store, texts = viewModel.texts, state = state, availability = first + second), styleDurations = styleDurations)
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }.failed { scene.failed(result = it) }
    }

    suspend fun prev(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (viewModel !is ViewModel.Ready) return scene.failed("Can't go next if not Ready")

        val style = viewModel.state.style
        val offset = viewModel.state.offset
        val interval1 = listOf(Reservation.Filter.ForMonthOffset(offset - 1))

        return store.refreshObject(objectId = viewModel.state.obj.id, agreementId = viewModel.state.obj.currentAgreement.id)
            .flatMap { (actions, obj) ->
                store.reduce(actions)
                    .findAvailability(obj = obj, resource = obj.resource.key, filters = interval1, style = style)
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj, it.second) }
            }
            .map { (actions, obj, prev) ->
                val state = viewModel.state.copy(
                    obj = obj,
                    offset = offset - 1,
                    first = prev,
                    second = viewModel.state.first,
                )

                val params = viewModel.params.copy(
                    items = buildItems(store = store, texts = viewModel.texts, state = state, availability = state.first + state.second)
                )
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }.failed { scene.failed(result = it) }
    }

    suspend fun next(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (viewModel !is ViewModel.Ready) return scene.failed("Can't go next if not Ready")

        val style = viewModel.state.style
        val offset = viewModel.state.offset
        val interval2 = listOf(Reservation.Filter.ForMonthOffset(offset + 2))

        return store.refreshObject(objectId = viewModel.state.obj.id, agreementId = viewModel.state.obj.currentAgreement.id)
            .flatMap { (actions, obj) ->
                store.reduce(actions)
                    .findAvailability(obj = obj, resource = obj.resource.key, filters = interval2, style = style)
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj, it.second) }
            }
            .map { (actions, obj, next) ->
                val state = viewModel.state.copy(
                    obj = obj,
                    offset = offset + 1,
                    first = viewModel.state.second,
                    second = next,
                )

                val params = viewModel.params.copy(
                    items = buildItems(store = store, texts = viewModel.texts, state = state, availability = state.first + state.second)
                )
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }.failed { scene.failed(result = it) }
    }

    suspend fun setStyle(scene: Scene.Input<ViewModel>, option: DesignSystem.Option): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (viewModel !is ViewModel.Ready) return scene.failed("Can't go next if not Ready")

        val style = (option.data as? Reservation.Style) ?: viewModel.state.style
        val offset = viewModel.state.offset
        val interval1 = listOf(Reservation.Filter.ForMonthOffset(offset))
        val interval2 = listOf(Reservation.Filter.ForMonthOffset(offset + 1))

        return store.refreshObject(objectId = viewModel.state.obj.id, agreementId = viewModel.state.obj.currentAgreement.id)
            .flatMap { (actions, obj) ->
                store.reduce(actions)
                    .findAvailability(obj = obj, resource = obj.resource.key, filters = interval1, style = style)
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj, it.second) }
            }
            .flatMap { (actions, obj, first) ->
                store.reduce(actions)
                    .findAvailability(obj = obj, resource = obj.resource.key, filters = interval2, style = style)
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj, first, it.second) }
            }
            .map { (actions, obj, first, second) ->
                val state = viewModel.state.copy(
                    obj = obj,
                    style = style,
                    first = first,
                    second = second,
                )

                val params = viewModel.params.copy(
                    items = buildItems(store = store, texts = viewModel.texts, state = state, availability = state.first + state.second)
                )
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }.failed { scene.failed(result = it) }
    }

    suspend fun setValues(
        scene: Scene.Input<ViewModel>,
        cell: DesignSystem.Cell?,
    ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (viewModel !is ViewModel.Ready) return scene.failed("Can't update values if not Ready")

        successfulOf(true).noActions()
            .flatMap { (actions, x) ->
                successfulOf(tupleOf(actions, x))
            }
            .map { (actions, availality) ->

            }
        val date = cell?.let { DateSerializer.deserialize(it.id.rawValue) }
        val cellIsGold = cell?.isGold ?: false

        val obj = viewModel.state.obj
        val zone = TimeZone.of(obj.resource.timeZone)

        val isToday = Date(Date.now().dateTime.toInstant(zone).toLocalDateTime(TimeZone.UTC))

        val (start, end, isGold) = when {
            viewModel.state.startsAt == null && viewModel.state.endsAt == null -> tupleOf(date, null, cellIsGold)
            viewModel.state.startsAt != null && viewModel.state.endsAt == null -> tupleOf(viewModel.state.startsAt, date, cellIsGold || viewModel.state.isGold)
            viewModel.state.startsAt != null && viewModel.state.endsAt != null -> tupleOf(date, null, cellIsGold)
            else -> tupleOf(date, null, cellIsGold) // An error really
        }

        val state = viewModel.state.copy(
            isGold = isGold,
            startsAt = if ((end != null && start != null) && (end < start || start == end)) null else if (start != null && start > isToday) start else null,
            endsAt = if (end != null && end > start!!) end else null,
        )

        val params = viewModel.params.copy(
            items = buildItems(store = store, texts = viewModel.texts, state = state, availability = state.first + state.second)
        )

        return sceneOf(
            viewModel.ready(state = state, params = params)
        )
    }

    suspend fun check(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        if (viewModel !is ViewModel.Ready) return scene.failed("Can't check values if not Ready")

        return sceneOf(viewModel.creating(texts = viewModel.texts, state = viewModel.state, readyParams = viewModel.params))
    }


    private fun warningsText(texts: Texts, warning: Warning): String =
        when (warning.code) {
            Reservation.WARNING_REVERSED -> texts.warningReversed
            Reservation.WARNING_PASSED -> texts.warningPassed
            Reservation.WARNING_RESTRICTED -> texts.warningRestricted
            Reservation.WARNING_OVERLAPS -> texts.warningOverlaps
            Reservation.WARNING_CONCURRENT_SHORT_HIGH -> texts.warningConcurrentShortHigh
            Reservation.WARNING_CONCURRENT_LONG_HIGH -> texts.warningConcurrentLongHigh
            Reservation.WARNING_CONCURRENT_SHORT_NORMAL -> texts.warningConcurrentShortNormal
            Reservation.WARNING_CONCURRENT_LONG_NORMAL -> texts.warningConcurrentLongNormal
            Reservation.WARNING_DURATION_SHORT -> texts.warningDurationShort
            Reservation.WARNING_DURATION_LONG -> texts.warningDurationLong
            else -> ""
        }

    suspend fun book(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (viewModel !is ViewModel.Creating) return scene.failed("Can't update values if not Creating")
        val obj = viewModel.state.obj

        // Start and end is expressed in resource timezone and converted to UTC before creation
        val zone = TimeZone.of(obj.resource.timeZone)
        val startsAt = viewModel.state.startsAt?.toKotlinxLocalDateTime()?.let {
            val dateTime = LocalDateTime(year = it.year, monthNumber = it.monthNumber, dayOfMonth = it.dayOfMonth, 12, 0, 0)
            Date(dateTime.toInstant(zone).toLocalDateTime(TimeZone.UTC))
        } ?: return scene.failed("Start date needed for booking")
        val endsAt = viewModel.state.endsAt?.toKotlinxLocalDateTime()?.let {
            val dateTime = LocalDateTime(year = it.year, monthNumber = it.monthNumber, dayOfMonth = it.dayOfMonth, 12, 0, 0)
            Date(dateTime.toInstant(zone).toLocalDateTime(TimeZone.UTC))
        } ?: return scene.failed("End date needed for booking")

        val create = Reservation.Create(
            resource = obj.resource.key,
            name = "",
            startsAt = startsAt,
            endsAt = endsAt,
            style = viewModel.state.style,
            value = if (viewModel.state.isGold) Reservation.Value.High else Reservation.Value.Normal,
        )

        return store.createReservation(obj = obj, create = create)
            .mapWithWarnings { (actions, reservation), warnings ->
                val state = viewModel.state.copy(
                    startsAt = null,
                    endsAt = null,
                )

                val warning = warnings.joinToString("\n") { "- ${warningsText(viewModel.texts, it)}" }
                sceneOf<ViewModel>(
                    viewModel.created(state = state, params = CreatedParams(reservation = reservation), warning = warning), actions
                )

            }
            .onInvalid { warnings ->
                val state = viewModel.state.copy(endsAt = null)
                val params = viewModel.readyParams.copy(
                    items = buildItems(store = store, texts = viewModel.texts, state = state, availability = state.first + state.second)
                )
                val warning = warnings.filter { it.code.isNotEmpty() }.joinToString("\n") { "- ${warningsText(viewModel.texts, it)}" }
                return@book sceneOf(viewModel.ready(state = state, params = params, warning = warning))
            }
            .failed { scene.failed(result = it) }
    }

    fun dismiss(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return when (viewModel) {
            is ViewModel.Ready -> sceneOf(viewModel.ready(params = viewModel.params, warning = null))
            is ViewModel.Created -> sceneOf(viewModel.created(params = viewModel.params, warning = null))
            else -> scene.failed("Can only dismiss Ready or Created")
        }
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun success(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return load(scene = scene, objectId = viewModel.state.obj.id, agreementId = viewModel.state.obj.currentAgreement.id)
    }

    private fun buildItems(store: Store, texts: Texts, state: State, availability: List<Availability>): List<DesignSystem.Month> =
        AvailabilityHelper.buildItems(texts = texts, obj = state.obj, availability = availability, style = state.style, startsAt = state.startsAt, endsAt = state.endsAt)
}

