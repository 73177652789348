package della8.web.items

import della8.core.items.RuleItem
import della8.web.components.*
import react.ChildrenBuilder
import react.FC
import react.PropsWithChildren


external interface RuleItemSimpleSectionProps : PropsWithChildren {
    var className: String?
    var viewModel: RuleItem.ViewModel.SimpleSection
}


val D8RuleItemSimpleSection = FC<RuleItemSimpleSectionProps> { props ->
    if (props.viewModel.visible)
        D8Text {
            design = props.viewModel.header.title
        }
}


fun ChildrenBuilder.ruleItemSimpleSection(
    viewModel: RuleItem.ViewModel.SimpleSection,
    className: String = "",
) =
    D8RuleItemSimpleSection {
        this.className = className
        this.viewModel = viewModel
    }