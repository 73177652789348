package bootstrap

import org.w3c.dom.events.Event
import react.*

@JsNonModule
@JsModule("react-bootstrap/Dropdown")
private external val dropdownModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bDropdownItem: ComponentType<DropdownItemProps> = dropdownModule.default.Item

external interface DropdownItemProps : PropsWithChildren {
    var `as`: dynamic /* 'input' | elementType */
    var active: Boolean
    var block: Boolean
    var disabled: Boolean
    var href: String
    var size: String /* 'sm' | 'lg' */
    var target: Any
    var type: String? /* 'button' | 'reset' | 'submit' | null */
    var variant: String
    var bsPrefix: String
    var className: String
    var eventKey: String

    var onClick: ((event: Event) -> Unit)
}
/*
class DropdownItemElementBuilder internal constructor(
    private val type: ComponentType<DropdownItemProps>, props: DropdownItemProps = jsObject { }
) : RElementBuilderImpl<DropdownItemProps>(props) {

    fun create() = createElement(type, attrs, *childList.toTypedArray())
}

fun RBuilder.bDropdownItem(
    bsPrefix: String? = null,
    disabled: Boolean? = null,
    active: Boolean? = null,
    onClick: ((event: Event) -> Unit)? = null,
    eventKey: String? = null,
    href: String? = null,
    block: DropdownItemElementBuilder.() -> Unit = {},

    ) =
    child(
        DropdownItemElementBuilder(dropdownItemComponent)
            .apply {
                bsPrefix?.let { attrs.bsPrefix = it }
                disabled?.let { attrs.disabled = it }
                active?.let { attrs.active = it }
                onClick?.let { attrs.onClick = it }
                eventKey?.let { attrs.eventKey = it }
                href?.let { attrs.href = it }
                block()
            }.create()
    )

fun <T : Any> RBuilder.bDropdownItem(
    bsPrefix: String? = null,
    disabled: Boolean? = null,
    active: Boolean? = null,
    onClick: ((event: Event) -> Unit)? = null,
    eventKey: String? = null,
    href: String? = null,
    block: DropdownItemElementBuilder.() -> Unit = {},
) =
    child(
        DropdownItemElementBuilder(dropdownItemComponent)
            .apply {
                bsPrefix?.let { attrs.bsPrefix = it }
                disabled?.let { attrs.disabled = it }
                active?.let { attrs.active = it }
                onClick?.let { attrs.onClick = it }
                eventKey?.let { attrs.eventKey = it }
                href?.let { attrs.href = it }
                block()
            }.create()
    )
*/