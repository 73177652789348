package della8.core.support

import techla.agreement.Term
import techla.base.ConditionsOfSale

val ConditionsOfSale.displayName
    get() =
        when (this) {
            is ConditionsOfSale.AccordingToLaw -> "Enligt lag (fritt att ske till vilket pris som helst)"
            is ConditionsOfSale.ExternalValuation -> "Får ske, men enbart till ett pris som värderingsman har gjort"
            is ConditionsOfSale.Preemption -> "Får ske, men övriga delägare har förköpsrätt"
            is ConditionsOfSale.Other -> description
            else -> ""
        }

val Term.Clause.Sale.displayName
    get() =
        conditionsOfSale.displayName

data class EditableConditionsOfSale(
    val style: Style,
    val description: String = "",
) {
    // With meeting with Martin 14/9 -22 EXTERNAL_VALUATION were removed for new agreements
    enum class Style { ACCORDING_TO_LAW/*, EXTERNAL_VALUATION*/, PREEMPTION, OTHER }

    companion object {
        fun fromTruth(truth: ConditionsOfSale) =
            when (truth) {
                is ConditionsOfSale.AccordingToLaw ->
                    EditableConditionsOfSale(
                        style = Style.ACCORDING_TO_LAW,
                    )
                is ConditionsOfSale.ExternalValuation ->
                    EditableConditionsOfSale(
                        style = Style.OTHER,
                        description = truth.displayName
                    )
                is ConditionsOfSale.Preemption ->
                    EditableConditionsOfSale(
                        style = Style.PREEMPTION,
                    )
                is ConditionsOfSale.Other ->
                    EditableConditionsOfSale(
                        style = Style.OTHER,
                        description = truth.description
                    )
                else ->
                    EditableConditionsOfSale(
                        style = Style.OTHER,
                        description = ""
                    )
            }
    }

    val truth
        get() =
            when {
                style == Style.ACCORDING_TO_LAW -> ConditionsOfSale.AccordingToLaw
                // style == Style.EXTERNAL_VALUATION -> ConditionsOfSale.ExternalValuation
                style == Style.PREEMPTION -> ConditionsOfSale.Preemption
                style == Style.OTHER -> ConditionsOfSale.Other(description = description)
                else -> null
            }
}

val EditableConditionsOfSale.Style.option
    get() =
        when (this) {
            EditableConditionsOfSale.Style.ACCORDING_TO_LAW -> DesignSystem.Option.item(title = ConditionsOfSale.AccordingToLaw.displayName, value = name, data = this)
            // EditableConditionsOfSale.Style.EXTERNAL_VALUATION -> DesignSystem.Option.item(title = ConditionsOfSale.ExternalValuation.displayName, value = name, data = this)
            EditableConditionsOfSale.Style.PREEMPTION -> DesignSystem.Option.item(title = ConditionsOfSale.Preemption.displayName, value = name, data = this)
            EditableConditionsOfSale.Style.OTHER -> DesignSystem.Option.other(title = "Annat", value = name, data = this)
        }
