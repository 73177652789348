package della8.core.services

import della8.core.support.ActionOutcome
import della8.core.support.Demo
import della8.core.support.Store
import techla.base.*
import techla.guard.*
import techla.personal.Bio
import techla.personal.Narrative
import techla.personal.PersonalAPI
import techla.personal.PersonalResource

suspend fun Store.myNarrative(): ActionOutcome<Narrative?> =
    PersonalEndpoint.MyNarrative(this)


suspend fun Store.createNarrative(create: Narrative.Create): ActionOutcome<Narrative> =
    PersonalEndpoint.createNarrative(this, create)


suspend fun Store.editNarrative(id: Identifier<Narrative>, edit: Narrative.Edit): ActionOutcome<Narrative> =
    PersonalEndpoint.editNarrative(this, id, edit)


suspend fun Store.bios(batch: Bio.Batch): ActionOutcome<List<Bio>> =
    PersonalEndpoint.bios(this, batch)


val Store.personalAPI get() =
    PersonalAPI(httpClient).also { api ->
        api.host = if (deployment.isSandbox) GuardAPI.sandbox else GuardAPI.shared
        api.token = userToken ?: applicationToken
    }

object PersonalEndpoint {
    var MyNarrative: suspend (store: Store) -> ActionOutcome<Narrative?> = { store ->
        if (store.demoMode)
            Demo.narrative.map { emptyList<Store.Action>() to it }
        else {
            store.withUserToken { updated ->
                val api = updated.personalAPI
                measureAPI(PersonalResource.MyNarrative, api) {
                    api.myNarrative()
                        .onNotSuccess { techla_log("WARN: $it") }
                        .fold(
                            { narrative -> successfulOf(narrative) },
                            { successfulOf(null) }
                        )
                }
            }
        }
    }

    var createNarrative: suspend (store: Store, create: Narrative.Create) -> ActionOutcome<Narrative> = { store, create ->
        if (store.demoMode)
            Demo.narrative.map { emptyList<Store.Action>() to it }
        else {
            store.withUserToken { updated ->
                val api = updated.personalAPI
                measureAPI(PersonalResource.CreateNarrative(create), api) {
                    api.createNarrative(create)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var editNarrative: suspend (store: Store, id: Identifier<Narrative>, edit: Narrative.Edit) -> ActionOutcome<Narrative> = { store, id, edit ->
        if (store.demoMode)
            Demo.narrative.map { emptyList<Store.Action>() to it }
        else {
            store.withUserToken { updated ->
                val api = updated.personalAPI
                measureAPI(PersonalResource.EditNarrative(id, edit), api) {
                    api.editNarrative(id, edit)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }

    var bios: suspend (store: Store, batch: Bio.Batch) -> ActionOutcome<List<Bio>> = { store, batch ->
        if (store.demoMode)
            Demo.listAllBios.map { emptyList<Store.Action>() to it }
        else {
            store.withUserToken { updated ->
                val api = updated.personalAPI
                measureAPI(PersonalResource.Bios(batch), api) {
                    api.bios(batch)
                        .onNotSuccess { techla_log("WARN: $it") }
                }
            }
        }
    }
}