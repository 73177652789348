package della8.web.views

import StoreContext
import bootstrap.*
import della8.core.items.LandingItem
import della8.core.screens.LandingScreen
import della8.core.support.DesignSystem
import della8.core.support.Location
import della8.core.support.Object
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.items.landingItem
import della8.web.support.className
import della8.web.support.standardNavigation
import della8.web.support.useAsyncEffect
import kotlinx.browser.window
import kotlinx.coroutines.*
import react.*
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import techla.agreement.Agreement
import techla.base.Identifier
import techla.base.techla_log


val Landing = FC<PropsWithChildren>("Landing") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(LandingScreen.ViewModel.None as LandingScreen.ViewModel)
    val navigate = useNavigate()

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is LandingScreen.ViewModel.None ->
                LandingScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        window.scrollTo(x = 0.0, y = 0.0)
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }

            is LandingScreen.ViewModel.Loading -> {
                LandingScreen.load(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    if (coroutineScope.isActive) {
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }
            }

            is LandingScreen.ViewModel.Empty -> {
            }

            is LandingScreen.ViewModel.Ready -> {
            }

            is LandingScreen.ViewModel.Failed ->
                techla_log("failed")
        }
    }

    fun handleLogout() {
        MainScope().launch {
            LandingScreen.logout(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
                navigate(Location.Start.route)
            }
        }
    }

    fun handleCreate() {
        navigate(Location.Create.route)
    }

    fun handleRedeemCode() {
        navigate(Location.RedeemCode.route)
    }

    suspend fun onGroup(id: Identifier<LandingItem>, objectId: Identifier<Object>, agreementId: Identifier<Agreement>) {
        techla_log("Object: ${objectId.rawValue}/${agreementId.rawValue}")
        navigate(Location.Overview(objectId = objectId, agreementId = agreementId).route)
    }

    main {
        when (viewModel) {
            is LandingScreen.ViewModel.Ready, is LandingScreen.ViewModel.Empty -> d8Navigation(design = viewModel.navigation, onClick = { action ->
                when (action) {
                    DesignSystem.Action.LOGOUT -> handleLogout()
                    else -> {}
                }
            })

            else -> d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
        }
        if (viewModel is LandingScreen.ViewModel.Empty) {
            bContainer {
                className = className("px-5 n-bottom-space n-top-space")
                bRow {
                    bCol {
                        className = className("p-0 my-auto px-0 px-md-4")
                        d8Text(className = "d-inline text-white", design = viewModel.title)
                    }
                }
                bRow {
                    className = className("row-cols-1 row-cols-md-2 row-cols-lg-2 mb-0 mb-md-1")
                    ReactHTML.div {
                        className = className("px-1 py-4 px-md-4 py-md-4 d-flex align-items-stretch")
                        bCard {
                            className = className("radius-25 border-0 card-shadow")
                            bCardBody {
                                className = className("p-0")
                                ReactHTML.div {
                                    className = className("p-0")
                                    d8Image(className = "img-fluid radius-25", design = viewModel.image)
                                    ReactHTML.div {
                                        className = className("px-4 pt-4 pb-2")
                                        d8Text(className = "d-inline", design = viewModel.name)
                                        d8Text(design = viewModel.body)
                                        div {
                                            className = className("grid")
                                            d8Button(className = "me-2 mb-2", design = viewModel.redeemCode, onClick = { handleRedeemCode() })
                                            d8Button(className = "mb-2", design = viewModel.create, onClick = { handleCreate() })
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        if (viewModel is LandingScreen.ViewModel.Ready) {
            bContainer {
                className = className("px-5 n-bottom-space n-top-space")
                bRow() {
                    bCol {
                        className = className("p-0 my-auto px-0 px-md-4")
                        d8Text(className = "d-inline text-white", design = viewModel.title)
                        d8Button(className = "btn-lg px-3 px-md-5 d-inline float-end", design = viewModel.create, onClick = { handleCreate() })
                    }
                }
                bRow {
                    className = className("row-cols-1 row-cols-md-2 row-cols-lg-2 mb-0 mb-md-1")
                    viewModel.items.map { item ->
                        landingItem(item, onClick = ::onGroup)
                    }
                }
            }
            D8Clippy { design = viewModel.clippy }
        }
        if (viewModel is LandingScreen.ViewModel.Loading) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is LandingScreen.ViewModel.Failed) {
            bContainer {
                className = className(listOfNotNull("px-5 n-top-space"))
                d8failure(viewModel.failure, ::handleLogout)
            }
        }
    }
}
