package della8.web.views

import StoreContext
import bootstrap.*
import della8.core.screens.ClippyScreen
import della8.core.support.DesignSystem
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.className
import della8.web.support.useExecutor
import della8.web.support.useReactScope
import react.*
import react.dom.html.ReactHTML.main

external interface ClippyViewProps : PropsWithChildren {
}


val ClippyView = FC<ClippyViewProps>("ClippyView") { props ->
    val (store, dispatch) = useContext(StoreContext)
    var viewModel: ClippyScreen.ViewModel by useState(ClippyScreen.ViewModel.None)
    val executor by useExecutor<ClippyScreen.ViewModel> { (updated, actions) ->
        viewModel = updated
        dispatch(actions)
    }

    useEffect(viewModel) {
        if (viewModel is ClippyScreen.ViewModel.None)
            executor.call { ClippyScreen.start(sceneInputOf(store, viewModel)) }
        if (viewModel is ClippyScreen.ViewModel.Loading)
            executor.call { ClippyScreen.load(sceneInputOf(store, viewModel)) }
    }

    fun onLogout() {
    }

    suspend fun onPartnership(option: DesignSystem.Option) {
        executor.call { ClippyScreen.update(sceneInputOf(store, viewModel), option) }
    }

    main {
        viewModel.asReady?.run {
            D8Movie { this.className = "img-fluid"; design = movie }
            D8Text { this.className = "text-center"; design = title }
            D8SelectInput { design = partnership; onClick = ::onPartnership }
            D8FAQ { this.className = "mt-3"; design = faq }
        }

        viewModel.asFailed?.run {
            bContainer {
                className = className("px-5 n-top-space")
                D8Failure { design = failure; onLogout = ::onLogout }
            }
        }
    }
}
