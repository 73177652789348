package bootstrap

import csstype.ClassName
import react.*

@JsNonModule
@JsModule("react-bootstrap/Form")
private external val formModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bFormGroup: ComponentType<FormGroupProps> = formModule.default.Group

external interface FormGroupProps : PropsWithChildren {
    var controlId: String
    var bsPrefix: String
    var className: ClassName
}
/*
class FormGroupElementBuilder internal constructor(
    private val type: ComponentType<FormGroupProps>, props: FormGroupProps = jsObject { }
) : RElementBuilderImpl<FormGroupProps>(props) {

    fun create() = createElement(type, attrs, *childList.toTypedArray())
}

fun RBuilder.bFormGroup(
    controlId: String? = null,
    block: FormGroupElementBuilder.() -> Unit = {},
) =
    child(FormGroupElementBuilder(formGroupComponent)
        .apply {
            controlId?.let { attrs.controlId = it }
            block()
        }.create()
    )
*/