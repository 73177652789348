package bootstrap

import csstype.ClassName
import org.w3c.dom.events.Event
import react.*

@JsNonModule
@JsModule("react-bootstrap/Accordion")
private external val accordionModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bAccordion: ComponentType<AccordionProps> = accordionModule.default

@Suppress("UnsafeCastFromDynamic")
val bAccordionCollapse: ComponentType<AccordionCollapseProps> = accordionModule.default.Collapse

external interface AccordionProps : PropsWithChildren {
    var activeKey: String // The current active key that corresponds to the currently expanded card
    var alwaysOpen: Boolean // Allow accordion items to stay open when another item is opened
    var `as`: Any // Set a custom element for this component
    var defaultActiveKey: String //  The default active key that is expanded on start
    var flush: Boolean // Renders accordion edge-to-edge with its parent container
    var bsPrefix: String // Change the underlying component CSS base class name and modifier class names prefix.
    var className: ClassName

    var onSelect: ((event: Event) -> Unit) // Callback fired when the active item changes.
}

external interface AccordionCollapseProps : PropsWithChildren {
    var eventKey: String
}
