package della8.web.views

import StoreContext
import bootstrap.*
import della8.core.screens.ObjectScreen
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.items.moduleItem
import della8.web.items.moduleItemOnboarding
import della8.web.items.moduleItemStarted
import della8.web.support.*
import kotlinx.browser.window
import kotlinx.js.get
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.router.useParams
import techla.base.Identifier


val ObjectView = FC<PropsWithChildren>("ObjectView") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(ObjectScreen.ViewModel.None as ObjectScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()

    val scopedCall = scopedCall<ObjectScreen.ViewModel> { (viewModel, actions) ->
        window.scrollTo(x = 0.0, y = 0.0)
        setViewModel(viewModel)
        dispatch(actions)
    }
    val call = call<ObjectScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }
    val mainCall = mainCall<ObjectScreen.ViewModel> { (viewModel, actions) ->
        window.scrollTo(x = 0.0, y = 0.0)
        setViewModel(viewModel)
        dispatch(actions)
    }

    fun launch() {
        mainCall { ObjectScreen.launch(sceneInputOf(store, viewModel)) }
    }

    suspend fun onSuccess() {
        call { ObjectScreen.success(sceneInputOf(store, viewModel)) }
    }

    fun recover() {
        mainCall { ObjectScreen.success(sceneInputOf(store, viewModel)) }
    }

    fun logout() {
        mainCall { ObjectScreen.logout(sceneInputOf(store, viewModel)) }
        navigate("/")
    }

    fun goToModule(modulePath: String, mode: String?, module: String?) {
        val path = when {
            modulePath.isEmpty() -> "${mode}/${module}/rules"
            else -> modulePath
        }
        when (path) {
            "launch" -> launch()
            else -> navigate("/object/${params["objectId"]}/${params["agreementId"]}/${path}")
        }
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is ObjectScreen.ViewModel.None -> {
                scopedCall(coroutineScope) { ObjectScreen.start(sceneInputOf(store, viewModel)) }
            }

            is ObjectScreen.ViewModel.Loading -> {
                params["objectId"]?.let { objectId ->
                    params["agreementId"]?.let { agreementId ->
                        scopedCall(coroutineScope) { ObjectScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId)) }
                    }
                }
            }

            is ObjectScreen.ViewModel.Launching -> {
                scopedCall(coroutineScope) { ObjectScreen.liftoff(sceneInputOf(store, viewModel)) }
            }

            else -> {}
        }
    }
    main {
        when (viewModel) {
            is ObjectScreen.ViewModel.Ready -> d8Navigation(
                design = viewModel.navigation,
                className = "nav-top",
                onClick = standardNavigation(navigate)
            )

            else -> d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
        }

        if (viewModel is ObjectScreen.ViewModel.Ready) {
            bContainer {
                fluid = true
                className = className("p-0 n-header-video position-relative")
                div {
                    className = className("text-center w-100 pt-5 n-video-header-container position-absolute")

                    div {
                        className = className("pt-5 mt-5 position-relative d-flex align-items-center justify-content-center")

                        d8Text(className = "d-inline me-2", design = viewModel.title)
                        d8Element(className = "mt-1", design = viewModel.draft)
                    }
                }
                d8Movie(className = "n-video-header", design = viewModel.movie)
            }
            bContainer {
                className = className("px-4 pt-4 position-relative n-bottom-space-160")

                viewModel.items.map { item ->
                    //  moduleItem(item, action = ::goToModule)
                    moduleItemOnboarding(item, action = ::goToModule)
                    moduleItemStarted(item, action = ::goToModule)
                }
            }
            D8Clippy { this.design = viewModel.clippy; className = "n-clippy-bottom" }
        }

        if (viewModel is ObjectScreen.ViewModel.Loading) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is ObjectScreen.ViewModel.Launching) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is ObjectScreen.ViewModel.Launched) {
            Della8.Success { design = viewModel.success; onSuccess = ::onSuccess }
        }

        if (viewModel is ObjectScreen.ViewModel.Failed) {
            d8failure(viewModel.failure, onLogout = ::logout, onRecover = ::recover)
        }
    }
}
