package bootstrap

import csstype.ClassName
import react.*

@JsNonModule
@JsModule("react-bootstrap/NavbarBrand")
private external val navbarBrandModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bNavbarBrand: ComponentType<NavbarBrandProps> = navbarBrandModule.default

external interface NavbarBrandProps : PropsWithChildren {
    var `as`: Any
    var href: String
    var bsPrefix: String
    var className: ClassName
}
