package della8.core.support

import techla.agreement.Term
import techla.base.Approval

val Approval.displayName
    get() =
        when (this) {
            is Approval.None -> "Ingen"
            is Approval.Approved -> "Ja"
            is Approval.NotApproved -> "Nej"
            is Approval.Other -> description
            else -> ""
        }

val Term.Clause.Rental.displayName
    get() =
        approval.displayName

val Approval.option
    get() =
        when (this) {
            is Approval.None -> DesignSystem.Option.item(title = "Ingen", value = rawValue, data = this)
            is Approval.Approved -> DesignSystem.Option.item(title = "Ja", value = rawValue, data = this)
            is Approval.NotApproved -> DesignSystem.Option.item(title = "Nej", value = rawValue, data = this)
            is Approval.Other -> DesignSystem.Option.other(title = "Annat", value = rawValue, data = this, other = description)
            else -> DesignSystem.Option.None
        }
