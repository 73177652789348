package della8.core.support

import techla.base.Region

val Region.displayName
    get() =
        when (this) {
            is Region.Sweden -> "Sverige"
            is Region.Nordics -> "Norden"
            is Region.Europe -> "Europa"
            is Region.World -> "Världen"
            is Region.Other ->  description
            else -> ""
        }

val Region.option
    get() =
        when (this) {
            is Region.Sweden -> DesignSystem.Option.item(title = "Sverige", value = rawValue, data = this)
            is Region.Nordics -> DesignSystem.Option.item(title = "Norden", value = rawValue, data = this)
            is Region.Europe -> DesignSystem.Option.item(title = "Europa", value = rawValue, data = this)
            is Region.World -> DesignSystem.Option.item(title = "Världen", value = rawValue, data = this)
            is Region.Other -> DesignSystem.Option.other(title = "Annat", value = rawValue, data = this, other = description)
            else -> DesignSystem.Option.None
        }