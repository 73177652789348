package lottie

import react.*

@JsNonModule
@JsModule("lottie-react")
private external val lottieModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val lPlayer: ComponentType<LottieProps> = lottieModule.default

external interface LottieProps : PropsWithChildren {
    var animationData: dynamic
    var loop: dynamic
    var autoplay: Boolean
}