package della8.core.support

import io.ktor.http.*
import kotlinx.datetime.DayOfWeek
import techla.agreement.Agreement
import techla.agreement.Signature
import techla.agreement.Policy
import techla.agreement.Term
import techla.base.*
import techla.billing.Buyer
import techla.content.Content
import techla.content.Media
import techla.content.ShortLink
import techla.content.Tag
import techla.control.Event
import techla.conversation.*
import techla.form.Field
import techla.form.Form
import techla.form.Submission
import techla.guard.*
import techla.loyalty.Member
import techla.notification.Registration
import techla.order.Article
import techla.order.Order
import techla.order.Subscription
import techla.personal.Bio
import techla.personal.Narrative
import techla.reservation.Availability
import techla.reservation.Reservation
import techla.reservation.Resource

object Demo {

    const val GOVID_ANDERS_NILSSON = "201701012393"

    const val UUID_ANDERS_NILSSON = "48D06410-1C9A-43A9-858E-F18C9DCBABE5"

    val DEMO_USER_AUTHENTICATION_ID =
        Identifier<UserAuthentication>("33ED4478-5420-469C-AD68-2EB269A17DC7")

    val completedUserAuthentication: Outcome<UserAuthentication>
        get() =
            successfulOf(
                UserAuthentication(
                    index = Index(DEMO_USER_AUTHENTICATION_ID.rawValue),
                    claims = "",
                    provider = UserAuthentication.Provider.None,
                    display = "Demo Authentication",
                    status = UserAuthentication.Status.Complete,
                    autoStartToken = "",
                    token = "demo",
                    tokenExpiresAt = Date.distantFuture,
                    externalToken = null,
                    reason = null,
                    profileId = Identifier(UUID_ANDERS_NILSSON),
                    device = Device.Unknown,
                    createdAt = Date.now(),
                    govId = GOVID_ANDERS_NILSSON,
                    tokens = listOf(
                        Token.User(token = "demo", expiresAt = Date.distantFuture, group = Key("demo"))
                    ),
                    authenticationUrl = null,
                )
            )

    fun deleteUserAuthentication(store: Store): Outcome<List<Store.Action>> =
        successfulOf(listOf(Store.Action.Logout))

    private fun <T> Collection<T>.repeat(times: Int = 1): List<T> {
        val list = this
        val result = mutableListOf<T>()
        for (i in 1..times) {
            result.addAll(list)
        }
        return result
    }

    private fun <Tag> createPageContent(item: Tag, index: PageIndex): Outcome<PageContent<Tag>> {
        val numItems = if (index.size != 50) index.size else 51   // to test multiple pages
        return successfulOf(
            PageContent(
                contents = listOf(
                    item
                ).repeat(numItems),
                index = PageIndex(
                    page = index.page,
                    size = numItems,
                    search = index.search
                ),
                items = PageSummary(begin = 1, end = 1, total = numItems),
                pages = PageSummary(begin = 1, end = 1, total = 1)
            )
        )
    }

    val application get() = successfulOf(rawApplication)
    val applicationAuthentication get() = successfulOf(rawApplicationAuthentication)
    val article get() = successfulOf(rawArticle)
    val buyer get() = successfulOf(rawBuyer)
    val content get() = successfulOf(rawContent)
    val cross get() = successfulOf(rawCross)
    val event get() = successfulOf(rawEvent)
    val feed get() = successfulOf(rawFeed)
    val feedNullable: Outcome<Feed?> get() = successfulOf(null)
    val field get() = successfulOf(rawField)
    val form get() = successfulOf(rawForm)
    val me get() = successfulOf(rawMe)
    val media get() = successfulOf(rawMedia)
    val member get() = successfulOf(rawMember)
    val message get() = successfulOf(rawMessage)
    val emitter get() = successfulOf(rawEmitter)
    val mix get() = successfulOf(rawMix)
    val narrative get() = successfulOf(rawNarrative)
    val bio get() = successfulOf(rawBio)
    val order get() = successfulOf(rawOrder)
    val policy get() = successfulOf(rawPolicy)
    val profile get() = successfulOf(rawProfile)
    val registration get() = successfulOf(rawRegistration)
    val reservation get() = successfulOf(rawReservation)
    val availability get() = successfulOf(rawAvailability)
    val agreement get() = successfulOf(rawAgreement)
    val signature get() = successfulOf(rawSignatures.first())
    val term get() = successfulOf(rawTerms.first())
    val resource get() = successfulOf(rawResource)
    val serviceList get() = successfulOf(rawServices)
    //val shortLink get() = successfulOf(rawShortLink)
    val submission get() = successfulOf(rawSubmission)
    val subscription get() = successfulOf(rawSubscription)
    val tag get() = successfulOf(rawTag)
    val userAuthentication get() = successfulOf(rawUserAuthentication)
    val workspace get() = successfulOf(rawWorkspaces.first())
    val group get() = successfulOf(rawGroups.first())
    val invite get() = successfulOf(rawInvites.first())

    fun applicationAuthenticationPageContent(index: PageIndex) =
        createPageContent(rawApplicationAuthentication, index)

    fun applicationPageContent(index: PageIndex) = createPageContent(rawApplication, index)
    fun articlePageContent(index: PageIndex) = createPageContent(rawArticle, index)
    fun buyerPageContent(index: PageIndex) = createPageContent(rawBuyer, index)
    fun crossPageContent(index: PageIndex) = createPageContent(rawCross, index)
    fun eventPageContent(index: PageIndex) = createPageContent(rawEvent, index)
    fun feedPageContent(index: PageIndex) = createPageContent(rawFeed, index)
    fun formPageContent(index: PageIndex) = createPageContent(rawForm, index)
    fun mediaPageContent(index: PageIndex) = createPageContent(rawMedia, index)
    fun memberPageContent(index: PageIndex) = createPageContent(rawMember, index)
    fun messagePageContent(index: PageIndex) = createPageContent(rawMessage, index)
    fun emitterPageContent(index: PageIndex) = createPageContent(rawEmitter, index)
    fun mixPageContent(index: PageIndex) = createPageContent(rawMix, index)
    fun narrativePageContent(index: PageIndex) = createPageContent(rawNarrative, index)
    fun bioPageContent(index: PageIndex) = createPageContent(rawBio, index)
    fun orderPageContent(index: PageIndex) = createPageContent(rawOrder, index)
    fun policyPageContent(index: PageIndex) = createPageContent(rawPolicy, index)
    fun profilePageContent(index: PageIndex) = createPageContent(rawProfile, index)
    fun registrationPageContent(index: PageIndex) = createPageContent(rawRegistration, index)
    fun reservationPageContent(index: PageIndex) = createPageContent(rawReservation, index)
    fun agreementPageContent(index: PageIndex) = createPageContent(rawAgreements, index)
    fun resourcePageContent(index: PageIndex) = createPageContent(rawResources, index)
    //fun shortLinkPageContent(index: PageIndex) = createPageContent(rawShortLink, index)
    fun submissionPageContent(index: PageIndex) = createPageContent(rawSubmission, index)
    fun subscriptionPageContent(index: PageIndex) = createPageContent(rawSubscription, index)
    fun tagPageContent(index: PageIndex) = createPageContent(rawTag, index)
    fun userAuthenticationPageContent(index: PageIndex) = createPageContent(rawUserAuthentication, index)
    fun workspacePageContent(index: PageIndex) = createPageContent(rawWorkspaces.first(), index)
    fun groupPageContent(index: PageIndex) = createPageContent(rawGroups.first(), index)
    fun invitePageContent(index: PageIndex) = createPageContent(rawInvites.first(), index)

    val listAllArticles get() = successfulOf(listOf(rawArticle))
    val listAllBuyers get() = successfulOf(listOf(rawBuyer))
    val listAllCrosses get() = successfulOf(listOf(rawCross))
    val listAllEvents get() = successfulOf(listOf(rawEvent))
    val listAllFeeds get() = successfulOf(listOf(rawFeed))
    val listAllForms get() = successfulOf(listOf(rawForm))
    val listAllMedias get() = successfulOf(listOf(rawMedia))
    val listAllMessages get() = successfulOf(listOf(rawMessage))
    val listAllEmitters get() = successfulOf(listOf(rawEmitter))
    val listAllMixes get() = successfulOf(listOf(rawMix))
    val listAllNarratives get() = successfulOf(listOf(rawNarrative))
    val listAllBios get() = successfulOf(listOf(rawBio))
    val listAllPolicies get() = successfulOf(listOf(rawPolicy))
    val listAllProfiles get() = successfulOf(listOf(rawProfile))
    val listAllAgreements get() = successfulOf(rawAgreements)
    val listAllSignatures get() = successfulOf(rawSignatures)
    val listAllTerms get() = successfulOf(rawTerms)
    val listAllResources get() = successfulOf(rawResources)
    val listAllAvailability get() = successfulOf(rawAvailability)
    //val listAllShortLinks get() = successfulOf(listOf(rawShortLink))
    val listAllSubmissions get() = successfulOf(listOf(rawSubmission))
    val listAllTags get() = successfulOf(listOf(rawTag))
    val listAllWorkspaces get() = successfulOf(rawWorkspaces)
    val listAllGroups get() = successfulOf(rawGroups)
    val listAllInvites get() = successfulOf(rawInvites)

    private val rawApplicationAuthentication
        get() =
            ApplicationAuthentication(
                index = Index("4882893C-355A-4164-BDEC-7EB921C9A117"),
                claims = "",
                token = "Some token",
                device = Device.Unknown,
                createdAt = Date.now(),
                application = rawApplication,
                tokens = listOf(
                    Token.Application(token = "Some token", expiresAt = Date.distantFuture),
                )
            )

    private val rawApplication
        get() =
            Application(
                id = Identifier("81F37B0E-71D9-43EE-A21B-F12F87AF54FC"),
                key = Key("Application"),
                name = "Application",
                applicationKey = "Demo.key",
                applicationSecret = null
            )

    private val rawField
        get() =
            Field(
                id = Identifier("357C30DF-FFD2-4C40-9CCE-B6D7775EDA52"),
                key = Key("Field key"),
                style = Field.Style.Number,
                order = 1,
                page = 1,
                required = true,
                hidden = false,
                autofill = true,
                label = "Age",
                predefined = null,
                placeholder = null,
                hint = null,
                answer = null,
                url = null,
                assetId = null,
                attributes = emptyList(),
                max = null,
                min = null
            )

    private val rawForm
        get() =
            Form(
                id = Identifier("731307F5-D1FE-4FE5-A49A-0A88CB779C4F"),
                key = Key("Form key"),
                name = "Form",
                kind = Form.Kind.EmployeeSurvey,
                feedback = Form.Feedback.None,
                fields = listOf(rawField),
                links = emptyList(),
                info = "",
                status = Form.Status.Active,
                policy = Form.Policy.Append,
                sources = emptyList(),
            )

    private val rawSubmission
        get() =
            Submission(
                index = Index("8AEEA700-D80A-4CE1-AA0C-F03BBF4EF855"),
                state = Submission.State.Open,
                timestamp = Date.now(),
                form = rawForm,
                entries = listOf(Submission.Entry(Key("Age"), "How old are you?")),
                profileId = rawProfile.index.id,
                links = emptyList(),
                group = Group.STANDARD
            )

    private val rawSubscription
        get() =
            Subscription(
                index = Index("7D989723-EA53-4FFA-A875-9EEFC69CCBBB"),
                profileId = rawProfile.index.id,
                key = Key("Subscription"),
                name = "Subscription",
                frequency = Subscription.Frequency.Unknown,
                articles = listOf(rawArticle)
            )

    private val rawRegistration
        get() =
            Registration(
                id = Identifier("415CC844-2950-4136-8AA8-18E2CD214313"),
                profileId = rawProfile.index.id,
                device = Device.Other,
                token = "Some token",
                createdAt = Date.now()
            )

    private val rawReservation
        get() =
            Reservation(
                id = Identifier("D31B4293-8457-40C3-BBB7-ED1DA6C4CCDB"),
                profileId = rawProfile.index.id,
                name = "Reservation name",
                startsAt = Date.now(),
                endsAt = Date.now(),
                style = Reservation.Style.Short,
                value = Reservation.Value.High,
                resource = rawResource
            )

    private val rawCross
        get() =
            Cross(
                id = Identifier("9D266310-71BC-4731-95D1-C171EB2A00B3"),
                profileId = rawProfile.index.id,
                role = Cross.Role.Moderator,
                privacy = Cross.Privacy.None,
                feeds = listOf(rawFeed)
            )

    private val rawMix
        get() =
            Mix(
                id = Identifier("81B6D0E9-2D27-401C-B0FC-2220FA35C139"),
                destination = Mix.Destination.PersonalDefault,
                feeds = listOf(rawFeed)
            )

    private val rawMessage
        get() =
            Message(
                id = Identifier("8332FAA2-6880-4C50-B83B-69AC62527884"),
                key = Key(""),
                kind = Message.Kind.None,
                status = Message.Status.Unknown,
                display = Message.Display.Normal,
                author = rawProfile.index.id,
                timestamp = Date.now(),
                flags = Message.Flags(false),
                likes = 999_999,
                liked = true,
                comments = 0,
                commented = false,
                digest = "Some digest",
                origin = rawFeed.key,
                links = emptyList(),
                attachments = emptyList()
            )

    private val rawEmitter
        get() =
            Emitter(
                id = Identifier("D8145D41-1125-4DA8-A5C8-6F0252E9F81A"),
                key = Key("DEMO"),
                schedule = Schedule.Weekly(DayOfWeek.FRIDAY, 15),
                feed = Key("FEED"),
                kind = Message.Kind.Post,
                status = Message.Status.Published,
                display = Message.Display.Normal,
            )

    private val rawFeed
        get() =
            Feed(
                id = Identifier("B9F20170-098A-4A5F-8822-DC662DD8F042"),
                key = Key("Feed key"),
                name = "First feed",
                style = Feed.Style.Personal,
                links = emptyList(),
                status = Feed.Status.Active,
                visibility = Feed.Visibility.Public
            )

    private val rawUserAuthentication
        get() =
            UserAuthentication(
                index = Index(),
                claims = "",
                provider = UserAuthentication.Provider.None,
                display = "",
                status = UserAuthentication.Status.Complete,
                reason = null,
                token = "demo-token",
                device = Device.Other,
                createdAt = Date(),
                govId = null,
                profileId = Identifier("demo-profile"),
                tokens = listOf(
                    Token.User(token = "demo-token", expiresAt = Date.distantFuture, group = Key("demo"))
                ),
                authenticationUrl = null,
            )

    private val rawProfile
        get() =
            Profile(
                index = Index("C81882C3-E30F-4E35-9BF0-586B1512E336"),
                govId = "199999-9999",
                firstName = "Demo",
                lastName = "Demosson",
                email = "demo@demosson.com",
                source = Profile.Source.Unknown,
                access = rawServices.map { Access(it.key, Role.Admin) },
                workspaces = rawWorkspaces.map { it.key },
                inactivatedAt = null,
                createdAt = Date.now(),
                editedAt = Date.now(),
                phone = "0730000000"
            )

    private val rawMe
        get() =
            Me(
                govId = rawProfile.govId,
                firstName = rawProfile.firstName,
                lastName = rawProfile.lastName,
                email = rawProfile.email,
                workspaces = rawWorkspaces.map { it.key },
                access = rawProfile.access,
                profileId = rawProfile.index.id,
                phone = rawProfile.phone
            )

    private val rawNarrative
        get() =
            Narrative(
                id = Identifier("EA6AED54-541E-44E5-861C-5E3905754850"),
                profileId = rawProfile.index.id,
                firstName = rawProfile.firstName,
                lastName = rawProfile.lastName,
                alias = null,
                email = rawProfile.email,
                phone = rawProfile.phone,
                city = "Demostaden",
                department = null,
                role = Narrative.Role.Unknown,
                position = null,
                manager = null,
                symbol = null,
                birthday = null,
                birthmonth = null,
                memberSince = Date.now(),
                lastLoginAt = Date.now(),
                preferences = listOf(Narrative.Preference.Unknown),
                biography = null,
                avatar = null
            )
    private val rawBio
        get() =
            rawNarrative
                .let {
                    Bio(
                        profileId = it.profileId,
                        firstName = it.firstName,
                        lastName = it.lastName,
                        email = it.email,
                        phone = it.phone,
                        city = it.city,
                        department = it.department,
                        position = it.position,
                        birthday = it.birthday,
                        birthmonth = it.birthmonth,
                        lastLoginAt = it.lastLoginAt,
                        biography = it.biography,
                        dietExcludes = emptyList(),
                        dietIncludes = emptyList(),
                        interests = emptyList(),
                        avatar = null
                    )
                }


    private val rawArticle
        get() =
            Article(
                id = Identifier("D6211F6C-E9FA-48C3-A9EC-7E7B42079178"),
                key = Key("Article 1"),
                specification = "Some specification",
                kind = Article.Kind.Unknown,
                status = Article.Status.Active,
                cost = Article.Cost.None,
                tags = listOf(Article.Tag.Unknown)
            )

    private val rawOrder
        get() =
            Order(
                id = Identifier("BFE77864-72D8-4DAC-8F27-74738EC5DA74"),
                profileId = rawProfile.index.id,
                name = "Order 1",
                status = Order.Status.Created,
                discount = Order.Discount.None,
                total = Order.Total.None,
                discounts = Order.Total.None,
                createdAt = Date.now(),
                delivery = Order.Delivery.None,
                contact = Order.Contact.None,
                rounding = Order.Rounding.None
            )

    private val rawAgreements
        get() =
            listOf(
                Agreement(
                    id = Identifier("AGREEMENT_1"),
                    key = Key("AGREEMENT_1"),
                    name = "Demo 1",
                    content = "TBD",
                    version = 1,
                    status = Agreement.Status.Draft,
                    editedAt = Date.now(),
                    createdAt = Date.now(),
                    approvalMethod = Agreement.ApprovalMethod.Manual
                ),
                Agreement(
                    id = Identifier("AGREEMENT_2"),
                    key = Key("AGREEMENT_2"),
                    name = "Demo 2",
                    content = "TBD",
                    version = 1,
                    status = Agreement.Status.Pending,
                    editedAt = Date.now(),
                    createdAt = Date.now(),
                    approvalMethod = Agreement.ApprovalMethod.Manual
                ),
                Agreement(
                    id = Identifier("AGREEMENT_3"),
                    key = Key("AGREEMENT_3"),
                    name = "Demo 3",
                    content = "TBD",
                    version = 1,
                    status = Agreement.Status.Approved,
                    editedAt = Date.now(),
                    createdAt = Date.now(),
                    approvalMethod = Agreement.ApprovalMethod.Manual
                ),
            )

    private val rawSignatures
        get() =
            listOf(
                Signature(
                    index = Index("SIGNATURE_1"),
                    profileId = rawProfile.index.id,
                    signed = Signature.Signed.Agreement(Identifier("AGREEMENT_3")),
                    signature = "",
                    timestamp = Date.now(),
                    firstName = "",
                    lastName = ""
                ),
            )

    private val rawTerms
        get() =
            listOf(
                Term(
                    id = Identifier("TERM_1"),
                    agreementId = Identifier("AGREEMENT_1"),
                    key = Key("TERM_1"),
                    name = "Demo 1",
                    clauses = emptyList(),
                    editedAt = Date.now(),
                    createdAt = Date.now(),
                    value = null
                ),
            )

    val rawAgreement
        get() = rawAgreements.first()

    private val rawResources
        get() =
            listOf(
                Resource(
                    id = Identifier("57BAAABF-9F0F-46AE-A2DF-923234968D47"),
                    key = Key("Resource 1"),
                    name = "Resource 1",
                    tags = listOf(Resource.Tag.Unknown),
                    restricted = false,
                    inAdvance = 0,
                    timeZone = "Europe/Stockholm",
                    locale = "sv-SE",
                )
            )

    private val rawResource
        get() = rawResources.first()

    private val rawAvailability
        get() =
            listOf(
                Availability(
                    date = Date.now(),
                    value = Availability.Value.Normal,
                    restrictions = emptyList(),
                )
            )

    private val rawTag
        get() =
            Tag(
                id = Identifier("E7DBB47B-59BD-46A5-8B15-9B5188D1F85A"),
                key = Key("Some key"),
                name = "Some Tag"
            )

    private val rawContent
        get() =
            Content(
                id = Identifier("DE5DE67A-D957-4BA6-94D8-7C8E3D948FEE"),
                key = Key("Some content key"),
                type = Content.Type.Text,
                disposition = Content.Disposition.Inline,
                value = "Some plain text :)",
                language = Content.Language.EnglishUnitedStates,
                probability = null,
                requested = 0,
                links = emptyList(),
                tags = emptyList()
            )

    private val rawMedia
        get() =
            Media(
                index = Index("E3755A32-D2C7-4BF7-90A3-A5E7C376203F"),
                key = Key("Some key"),
                policy = Media.Policy.Random,
                tags = listOf(rawTag),
                contents = listOf(rawContent)
            )

    /*private val rawShortLink
        get() =
            ShortLink(
                id = Identifier("23E41CF7-78D4-4BC6-A764-995CCC3111EB"),
                href = Url("https://example.com"),
                name = "Example",
                expires = null,
                short = Url("https://example.com")
            )
     */

    private val rawBuyer
        get() =
            Buyer(
                id = Identifier("0B77E609-A0E4-4C12-BA00-5B9EB3143638"),
                identity = Buyer.Identity.Unknown,
                address = Address.None,
                logo = Buyer.Logo.None,
                tier = Buyer.Tier.None
            )

    private val rawPolicy
        get() =
            Policy(
                id = Identifier("700DA109-0622-407E-90EF-C0AF2DDFD1D0"),
                key = Key("Some key"),
                name = "Privacy Policy",
                version = 1
            )

    private val rawMember
        get() =
            Member(
                id = Identifier("C39911B8-9DE5-4D73-985F-67B881F04148"),
                profileId = rawProfile.index.id,
                balance = 1_000,
                notifications = Member.Notifications.Off,
                lastInteractionAt = Date.now()
            )

    private val rawEvent
        get() =
            Event(
                id = Identifier("0CA8E112-7A69-4CF6-A0F6-1BDD0D58BFA9"),
                application = Key(""),
                profileId = rawProfile.index.id,
                action = Event.Action.Created,
                category = Event.Category.Agreement,
                subcategory = null,
                primary = Index("0CA8E112-7A69-4CF6-A0F6-1BDD0D58BFA9"),
                secondary = null,
                timestamp = Date.now()
            )

    private val rawWorkspaces = listOf(
        Workspace(
            id = Identifier(),
            key = Key("DEMO"),
            name = "Demo",
            environment = Environment.Local,
            storage = Workspace.Storage.Sandbox,
            providers = listOf(UserAuthentication.Provider.None),
            authenticationValidForHours = 3,
            display = null,
            hashedProfile = true,
            standard = emptyList(),
            services = emptyList()
        ),
        Workspace(
            id = Identifier(),
            key = Key("DEMO 2"),
            name = "Demo 2",
            environment = Environment.Local,
            storage = Workspace.Storage.Sandbox,
            providers = listOf(UserAuthentication.Provider.None),
            authenticationValidForHours = 3,
            display = null,
            hashedProfile = true,
            standard = emptyList(),
            services = emptyList()
        ),
        Workspace(
            id = Identifier(),
            key = Key("+s"),
            name = "Demo 3",
            environment = Environment.Local,
            storage = Workspace.Storage.Sandbox,
            providers = listOf(UserAuthentication.Provider.None),
            authenticationValidForHours = 3,
            display = null,
            hashedProfile = true,
            standard = emptyList(),
            services = emptyList()
        ),
    )
    private val rawGroups = listOf(
        Group(
            id = Identifier(),
            key = Group.STANDARD,
            name = "Standard",
            status = Group.Status.None,
            visualization = Group.Visualization.Predefined(template = "1"),
            members = emptyList(),
            leader = null,
            numberOfInvites = 0,
            kind = Group.Kind.None,
            createdAt = Date.now(),
            order = 0
        ),
    )
    private val rawInvites = listOf(
        Invite(
            id = Identifier(),
            key = Key.random(20),
            groupId = rawGroups[0].id,
            issuedBy = rawProfile.index.id,
            expiresAt = Date.distantFuture,
        ),
    )

    private val rawServices = listOf(
        Service(Identifier("tech-account"), Service.TECH_ACCOUNT, "TechAccount"),
        Service(Identifier("tech-agreement"), Service.TECH_AGREEMENT, "TechAgreement"),
        Service(Identifier("tech-billing"), Service.TECH_BILLING, "TechBilling"),
        Service(Identifier("tech-card"), Service.TECH_CARD, "TechCard"),
        Service(Identifier("tech-control"), Service.TECH_CONTROL, "TechControl"),
        Service(Identifier("tech-content"), Service.TECH_CONTENT, "TechContent"),
        Service(Identifier("tech-conversation"), Service.TECH_CONVERSATION, "TechConversation"),
        Service(Identifier("tech-form"), Service.TECH_FORM, "TechForm"),
        Service(Identifier("tech-guard"), Service.TECH_GUARD, "TechGuard"),
        Service(Identifier("tech-loyalty"), Service.TECH_LOYALTY, "TechLoyalty"),
        Service(Identifier("tech-notification"), Service.TECH_NOTIFICATION, "TechNotification"),
        Service(Identifier("tech-order"), Service.TECH_ORDER, "TechOrder"),
        Service(Identifier("tech-payment"), Service.TECH_PAYMENT, "TechPayment"),
        Service(Identifier("tech-personal"), Service.TECH_PERSONAL, "TechPersonal"),
        Service(Identifier("tech-reservation"), Service.TECH_RESERVATION, "TechReservation"),
        Service(Identifier("tech-social"), Service.TECH_SOCIAL, "TechSocial"),
        Service(Identifier("tech-storage"), Service.TECH_STORAGE, "TechStorage"),
    )
}