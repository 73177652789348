package della8.web.views

import StoreContext
import bootstrap.*
import della8.core.items.MessageItem
import della8.core.screens.MessageScreen
import della8.core.support.DesignSystem
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.items.messageItemBlog
import della8.web.items.messageItemReaction
import della8.web.support.*
import kotlinx.js.get
import react.*
import react.dom.html.ButtonType
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.router.useParams
import techla.base.Identifier
import techla.base.techla_log
import techla.conversation.Message
import techla.storage.Asset

val MessageView = FC<PropsWithChildren>("MessageView") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(MessageScreen.ViewModel.None as MessageScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()
    val messageId = params["messageId"]?.let { Identifier<Message>(it) }
    val type = params["type"]
    val (disableButton, setDisableButton) = useState(false)

    val scopedCall = scopedCall<MessageScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }
    val call = call<MessageScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }
    val mainCall = mainCall<MessageScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    suspend fun handlePost(formData: Map<String, FormValue>) {
        val heading = formData.getValue(MessageScreen.Header.heading.id).string
        val body = formData.getValue(MessageScreen.Header.body.id).string
        mainCall { MessageScreen.validate(sceneInputOf(store, viewModel), heading, body) }

    }

    fun recover() {
        mainCall { MessageScreen.success(sceneInputOf(store, viewModel)) }
    }

    suspend fun onSuccess() {
        navigate("/object/${params["objectId"]}/${params["agreementId"]}/feed")
    }

    suspend fun onSuccessComment() {
        params["objectId"]?.let { objectId ->
            params["agreementId"]?.let { agreementId ->
                if (messageId != null)
                    mainCall { MessageScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId), messageId, "reactions") }
            }
        }
    }

    fun logout() {
        mainCall { MessageScreen.logout(sceneInputOf(store, viewModel)) }
        navigate("/")
    }


    fun selectAsset(asset: Asset.Create) {
        setDisableButton(true)
        mainCall { MessageScreen.upload(sceneInputOf(store, viewModel), asset) }
    }

    fun selectAsset(assets: List<Asset.Create>) {
        setDisableButton(true)
        mainCall { MessageScreen.upload(sceneInputOf(store, viewModel), assets) }
    }

    fun setBody(body: String) {
        mainCall { MessageScreen.setValue(sceneInputOf(store, viewModel), null, body, null) }
    }

    fun setHeading(heading: String) {
        mainCall { MessageScreen.setValue(sceneInputOf(store, viewModel), heading, null, null) }
    }

    suspend fun handleAssetType(option: DesignSystem.Option) {
        mainCall { MessageScreen.setValue(sceneInputOf(store, viewModel), null, null, option) }
    }

    suspend fun handleComment() {
        params["objectId"]?.let { objectId ->
            params["agreementId"]?.let { agreementId ->
                if (messageId != null)
                    mainCall { MessageScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId), messageId, "comment") }
            }
        }
    }
    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is MessageScreen.ViewModel.None ->
                scopedCall(coroutineScope) { MessageScreen.start(sceneInputOf(store, viewModel)) }

            is MessageScreen.ViewModel.Loading -> {
                params["objectId"]?.let { objectId ->
                    params["agreementId"]?.let { agreementId ->
                        if (messageId != null && type == null) {
                            //EDIT
                            scopedCall(coroutineScope) { MessageScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId), messageId) }
                        } else if (messageId != null && type != null) {
                            // COMMENT & REACTIONS
                            scopedCall(coroutineScope) { MessageScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId), messageId, type) }
                        } else {
                            //POST
                            scopedCall(coroutineScope) { MessageScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId)) }
                        }
                    }
                }
            }

            is MessageScreen.ViewModel.Ready -> {
                if (disableButton && viewModel.state.processingAssets) {
                    setDisableButton(false)
                    mainCall { MessageScreen.resetProcessingAssets(sceneInputOf(store, viewModel)) }
                }
                techla_log("READY")
            }

            is MessageScreen.ViewModel.Publishing -> {
                scopedCall(coroutineScope) {
                    if (messageId != null && type == null) return@scopedCall MessageScreen.edit(sceneInputOf(store, viewModel), messageId)
                    if (messageId != null && type != null) return@scopedCall MessageScreen.comment(sceneInputOf(store, viewModel), messageId)
                    else MessageScreen.post(sceneInputOf(store, viewModel))
                }
            }

            is MessageScreen.ViewModel.Removed -> navigate("/object/${params["objectId"]}/${params["agreementId"]}/feed")

            is MessageScreen.ViewModel.Published -> techla_log("PUBLISHING")
            is MessageScreen.ViewModel.Failed -> techla_log("FAILED")
            else -> {}
        }
    }

    fun removeAsset() {
        mainCall { MessageScreen.deleteAsset(sceneInputOf(store, viewModel)) }
    }

    suspend fun handleComment(formData: Map<String, FormValue>) {
        val body = formData.getValue(MessageScreen.Header.body.id).string
        mainCall { MessageScreen.validateComment(sceneInputOf(store, viewModel), body) }
    }

    fun handleOption(option: DesignSystem.Option) {
        when (option.actionValue) {
            DesignSystem.Action.REMOVE -> mainCall { MessageScreen.removeMessage(sceneInputOf(store, viewModel), Identifier(option.data.toString())) }
            DesignSystem.Action.EDIT -> {
                navigate("/object/${params["objectId"]}/${params["agreementId"]}/feed/${messageId?.rawValue}")
                params["objectId"]?.let { objectId ->
                    params["agreementId"]?.let { agreementId ->
                        if (messageId != null) {
                            mainCall {
                                MessageScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId), messageId)
                            }
                        }
                    }
                }
            }

            DesignSystem.Action.LIKE -> mainCall { MessageScreen.likeMessage(sceneInputOf(store, viewModel), Identifier(option.data.toString())) }
            DesignSystem.Action.COMMENT -> {
                navigate("/object/${params["objectId"]}/${params["agreementId"]}/feed/${messageId?.rawValue}/comment")
                params["objectId"]?.let { objectId ->
                    params["agreementId"]?.let { agreementId ->
                        if (messageId != null)
                            mainCall { MessageScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId), messageId, "comment") }
                    }
                }
            }

            DesignSystem.Action.REACTIONS -> {
                params["objectId"]?.let { objectId ->
                    params["agreementId"]?.let { agreementId ->
                        if (messageId != null && type != null) {
                            mainCall { MessageScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId), messageId, "reactions") }
                        }
                    }
                }
            }

            else -> {}
        }
    }


    main {
        d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))

        if (viewModel is MessageScreen.ViewModel.Ready) {
            bContainer {
                className = className("px-3 px-md-5  position-relative n-bottom-space-160 n-top-space")

                bRow {
                    className = className("justify-content-center")

                    bCol {
                        xs = 12; md = 9
                        d8Text(className = "text-center", design = viewModel.createTitle)
                        d8Text(className = "text-center", design = viewModel.editTitle)

                        D8Form {
                            className = className("d-flex flex-column")
                            this.onSubmit = ::handlePost

                            d8TextInput(classNameInput = "mb-3", design = viewModel.heading, onChange = ::setHeading)
                            d8TextInput(classNameInput = "mb-3", design = viewModel.text, onChange = ::setBody)
                            d8Text(className = "", design = viewModel.assetLabel)
                            div {
                                className = className("mb-3")
                                D8SelectInput { design = viewModel.assetType; onClick = ::handleAssetType }
                            }
                            div {
                                className = className("w-100 mb-3 d-flex  align-items-start justify-content-between")
                                d8FileInput(classNameInput = "", design = viewModel.movieUpload, onChange = ::selectAsset)
                                d8FileInput(classNameInput = "", design = viewModel.imageUpload, onChangeList = ::selectAsset)
                                d8Button(className = "", design = viewModel.removeAsset, onClick = ::removeAsset)
                            }
                            div {
                                className = className("mb-3  d-flex flex-wrap align-items-center justify-content-center py-4")
                                if (disableButton)
                                    d8Progress(viewModel.progress)

                                viewModel.images.map {
                                    d8Image(className = "m-1 n-image-feed", design = it)
                                }
                                if (viewModel.movie.visible)
                                    d8Image(className = "w-10 radius-25", design = viewModel.movie)
                            }

                            div {
                                className = className("d-grid")
                                d8Button(className = if (disableButton) "disabled" else "", design = viewModel.create, type = ButtonType.submit)
                                d8Button(className = "", design = viewModel.edit, type = ButtonType.submit)
                            }
                        }
                    }
                }
            }
        }

        if (viewModel is MessageScreen.ViewModel.Comment) {
            bContainer {
                className = className("px-3 px-md-5  position-relative n-bottom-space-160 n-top-space")
                bRow {
                    className = className("justify-content-center mb-5")
                    bCol {
                        xs = 12; md = 9
                        viewModel.items.map { item ->
                            if (item is MessageItem.ViewModel.Blog)
                                messageItemBlog(viewModel = item, onClick = ::handleOption)

                        }
                    }
                }
            }
            bContainer {
                fluid = true
                className = className("n-booking-summary d-flex py-4 justify-content-center")
                bRow {
                    className = className("justify-content-center")
                    d8Text(className = "text-center", design = viewModel.commentTitle)
                    D8Form {
                        this.onSubmit = ::handleComment
                        d8TextInput(classNameInput = "mb-3", design = viewModel.text)
                        ReactHTML.div {
                            className = className("d-grid")
                            d8Button(className = "", design = viewModel.commentBtn, type = ButtonType.submit)
                        }
                    }
                }
            }
        }

        if (viewModel is MessageScreen.ViewModel.Reactions) {
            bContainer {
                className = className("px-3 px-md-5  position-relative n-bottom-space-160 n-top-space")
                bRow {
                    className = className("justify-content-center")
                    bCol {
                        xs = 12; md = 9
                        d8Text(className = "text-center", design = viewModel.reactionsTitle)
                        ReactHTML.div {
                            className = className("w-100 d-flex justify-content-end")
                            d8Button(className = "", design = viewModel.commentBtn, onClick = ::handleComment)
                        }

                        viewModel.items.map { item ->
                            if (item is MessageItem.ViewModel.Reaction)
                                messageItemReaction(viewModel = item)
                        }
                        if (viewModel.noReactions.visible)
                            bCard {
                                className = className("radius-25 p-3 mt-4  border-0 card-shadow")
                                d8Text(className = "pt-1", design = viewModel.noReactions)
                            }


                    }
                }
            }
        }


        if (viewModel is MessageScreen.ViewModel.Published) {
            Della8.Success { design = viewModel.success; onSuccess = ::onSuccess }
        }

        if (viewModel is MessageScreen.ViewModel.Commented) {
            Della8.Success { design = viewModel.success; onSuccess = ::onSuccessComment }
        }

        if (viewModel is MessageScreen.ViewModel.Publishing) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is MessageScreen.ViewModel.Loading) {
            d8Progress(viewModel.progress)
        }


        if (viewModel is MessageScreen.ViewModel.Failed) {
            d8failure(viewModel.failure, onLogout = ::logout, onRecover = ::recover)
        }
    }
}
