import browser.window
import browser.document
import della8.core.support.jsJodaTz
import react.create
import react.dom.client.createRoot
import techla.base.techla_log

fun main() {
    js("import('./css/style.css')")

    // We use this workaround to avoid pruning of tz data during tree-shake
    techla_log("jsjoda: $jsJodaTz")

    window.onload = {
        val container = document.getElementById("app")!!
        val app = App.create {}
        createRoot(container).render(app)
    }
}
