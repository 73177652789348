package della8.web.components

import della8.core.support.DesignSystem
import della8.web.support.className
import della8.web.theme.Design
import dom.html.HTMLVideoElement
import kotlinx.js.jso
import mux.MuxPlayer
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.iframe
import react.dom.html.ReactHTML.video
import techla.base.Either
import techla.base.leftOf
import techla.base.rightOf

external interface D8RawMovieProps : PropsWithChildren {
    var className: String?
    var movie: Either<DesignSystem.Movie, String>
    var player: DesignSystem.MoviePlayer?
    var autoplay: Boolean?
    var loop: Boolean?
    var playsInline: Boolean?
    var muted: Boolean?
    var showControls: Boolean?
    var muxPlaybackToken: String?
    var muxThumbnailToken: String?
}

val D8RawMovie = FC<D8RawMovieProps>("D8RawMovieProps") { props ->
    val refVideo = useRef<HTMLVideoElement>(null)

    useEffect {
        if (props.muted != false) {
            // Open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
            refVideo.current?.defaultMuted = true
            refVideo.current?.muted = true
        }
    }

    val sourceHTML5 = when (val movie = props.movie) {
        is Either.Left -> Design.movieHTML5(movie.value)
        is Either.Right -> movie.value
    }

    val sourceMUX = when (val movie = props.movie) {
        is Either.Left -> Design.movieMUX(movie.value)
        is Either.Right -> movie.value
    }

    when (props.player) {
        DesignSystem.MoviePlayer.YOUTUBE ->
            sourceHTML5?.let {
                iframe {
                    //   width = 1254.0
                    //  height = 705.0
                    src = it
                    allowFullScreen = false
                    allowTransparency = false
                    allow = "accelerometer; autoplay; modestbranding; encrypted-media; gyroscope; picture-in-picture"
                }
            }

        DesignSystem.MoviePlayer.MUX ->
            sourceMUX?.let {
                div {
                    className = className(props.className)
                    MuxPlayer {
                        style = jso { height = "100%"; width = "100%" }
                        playbackId = it
                        autoPlay = props.autoplay ?: true
                        muted = props.muted ?: true
                        loop = props.loop ?: true
                        streamType = "on-demand"
                        thumbnailTime = "0"
                        playbackToken = props.muxPlaybackToken
                        thumbnailToken = props.muxThumbnailToken
                    }
                }
            }

        else ->
            sourceHTML5?.let {
                video {
                    ref = refVideo
                    className = className(props.className)
                    autoPlay = props.autoplay ?: true
                    loop = props.loop ?: true
                    src = it
                    playsInline = props.playsInline ?: true
                    muted = props.muted ?: true
                    controls = props.showControls ?: false
                    /*poster = "https://storage.googleapis.com/techla-4ababd96-f873-4ba0-9bca-800870c9f601/della8/share_boat.jpg"*/
                }
            }
    }
}

external interface D8MovieProps : PropsWithChildren {
    var className: String?
    var design: DesignSystem.MovieView
}

val D8Movie = FC<D8MovieProps>("D8Movie") { props ->
    if (props.design.visible) {
        val movie: Either<DesignSystem.Movie, String>? = when {
            props.design.movie != null -> leftOf(props.design.movie!!)
            props.design.href != null -> rightOf(props.design.href!!)
            else -> null
        }
        movie?.let {
            D8RawMovie {
                this.movie = movie
                this.player = props.design.player
                this.showControls = props.design.showControls
                this.autoplay = props.design.autoplay
                this.loop = props.design.loop
                this.playsInline = props.design.playsInline
                this.muted = props.design.muted
                this.className = props.className
                this.muxPlaybackToken = props.design.muxPlaybackToken
                this.muxThumbnailToken = props.design.muxThumbnailToken
            }
        }
    }
}

fun ChildrenBuilder.d8Movie(design: DesignSystem.MovieView, className: String? = null) =
    D8Movie {
        this.className = className
        this.design = design
    }
