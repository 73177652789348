package della8.core.screens

import della8.core.items.BookingItem
import della8.core.native.formatDateTimeLong
import della8.core.services.bios
import della8.core.services.deleteReservation
import della8.core.services.getReservations
import della8.core.support.*
import techla.agreement.Agreement
import techla.base.*
import techla.personal.Bio
import techla.reservation.Reservation

object BookingScreen {
    data class Texts(
        val title: String,
        val back: String,
        val bookedBy: String,
        val from: String,
        val to: String,
        val modalTitle: String,
        val modalBody: String,
        val no: String,
        val yes: String,
        val noBooking: String,
        override val progressInfo: String,
        override val failureTitle: String,
    ) : ProgressTexts, FailureTexts {
        companion object
    }

    data class State(
        val fullObject: Object,
        val cellReservations: List<Identifier<Reservation>>,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(Object.None, emptyList()),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val items: List<BookingItem.ViewModel>,
            val title: DesignSystem.Text,
            val modal: DesignSystem.Modal,
            val noBooking: DesignSystem.Text,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation) {
            companion object
        }

        fun loading(texts: Texts) = Loading(
            texts = texts,
            state = state,
            navigation = DesignSystem.Navigation.progress(),
            progress = progress(texts = texts)
        )

        fun ready(state: State? = null, items: List<BookingItem.ViewModel>) =
            Ready(
                texts = texts,
                state = (state ?: this.state),
                title = DesignSystem.Text(text = texts.title, style = DesignSystem.TextStyle.TITLE2),
                navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing),
                items = items,
                modal = DesignSystem.Modal(
                    title = DesignSystem.Text(text = texts.modalTitle, style = DesignSystem.TextStyle.TITLE2),
                    body = DesignSystem.Text(text = texts.modalBody, alignment = DesignSystem.TextAlignment.CENTER),
                    firstButton = DesignSystem.Button(title = texts.no, visible = true, style = DesignSystem.ButtonStyle.SECONDARY),
                    secondButton = DesignSystem.Button(title = texts.yes, visible = true),
                ),
                noBooking = DesignSystem.Text(text = texts.noBooking, visible = items.isEmpty())
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false) =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val ready get() = this as? Ready
        val load get() = this as? Loading
        val failed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        // TODO: Fetch from Store
        val texts = Texts(
            title = "Bokning",
            back = "Tillbaka",
            from = "Från",
            to = "Till",
            bookedBy = "Bokad av",
            progressInfo = "Laddar...",
            failureTitle = "Oj, ett fel har uppstått",
            yes = "Ja",
            no = "Nej",
            modalTitle = "Är du säker?",
            modalBody = "Är du säker på att du vill ta bort din bokning?",
            noBooking = "Din bokning är nu borttagen.",
        )

        return sceneOf<ViewModel>(viewModel.loading(texts = texts))
    }

    suspend fun load(
        scene: Scene.Input<ViewModel>,
        objectId: Identifier<Object>,
        agreementId: Identifier<Agreement>,
        cellReservations: List<Identifier<Reservation>>,
    ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.refreshObject(objectId = objectId, agreementId = agreementId)
            .flatMap { (actions, obj) ->
                store.reduce(actions).getReservations(obj = obj, ids = cellReservations)
                    .accumulate(actions)
                    .map { tupleOf(it.first, obj, it.second) }
            }
            .flatMap { (actions, obj, reservations) ->
                val batch = Bio.Batch(ids = obj.group.members.distinct())
                store.bios(batch)
                    .map { tupleOf(actions, obj, reservations, it.second) }
            }

            .map { (actions, obj, reservations, bios) ->
                val state = viewModel.state.copy(
                    fullObject = obj,
                    cellReservations = cellReservations,
                )

                sceneOf<ViewModel>(viewModel.ready(state = state, items = buildItems(store, viewModel.texts, reservations, bios)), actions)
            }.failed { scene.failed(result = it) }
    }

    suspend fun cancelBooking(scene: Scene.Input<ViewModel>, id: Identifier<Reservation>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return successfulOf(store.deleteReservation(viewModel.state.fullObject, id))
            .map {
                val newReservationList = viewModel.state.cellReservations.filter { it.rawValue != id.rawValue }
                load(sceneInputOf(store, viewModel), viewModel.state.fullObject.id, agreementId = viewModel.state.fullObject.currentAgreement.id, newReservationList)
            }
            .failed { scene.failed(result = it) }

    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }

    private fun buildItems(store: Store, texts: Texts, reservations: List<Reservation>, bios: List<Bio>): List<BookingItem.ViewModel> =
        reservations.map { reservation ->
            val myBooking = store.profileId == reservation.profileId
            val bookingIcon = if (myBooking) {
                DesignSystem.Image.BOOKED_ME
            } else DesignSystem.Image.BOOKED_OTHER

            val bookedBy = bios.firstOrNull { it.profileId == reservation.profileId }?.firstName ?: ""

            BookingItem.reservation(
                id = reservation.id,
                from = formatDateTimeLong(reservation.startsAt, reservation.resource.timeZone),
                to = formatDateTimeLong(reservation.endsAt, reservation.resource.timeZone),
                person = bookedBy,
                image = bookingIcon,
                bookedBy = texts.bookedBy,
                visible = myBooking
            )
        }
}

