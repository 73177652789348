package della8.web.components

import della8.core.support.DesignSystem
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.*
import react.dom.html.ReactHTML.form

sealed class FormValue() {
    data class BooleanValue(val value: Boolean) : FormValue()
    data class StringValue(val value: String) : FormValue()
    data class IntValue(val value: Int) : FormValue()
    data class DoubleValue(val value: Double) : FormValue()
    data class OptionValue(val value: DesignSystem.Option) : FormValue()

    val boolean get() = (this as BooleanValue).value
    val string get() = (this as StringValue).value
    val int get() = (this as IntValue).value
    val double get() = (this as DoubleValue).value
    val option get() = (this as OptionValue).value
}

external interface D8FormProps : PropsWithChildren, PropsWithClassName {
    var onSubmit: suspend (Map<String, FormValue>) -> Unit
}

var FormContext = createContext<MutableMap<String, FormValue>>(mutableMapOf())

val D8Form = FC<D8FormProps>("D8Form") { props ->
    FormContext.Provider(mutableMapOf()) {
        _D8Form() {
            this.className = props.className
            this.onSubmit = props.onSubmit
            +props.children
        }
    }
}

private val _D8Form = FC<D8FormProps>("_D8Form") { props ->
    val formValues = useContext(FormContext)
    form {
        this.className = props.className
        this.onSubmit = { event ->
            event.preventDefault()
            MainScope().launch {
                props.onSubmit(formValues)
            }
        }
        +props.children
    }
}