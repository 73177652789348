package helmet

import react.*

@JsNonModule
@JsModule("react-helmet")
private external val helmetModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val Helmet: ComponentType<HelmetProps> = helmetModule.Helmet

external interface HelmetProps : PropsWithChildren {
}