package della8.web.components

import bootstrap.bCol
import bootstrap.bContainer
import della8.core.support.DesignSystem
import della8.web.support.className
import react.*
import react.dom.html.ReactHTML.div

external interface SuccessProps : PropsWithChildren {
    var design: DesignSystem.Success
    var onSuccess: suspend () -> Unit
}


val Della8.Success
    get() = FC<SuccessProps>("D8Success") { props ->

        bContainer {
            className = className(
                listOfNotNull("px-5 d-flex n-top-space align-items-center flex-column justify-content-center text-center")
            )
            bCol {
                xs = 12; md = 5
                d8Image(className = "d-block mx-auto", design = props.design.image)
                d8Text(className = "mb-2", design = props.design.title)
                d8Text(className = "mb-4", design = props.design.info)
                div {
                    className = className("d-grid")
                    d8Button(className = "btn-block", design = props.design.next, onClick = props.onSuccess)
                }
            }
        }
    }


fun ChildrenBuilder.d8Success(
    design: DesignSystem.Success,
    onSuccess: suspend () -> Unit = {}
) =
    Della8.Success {
        this.design = design
        this.onSuccess = onSuccess
    }
