package bootstrap

import org.w3c.dom.events.Event
import react.*

@JsNonModule
@JsModule("react-bootstrap/Form")
private external val formModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bFormRange: ComponentType<FormRangeProps> = formModule.default.Range

external interface FormRangeProps : PropsWithChildren {
    var id: String
    var disabled: Boolean
    var bsPrefix: String
    var onChange: (Event) -> Unit
    var value: dynamic /* string | arrayOf | number */
    var max: dynamic /* string | arrayOf | number */
    var min: dynamic /* string | arrayOf | number */
    var step: dynamic /* string | arrayOf | number */
    var defaultValue: dynamic /* string | arrayOf | number */

}
