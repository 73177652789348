package della8.web.components

import StoreContext
import della8.core.support.DesignSystem
import della8.web.support.className
import kotlinx.browser.window
import react.*
import react.dom.html.ReactHTML.div

private external interface QrCodeProps : Props {
    var bgColor: String
    var fgColor: String
    var level: String // "L", "M", "Q", "H"
    var size: Int
    var title: String
    var value: String
}

@JsNonModule
@JsModule("react-qr-code")
private external val reactQrCodeModule: dynamic

@Suppress("UnsafeCastFromDynamic")
private val QrCode: ComponentType<QrCodeProps> = reactQrCodeModule.default


external interface D8BankIdProps : Props {
    var value: String
    var scanQr: DesignSystem.Text?
    var openApp: DesignSystem.Text?
    var showOtherDevice: Boolean?
}


val D8BankId = FC<D8BankIdProps> { props ->
    val (store, _) = useContext(StoreContext)
    val screenWidth = window.screen.width
    val smallQrCode = screenWidth < 400
    val isLargeScreen = screenWidth >= 992
    val isXLargeScreen = screenWidth >= 1200
    var title by useState(if (isLargeScreen) props.scanQr else props.openApp)

    var otherDevice by useState(isLargeScreen)

    useEffect(otherDevice) {
        title = if (otherDevice) props.scanQr else props.openApp
    }

    div {
        className = className(
            "rounded bg-white px-5 py-4 mx-auto d-flex flex-column align-items-center",
            if (isXLargeScreen) "w-75" else "w-100"
        )
        title?.let { d8Text(design = it) }
        D8Text {
            className = "text-center mb-3 fw-bold"
            design = DesignSystem.Text(
                text = if (otherDevice) "Scanna QR kod med BankID på din enhet" else "BankID på denna enhet",
                style = DesignSystem.TextStyle.TITLE2,
                textColor = DesignSystem.Color.MARINE
            )
        }
        if (otherDevice) {
            QrCode {
                this.value = props.value
                this.bgColor = "#fff"
                this.fgColor = "#000"
                this.size = if (smallQrCode) 128 else 256
                this.level = "L"
            }
        } else {
            D8Button {
                design = DesignSystem.Button(
                    title = "Öppna BankID", image = DesignSystem.Image.BANK_ID, style = DesignSystem.ButtonStyle.PRIMARY
                )
                onClick = {
                    //window.location.href = "bankid:///?autostarttoken=${store.autoStartToken}&redirect=http%3A%2F%2Flocalhost%3A8080%2Flogin"
                    window.location.href = "bankid:///?autostarttoken=${store.autoStartToken}&redirect=null"
                }
            }
        }
        if (props.showOtherDevice != false)
            D8Button {
                className = "mt-2 fw-bold"
                design = DesignSystem.Button(
                    title = if (otherDevice) "BankID på denna enhet" else "BankID på annan enhet",
                    style = DesignSystem.ButtonStyle.TRANSPARENT
                )
                onClick = { otherDevice = !otherDevice }
            }
    }
}
