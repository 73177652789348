package della8.web.components

import bootstrap.bCol
import bootstrap.bContainer
import della8.core.support.DesignSystem
import della8.web.support.className
import kotlinx.js.jso
import react.*
import react.dom.html.ReactHTML.div
import react.router.useNavigate

external interface FailureProps : PropsWithChildren {
    var design: DesignSystem.Failure
    var onRecover: () -> Unit
    var onLogout: () -> Unit
}


val D8Failure = FC<FailureProps>("D8Failure") { props ->
    val navigate = useNavigate()

    useEffect {
        if (props.design.automaticLogout) {
            navigate("/", jso { replace = true; state = null })
        }
    }

    bContainer {
        className = className("px-5 d-flex h-75 align-items-center flex-column justify-content-center text-center")

        bCol {
            xs = 12; md = 5
            d8Image(className = "d-block mx-auto", design = props.design.image)
            d8Text(className = "mb-2", design = props.design.title)
            d8Text(className = "mb-4", design = props.design.details)
            div {
                className = className("d-grid")
                D8Button {
                    design = props.design.recover; onClick = {
                    if (props.design.shouldLogout)
                        props.onLogout()
                    else
                        props.onRecover()
                }
                }
            }
        }
    }
}


fun ChildrenBuilder.d8failure(design: DesignSystem.Failure, onRecover: () -> Unit = {}, onLogout: () -> Unit = {}) =
    D8Failure {
        this.design = design
        this.onRecover = onRecover
        this.onLogout = onLogout
    }
