package bootstrap


import csstype.ClassName
import org.w3c.dom.events.Event
import react.*
import kotlin.reflect.KFunction0

@JsNonModule
@JsModule("react-bootstrap/Form")
private external val formModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bFormControl: ComponentType<FormControlProps> = formModule.default.Control

external interface FormControlProps : PropsWithChildren {
    var className: ClassName
    var ref: RefObject<Any>
    var `as`: dynamic /* 'input' | 'textarea' | 'select' | elementType */
    var custom: Boolean
    var disabled: Boolean
    var htmlSize: Number
    var id: String
    var isInvalid: Boolean
    var isValid: Boolean
    var onChange: (Event) -> Unit
    var plaintext: Boolean
    var placeholder: String
    var readOnly: Boolean
    var size: String /* 'sm' | 'lg' */
    var type: String
    var value: dynamic /* string | arrayOf | number */
    var defaultValue: dynamic /* string | arrayOf | number */
    var bsPrefix: String
    var max: dynamic /* string | arrayOf | number */
    var min: dynamic /* string | arrayOf | number */
    var step: dynamic /* string | arrayOf | number */
    var checked: Boolean
    var name: String
    var multiple: String
    var accept: String
    var rows: String
}
