package della8.web.items

import bootstrap.bCard
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.items.RuleItem
import della8.core.screens.RuleValue
import della8.core.support.DesignSystem
import della8.core.support.EditableFinancing
import della8.web.components.*
import della8.web.support.className
import react.FC
import react.PropsWithChildren
import react.dom.html.ReactHTML.div
import techla.base.*

external interface RuleItemFinancingProps : PropsWithChildren {
    var className: String
    var viewModel: RuleItem.ViewModel.Financing
    var onChange: (Identifier<RuleItem>, RuleValue) -> Unit
    var onInfo: (String, Boolean) -> Unit
}


val D8RuleItemFinancing = FC<RuleItemFinancingProps> { props ->
    fun onStyle(value: DesignSystem.Option) {
        (props.viewModel.value as? RuleValue.FinancingValue)?.let { financingValue ->
            (value.data as? EditableFinancing.Style)?.let {
                props.onChange(props.viewModel.id, RuleValue.FinancingValue(financingValue.value.copy(style = it)))
            }
        }
    }

    fun onAmount(value: Pair<String, Double?>) {
        (props.viewModel.value as? RuleValue.FinancingValue)?.let { financingValue ->
            props.onChange(props.viewModel.id, RuleValue.FinancingValue(financingValue.value.copy(amount = value)))
        }
    }

    fun onDue(value: String) {
        (props.viewModel.value as? RuleValue.FinancingValue)?.let { financingValue ->
            props.onChange(props.viewModel.id, RuleValue.FinancingValue(financingValue.value.copy(due = value)))
        }
    }

    fun onAccount(value: String) {
        (props.viewModel.value as? RuleValue.FinancingValue)?.let { financingValue ->
            props.onChange(props.viewModel.id, RuleValue.FinancingValue(financingValue.value.copy(account = value)))
        }
    }

    fun onOther(value: String) {
        (props.viewModel.value as? RuleValue.FinancingValue)?.let { financingValue ->
            props.onChange(props.viewModel.id, RuleValue.FinancingValue(financingValue.value.copy(description = value)))
        }
    }

    bCard {
        className = className("radius-10 border-0 card-shadow my-3 px-4 py-3")
        bContainer {
            className = className("p-0")
            D8RuleItemHeader {
                viewModel = props.viewModel
                onChange = props.onChange
                onInfo = props.onInfo
            }
            if (props.viewModel.value !is RuleValue.Off) {
                div {
                    className = className("flex-row")
                    if (props.viewModel.editable) {
                        bRow {
                            className = className("mb-3")
                            d8SelectInput(classNameInput = "d-inline mb-2", design = props.viewModel.style, onClick = ::onStyle)
                        }
                        if (props.viewModel.amount.visible || props.viewModel.due.visible || props.viewModel.account.visible) {
                            bRow {
                                div {
                                    className = className("pt-2")
                                    d8TextInput(design = props.viewModel.amount, onChangeDouble = ::onAmount)
                                }
                            }
                            bRow {
                                div {
                                    className = className("pt-2")
                                    d8TextInput(design = props.viewModel.due, onChange = ::onDue)
                                }
                            }
                            bRow {
                                div {
                                    className = className("pt-2")
                                    d8TextInput(design = props.viewModel.account, onChange = ::onAccount)
                                }
                            }
                        }
                        div {
                            className = className("pt-2")
                            d8TextInput(design = props.viewModel.other, onChange = ::onOther)
                        }
                    } else {
                        d8Text(design = props.viewModel.show)
                        d8Element(design = props.viewModel.showAmount)
                        d8Element(design = props.viewModel.showDue)
                        d8Element(design = props.viewModel.showAccount)
                    }
                }
            }
            D8RuleItemFooter {
                viewModel = props.viewModel
            }
        }
    }
}