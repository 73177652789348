package bootstrap

import csstype.ClassName
import react.*

@JsNonModule
@JsModule("react-bootstrap/NavbarCollapse")
private external val navbarCollapseModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bNavbarCollapse: ComponentType<NavbarCollapseProps> = navbarCollapseModule.default

external interface NavbarCollapseProps : PropsWithChildren {
    var bsPrefix: String
    var className: ClassName
}
