const data = {
  "v": "5.5.9",
  "fr": 30,
  "ip": 0,
  "op": 40,
  "w": 68,
  "h": 95,
  "nm": "spinner",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "Shape 2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 20,
              "s": [
                100
              ]
            },
            {
              "t": 40,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            33,
            47,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            0,
            0,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            93,
            93,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      16.34,
                      0
                    ],
                    [
                      0,
                      -16.28
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -3.86,
                      -4.9
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      -2.65
                    ],
                    [
                      -10.32,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -2.42,
                      1.1
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      4.17,
                      9.32
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      6.83
                    ]
                  ],
                  "o": [
                    [
                      -16.34,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0.16,
                      6.4
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -1.07,
                      2.41
                    ],
                    [
                      0,
                      10.4
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      2.66,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      9.06,
                      -4.44
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      4.17,
                      -5.14
                    ],
                    [
                      0,
                      -16.28
                    ]
                  ],
                  "v": [
                    [
                      0.05,
                      -47.5
                    ],
                    [
                      -29.59,
                      -18.09
                    ],
                    [
                      -29.58,
                      -17.32
                    ],
                    [
                      -23.31,
                      0.02
                    ],
                    [
                      -23.18,
                      0.17
                    ],
                    [
                      -32.38,
                      20.99
                    ],
                    [
                      -34,
                      28.66
                    ],
                    [
                      -15.32,
                      47.5
                    ],
                    [
                      15.31,
                      47.5
                    ],
                    [
                      23.01,
                      45.83
                    ],
                    [
                      23.49,
                      45.6
                    ],
                    [
                      32.34,
                      20.9
                    ],
                    [
                      23.13,
                      0.36
                    ],
                    [
                      23.16,
                      0.33
                    ],
                    [
                      29.69,
                      -18.09
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ind": 1,
              "ty": "sh",
              "ix": 2,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      -9.07,
                      0
                    ],
                    [
                      0,
                      -8.82
                    ],
                    [
                      3.23,
                      -3
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -1.21,
                      -2.7
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      2.72,
                      -1.24
                    ],
                    [
                      0.77,
                      0
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      3.01
                    ],
                    [
                      -0.31,
                      0.7
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      2.03,
                      1.94
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      0,
                      4.58
                    ]
                  ],
                  "o": [
                    [
                      9.07,
                      0
                    ],
                    [
                      0,
                      4.45
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -2.23,
                      1.94
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      1.23,
                      2.74
                    ],
                    [
                      -0.7,
                      0.32
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -2.98,
                      0
                    ],
                    [
                      0,
                      -0.77
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      1.15,
                      -2.6
                    ],
                    [
                      0,
                      0
                    ],
                    [
                      -3.41,
                      -3.03
                    ],
                    [
                      0,
                      -8.82
                    ]
                  ],
                  "v": [
                    [
                      0.049,
                      -34.109
                    ],
                    [
                      16.419,
                      -18.089
                    ],
                    [
                      11.329,
                      -6.489
                    ],
                    [
                      10.899,
                      -6.099
                    ],
                    [
                      9.179,
                      1.731
                    ],
                    [
                      20.239,
                      26.411
                    ],
                    [
                      17.539,
                      33.621
                    ],
                    [
                      15.309,
                      34.111
                    ],
                    [
                      -15.321,
                      34.111
                    ],
                    [
                      -20.721,
                      28.661
                    ],
                    [
                      -20.251,
                      26.441
                    ],
                    [
                      -9.251,
                      1.541
                    ],
                    [
                      -10.731,
                      -6.029
                    ],
                    [
                      -10.931,
                      -6.219
                    ],
                    [
                      -16.311,
                      -18.089
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 2",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "mm",
              "mm": 1,
              "nm": "Merge Paths 1",
              "mn": "ADBE Vector Filter - Merge",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [
                  0,
                  0.439215689898,
                  1,
                  1
                ],
                "ix": 4
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 5
              },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Shape",
          "np": 4,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 1800,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
module.exports = data;