package bootstrap

import react.ComponentType
import react.PropsWithChildren

@JsNonModule
@JsModule("react-bootstrap/AccordionItem")
private external val accordionItemModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bAccordionItem: ComponentType<AccordionItemProps> = accordionItemModule.default

external interface AccordionItemProps : PropsWithChildren {
    var `as`: Any // Set a custom element for this component
    var eventKey: String // A unique key used to control this item's collapse/expand.
    var bsPrefix: String // Change the underlying component CSS base class name and modifier class names prefix.
}
