package bootstrap

import react.*

@JsNonModule
@JsModule("react-bootstrap/ModalHeader")
private external val modalHeaderModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bModalHeader: ComponentType<ModalHeaderProps> = modalHeaderModule.default

external interface ModalHeaderProps : PropsWithChildren {
    var closeButton: Boolean
    var closeLabel: String
    var onHide: () -> Unit
    var bsPrefix: String
}