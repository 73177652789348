package mux

import react.ComponentType
import react.Props

@JsNonModule
@JsModule("@mux/mux-player-react")
private external val muxPlayerModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val MuxPlayer: ComponentType<MuxPlayerProps> = muxPlayerModule.default

external interface MuxPlayerProps : Props {
    var style: dynamic // {{ height: '100%', maxWidth: '100%' }}
    var playbackId: String?
    var metadata: dynamic // {{ video_id: 'video-id-123456', video_title: 'Super Interesting Video',viewer_user_id: 'user-id-bc-789', }}
    var streamType: String? // "on-demand"
    var autoPlay: Boolean?
    var muted: Boolean?
    var audio: Boolean?
    var loop: Boolean?
    var thumbnailTime:String?
    var playbackToken: String?
    var thumbnailToken: String?
}