package della8.core.support

import della8.core.services.*
import techla.agreement.Agreement
import techla.agreement.Signature
import techla.agreement.Term
import techla.base.*
import techla.conversation.Feed
import techla.guard.Group
import techla.guard.Profile
import techla.personal.Bio
import techla.reservation.Reservation
import techla.reservation.Resource
import kotlin.time.ExperimentalTime

val Bio.initials
    get() =
        firstName?.take(1)?.uppercase()?.let { f ->
            lastName?.take(1)?.uppercase()?.let { l ->
                "$f$l"
            }
        } ?: "??"


fun List<Signature>.haveIApproved(store: Store) =
    firstOrNull { it.profileId == store.profileId } != null

sealed class Object {
    data class Min(
        val id: Identifier<Object>,
        val profileId: Identifier<Profile>,
        val group: Group,
        val agreements: List<Agreement>,
    )

    data class Max(
        val bios: List<Bio>,
        val terms: List<Term>,
        val currentAgreement: Agreement,
        val signatures: List<Signature>,
        val resource: Resource,
    )

    object None : Object()

    data class Minimal(
        val min: Min,
    ) : Object()

    data class Full(
        val min: Min,
        val max: Max,
    ) : Object()

    data class Edit(
        val name: Modification<String> = Modification.Unmodified
    )

    val minimal
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None can't produce Minimal")
                is Minimal -> this
                is Full -> Minimal(min = min)
            }

    val id
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no id")
                is Minimal -> min.id
                is Full -> min.id
            }

    val key
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no key")
                is Minimal -> Key<Object>(min.group.key.rawValue)
                is Full -> Key<Object>(min.group.key.rawValue)
            }

    val name
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no key")
                is Minimal -> min.group.name
                is Full -> min.group.name
            }

    val profileId
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no profileId")
                is Minimal -> min.profileId
                is Full -> min.profileId
            }

    val group
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no group")
                is Minimal -> min.group
                is Full -> min.group
            }

    val agreements: List<Agreement>
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no agreements")
                is Minimal -> min.agreements
                is Full -> min.agreements
            }

    val currentAgreement: Agreement
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no currentAgreement")
                is Minimal -> throw TechlaError.InternalServerError("Minimal has no currentAgreement")
                is Full -> max.currentAgreement
            }

    val signatures: List<Signature>
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no signatures")
                is Minimal -> throw TechlaError.InternalServerError("Minimal has no signatures")
                is Full -> max.signatures
            }

    val bios: List<Bio>
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no bios")
                is Minimal -> throw TechlaError.InternalServerError("Minimal has no bios")
                is Full -> max.bios
            }

    val terms: List<Term>
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no terms")
                is Minimal -> throw TechlaError.InternalServerError("Minimal has no terms")
                is Full -> max.terms
            }

    val partnershipTerm: Term?
        get() =
            terms.firstOrNull { it.key == Term.partnershipKey }

    val bookingTerm: Term?
        get() =
            terms.firstOrNull { it.key == Term.bookingKey }

    fun duration(style: Reservation.Style): Int? =
        bookingTerm?.clauses
            ?.filterIsInstance<Term.Clause.Duration>()
            ?.filter { it.style == style }
            ?.map { it.numberOfDays }
            ?.firstOrNull()

    val classification: Classification?
        get() =
            partnershipTerm?.clauses
                ?.filterIsInstance<Term.Clause.Classification>()
                ?.map { it.classification }
                ?.firstOrNull()

    val resource: Resource
        get() =
            when (this) {
                is None -> throw TechlaError.InternalServerError("None has no resource")
                is Minimal -> throw TechlaError.InternalServerError("Minimal has no resource")
                is Full -> max.resource
            }

    val isAgreementEmpty
        get() =
            when (this) {
                is None -> true
                is Minimal -> true
                is Full -> max.terms.isEmpty()
            }

    val isGroupActive get() = group.status is Group.Status.Active
    fun isGroupLeader() = group.leader == profileId

    fun hasGroupLeaderSigned() = signatures.filter { it.profileId == group.leader }
    val isRulesReady get() = bookingTerm != null && partnershipTerm != null
    val hasInvites get() = group.numberOfInvites > 0
}


suspend fun Store.refreshMinimalObject(objectId: Identifier<Object>): ActionOutcome<Object.Minimal> =
    successfulOf(objects?.firstOrNull { it.id == objectId }).noActions()
        .flatMap { (actions, obj) ->
            when (obj) {
                is Object.Minimal -> successfulOf(tupleOf(actions, obj))
                is Object.Full -> successfulOf(tupleOf(actions, obj.minimal))
                else -> failedOf(TechlaError.InternalServerError("Requested missing object"))
            }
        }


suspend fun Store.refreshObject(objectId: Identifier<Object>, agreementId: Identifier<Agreement>): ActionOutcome<Object.Full> =
    successfulOf(objects?.firstOrNull { it.id == objectId }).noActions()
        .map { (actions, obj) ->
            // Check if agreement matches, otherwise refresh
            if (obj is Object.Full && obj.currentAgreement.id != agreementId)
                tupleOf(actions, obj.minimal)
            else
                tupleOf(actions, obj)
        }
        .flatMap { (actions, obj) ->
            when (obj) {
                is Object.Full -> successfulOf(tupleOf(actions, obj))
                is Object.Minimal ->
                    successfulOf(Unit)
                        .flatMap {
                            reduce(actions).getGroup(id = obj.group.id)
                                .map { tupleOf(it.first, it.second) }
                        }
                        .flatMap { (actions, group) ->
                            reduce(actions).findOutstandingAgreements(obj = obj, key = Key(obj.group.key.rawValue))
                                .accumulate(actions)
                                .map { tupleOf(it.first, group, it.second) }
                        }
                        .flatMap { (actions, group, agreements) ->
                            val currentAgreement = agreements.firstOrNull { it.id == agreementId } ?: return@flatMap failedOf(TechlaError.NotFound("Agreement not found"))
                            successfulOf(tupleOf(actions, group, agreements, currentAgreement))
                        }
                        .flatMap { (actions, group, agreements, currentAgreement) ->
                            reduce(actions).listTerms(obj = obj, agreementId = currentAgreement.id)
                                .accumulate(actions)
                                .map { tupleOf(it.first, group, agreements, currentAgreement, it.second) }
                        }
                        .flatMap { (actions, group, agreements, currentAgreement, terms) ->
                            reduce(actions).listResources(obj = obj)
                                .accumulate(actions)
                                .map { tupleOf(it.first, group, agreements, currentAgreement, terms, it.second.firstOrNull()) }
                        }
                        .flatMap { (actions, group, agreements, currentAgreement, terms, resource) ->
                            reduce(actions).bios(Bio.Batch(ids = group.members))
                                .accumulate(actions)
                                .map { tupleOf(it.first, group, agreements, currentAgreement, terms, resource, it.second) }
                        }
                        .flatMap { (actions, group, agreements, currentAgreement, terms, resource, bios) ->
                            reduce(actions).findSignatures(obj = obj, agreementId = currentAgreement.id, approvedOnly = true)
                                .accumulate(actions)
                                .map { tupleOf(it.first, group, agreements, currentAgreement, terms, resource, bios, it.second) }
                        }
                        .flatMap { (actions, group, agreements, currentAgreement, terms, resource, bios, signatures) ->
                            val profileId = this.profileId ?: return@flatMap failedOf(TechlaError.InternalServerError("Missing profile"))

                            val full = Object.Full(
                                min = Object.Min(
                                    id = obj.id,
                                    profileId = profileId,
                                    group = group,
                                    agreements = agreements,
                                ),
                                max = Object.Max(
                                    bios = bios,
                                    terms = terms,
                                    currentAgreement = currentAgreement,
                                    signatures = signatures,
                                    resource = resource!!,
                                )
                            )
                            val action = Store.Action.ReplaceObject(id = obj.id, obj = full)
                            successfulOf(actions + action, full)
                        }

                else ->
                    failedOf(TechlaError.InternalServerError("Requested missing object"))
            }
        }


suspend fun Store.refreshObjects(): ActionOutcome<List<Object>> =
    successfulOf(true).noActions()
        .flatMap { (actions, _) ->
            reduce(actions).myGroups()
                .accumulate(actions)
        }
        .flatMap { (actions, groups) ->
            val profileId = this.profileId ?: return@flatMap failedOf(TechlaError.InternalServerError("Missing profile"))

            val objects = groups.map {
                Object.Minimal(
                    min = Object.Min(
                        id = Identifier(it.id.rawValue),
                        profileId = profileId,
                        group = it,
                        agreements = emptyList(),
                    ),
                )
            }
            successfulOf(tupleOf(actions, objects))
        }
        .flatMap { (actions, objects) ->
            // This will not accumulate actions, but we should be OK
            val x = objects.map { obj ->
                reduce(actions).findOutstandingAgreements(obj = obj, key = Key(obj.group.key.rawValue))
                    .map { obj to it.second }
            }
            x.all()
                .map { tupleOf(actions, it) }
        }
        .map { (actions, pairs) ->
            val objects = pairs.map { (obj, agreements) ->
                obj.copy(min = obj.min.copy(agreements = obj.min.agreements + agreements))
            }
            tupleOf(actions, objects)
        }
        .flatMap { (actions, objects) ->
            reduce(actions).me()
                .accumulate(actions, objects)
        }
        .map { (actions, objects, me) ->
            val action = Store.Action.ObjectsRefreshed(me = me, objects = objects)
            tupleOf(actions + action, objects)
        }


suspend fun Store.createObject(name: String, visualization: Group.Visualization): ActionOutcome<Object.Minimal> =
    successfulOf(Key.random<Group>(20)).noActions()
        .flatMap { (actions, key) ->
            val profileId = this.profileId ?: return@flatMap failedOf(TechlaError.InternalServerError("Missing profile"))
            val group = Group.Create(
                name = name,
                key = key,
                status = Group.Status.None,
                visualization = visualization,
                join = true
            )
            reduce(actions).createGroup(create = group)
                .accumulate(actions)
                .map {
                    val minimal = Object.Minimal(
                        min = Object.Min(
                            id = Identifier(it.second.id.rawValue),
                            profileId = profileId,
                            group = it.second,
                            agreements = emptyList(),
                        )
                    )
                    tupleOf(it.first, key, minimal)
                }
        }
        .flatMap { (actions, key, minimal) ->
            val create = Agreement.Create(
                key = Key(key.rawValue),
                name = name,
                status = Agreement.Status.Draft,
            )
            reduce(actions).createAgreement(obj = minimal, create = create)
                .accumulate(actions)
                .map {
                    tupleOf(it.first, key, minimal.copy(min = minimal.min.copy(agreements = listOf(it.second))))
                }
        }
        .flatMap { (actions, key, minimal) ->
            val create = Resource.Create(
                key = Key(key.rawValue),
                name = name,
            )
            reduce(actions).createResource(obj = minimal, create)
                .accumulate(actions)
                .map {
                    tupleOf(it.first, key, minimal)
                }
        }

        .flatMap { (actions, _, minimal) ->
            reduce(actions).refreshObjects()
                .accumulate(actions)
                .map { (actions, _) -> tupleOf(actions, minimal) }
        }


suspend fun Store.editObject(obj: Object, edit: Object.Edit): ActionOutcome<Object.Minimal> =
    successfulOf(true).noActions()
        // Load full object
        .flatMap {
            refreshObject(objectId = obj.id, agreementId = obj.agreements.first().id)
        }
        .flatMap { (actions, full) ->
            val profileId = this.profileId ?: return@flatMap failedOf(TechlaError.InternalServerError("Missing profile"))
            val group = Group.Edit(
                name = edit.name
            )
            reduce(actions).editGroup(obj = obj, edit = group)
                .accumulate(actions)
                .map {
                    val minimal = Object.Minimal(
                        min = Object.Min(
                            id = Identifier(it.second.id.rawValue),
                            profileId = profileId,
                            group = it.second,
                            agreements = emptyList(),
                        )
                    )
                    tupleOf(it.first, full, minimal)
                }
        }
        .flatMap { (actions, full, minimal) ->
            val agreement = Agreement.Edit(
                name = edit.name,
            )
            reduce(actions).editAgreement(obj = minimal, id = full.currentAgreement.id, edit = agreement)
                .accumulate(actions)
                .map {
                    tupleOf(it.first, full, minimal)
                }
        }
        .flatMap { (actions, full, minimal) ->
            val resource = Resource.Edit(
                name = edit.name,
            )
            reduce(actions).editResource(obj = minimal, id = full.resource.id, edit = resource)
                .accumulate(actions)
                .map {
                    tupleOf(it.first, full, minimal)
                }
        }
        .flatMap { (actions, _, minimal) ->
            reduce(actions).refreshObjects()
                .accumulate(actions)
                .map { (actions, _) -> tupleOf(actions, minimal) }
        }


suspend fun Store.deleteObject(obj: Object): ActionOutcome<Unit> =
    successfulOf(Unit).noActions()
        .flatMap { (actions, _) ->
            if (obj is Object.Full) {
                reduce(actions).deleteAgreement(obj = obj, id = obj.currentAgreement.id)
                    .accumulate(actions)
            } else {
                successfulOf(Unit).noActions()
            }
        }
        .flatMap { (actions, _) ->
            if (obj is Object.Full) {
                reduce(actions).deleteResource(obj = obj, id = obj.resource.id)
                    .accumulate(actions)
            } else {
                successfulOf(Unit).noActions()
            }
        }
        .flatMap { (actions, _) ->
            reduce(actions).deleteGroup(obj = obj)
                .accumulate(actions)
        }
        .flatMap { (actions, _) ->
            reduce(actions).refreshObjects()
                .accumulate(actions)
                .map { (actions, _) -> tupleOf(actions, Unit) }
        }


suspend fun Store.createDraft(obj: Object): ActionOutcome<Unit> =
    successfulOf(Unit).noActions()
        // Load full object
        .flatMap {
            refreshObject(objectId = obj.id, agreementId = obj.agreements.first().id)
        }
        // Create new agreement based on object
        .flatMap { (actions, obj) ->
            val create = Agreement.Create(
                key = Key(obj.key.rawValue),
                name = obj.name,
                status = Agreement.Status.Draft,
                version = obj.agreements.first().version + 1,
            )
            reduce(actions).createAgreement(obj = obj, create = create)
                .accumulate(actions)
                .map { tupleOf(it.first, obj, it.second) }
        }
        // Copy terms, terms will be added to the agreement with matching key and highest version
        .flatMap { (actions, obj, agreement) ->
            obj.terms.map {
                val create = Term.Create(
                    agreement = agreement.key,
                    key = it.key,
                    name = it.name,
                    clauses = it.clauses,
                )
                reduce(actions).createTerm(obj = obj, create = create)
                    .accumulate(actions)
            }.all2()
                .map { tupleOf(it.first, obj, agreement, it.second) }
        }
        // Reload full object
        .flatMap { (actions, obj, agreement, _) ->
            reduce(actions).refreshObject(objectId = obj.id, agreementId = agreement.id)
                .accumulate(actions)
        }
        .flatMap { (actions, obj) ->
            reduce(actions).editAgreementContent(obj = obj)
                .accumulate(actions)
        }
        .flatMap { (actions, _) ->
            reduce(actions).refreshObjects()
                .accumulate(actions)
                .map { (actions, _) -> tupleOf(actions, Unit) }
        }
