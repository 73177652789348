package bootstrap

import csstype.ClassName
import react.*

@JsNonModule
@JsModule("react-bootstrap/InputGroup")
private external val inputGroupModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bInputGroupText: ComponentType<InputGroupTextProps> = inputGroupModule.default.Text

external interface InputGroupTextProps : PropsWithChildren {
    var className: ClassName
}