package della8.web.components

import bootstrap.bContainer
import della8.core.support.DesignSystem
import della8.web.support.className
import della8.web.theme.Design
import lottie.lPlayer
import react.*
import react.dom.html.ReactHTML

external interface ProgressProps : PropsWithChildren {
    var viewModel: DesignSystem.Progress
}


val D8Progress = FC<ProgressProps>("D8Progress") { props ->
    bContainer {
        className = className(
            listOfNotNull("px-5 d-flex h-75 align-items-center flex-column justify-content-center")
        )
        ReactHTML.div {
            className = className(
                listOfNotNull("n-spinner d-flex justify-content-center")
            )
            lPlayer {
                animationData = Design.animation(DesignSystem.Animation.SPINNER)
                loop = true
                autoplay = true
            }
        }
        d8Text(className = "mt-1 ms-2 text-center d-flex", design = props.viewModel.info)
    }
}


fun ChildrenBuilder.d8Progress(viewModel: DesignSystem.Progress) =
    D8Progress {
        this.viewModel = viewModel
    }
