package della8.core.screens


import della8.core.services.acceptInvite
import della8.core.services.bios
import della8.core.support.*
import techla.base.*
import techla.conversation.Message
import techla.guard.Invite
import techla.personal.Bio

object RedeemCodeScreen {
    object Header {
        val code = DesignSystem.Header(id = "code")
    }

    data class Texts(
        val title: String,
        val back: String,
        val submit: String,
        val body: String,
        val code: String,
        val codeRequired: String,
        val codeInvalid: String,
        override val successTitle: String,
        override val successInfo: String,
        override val successNext: String,
        override val progressInfo: String,
        override val failureTitle: String,
    ) : ProgressTexts, FailureTexts, SuccessTexts {
        companion object
    }

    data class State(
        val message: Message?,
        val code: String?,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(null, ""),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val progress: DesignSystem.Progress,
        ) : ViewModel(texts, state, navigation)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val title: DesignSystem.Text,
            val image: DesignSystem.ImageView,
            val body: DesignSystem.Text,
            val code: DesignSystem.TextInput,
            val submit: DesignSystem.Button,
            val status: DesignSystem.Status,
        ) : ViewModel(texts, state, navigation)

        data class Accepted(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val success: DesignSystem.Success,
        ) : ViewModel(texts, state, navigation)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state, navigation)

        fun loading(texts: Texts) = Loading(
            texts = texts, state = state,
            navigation = DesignSystem.Navigation.progress(),
            progress = progress(texts = texts),
        )

        fun ready(
            texts: Texts,
            state: State,
            status: List<Pair<DesignSystem.Header, DesignSystem.Status>> = emptyList()
        ) =
            Ready(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.backLight(title = texts.back, location = Location.Landing),
                image = DesignSystem.ImageView(image = DesignSystem.Image.CODE, alt = texts.title),
                title = DesignSystem.Text(
                    text = texts.title,
                    style = DesignSystem.TextStyle.TITLE1
                ),
                body = DesignSystem.Text(
                    text = texts.body,
                    style = DesignSystem.TextStyle.BODY,
                ),
                code = DesignSystem.TextInput(
                    header = Header.code,
                    title = texts.code,
                    value = state.code,
                    input = DesignSystem.Input.TEXT,
                    status = status.statusOf(Header.code)
                ),
                submit = DesignSystem.Button(title = texts.submit),
                status = status.overallStatus(),
            )

        fun accepted(texts: Texts, state: State) = Accepted(
            texts = texts,
            state = state,
            navigation = navigation,
            success = success(texts = object : SuccessTexts {
                override val successTitle = texts.successTitle
                override val successNext = texts.successNext
                override val successInfo = texts.successInfo
            })
        )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false) =
            Failed(
                texts = texts,
                state = state,
                navigation = DesignSystem.Navigation.failure,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String) =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoad get() = this as? Loading
        val ready get() = this as? Ready
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.invalid() =
        sceneOf(viewModel.failed(Either.Right(TechlaError.Unauthorized("Session invalid")), true))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    private fun Scene.Input<ViewModel>.failed(message: String) =
        sceneOf(viewModel.failed(message))


    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isValid) return scene.invalid()

        return successfulOf(true)
            .map {
                val texts = Texts(
                    title = "Lös in en kod",
                    back = "Mina objekt",
                    body = "Om koden är giltig så kommer du tillbaka till startsidan och knutet objekt kommer att bli tillgängligt.",
                    code = "Kod",
                    submit = "Lös in",
                    codeRequired = "Kod måste anges",
                    codeInvalid = "Koden är inte giltig",
                    progressInfo = "Laddar...",
                    failureTitle = "Oj, ett fel har uppstått",
                    successNext = "OK",
                    successTitle = "Giltig kod",
                    successInfo = "Objektet <PLACEHOLDER> har nu dykt upp under Mina objekt. Klicka på OK för att ta dig dit.",
                )
                sceneOf<ViewModel>(viewModel.loading(texts = texts))
            }
            .failed { scene.failed(result = it) }
    }

    fun update(scene: Scene.Input<ViewModel>, code: String?): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        if (viewModel !is ViewModel.Ready) return scene.failed("Can't update values if not Ready")

        val state = viewModel.state.copy(code = code ?: viewModel.state.code)

        return sceneOf(viewModel.ready(texts = viewModel.texts, state = state))
    }

    suspend fun load(
        scene: Scene.Input<ViewModel>,
    ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return successfulOf(store.profileId ?: Identifier())
            .flatMap { profile ->
                val batch = Bio.Batch(ids = listOf(profile))
                store.bios(batch)
                    .map { tupleOf(it.first, it.second) }
            }
            .map { (actions, bios) ->
                sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = viewModel.state), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun check(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val status: MutableList<Pair<DesignSystem.Header, DesignSystem.Status>> = mutableListOf()
        var state = viewModel.state

        Validator.text(
            text = state.code,
            isEmpty = { status.add(Header.code to DesignSystem.Status.Invalid(warning = viewModel.texts.codeRequired)) },
            formatted = { state = state.copy(code = it) },
            passed = { status.add(Header.code to DesignSystem.Status.Valid) },
        )

        if (status.overallStatus() !is DesignSystem.Status.Valid)
            return sceneOf<ViewModel>(viewModel.ready(texts = viewModel.texts, state = state, status = status))

        val accept = Invite.Accept(invite = Key(viewModel.state.code ?: ""))

        return store.acceptInvite(accept = accept)
            .flatMap { (actions) ->
                store.reduce(actions).refreshObjects().map {
                    val previousObjects = store.objects?.map { it.id } ?: emptyList()
                    val newObject = it.second.singleOrNull { !previousObjects.contains(it.id) }
                    tupleOf(actions + it.first, newObject)
                }
            }
            .map { (actions, newObject) ->
                val texts = viewModel.texts.copy(successInfo = viewModel.texts.successInfo.replace("<PLACEHOLDER>", newObject?.name ?: ""))
                sceneOf<ViewModel>(viewModel.accepted(texts = texts, state = viewModel.state), actions)
            }
            .failed {
                if (it.rightOrNull() is TechlaError.BadRequest) {
                    return sceneOf(viewModel.ready(texts = viewModel.texts, state = viewModel.state, status = listOf(Header.code to DesignSystem.Status.Invalid(warning = viewModel.texts.codeInvalid))))
                }
                scene.failed(result = it)
            }
    }

    suspend fun success(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return load(scene = scene)
    }

    fun logout(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit)
            .map {
                sceneOf<ViewModel>(ViewModel.None, Store.Action.Logout)
            }
            .failed { scene.failed(result = it) }
    }
}


