package della8.core.services

import della8.core.support.ActionOutcome
import della8.core.support.Store
import techla.base.*
import techla.content.*

suspend fun Store.findMedias(tags: List<Key<Tag>>? = null): ActionOutcome<List<Media>> {
    val api = contentAPI
    return ContentEndpoint.findMedias(api, tags)
        .map { listOf<Store.Action>(Store.Action.MediasLoaded(it)) to it }
}

/*
suspend fun Store.listShortLinks(): ActionOutcome<List<ShortLink>> {
    val api = contentAPI
    return ContentEndpoint.listShortLinks(api)
        .map { listOf<Store.Action>(Store.Action.ShortLinkLoaded(it)) to it }
}
 */


val Store.contentAPI
    get() =
        ContentAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) ContentAPI.sandbox else ContentAPI.shared
            api.token = userToken ?: applicationToken
        }

object ContentEndpoint {
    var findMedias: suspend (api: ContentAPI, tags: List<Key<Tag>>?) -> Outcome<List<Media>> = { api, tags ->
        measureAPI(ContentResource.FindMedias(tags = tags, languages =  null, keys = null), api) {
            api.findMedias(tags = tags)
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
  /*  var listShortLinks: suspend (api: ContentAPI) -> Outcome<List<ShortLink>> = { api ->
        measureAPI(ContentResource.ListShortLinks, api) {
            api.listShortLinks()
                .onNotSuccess { techla_log("WARN: $it") }
        }
    }
   */


}