package della8.core.support

import kotlinx.datetime.internal.JSJoda.Locale

@JsModule("@js-joda/locale_sv")
@JsNonModule
external val JsJodaLocaleSvModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val svLocale: Locale = JsJodaLocaleSvModule.Locale.SWEDISH
