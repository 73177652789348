package della8.core.admin

import della8.core.services.findStatistics
import della8.core.support.*
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.minus
import techla.base.*
import techla.control.Event
import techla.guard.Application

val Application.Companion.DELLA8: Key<Application> get() = Key("del")

val Event.Interval.date
    get() =
        when (this) {
            is Event.Interval.Monthly -> "$year-${monthNumber.toString().padStart(2, '0')}"
            is Event.Interval.Daily -> "$year-${monthNumber.toString().padStart(2, '0')}-${dayOfMonth.toString().padStart(2, '0')}"
            else -> ""
        }

val Event.Statistic.text
    get() =
        when (category) {
            is Event.Category.ApplicationAuthentication -> "Besök: **$count**"
            is Event.Category.Message -> "Lästa artiklar: **$count**"
            is Event.Category.Submission -> "Intresseanmälningar: **$count**"
            is Event.Category.GroupMember -> "Samägarskap: **$count**"
            else -> ""
        }


object DashboardScreen {
    data class Texts(
        val back: String,
    ) {
        companion object
    }

    sealed class ViewModel(open var texts: Texts, open val navigation: DesignSystem.Navigation) {
        object None : ViewModel(
            texts = Texts(""),
            navigation = DesignSystem.Navigation.minimal,
        )

        data class Loading(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
        ) : ViewModel(texts, navigation) {
            companion object
        }

        data class Ready(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val body: DesignSystem.Text,
            val footer: DesignSystem.Footer
        ) : ViewModel(texts, navigation) {
            companion object
        }

        data class Failed(
            override var texts: Texts,
            override val navigation: DesignSystem.Navigation,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, navigation) {
            companion object
        }

        fun loading(): ViewModel =
            Loading(texts = texts, navigation = navigation)

        fun ready(texts: Texts, statistics: String): ViewModel =
            Ready(
                texts = texts,
                navigation = DesignSystem.Navigation.backLight(
                    title = texts.back,
                    location = Location.Landing
                ),
                body = DesignSystem.Text(
                    text = statistics,
                    style = DesignSystem.TextStyle.BODY,
                    isMarkdown = true,
                ),
                footer = DesignSystem.Footer.front(),
            )

        fun failed(message: String): ViewModel =
            Failed(
                texts = texts,
                failure = DesignSystem.Failure(
                    title = DesignSystem.Text(text = "Något gick fel", style = DesignSystem.TextStyle.TITLE1),
                    details = DesignSystem.Text(text = message, style = DesignSystem.TextStyle.BODY)
                ),
                navigation = DesignSystem.Navigation.failure,
            )

        fun failed(result: Either<List<Warning>, Throwable>): ViewModel =
            failed(
                message = when (result) {
                    is Either.Left -> "(${result.value.joinToString(", ") { it.message }})"
                    is Either.Right -> "(${result.value.message})"
                }
            )

        val asLoad get() = this as? Loading
        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return sceneOf(viewModel.loading())
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val intervals = createIntervals()
        val actions = listOf(Event.Action.Created, Event.Action.Read)
        val categories = listOf(
            Event.Category.ApplicationAuthentication,
            Event.Category.Message,
            Event.Category.Submission,
            Event.Category.GroupMember,
        )

        return store.findStatistics(intervals = intervals, actions = actions, categories = categories, timeZone = "Europe/Stockholm", locale = "sv-SE", application = Application.DELLA8)
            .map { (actions, statistics) ->
                val updated = store.reduce(actions = actions)
                val texts = Texts(
                    back = updated.get(media = Key("screen:policy"), content = Key("back")),
                )

                val filtered = statistics.filter { statistic ->
                    (statistic.action == Event.Action.Created && statistic.category == Event.Category.ApplicationAuthentication) ||
                            (statistic.action == Event.Action.Created && statistic.category == Event.Category.Submission) ||
                            (statistic.action == Event.Action.Read && statistic.category == Event.Category.Message) ||
                            (statistic.action == Event.Action.Created && statistic.category == Event.Category.GroupMember)
                }
                val total = filtered.filter { it.interval is Event.Interval.Total }
                val daily = filtered.filter { it.interval is Event.Interval.Daily }
                val monthly = filtered.filter { it.interval is Event.Interval.Monthly }
                val markdown = buildString {
                    append("## Totalt\n")
                    total.forEach {
                        append("${it.text}\n")
                    }
                    append("## Dagligen\n")
                    daily.sortedBy { it.interval.date }.reversed().groupBy { it.interval.date }.forEach { entry ->
                        append("**${entry.key}**\n")
                        entry.value.reversed().forEach {
                            append("${it.text}\n")
                        }
                        append("\n")
                    }
                    append("## Månatligen\n")
                    monthly.sortedBy { it.interval.date }.reversed().groupBy { it.interval.date }.forEach { entry ->
                        append("**${entry.key}**\n")
                        entry.value.reversed().forEach {
                            append("${it.text}\n")
                        }
                        append("\n")
                    }
                }
                sceneOf(viewModel.ready(texts = texts, statistics = markdown))
            }
            .failed { scene.failed(result = it) }
    }

    private fun createIntervals(): List<Event.Interval> {
        val today = Date.now().dateTime.date

        val total = listOf<Event.Interval>(Event.Interval.Total)
        val last6Month = (0..5).map {
            val d = today.minus(it, DateTimeUnit.MONTH)
            Event.Interval.Monthly(year = d.year, monthNumber = d.monthNumber)
        }.reversed()
        val last7Days = (0..6).map {
            val d = today.minus(it, DateTimeUnit.DAY)
            Event.Interval.Daily(year = d.year, monthNumber = d.monthNumber, dayOfMonth = d.dayOfMonth)
        }.reversed()

        return total + last6Month + last7Days
    }
}