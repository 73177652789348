package della8.core.screens

import della8.core.support.*
import techla.base.*

object ClippyScreen {
    object Header {
        val partnership = DesignSystem.Header(id = "partnership")
    }

    data class Texts(
        val title: String,
        override val failureTitle: String,
    ) : FailureTexts

    enum class Partnership {
        ADMIN, NORMAL;

        val option
            get() =
                when (this) {
                    ADMIN -> DesignSystem.Option.item(title = "Kom igång Admin/samägare", value = "admin", data = this)
                    NORMAL -> DesignSystem.Option.item(title = "Kom igång samägare", value = "normal", data = this)
                }

        val faq
            get() =
                when (this) {
                    ADMIN -> listOf(
                        "Lägg till objekt" to "Lägg enkelt till ditt objekt genom att trycka på lägg till objekt. Här kan du ställa in namnet på ert objekt och välja en av våra läckra bilder.",
                        "Ställ in avtalsregler" to "Nu är det dags för det roliga! Ställ in avtalsregler för ert specifika objekt. Har ni redan regler kan ni hoppa över denna. För att förtydliga vissa regler finns ett info-i som ni kan klicka på för att få mer information och om ni vill hoppa över någon fråga i ert avtal kan ni enkelt stänga av det genom att trycka på ögat som då döljer regeln i avtalet.",
                        "Ställ in bokningsregler" to "Ställ in bokningsregler och kom överens om era guldveckor. Guldveckor är det vi kallar attraktiva veckor som kan vara mer populära och som det är viktigt att ni känner fördelas rättvist. Det varierar såklart beroende på vilket objekt ni har. Här kan ni också gemensamt komma överens om vad en kort- respektive långbokning är och vill ni göra olika regler för dessa veckor så kan ni skriva ner det här. Även här finns ett info-i för mer information och ett öga som ni kan trycka på för att dölja en viss regel.",
                        "Signera avtal" to "När du känner dig klar är det bara att signera avtalet med BankID. Kom ihåg att du kan ändra i ditt avtal senare.",
                        "Bjud in samägare" to "Äntligen dags att bjuda in resterande samägare! Nu delar du koden till ert objekt och denna kod löser samägarna sedan in på hemsidan när de har loggat in med mobilt BankID.",
                        "Samägare signera avtal" to "Låt alla samägare läsa igenom och enkelt signera med BankID.",
                        "Kör i vind!" to "När alla har signerat är det dags för dig att trycka på GO. Nu kör vi, mot ett krångelfritt och tryggt samägande! Lycka till!",
                    )

                    NORMAL -> listOf(
                        "Lös in kod" to "Du får en kod skickad till dig från er admin och den kopierar du enkelt in under Lös in kod. Om koden är giltig så kommer du tillbaka till startsidan och ert objekt kommer nu bli tillgängligt.",
                        "Läs igenom avtalet" to "Läs igenom avtalet som ni tillsammans har kommit överens om.",
                        "Signera avtal" to "När du känner dig redo är det bara att signera avtalet med BankID",
                        "Kör i vind!" to "När admin trycker kör i vind är det bara att tuta och köra! Lycka till!",
                    )
                }
    }

    data class State(
        val partnership: Partnership = Partnership.ADMIN
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {
        object None : ViewModel(
            texts = Texts("", ""),
            state = State(),
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val movie: DesignSystem.MovieView,
            val title: DesignSystem.Text,
            val partnership: DesignSystem.SelectInput,
            val faq: DesignSystem.FAQ,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val failure: DesignSystem.Failure,
        ) : ViewModel(texts, state)

        fun loading(): ViewModel = Loading(texts = texts, state = state)

        fun ready(texts: Texts? = null, state: State): ViewModel =
            Ready(
                texts = (texts ?: this.texts),
                state = state,
                movie = DesignSystem.MovieView(
                    movie = DesignSystem.Movie.INTRODUCTION,
                    loop = false,
                    muted = false,
                    showControls = true,
                    player = DesignSystem.MoviePlayer.MUX,
                ),
                title = DesignSystem.Text(text = (texts ?: this.texts).title, style = DesignSystem.TextStyle.TITLE1),
                partnership = DesignSystem.SelectInput(
                    header = Header.partnership,
                    options = listOf(Partnership.ADMIN.option, Partnership.NORMAL.option),
                    selected = state.partnership.option,
                    style = DesignSystem.SelectStyle.SWITCH,
                ),
                faq = DesignSystem.FAQ(contents = state.partnership.faq),
            )

        fun failed(failure: Either<List<Warning>, Throwable>, automaticLogout: Boolean = false): ViewModel =
            Failed(
                texts = texts,
                state = state,
                failure = failure(texts = texts, failure = failure, automaticLogout = automaticLogout),
            )

        fun failed(message: String): ViewModel =
            failed(Either.Right(TechlaError.InternalServerError(message)))

        val asLoading get() = this as? Loading
        val asReady get() = this as? Ready
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return sceneOf(viewModel.loading())
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(1).noActions()
            .map { (actions, _) ->
                val texts = Texts(
                    title = "Kom igång",
                    failureTitle = "Oj, ett fel har uppstått",
                )

                val state = viewModel.state
                sceneOf(viewModel.ready(texts, state), actions)
            }
            .failed { scene.failed(result = it) }
    }

    fun update(scene: Scene.Input<ViewModel>, partnership: DesignSystem.Option?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        viewModel as ViewModel.Ready

        val state = viewModel.state.copy(
            partnership = (partnership?.data as? Partnership) ?: viewModel.state.partnership,
        )

        return sceneOf(viewModel.ready(state = state))
    }
}

