package della8.core.support

object Mustache {
    fun parse(value: String, replacements: Map<String, String>): String {
        var result = value
        replacements.forEach { entry ->
            result = result.replace("{{${entry.key}}}", entry.value)
        }
        return result
    }
}