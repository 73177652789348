package bootstrap


import csstype.ClassName
import react.*

@JsNonModule
@JsModule("react-bootstrap/Form")
private external val formModule: dynamic

@Suppress("UnsafeCastFromDynamic")
val bFormLabel: ComponentType<FormLabelProps> = formModule.default.Label

external interface FormLabelProps : PropsWithChildren {
    var column: dynamic /* boolean | 'sm' | 'lg' */
    var htmlFor: String
    var srOnly: Boolean
    var bsPrefix: String
    var className: ClassName?
}
