package della8.web.views

import StoreContext
import bootstrap.*
import della8.core.screens.*
import della8.core.support.DesignSystem
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.*
import kotlinx.js.get
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import react.router.useParams
import techla.base.Identifier

val PartnershipView = FC<PropsWithChildren>("PartnershipView") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(PartnershipScreen.ViewModel.None as PartnershipScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()


    val scopedCall = scopedCall<PartnershipScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }
    val call = call<PartnershipScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    val mainCall = mainCall<PartnershipScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }



    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is PartnershipScreen.ViewModel.None -> {
                scopedCall(coroutineScope) { PartnershipScreen.start(sceneInputOf(store, viewModel)) }
            }
            is PartnershipScreen.ViewModel.Loading -> {
                params["objectId"]?.let { objectId ->
                    params["agreementId"]?.let { agreementId ->
                        scopedCall(coroutineScope) { PartnershipScreen.load(sceneInputOf(store, viewModel), Identifier(objectId), Identifier(agreementId)) }
                    }
                }
            }
            else -> {
            }
        }
    }

    fun handleGoTo(path: String) {
        navigate("/object/${params["objectId"]}/${params["agreementId"]}/${path}")
    }

    fun logout() {
        mainCall { PartnershipScreen.logout(sceneInputOf(store, viewModel)) }
        navigate("/")
    }
    //d8Button(design = viewModel.settings, onClick = { handleGoTo("show/partnership/rules") })
    //d8Button(design = viewModel.help, onClick = { handleGoTo("partnership/help") })
    main {
        when (viewModel) {
            is PartnershipScreen.ViewModel.Ready -> d8Navigation(
                design = viewModel.navigation,
                className = "nav-top",
                onClick = { action ->
                    when (action) {
                        DesignSystem.Action.BACK -> navigate(-1)
                        else -> {}
                    }
                })
            else -> d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))
        }

        if (viewModel is PartnershipScreen.ViewModel.Ready) {
            bContainer {
                className = className("px-5 position-relative n-bottom-space n-top-space")
                bRow {
                    className = className("pb-4 justify-content-center")

                    bCol {
                        xs = 12; md = 10
                        bContainer {
                            className = className("py-3 px-0")
                            d8Text(className = "d-inline", design = viewModel.title)
                            div {
                                className = className("float-end")
                                d8Button(design = viewModel.settings, onClick = { handleGoTo("show/partnership/rules") })
                                d8Button(design = viewModel.help, onClick = { handleGoTo("partnership/help") })
                            }
                        }
                        bContainer {
                            className = className("px-5 n-white-bg-box text-center")
                            d8Image(className = "pt-4 img-fluid", design = viewModel.image)
                            bRow {
                                className = className("py-3")
                                d8Text(className = "text-center", design = viewModel.name)
                                bCard {
                                    className = className("radius-10 border-0 card-shadow my-3 px-4 py-3 d8-marine-bg-opacity-2")
                                    d8Text(className = "text-left", design = viewModel.body)
                                }
                            }
                        }
                    }
                }
            }
        }

        if (viewModel is PartnershipScreen.ViewModel.Loading) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is PartnershipScreen.ViewModel.Failed) {
            d8failure(viewModel.failure, ::logout)
        }
    }
}
