package della8.web.views

import StoreContext
import bootstrap.bCol
import bootstrap.bContainer
import bootstrap.bRow
import della8.core.screens.CreateScreen
import della8.core.screens.LandingScreen
import della8.core.support.DesignSystem
import della8.core.support.Object
import della8.core.support.sceneInputOf
import della8.web.components.*
import della8.web.support.*
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.main
import react.router.useNavigate
import techla.agreement.Agreement
import techla.base.Identifier
import techla.base.techla_log


val Create = FC<PropsWithChildren>("Create") {
    val (store, dispatch) = useContext(StoreContext)
    val (viewModel, setViewModel) = useState(CreateScreen.ViewModel.None as CreateScreen.ViewModel)
    val (viewModelLanding, setViewModelLanding) = useState(LandingScreen.ViewModel.None as LandingScreen.ViewModel)
    val navigate = useNavigate()

    val scopedCall = scopedCall<CreateScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    val mainCall = mainCall<CreateScreen.ViewModel> { (viewModel, actions) ->
        setViewModel(viewModel)
        dispatch(actions)
    }

    fun nameChange(value: String) {
        mainCall { CreateScreen.update(sceneInputOf(store, viewModel), name = value, visualization = null) }
    }

    fun visualizationChange(value: DesignSystem.Option) {
        mainCall { CreateScreen.update(sceneInputOf(store, viewModel), name = null, visualization = value) }
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is CreateScreen.ViewModel.None ->
                scopedCall(coroutineScope) { CreateScreen.start(sceneInputOf(store, viewModel)) }

            is CreateScreen.ViewModel.Loading ->
                scopedCall(coroutineScope) { CreateScreen.load(sceneInputOf(store, viewModel)) }

            is CreateScreen.ViewModel.Ready -> {
                techla_log("READY")
            }
            is CreateScreen.ViewModel.Creating -> {
                techla_log("CREATING")
                scopedCall(coroutineScope) { CreateScreen.create(sceneInputOf(store, viewModel)) }
            }
            is CreateScreen.ViewModel.Created -> {
                techla_log("CREATED ")
            }
            is CreateScreen.ViewModel.Failed ->
                techla_log("FAILED  ")
        }
    }

    suspend fun handleNext() {
        mainCall { CreateScreen.validate(sceneInputOf(store, viewModel)) }
    }

    fun handleCancel() {
        LandingScreen.start(sceneInputOf(store, viewModelLanding))
            .also { (viewModel, actions) ->
                setViewModelLanding(viewModel)
                dispatch(actions)
                navigate("/landing")
            }
    }

    fun handleDone(objectId: Identifier<Object>, agreementId: Identifier<Agreement>) {
        navigate("/object/${objectId.rawValue}/${agreementId.rawValue}")
    }

    fun handleLogout() {
        mainCall { CreateScreen.logout(sceneInputOf(store, viewModel)) }
    }

    main {
        when (viewModel) {
            is CreateScreen.ViewModel.Ready -> d8Navigation(design = viewModel.navigation, onClick = { action ->
                when (action) {
                    DesignSystem.Action.LOGOUT -> {}
                    DesignSystem.Action.BACK -> navigate("/landing")
                    else -> {}
                }
            })
            is CreateScreen.ViewModel.Created -> d8Navigation(design = viewModel.navigation, onClick = { action ->
                when (action) {
                    DesignSystem.Action.LOGOUT -> {}
                    else -> {}
                }
            })
            else -> d8Navigation(design = viewModel.navigation, onClick = standardNavigation(navigate))

        }
        if (viewModel is CreateScreen.ViewModel.Ready) {
            bContainer {
                className = className("px-5 n-top-space")
                bRow {
                    className = className("justify-content-center")

                    bCol {
                        xs = 12; md = 6
                        d8Text(className = "text-center", design = viewModel.title)
                        d8TextInput(
                            classNameInput = "mb-3",
                            design = viewModel.name,
                            onChange = ::nameChange
                        )
                        d8MovieInput(
                            design = viewModel.visualization,
                            onChange = ::visualizationChange
                        )
                        div {
                            className = className("d-grid")
                            d8Button(
                                className = "btn-lg mt-3",
                                design = viewModel.next,
                                onClick = ::handleNext
                            )
                        }
                        div {
                            className = className("d-grid")
                            d8Button(
                                className = "btn-lg mt-3",
                                design = viewModel.cancel,
                                onClick = ::handleCancel
                            )
                        }
                    }
                }
            }
            D8Clippy { this.design = viewModel.clippy }
        }

        if (viewModel is CreateScreen.ViewModel.Created) {
            bContainer {
                className = className("px-5 n-top-space")

                bRow {
                    className = className("justify-content-center")

                    bCol {
                        xs = 12; md = 6
                        d8Icon(className = "d-block mx-auto", design = viewModel.icon)
                        d8Text(className = "text-center", design = viewModel.title)
                        d8Text(className = "text-center", design = viewModel.info)
                    }
                }
                bRow {
                    bCol {
                        className = className("p-0 pb-4 d-flex justify-content-center")
                        d8Button(
                            className = "btn-lg mt-3 px-5",
                            design = viewModel.done,
                            onClick = { handleDone(viewModel.objectId, viewModel.agreementId) }
                        )
                    }
                }
            }
        }

        if (viewModel is CreateScreen.ViewModel.Loading) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is CreateScreen.ViewModel.Creating) {
            d8Progress(viewModel.progress)
        }

        if (viewModel is CreateScreen.ViewModel.Failed) {
            bContainer {
                className = className("px-5 n-top-space")

                d8failure(viewModel.failure, ::handleLogout)
            }
        }
    }
}
