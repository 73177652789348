package della8.core.support

object Validator {
    fun <T> notNull(
        value: T?,
        isEmpty: () -> Unit,
        formatted: (T) -> Unit,
        passed: () -> Unit,
    ) {
        // Validate
        when {
            value == null -> isEmpty()
            else -> {
                formatted(value)
                passed()
            }
        }
    }

    fun text(
        text: String?,
        isEmpty: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Filter

        // Validate
        when {
            text.isNullOrEmpty() -> isEmpty()
            else -> {
                formatted(text)
                passed()
            }
        }
    }

    fun govId(
        govId: String?,
        illegalCharacters: () -> Unit,
        isEmpty: () -> Unit,
        isWrongLength: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Filter
        var filtrered = govId?.filter { "0123456789".contains(it) } ?: ""
        val filtrered2 = govId?.filter { "0123456789-".contains(it) } ?: ""

        // Validate
        when {
            filtrered2 != govId -> illegalCharacters()
            filtrered.isEmpty() -> isEmpty()
            filtrered.length != 10 && filtrered.length != 12 -> isWrongLength()
            else -> passed()
        }

        // Format
        if (filtrered.length == 10) {
            val year = filtrered.dropLast(8).toIntOrNull() ?: 70
            filtrered = if (year > 20) "19$filtrered" else "20$filtrered"
        }

        formatted(filtrered)
    }

    fun email(
        email: String?,
        isWrongFormat: () -> Unit,
        isEmpty: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Validate
        when {
            email.isNullOrEmpty() -> isEmpty()
            email.firstOrNull { it == '@' } == null -> isWrongFormat()
            else -> passed()
        }

        formatted(email?.lowercase() ?: "")
    }

    fun postalCode(
        postalCode: String?,
        illegalCharacters: () -> Unit,
        isEmpty: () -> Unit,
        isWrongLength: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Filter
        val filtrered = postalCode?.filter { "0123456789".contains(it) } ?: ""

        // Validate
        when {
            filtrered != postalCode -> illegalCharacters()
            filtrered.isEmpty() -> isEmpty()
            filtrered.length != 5 -> isWrongLength()
            else -> passed()
        }

        formatted(filtrered)
    }

    fun phone(
        phone: String?,
        illegalCharacters: () -> Unit,
        isEmpty: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Filter
        val filtered = phone?.filter { "0123456789-+ ".contains(it) } ?: ""

        // Validate
        when {
            filtered != phone -> illegalCharacters()
            filtered.isEmpty() -> isEmpty()
            else -> passed()
        }

        formatted(filtered)
    }
}